import React from 'react'

const PasswordFeedback = props => {
	if(Object.keys(props).length < 4) return null
	if(props.lowercase && props.uppercase && props.number && props.special && props.length) return null
	return <React.Fragment>
		<p>Your password needs to contain:</p>
		<span className={`pass-validation ${props.lowercase ? "valid": "invalide"}`}>A <b>lowercase</b> letter</span><br/>
		<span className={`pass-validation ${props.uppercase ? "valid": "invalide"}`}>A <b>capital (uppercase)</b> letter</span><br/>
		<span className={`pass-validation ${props.number ? "valid": "invalide"}`}>A <b>number</b></span><br/>
		<span className={`pass-validation ${props.special ? "valid": "invalide"}`}>A <b>special</b> character (such as: !@#\$%\^&*)</span><br/>
		<span className={`pass-validation ${props.length ? "valid": "invalide"}`}>Minimum <b>8 characters</b></span>
	</React.Fragment>
}

export default PasswordFeedback