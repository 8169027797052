const config = {
    version: 'v2.7.3',
    currentEnv: 'prod', // TODO: change into prod
    serverUrl: 'https://memoryhome.weill.cornell.edu/',
    autosave: 8000,
    feedbackFade: 3000,
    minJournalEntry: 100,
    minWritingTime: 1000*5*60,
    paths: {
        index: '/',
        auth: '/login',
        dashboard: '/dashboard',
        memorial: '/mainApp',
        config: '/config',
        error: '/error',
        resources: '/resources',
        diary: '/diary'
    },
    surveys: {
        DEMO: {
            surveyKey: 'DEMO', // used to retrieve survey questions using SurveyHelper
            questionKey: 'DEMO', // used to filter question columns within database entries
            dbKey: 'DEMO' // used to find this specific survey table in the database
        },
        PG13: {
            surveyKey: 'PG13',
            questionKey: 'PG',
            dbKey: 'PG13'
        },
        CSSRS: {
            surveyKey: 'CSSRS',
            questionKey: 'CSSRS',
            dbKey: 'CSSRS'
        },
        CDD: {
            surveyKey: 'CDD',
            questionKey: 'CDD',
            dbKey: 'CDD'
        }
    },
    storageKeys: {
        survey: 'survey'
    },
    emailList: 
    {
        dev: ['wjs2004@med.cornell.edu', 'lavendershe2016@gmail.com'],
        prod: ['wjs2004@med.cornell.edu', 'mar2851@med.cornell.edu', 'pam2056@med.cornell.edu', 'hgp2001@med.cornell.edu', 'hiw4002@med.cornell.edu', 'jsp4001@med.cornell.edu', 'fbf4001@med.cornell.edu', 'sok4004@med.cornell.edu', 'cel4003@med.cornell.edu']
    }
}

export default config