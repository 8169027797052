import methods from '../methods'
import { combineReducers } from 'redux'

const currentDay = (state = {}, action) => {
    // console.log(state)
    let newState = typeof state === 'object' ? {...state} : {}
    switch(action.type)
    {
        case methods.UPDATE_MEMORIAL_STEP_LOG:
            // console.log('pass data to the reducer')
            // console.log(action.payload)
            // console.log(newState)
            let activityLogsObj = {}
            if(newState.hasOwnProperty('activity_logs') && newState['activity_logs'] !== null)
            {
                activityLogsObj = typeof newState['activity_logs'] === 'string' ? JSON.parse(newState['activity_logs']) : newState['activity_logs']
                // newState['activity_logs'] = 
            }
            else
            {
                activityLogsObj = {}
            }
            // newState['activity_logs'] = newState['activity_logs'] !== null ? JSON.parse(newState['activity_logs']) : {}
            // newState['activity_logs'] = newState['activity_logs'] !== null && newState['activity_logs'] !== undefined ? typeof newState['activity_logs'] !== 'string' ? newState['activity_logs'] : JSON.parse(newState['activity_logs']) : {}
            // console.log(newState['activity_logs'])
            // console.log(`the key length ${Object.keys(action.payload)}`)
            Object.keys(action.payload).map(k => {
                // console.log(`copy key ${k} into object`)
                // newState['activity_logs'][k] = action.payload[k]
                activityLogsObj[k] = action.payload[k]
            })
            // console.log(newState['activity_logs'])
            newState['activity_logs'] = activityLogsObj
            return newState
        case methods.LOAD_DAY_LOG:
            return action.payload
        default: return state
    }
}

const activityLogs = (state = [], action) => {
    // console.log(state)
    // console.log(action.payload)
    let newState = Array.isArray(state) ? [...state] : []
    switch(action.type)
    {
        case methods.ADD_ACTIVITY_LOG:
            newState.push(action.payload)
            return newState
        case methods.LOAD_ACTIVITY_LOG:
            return action.payload
        default: return state
    }
}

const dayJournalEntries = (state = [], action) => {
    // console.log(`the action type is ${action.type}`)
    let newState = Array.isArray(state) ? [...state] : []
    switch(action.type)
    {
        case methods.ADD_DAY_JOURNALS:
            newState.push(action.payload)
            return newState
        case methods.UPDATE_DAY_JOURNAL:

            if(Array.isArray(action.payload))
            {
                // console.log("the updated payload is", action.payload)
                action.payload.forEach((entry) => {
                    let found = false
                    newState.forEach((obj, idx) => {
                        if(entry.day_logs_id === obj.day_logs_id && entry.journal_day === obj.journal_day
                            && entry.question_day_key === obj.question_day_key && entry.question_key === obj.question_key
                            && entry.journal_entry_hash === obj.journal_entry_hash)
                        {
                            newState[idx] = entry
                            found = true
                        }
                    })
                    // console.log(`found update ${found}`)
                    if(!found)
                    {
                        newState.push(entry)
                    }
                })
                // console.log(newState)

                return newState
            }
            
            let found = false
            // console.log("typeof entry is not array",action.payload)
            newState.forEach((entry, idx) => {
                const input = action.payload
                if(entry.day_logs_id === input.day_logs_id && entry.journal_day === input.journal_day
                && entry.question_day_key === input.question_day_key && entry.question_key === input.question_key
                && entry.journal_entry_hash === input.journal_entry_hash)
                {
                    // console.log("input key is the same?")
                    newState[idx] = action.payload
                    found = true
                }
            })
            if(!found)
            {
                newState.push(action.payload)
            }
            // console.log(newState)
            // console.log('you should not see me')
            return newState
        case methods.REMOVE_DAY_JOURNALS:
            // console.log('remove journal entry '+action.payload)
            let targetIdx = parseInt(action.payload, 10)
            // console.log("the existing states is", newState)
            let finalState = []
            newState.map((entry, idx) => {
                const entryId = parseInt(entry?.id, 10)
                if(entryId !== targetIdx)
                {
                    finalState.push(entry)
                }
            })
            return finalState
        case methods.LOAD_DAY_JOURNALS:
            return action.payload
        default: return state
    }
}

const currentJournalDay = (state = 1, action) => {
    switch(action.type)
    {
        case methods.LOAD_CURRENT_JOURNAL_DAY:
            return action.payload
        default: return state
    }
}

export default combineReducers({ currentDay, activityLogs, dayJournalEntries, currentJournalDay })