import React from 'react'
import { BtnGroup } from './Buttons'
/**
 * a floating panel to display system message when user triggers a certain activity
 * usage:
    import { ConfirmBox } from './assets/systemMessage';
    <ConfirmBox 
        messageTitle='System Message' // or any alternative
        confirmMessage='your message'
        reject={this.rejectFunc}
        confirm={this.confirmFunc}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
    />
 */

class ConfirmBox extends React.Component {
    constructor(props)
    {
        super(props);
        this.confirm = this.confirm.bind(this);
    }
    componentDidMount() {

    }
    confirm(t) {
        switch(t)
        {
            case 'No':
                return this.props.reject();
            case 'Confirm':
            default:
                return this.props.confirm();
        }
    }
    render() {
        const systemMsgWrapperStyles = {
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: `rgba(0,0,0,0.6)`,
            zIndex: 999,
        }
        const systemMsgImgContStyles = {
            // minWidth: '600px',
            width: '50%',
        }
        if(this.props.confirmMessage === '')
        {
            return (
                <div></div>
            );
        }
        // should display messages to user
        return (
            <div style={systemMsgWrapperStyles} className={this.props.confirmMessage !== '' ? 'system-message-wrapper active' : 'system-message-wrapper'} onClick={this.close}>
                <div style={systemMsgImgContStyles} className='system-message-img-container'>
                    <div className='system-message-text-container'>
                        <h3 className='system-message-title'>{'System Message' || this.props.messageTitle}</h3>
                        <p className='system-message'>{this.props.confirmMessage}</p>
                        <BtnGroup 
                            leftBtnTargetAction='No'
                            rightBtnTargetAction='Confirm'
                            handleClick={this.confirm}
                            leftBtnTitle={'Cancel' || this.props.cancelText}
                            rightBtnTitle={'Confirm' || this.props.confirmText}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfirmBox