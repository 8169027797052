import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import { Route, Switch, Redirect } from "react-router-dom";
import Index from './components/containers/Index';
import Auth from './components/containers/Auth';
import Dashboard from './components/containers/Dashboard'
import SelectConfig from './components/containers/SelectConfig'
import WritingDesk from './components/containers/WritingDesk'
import Error from './components/containers/Error'
import MemorialSpace from './components/containers/MemorialSpace'
import GetHelp from './components/ui/GetHelp'
import Survey from './components/containers/Survey'
import config from './components/init/config'
import AdminBoard from './components/containers/AdminBoard'
// import House from './components/house';
// import MainApp from './components/mainapp';
// import Diary from './components/diary';
import Resources from './components/containers/Resources';


export default class App extends Component {
  render() {
    const supportedLanguages = ["en", "zh"];
    return (
      <div className="background-pattern container-fluid p-0 m-0">
        <Switch>
          <Route path={`/:lang${config.paths.auth}`} component={Auth} />
          <Route path={`/:lang${config.paths.config}`} component={SelectConfig} />
          <Route path={`/:lang${config.paths.memorial}`} component={MemorialSpace} />
          {/*<Route path="/:lang/house" component={Dashboard} />*/}
          <Route path={`/:lang${config.paths.dashboard}`} component={Dashboard} />
          <Route path={`/:lang${config.paths.diary}`} component={WritingDesk} />
          <Route path={`/:lang${config.paths.resources}`} component={Resources} />
          <Route path={`/:lang/adminboard`} component={AdminBoard} />
          <Route path='/:lang/survey' component={Survey} />
          <Route path='/:lang/get-help' component={GetHelp} />
          {/* These are old deprecated components
            <Route path="/:lang/view" component={House} />
            <Route path="/:lang/mainapp" component={MemorialSpace} />
            <Route path="/:lang/diary" component={Diary} />
            <Route path="/:lang/resources" component={Resources} />
          */}
          <Route path={`/:lang${config.paths.error}`} component={Error} />
          <Route
              path="/:lang/"
              render={props =>
                  supportedLanguages.includes(props.match.params.lang) ? (
                      <Index {...props} />
                  ) : (
                      <Redirect to={`/en/${props.match.params.lang}`} />
                  )
              }
            />
          <Route path="/"
                 render={() =>
                    (
                         <Redirect to={`/en/`} />
                     )
                 } />
          <Route component={NotFound}/>
        </Switch>
      </div>
    );
  }
}

class NotFound extends Component {
  render() {
    return(
      <h1>The page is not found.</h1>
    )
  }
}
