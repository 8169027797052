import React from 'react';
import { colors } from '../../init/colors';
import './assets.scss'
/**
 * round icon button for watercolor icon
 * usage:
    <IconBtn 
        key={idx}
        btnValue={iconObj.btnValue}
        ctnStyle={styles}
        imgSrc={iconObj.imgSrc}
        alt={iconObj.alt}
        iconTitle={iconObj.iconTitle}
        handleClick={this.handleClick}
    />
 */
export const IconBtn = (props) => {
    let classes = '';
    if(props.ctnClass)
    {
        classes = props.ctnClass.join(' ');
    }
    return(
        <div className={`operation-btn-ctn ${classes}`} style={props.ctnStyle}>
        <button className='operation-btn' value={props.btnValue}
            onClick={props.handleClick}
        >
        <img className={`operation-btn-img`} src={props.imgSrc} alt={props.alt} /> 
        {props.iconTitle}</button></div>
    );
}

/* a simple square button with icon
 * usage:
import { IconBtnPlain } from 'components/assets/buttons';
    <IconBtnPlain
    btnValue='example'
    handleClick={handleClick}
    imgSrc={require('string')}
    alt='example'
    iconTitle='example'
    ctnStyle={styleObj}
    />
*/
export function IconBtnPlain(props) {
    return(
        <div className='btn-plain-ctn' style={props.ctnStyle}>
            <button className='btn-plain' value={props.btnValue}
                onClick={() => props.handleClick()}
            >
            <img className='btn-plain-thumbnail' src={props.imgSrc} alt={props.alt} />
            {props.iconTitle}
            </button>
        </div>
    );
}

export function IconBtnPlainWatercolor(props) {
    return(
        <div className='btn-plain-ctn' style={props.ctnStyle}>
            <button className='btn-plain' value={props.btnValue}
                onClick={() => props.handleClick()}
            >
            <img className='btn-plain-thumbnail-noborder' src={props.imgSrc} alt={props.alt} />
            {props.iconTitle}
            </button>
        </div>
    );
}

/* a simple rectangular button, good for login/logout button or menu button
 * usage:
import { RectBtnPlain } from 'components/assets/buttons';
    <RectBtnPlain
        btnValue='example'
        handleClick={handleClick}
        imgSrc={require('string')}
        alt='example'
        btnTitle='example'
        ctnStyle={styleObj}
    />
*/
export function RectBtnPlain(props) {
    let defaultCtnStyle = {
        minWidth: '120px',
        height: '60px',
    }
    let btnCtnStyle = {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    }
    let classes = '';
    if( props.classArr !== undefined && props.classArr.length > 0 )
    {
        classes = props.classArr.join(' ');
    }
    if(props.ctnStyle !== undefined && Object.keys(props.ctnStyle) > 0 )
    {
        btnCtnStyle = Object.assign({}, 
            btnCtnStyle,
            props.ctnStyle
        );
    }
    if( props.imgSrc!== undefined )
    {
        return(
            <div className={`rect-btn-plain-ctn ${classes}`} style={btnCtnStyle} >
            <button className='rect-btn-plain' value={props.btnValue}
                onClick={() => props.handleClick()}
                style={props.btnStyle}
            >{props.btnTitle} <img className='rect-btn-plain-thumbnail' src={props.imgSrc} alt={props.alt} /></button>
            </div>
        );
    }
    if(props.handleClick)
    {
        return(
            <div className={'rect-btn-plain-ctn '+classes} style={btnCtnStyle} >
                <button className='rect-btn-plain' value={props.btnValue}
                    onClick={() => props.handleClick()}
                    style={props.btnStyle}
                >{props.btnTitle}</button>
            </div>
        );
    }
    return(
        <div className={'rect-btn-plain-ctn '+classes} style={btnCtnStyle} >
            <button className='rect-btn-plain' value={props.btnValue}
                style={props.btnStyle}
            >{props.btnTitle}</button>
        </div>
    );
}

/* a simple rectangular button with watercolor background
 * usage:
import { RectBtnPlain } from 'components/assets/buttons';
    <IconRectBtnPlain
        btnValue='example'
        handleClick={handleClick}
        imgSrc={require('string')}
        alt='example'
        btnTitle='example'
        ctnStyle={styleObj}
    />
*/
export function IconRectBtnPlain(props) {
    let defaultCtnStyle = {
        minWidth: '120px',
        height: '60px',
    }
    let btnCtnStyle = {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    }
    let classes = '';
    if( props.classArr !== undefined && props.classArr.length > 0 )
    {
        classes = props.classArr.join(' ');
    }
    if(props.ctnStyle !== undefined && Object.keys(props.ctnStyle) > 0 )
    {
        btnCtnStyle = Object.assign({}, 
            btnCtnStyle,
            props.ctnStyle
        );
    }
    if( props.imgSrc!== undefined )
    {
        return(
            <div className={`icon-rect-btn-plain-ctn ${classes}`} style={btnCtnStyle} >
            <button className='icon-rect-btn-plain' value={props.btnValue}
                onClick={() => props.handleClick()}
                style={props.btnStyle}
            >{props.btnTitle} <img className='rect-btn-plain-thumbnail' src={props.imgSrc} alt={props.alt} /></button>
            </div>
        );
    }
    if(props.handleClick)
    {
        return(
            <div className={'icon-rect-btn-plain-ctn '+classes} style={btnCtnStyle} >
                <button className='icon-rect-btn-plain' value={props.btnValue}
                    onClick={() => props.handleClick()}
                    style={props.btnStyle}
                >{props.btnTitle}</button>
            </div>
        );
    }
    return(
        <div className={'icon-rect-btn-plain-ctn '+classes} style={btnCtnStyle} >
            <button className='icon-rect-btn-plain' value={props.btnValue}
                style={props.btnStyle}
            >{props.btnTitle}</button>
        </div>
    );
}


export function SubmitBtn(props)
{
    let classes = '';
    if( props.classArr !== undefined && props.classArr.length > 0 )
    {
        classes = props.classArr.join(' ');
    }
    return(
        <div>
            <button className={'submit-btn '+classes}>
                {props.btnTitle}
            </button>
        </div>
    )
}

/* button that has a round image on top of the text
 * usage:
    import { ImageBtn } from 'components/assets/buttons';
    <ImageBtn
        btnValue='example'
        handleClick={handleClick}
        imgSrc={require('string')}
        alt='example'
        btnTitle='example'
        ctnStyle={styleObj}
        classArr='example-class'
    />
*/
export function ImageBtn(props)
{
    let defaultCtnStyle = {
        width: '120px',
        height: '60px',
    }
    let classes = '';
    if( props.classArr !== undefined && props.classArr.length > 0 )
    {
        classes = props.classArr.join(' ');
    }
    if( props.imgSrc!== undefined )
    {
        return(
            <div className={'img-btn-plain-ctn '+classes} style={defaultCtnStyle} >
            <button className='img-btn-plain' value={props.btnValue}
                onClick={() => props.handleClick()}
            >{props.btnTitle} <img className='img-btn-plain-thumbnail' src={props.imgSrc} alt={props.alt} /></button>
            </div>
        );
    }
    return(
        <div className={'img-btn-plain-ctn '+classes} >
            <button className='img-btn-plain' value={props.btnValue}
                onClick={() => props.handleClick()}
            >{props.btnTitle}</button>
        </div>
    );
}

/**
 * a simple button with transparent background and shadow
 * @param {*} props 
 * usage:
	<LoginBtn 
		styles='login-btn'
		targetAction='login'
		handleClick={props.handleClick}
		title='LOG IN'
	/>
 */
export const LoginBtn = (props) => {
	return (
		<button
			className={props.styles}
			onClick={() => props.handleClick(props.targetAction)}
		>{props.title}</button>
	);
};
// 
/**
 * This is only used in the login page, should be modified to accommodate more btns
 * @param {props} props
 *  usage:
<BtnGroup 
    leftBtnTargetAction='register'
    rightBtnTargetAction='login'
    handleClick={this.handleClick}
    leftBtnTitle='REGISTER'
    rightBtnTitle='LOGIN'
/>
 */
export const BtnGroup = (props) => {
	return(
		<div className='btn-group-wrapper'>
			<LoginBtn 
				styles='login-btn lmh-secondary-btn'
				targetAction={props.leftBtnTargetAction}
				handleClick={props.handleClick}
				title={props.leftBtnTitle}
			/>
			<LoginBtn 
				styles='login-btn lmh-primary-btn'
				targetAction={props.rightBtnTargetAction}
				handleClick={props.handleClick}
				title={props.rightBtnTitle}
			/>
		</div>
	);
}

