import {createStore} from 'redux';
import {langPack, defaultLang} from './components/lang/init.js';


const reducer = (state = {lang: defaultLang, dict: langPack[defaultLang]}, action) => {
    if(action.type === "LANG"){
        return {
            lang: action.value,
            dict: langPack[action.value]
        }
        // console.log("Reducer")
    }
    else{
        return state;
    }
}
const store = createStore(reducer);
export default store;
