import React, { Component } from 'react'

import { diaryQuestions, activitiesFeedback } from '../init/initApp';
import { asyncForEach } from '../init/general';
// import { Offering } from '../classes/offering';
import OF from '../classes/v2/OfferingHelper'
import { S3Wrapper } from '../classes/s3bucket';
import { SystemTimeManagement } from '../classes/systemConfig';
// import { Journals } from '../classes/journals';
import JE from '../classes/v2/JournalHelper'

import '../../scss/style.scss';

import connect from "react-redux/es/connect/connect";
import {mapDispatchToProps, mapStateToProps} from "../lang/langRedux";


/**

/**
 * Journal preview tree
 * JournalPreview
 * JournalPreview.Section
 * JournalPreview.Title
 * JournalPreview.Content
 * JournalPreview.Image
 * JournalPreview.NavPage
 */

const JournalPreviewContext = React.createContext();

// const OF = new Offering();
// const JE = new Journals();
const S3B = new S3Wrapper();
const STM = new SystemTimeManagement();

class UnconnectedJournalPreview extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            entries: this.props.entries,
            userData: this.props.userData,
            dayLogs: this.props.dayLogs,
            entriesKeys: [...this.props.entries.keys()],
            currentDay: 1,
            currentPage: 0,
            beginDay: 1,
            endDay: 10,
            switchPage: (page) => {
                let realPage = page % 10 - 1 > 0 ? page % 10 - 1 : page % 10 !== 0 ? 0 : 9;
                this.setState({
                    currentPage: realPage,
                    currentDay: page
                });
            },
            retrieveNextTenEntries: async (beginDay = 1) => {
                let endDay = beginDay + 9;
                await this.getJournalEntriesGivenRange(beginDay, endDay)
                await this.getOfferingImgGivenRange()
            },
            retrievePrevTenEntries: async (endDay = 10) => {
                let beginDay = 1;
                if(endDay - 11 > 0)
                {
                    endDay = endDay - 1;
                    beginDay = endDay - 11;
                }
                else
                {
                    beginDay = 1;
                    endDay = 10;
                }
                await this.getJournalEntriesGivenRange(beginDay, endDay)
                await this.getOfferingImgGivenRange()
            },
        }
        this.getOfferingImgGivenRange = this.getOfferingImgGivenRange.bind(this)
        this.getJournalEntriesGivenRange = this.getJournalEntriesGivenRange.bind(this)
        // this.retrieveNextTenEntries = this.retrieveNextTenEntries.bind(this)
    }
    componentWillMount()
    {
        let beginDay = 1, endDay = 10
        this.props.dayLogs.day > 10 ? endDay = 10 : endDay = this.props.dayLogs.day
        this.setState({
            beginDay: beginDay,
            endDay: endDay
        }, async () => {
            await this.getJournalEntriesGivenRange(this.state.beginDay, this.state.endDay)
            await this.getOfferingImgGivenRange()
        })
    }
    async getJournalEntriesGivenRange(beginDay = 1, endDay = 10)
    {
        await JE.getJournalEntriesByRange(this.props.userData.id, [beginDay, endDay], async (err, data) => {
            if(err || data.size <= 0) return; // don't do shit
            let keys = [...data.keys()];
            let navStart = parseInt(data.get(keys[0])['journal_day'], 10);
            let navEnd = parseInt(data.get(keys[keys.length - 1])['journal_day'], 10);
            return this.setState({
                entries: data,
                entriesKeys: [...data.keys()],
                currentPage: 0,
                currentDay: navStart,
                beginDay: navStart,
                endDay: navEnd
            })
        });
    }
    async getOfferingImgGivenRange()
    {
        const defaultImgUrl = OF.returnOfferingDefaultImg(this.props.view);
        let imgObj = {}, dayData = {}; // { day: imgUrl }
        // remember to get the journal_entries, too
        await asyncForEach(this.state.entriesKeys, async key => {
            const targetEntry = this.state.entries.get(key);
            if(targetEntry)
            {
                const dayLogsId = targetEntry.day_logs_id;
                const journalDay = targetEntry.journal_day;
                const dayKey = journalDay.toString();
                dayData[dayKey] = {}
                return OF.getOfferingDataByDayLogsId(this.state.userData.id, dayLogsId, async (err, data) => {
                    if(err) return;
                    dayData[dayKey]['day'] = data.day;
                    dayData[dayKey]['time'] = data.created_at;
                    dayData[dayKey]['offering'] = data.offering_data;
                    const imgUrl = data.offering_img;
                    await S3B.getImage(imgUrl, 'ritual_imgs', (err, imgData) => {
                        if(err || !imgData)
                        {
                            return imgObj[dayKey] = defaultImgUrl;
                        }
                        return imgObj[dayKey] = imgData;
                    });
                });
            }
        });
        return this.setState({
            imgObj: imgObj,
            dayData: dayData
        });  
    }
    render()
    {
        // console.log(this.state.entriesKeys)
        // console.log(`The entries to be shown are ${this.state.entriesKeys[this.state.currentPage]}`)
        // console.log(this.state.entries.get(this.state.entriesKeys[this.state.currentPage]))
        // console.log(this.state.entries)
        return(
            <JournalPreviewContext.Provider value={this.state}>
                <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 justify-content-center d-flex my-5 journal-preview-header'>
                    <h3 className='mh-title-white mx-auto'>{this.props.dict.previousWritingEntries}</h3>
                </div>
                <div className='row row-eq-height journal-preview-panel d-flex py-5' style={this.props.contStyle}>

                    {this.props.children}
                </div>
                <JournalPreview.NavPage />
            </JournalPreviewContext.Provider>
        )
    }
}

const JournalPreview = connect(mapStateToProps, mapDispatchToProps)(UnconnectedJournalPreview);

/**
 * Specify the section panel that contains either article preview or image preview
 * @param {*} props 
 * usage:
    <Section 
        contStyle={yourStyle}
    >
    </Section>
 */

const Section = (props) => {
    return(
        <JournalPreviewContext.Consumer>
            {() => (
                <div className='journal-preview-section col-xs-12 col-sm-12 col-md-6 col-lg-6' style={props.contStyle}>
                    {props.children}
                </div>
            )}
        </JournalPreviewContext.Consumer>
    )
}
/**
 * Return the article block to contain several article section
 * @param {*} props 
 * usage:
    <Article 
        contStyle={yourStyle}
    >
    </Article>
 */
const Article = (props) => {
    return(
        <JournalPreviewContext.Consumer>
            {({entries, entriesKeys, currentPage}) => (
                <div className='w-100' style={props.contStyle}>
                    {diaryQuestions().map((qSet, i) =>
                        qSet.key === entries.get(entriesKeys[currentPage])['question_day_key']
                        ?
                        <JournalPreview.ArticleSection 
                            key={i}
                            questionSet={qSet}
                            qKey={qSet.key}
                            entries={entries.get(entriesKeys[currentPage])['values']}
                        />
                        :
                        null
                    )}
                </div>
            )}
        </JournalPreviewContext.Consumer>
    )
}

const Activity = (props) => {
    return (
        <JournalPreviewContext.Consumer>
            {({imgObj, currentPage, currentDay, dayData}) => 
                <div className='w-100 journal-preview-activity-section'>
                    <JournalPreview.Date 
                        dateObj={dayData}
                        dateIdx={currentDay.toString()}
                    />
                    {imgObj === undefined ? 
                    <JournalPreview.Loader active={true} />
                    :
                    null
                    }
                    <JournalPreview.Image
                        imgObj={imgObj}
                        imgIdx={currentDay.toString()}
                    />
                    <JournalPreview.List
                        listData={dayData}
                        listIdx={currentDay.toString()}
                    />
                </div>
            }
        </JournalPreviewContext.Consumer>
    )
}

const ArticleSection = (props) => {
    if(props.entries.length <= 0 || !props.qKey || !props.questionSet) return null;
    let promptsArr = [{
        key: "constant",
        prompt: "What has been on my mind today is...",
        require: true
    }, ...props.questionSet.prompts];
    return(
        <JournalPreviewContext.Consumer>
            {() => 
                <div className='w-100' style={props.contStyle}>
                {promptsArr.map((q, i) => // get the prompt and the answer
                    <div key={i} className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={props.contStyle}>
                        <JournalPreview.Title
                            key={`jt-${i}`}
                            value={q.prompt}
                        />
                        <JournalPreview.Content 
                            key={`jc-${i}`}
                            qKey={q.key}
                            value={props.entries[q.key]['value']}
                        />
                    </div>
                )}
                </div>
            }
        </JournalPreviewContext.Consumer>
    )
}

const Title = (props) => {
    if(!props.value) return null;
    return(
        <JournalPreviewContext.Consumer>
            {() => (
                <div className='w-100'>
                    <h4 className='mh-subtitle mh-subtitle-dark'>{props.value}</h4>
                </div>
            )}
        </JournalPreviewContext.Consumer>
    )
}

const Date = (props) => {
    if(!props.dateObj || !props.dateIdx || !props.dateObj[props.dateIdx]) return null;
    const date = props.dateObj[props.dateIdx]['time'];
    const format = STM.formatLocalDate(date);
    const finalVal = `My activity on ${format}`
    return (
        <JournalPreviewContext.Consumer>
            {() => (
                <JournalPreview.Title
                    value={finalVal}
                />
            )}
        </JournalPreviewContext.Consumer>
    )
}

const Content = (props) => {
    if(!props.value) return null;
    return(
        <JournalPreviewContext.Consumer>
            {() => (
                <div className='w-100'>
                    <p className='mh-paragraph-brown'>{props.value}</p>
                </div>
            )}
        </JournalPreviewContext.Consumer>
    )
}

const List = (props) => {
    if(!props.listData) return null;
    if(!props.listData[props.listIdx]) return null;
    if(!props.listData[props.listIdx]['offering']) return null;
    const offeringData = JSON.parse(props.listData[props.listIdx]['offering']) || {};
    let activityLogsCont = [];
    Object.keys(offeringData).forEach((key, idx) => {
        const targetKey = offeringData[key].originKey;
        if(activitiesFeedback()[targetKey])
        {
            activityLogsCont.push(<li className='house-activity-log mh-paragraph-brown' key={idx}>{activitiesFeedback()[targetKey]}</li>);
        }
    });
    return(
        <JournalPreviewContext.Consumer>
            {() => (
                <ul className='pt-4'>
                    {activityLogsCont}
                </ul>
            )}
        </JournalPreviewContext.Consumer>
    )
}

const Image = (props) => {
    if(!props.imgObj || Object.keys(props.imgObj).length <= 0 || !props.imgIdx) return null;
    if(props.imgObj[props.imgIdx] === undefined) return null;
    return(
        <JournalPreviewContext.Consumer>
            {() => 
                <div className='w-100'>
                    <img className='img-fluid' src={props.imgObj[props.imgIdx]} />
                </div>
            }
        </JournalPreviewContext.Consumer>
    )
}

const Loader = (props) => {
    if(props.active)
    {
        return (
            <div className='w-100 d-flex justify-content-center'>
                <video style={{marginLeft: 'auto', marginRight: 'auto'}} width="64" height="64" autoPlay={true} loop={true}>
                    <source src={require('../../videos/loader.mp4')} type="video/mp4"/>
                </video>
            </div>
        )
    }
    return null
}

/**
 * generate a pagination nav bar
 * @param {*} props 
 * usage:
    <JournalPreview.NavPage 
        currentPage={1}
        length={10}
        onChangeHandler={(page) => console.log(page)}
    />
 */
const NavPage = (props) => {
    return(
    <JournalPreviewContext.Consumer>
        {({entriesKeys, currentPage, currentDay, switchPage, retrieveNextTenEntries, retrievePrevTenEntries, beginDay, endDay}) => (
            <nav aria-label="Page navigation" className='nav-cont col mt-3'>
                {props.children}
                <JournalPreview.NavList 
                    begin={beginDay}
                    end={endDay}
                    currentDay={currentDay}
                    onClickHandler={(e) => {
                        let page = parseInt(e.target.innerText, 10);
                        switchPage(page)
                    }}
                />
            </nav>
        )}
    </JournalPreviewContext.Consumer>
    )
}

// <Pagination
// hideDisabled
// activePage={props.currentDay || currentDay}
// itemsCountPerPage={1}
// pageRangeDisplayed={10}
// totalItemsCount={props.length || entriesKeys.length}
// onChange={page => {
//     if(props.onChangeHandler)
//     {
//         return props.onChangeHandler(page)
//     }
//     return switchPage(page)
// }}
// activeLinkClass=''
// itemClass='page-item'
// linkClass='page-link'
// />

const NavList = (props) => {
    let navItem = []
    for(let i = props.begin ; i < props.end + 1; i ++)
    {
        if(parseInt(props.currentDay, 10) === i)
        {
            navItem.push(
                <li key={i} className="page-item page-link active mh-page-item" onClick={props.onClickHandler}>{i}</li>
            )
        }
        else
        {
            navItem.push(
                <li key={i} className="page-item page-link mh-page-item" onClick={props.onClickHandler}>{i}</li>
            )
        }
    }
    return (
        <JournalPreviewContext.Consumer>
        {({retrievePrevTenEntries, retrieveNextTenEntries, beginDay, endDay, currentDay}) => (
            <ul className="pagination mh-pagination">
                {currentDay >= 11 ? <li key='prev' className="page-item page-link mh-page-item" onClick={() => retrievePrevTenEntries(beginDay)}>{'<<'}</li>
                : null}
                {navItem}
                <li key='next' className="page-item page-link mh-page-item" onClick={() => retrieveNextTenEntries(endDay + 1)}>{'>>'}</li>
            </ul>
        )}

        </JournalPreviewContext.Consumer>
    )
}

JournalPreview.NavPage = NavPage
JournalPreview.Section = Section
JournalPreview.Activity = Activity
JournalPreview.Article = Article
JournalPreview.ArticleSection = ArticleSection
JournalPreview.Title = Title
JournalPreview.Date = Date
JournalPreview.Content = Content
JournalPreview.List = List
JournalPreview.Image = Image
JournalPreview.Loader = Loader
JournalPreview.NavList = NavList

export default JournalPreview