import React, { Component } from 'react'
import AmplifyHelper from './AmplifyHelper'
/**
 * usage:
    import FetchWrapper from '../utilities/FetchWrapper'
    const Fetch = new FetchWrapper()
 */
export default class FetchWrapper {
    constructor()
    {
        this.host = ''
    }
    fetchWithTimeout(url, options, timeout = 7000) {
        return Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('timeout')), timeout)
            )
        ]);
    }
    async get(url, params = [], onResults = () => null)
    {
        // console.log(`get request called`)

        let query = ''
        params.forEach(param => {
            query += `/${param}`
        })
        const completeUrl = this.host+url+query
        const session = await AmplifyHelper.getSession()
        // console.log(session.accessToken.jwtToken)

        const options = {
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json",
                "accesstoken": session?.accessToken?.jwtToken
            }
        }
        return this.fetchWithTimeout(completeUrl, options)
        // .then(r => r.json())
        // .then(response => {
        //     return onResults(null, response)
        // })
        // .catch(err => {
        //     return onResults(err, null)
        // })
    }
    async post(url, body, onResults = () => null)
    {
        const completeUrl = this.host+url
        const session = await AmplifyHelper.getSession()
        const options = {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json",
                "accesstoken": session?.accessToken?.jwtToken
            },
            body: JSON.stringify(body)
        }
        return this.fetchWithTimeout(completeUrl, options)
        // .then(r => r.json())
        // .then(response => {
        //     return onResults(null, response)
        // })
        // .catch(err => {
        //     return onResults(err, null)
        // })
    }


    async postForm(url, body, onResults = () => null)
    {
        const completeUrl = this.host+url
        const session = await AmplifyHelper.getSession()
        const options = {
            method: "POST",
            mode: 'cors',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "accesstoken": session?.accessToken?.jwtToken
            },
            body: body
        }
        return this.fetchWithTimeout(completeUrl, options)
        // .then(r => r.json())
        // .then(response => {
        //     return onResults(null, response)
        // })
        // .catch(err => {
        //     return onResults(err, null)
        // })
    }    

    async delete(url, params = [], onResults = () => null)
    {
        let query = ''
        params.forEach(param => {
            query += `/${param}`
        })
        const completeUrl = this.host+url+query
        const session = await AmplifyHelper.getSession()
        const options = {
            method: "DELETE",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept": "application/json",
                "accesstoken": session?.accessToken?.jwtToken
            }
        }
        return this.fetchWithTimeout(completeUrl, options)
        // .then(r => r.json())
        // .then(response => {
        //     return onResults(null, response)
        // })
        // .catch(err => {
        //     return onResults(err, null)
        // });
    }
}
