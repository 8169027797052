import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const VideoWrapper = ({
    youtubeID,
    onClose,
    title
}) => {
    return (<div className='video-wrapper-bg' onClick={onClose}>
        <h4 className='tutorial-title mh-title-white'>{title}</h4>
        <FontAwesomeIcon className='mh-paragraph-white video-wrapper-close-icon' onClick={onClose} icon={faTimes} />
        <iframe 
            allowFullScreen
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
            className='tutorial-video-iframe'
            title={title}
            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
            src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
        </iframe>

    </div>)
}

export default VideoWrapper