import React, { useState, useEffect, createRef } from 'react'
// import { ReactTabulator } from 'react-tabulator'
import Tabulator from 'tabulator-tables'
import moment from 'moment'
import momentTimezone from 'moment-timezone';
// import 'react-tabulator/lib/styles.css'; // required styles
import 'tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css'; // theme
import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import config from '../../init/config'
import SurveyHelper from '../../classes/v2/SurveyHelper';
import SystemTimeManagement from '../../classes/v2/SystemTimeManage'
import './AdminBoard.scss'
// var Tabulator = require('tabulator-tables')

const navArr = [
    {
        id: config.surveys.DEMO.dbKey,
        name: 'Demographic'
    },
    {
        id: config.surveys.PG13.dbKey,
        name: 'PG-13'
    },
    {
        id: config.surveys.CDD.dbKey,
        name: 'CDD'
    },
    {
        id: config.surveys.CSSRS.dbKey,
        name: 'CSSRS'
    },
]
let defaultSurvey = config.surveys.PG13.dbKey

/**
 * Render the data array and manages the nav tabs to switch the table display
 * The parent is still responsible of downloading all of the data in the background to optimize the performance
 */
const DisplayData = ({ data, targetSurvey, switchSurvey }) => {

    
    return (<div className='display-data-container'>
        <SurveyNav surveys={navArr} switchSurvey={(k = defaultSurvey) => switchSurvey(k)} targetData={targetSurvey} />
        <SurveyContent dataArr={data} targetData={targetSurvey} />    
    </div>)

}

const SurveyNav = ({ surveys, switchSurvey, targetData }) => {
    let surveyArr = []

    surveys.forEach((s, i) => {
        surveyArr.push(
            <li key={`survey-nav-${s.id}`} className={targetData === s.id ? `nav-item selected-survey`:`nav-item`} onClick={e => switchSurvey(s.id)}>
                <button className="nav-link" id={s.id} data-bs-toggle="tab" data-bs-target={`#${s.id}`} type="button" role="tab" aria-controls={s.id} aria-selected="false">{s.name}</button>
            </li>
        )
    })

    return <ul className="nav nav-tabs survey-nav-tab" id="surveyTab" role="tablist">
        {surveyArr}
    </ul>
}

const SurveyContent = ({ dataArr, targetData }) => {
    const _table = createRef()
    let tableRef = null
    const title = 'LMH_prescreening_survey'
    const SheetTitle = 'LML Prescreening Data '+targetData

    useEffect(() => {


        // console.log(_downloadCSV)
        /*
        //trigger download of data.csv file
        document.getElementById('download-csv').addEventListener("click", function(){
            _table.download("csv", `${title}.csv`);
        })

        //trigger download of data.json file
        document.getElementById("download-json").addEventListener("click", function(){
            table.download("json", `${title}.json`);
        });

        //trigger download of data.xlsx file
        document.getElementById("download-xlsx").addEventListener("click", function(){
            table.download("xlsx", `${title}.xlsx`, {sheetName:SheetTitle});
        });

        //trigger download of data.pdf file
        document.getElementById("download-pdf").addEventListener("click", function(){
            table.download("pdf", `${title}.pdf`, {
                orientation:"portrait", //set page orientation to portrait
                title:SheetTitle, //add title to report
            });
        });
        */
        const dataTable = document.getElementById('lmh-table')
        if(dataTable && dataArr && dataArr.length > 0)
        {
            // console.log('rendered!')
            // console.log(dataTable)
            console.log(dataArr)

            let columns = [                 //define the table columns
                // {title:"ID", field:"id", headerFilter:"input"},
                {title: "Submission Identifier", field: "submission_hash", headerFilter:"input"},
                {title: "Name", field: "first_name", headerFilter: "input"},
                // {title: "Eligibility", field: "eligibility", headerFilter:"input"},
                {title: "Email", field: "email", headerFilter:"input"},
                {title: "Phone", field: "phone", headerFilter:"input"},
                {title: "IP address", field: "ip_address", headerFilter: "input"},
                {title:"Time", field:"created_at", hozAlign:"center", headerFilter:"input", formatter:(cell, formatterParams, onRendered) =>{
                    //cell - the cell component
                    //formatterParams - parameters set for the column
                    //onRendered - function to call when the formatter has been rendered
                    const t = cell.getValue()
                    let formatted = moment(t).format('YYYY-MM-DD hh:mm:ss')
                    // return "Mr" + cell.getValue(); //return the contents of the cell;
                    return "(EST) "+formatted
                }},
                // {title: "Timezone", field: "", formatter: () => {
                //     return momentTimezone.tz.guess(true)
                // }}
            ]
            let filteredDataKey = config.surveys.PG13.questionKey
            // console.log(`the target data is ${targetData}`)
            switch(targetData)
            {
                case config.surveys.CDD.dbKey:
                    filteredDataKey = config.surveys.CDD.questionKey
                    break
                case config.surveys.DEMO.dbKey:
                    filteredDataKey = config.surveys.DEMO.questionKey
                    break
                case config.surveys.CSSRS.dbKey:
                    filteredDataKey = config.surveys.CSSRS.questionKey
                    break
                case config.surveys.PG13.dbKey:
                    filteredDataKey = config.surveys.PG13.questionKey
                    break
                default:
            }
            // console.log(`the filtered data key is ${filteredDataKey}`)
            // console.log(targetData)
            // filter the data using the question key
            let newDataArr = [], hasEligibility = false
            dataArr.map((d, i) => {
                const qKeys = Object.keys(d)
                let newDataObj = {}
                newDataObj['id'] = d['id']
                newDataObj['first_name'] = d['first_name']
                newDataObj['email'] = d['email']
                newDataObj['phone'] = d['phone']
                newDataObj['ip_address'] = d['ip_address']
                newDataObj['created_at'] = d['created_at']
                newDataObj['submission_hash'] = d['submission_hash']
                if(d?.eligibility)
                {
                    newDataObj['eligibility']=d['eligibility']
                    hasEligibility = true
                }
                qKeys.map((k, idx) => {
        
                    if(k.includes(filteredDataKey) || k.includes('meet') || k.includes('score'))
                    {
                        // Only get the question once and attach it to the columns
                        if(i === 0)
                        {
                            const question = SurveyHelper.getQuestionGivenQid(targetData, k)
                            // console.log(question)
                            columns.push({
                                title: question?.title || k,
                                field: k,
                                width: "24px",
                                headerFilter:"input"
                            })
                        }
        
                        const targetAnswer = JSON.parse(d[k])
                        newDataObj[k] = targetAnswer?.answer || ''
                    }
                })
                newDataArr.push(newDataObj)
            })
            if(hasEligibility)
            {
                columns.splice(1, 0, {title: "Eligibility", field: "eligibility", headerFilter:"input"})
            }
            // console.log(columns)
            // console.log(newDataArr)
        
            const options = {
                tooltipGenerationMode:"hover", //regenerate tooltip as users mouse enters the cell;
                tooltipsHeader:true, //enable header tooltips
                // responsiveLayout: true,  //hide columns that dont fit on the table
                resizableColumns: true,
                tooltips:true,            //show tool tips on cells
                pagination:"local",       //paginate the data
                paginationSize:10,         //allow 7 rows per page of data
                headerFilter:"input",
                // layout:"fitData",

                // autoColumns:true,
                // layout:"fitColumns",      //fit columns to width of table
                // movableColumns:true,      //allow column order to be changed
                // resizableRows:true,       //allow row order to be changed
                // initialSort:[             //set the initial sort order of the data
                //     {column:"id", dir:"asc"},
                // ],
            }

            tableRef = new Tabulator("#lmh-table", {
                data:newDataArr,
                columns:columns,
                ...options
            })
            //trigger download of data.csv file
            // document.getElementById('download-csv').addEventListener("click", function(){
            //     renderTable.download("csv", `${title}.csv`);
            // })
            // document.getElementById("download-xlsx").addEventListener("click", function(){
            //     renderTable.download("xlsx", `${title}.xlsx`, {sheetName:SheetTitle});
            // });
        }
    }, [_table])

    if(!dataArr || dataArr.length <= 0) return null
    // console.log(dataArr)
    // const columns = Object.keys(dataArr[0])
    /**

    let columns = [                 //define the table columns
        {title:"ID", field:"id", headerFilter:"input"},
        {title: "Email", field: "email", headerFilter:"input"},
        {title: "Phone", field: "phone", headerFilter:"input"},
        {title: "Submission Key", field: "submission_hash", headerFilter:"input"},
        {title:"Time", field:"created_at", sorter:"date", hozAlign:"center", headerFilter:"input"}
    ]
    let filteredDataKey = config.surveys.PG13.questionKey
    // console.log(`the target data is ${targetData}`)
    switch(targetData)
    {
        case config.surveys.CDD.dbKey:
            filteredDataKey = config.surveys.CDD.questionKey
            break
        case config.surveys.DEMO.dbKey:
            filteredDataKey = config.surveys.DEMO.questionKey
            break
        case config.surveys.CSSRS.dbKey:
            filteredDataKey = config.surveys.CSSRS.questionKey
            break
        case config.surveys.PG13.dbKey:
            filteredDataKey = config.surveys.PG13.questionKey
            break
        default:
    }
    // console.log(`the filtered data key is ${filteredDataKey}`)
    // console.log(targetData)
    // filter the data using the question key
    let newDataArr = []
    dataArr.map((d, i) => {
        const qKeys = Object.keys(d)
        let newDataObj = {}
        newDataObj['id'] = d['id']
        newDataObj['email'] = d['email']
        newDataObj['phone'] = d['phone']
        newDataObj['submission_hash'] = d['submission_hash']
        newDataObj['created_at'] = SystemTimeManagement.formatLocalTime(d['created_at'])
        qKeys.map((k, idx) => {

            if(k.includes(filteredDataKey) || k.includes('meet') || k.includes('score'))
            {
                // Only get the question once and attach it to the columns
                if(i === 0)
                {
                    const question = SurveyHelper.getQuestionGivenQid(targetData, k)
                    // console.log(question)
                    columns.push({
                        title: question?.title || k,
                        field: k,
                        headerFilter:"input"
                    })
                }

                const targetAnswer = JSON.parse(d[k])
                newDataObj[k] = targetAnswer?.answer || ''
            }
        })
        newDataArr.push(newDataObj)
    })
    // console.log(newDataArr)

    const options = {
        // autoColumns:true,
        // layout:"fitColumns",      //fit columns to width of table
        tooltipGenerationMode:"hover", //regenerate tooltip as users mouse enters the cell;
        tooltipsHeader:true, //enable header tooltips
        responsiveLayout: true,  //hide columns that dont fit on the table
        resizableColumns: true,
        tooltips:true,            //show tool tips on cells
        pagination:"local",       //paginate the data
        paginationSize:10,         //allow 7 rows per page of data
        headerFilter:"input",
        layout:"fitData",
        // movableColumns:true,      //allow column order to be changed
        // resizableRows:true,       //allow row order to be changed
        // initialSort:[             //set the initial sort order of the data
        //     {column:"id", dir:"asc"},
        // ],
    }
     */

    
    return <React.Fragment>
        <div className='my-2'>
        <div className='col-xs-12'>
        <button id='download-csv' className='lmh-primary-btn' onClick={() => {
            if(tableRef)
            {
                tableRef.download("csv", `${title}.csv`)
            }
        }}>Download CSV</button>
        <button id='download-xlsx' className='lmh-primary-btn ml-2' onClick={() => {
            if(tableRef)
            {
                tableRef.download("xlsx", `${title}.xlsx`, {sheetName:SheetTitle})
            }
        }}>Download XLSX</button>
        </div>
        </div>
        <div ref={_table} className='' id='lmh-table'>
        {/*<ReactTabulator data={newDataArr} columns={columns} options={options} />*/}
        
        </div>
    </React.Fragment>
}


export default DisplayData