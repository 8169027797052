import React from 'react'

const tutorial = dict => ({
    m0: {
        title: dict['w0title'],
        paragraph: dict['w0description'],
        // img: [
        //     {
        //         url: require("../../../../img/ms_intro_1_select-md.png"),
        //         srcset: {
        //             sm: require("../../../../img/ms_intro_1_select-sm.png"),
        //             md: require("../../../../img/ms_intro_1_select-md.png"),
        //             lg: require("../../../../img/ms_intro_1_select-lg.png"),
        //             xl: require("../../../../img/ms_intro_1_select-xl.png")
        //         }
        //     }
        // ]
    },
    // m1: {
    //     title: dict['w1title'],
    //     paragraph: dict['w1description'],
    //     img: [
    //         {
    //             url: require("../../../img/we_intro_1-md.png"),
    //             srcset: {
    //                 sm: require("../../../img/we_intro_1-sm.png"),
    //                 md: require("../../../img/we_intro_1-md.png"),
    //                 lg: require("../../../img/we_intro_1-lg.png"),
    //                 xl: require("../../../img/we_intro_1-xl.png")
    //             }
    //         }
    //     ]
    // },
    // m2: {
    //     title: dict['w2title'],
    //     paragraph: dict['w2description'],
    //     img: [
    //         {
    //             url: require("../../../img/we_intro_2-md.png"),
    //             srcset: {
    //                 sm: require("../../../img/we_intro_2-sm.png"),
    //                 md: require("../../../img/we_intro_2-md.png"),
    //                 lg: require("../../../img/we_intro_2-lg.png"),
    //                 xl: require("../../../img/we_intro_2-xl.png")
    //             }
    //         }
    //     ]
    // },
    m3: {
        title: dict['w3title'],
        paragraph: dict['w3description'],
        img: [
            {
                url: require("../../../img/we_intro_4-md.png"),
                srcset: {
                    sm: require("../../../img/we_intro_4-sm.png"),
                    md: require("../../../img/we_intro_4-md.png"),
                    lg: require("../../../img/we_intro_4-lg.png"),
                    xl: require("../../../img/we_intro_4-xl.png")
                }
            }
        ]
    },
    // m4: {
    //     title: dict['w4title'],
    //     paragraph: dict['w4description'],
    //     img: [
    //         // {
    //         //     url: require("../../../img/ms_intro_5_example-md.png"),
    //         //     srcset: {
    //         //         sm: require("../../../img/ms_intro_5_example-sm.png"),
    //         //         md: require("../../../img/ms_intro_5_example-md.png"),
    //         //         lg: require("../../../img/ms_intro_5_example-lg.png"),
    //         //         xl: require("../../../img/ms_intro_5_example-xl.png")
    //         //     }
    //         // },
    //         {
    //             url: require("../../../img/we_intro_5-md.png"),
    //             srcset: {
    //                 sm: require("../../../img/we_intro_5-sm.png"),
    //                 md: require("../../../img/we_intro_5-md.png"),
    //                 lg: require("../../../img/we_intro_5-lg.png"),
    //                 xl: require("../../../img/we_intro_5-xl.png")
    //             }
    //         }
    //     ]
    // },
    m5: {
        title: dict['w5title'],
        paragraph: dict['w5description'],
        img: [
            {
                url: require("../../../img/we_intro_6-md.png"),
                srcset: {
                    sm: require("../../../img/we_intro_6-sm.png"),
                    md: require("../../../img/we_intro_6-md.png"),
                    lg: require("../../../img/we_intro_6-lg.png"),
                    xl: require("../../../img/we_intro_6-xl.png")
                }
            },
            // {
            //     url: require("../../../img/ms_intro_7_uploadwindow-md.png"),
            //     srcset: {
            //         sm: require("../../../img/ms_intro_7_uploadwindow-sm.png"),
            //         md: require("../../../img/ms_intro_7_uploadwindow-md.png"),
            //         lg: require("../../../img/ms_intro_7_uploadwindow-lg.png"),
            //         xl: require("../../../img/ms_intro_7_uploadwindow-xl.png")
            //     }
            // }
        ]
    },
    m6: {
        title: dict['w6title'],
        paragraph: dict['w6description'],
        img: [
            {
                url: require("../../../img/we_intro_7-md.png"),
                srcset: {
                    sm: require("../../../img/we_intro_7-sm.png"),
                    md: require("../../../img/we_intro_7-md.png"),
                    lg: require("../../../img/we_intro_7-lg.png"),
                    xl: require("../../../img/we_intro_7-xl.png")
                }
            },
            // {
            //     url: require("../../../img/ms_intro_7_uploadwindow-md.png"),
            //     srcset: {
            //         sm: require("../../../img/ms_intro_7_uploadwindow-sm.png"),
            //         md: require("../../../img/ms_intro_7_uploadwindow-md.png"),
            //         lg: require("../../../img/ms_intro_7_uploadwindow-lg.png"),
            //         xl: require("../../../img/ms_intro_7_uploadwindow-xl.png")
            //     }
            // }
        ]
    }
})


export default tutorial