import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import config from './components/init/config'
import rootReducer from './reducers'

let composeEnhancers = compose
if(config.currentEnv !== 'prod')
{
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const composeEnhancers = compose


const initState = (localStorage['redux-store']) ? JSON.parse(localStorage.getItem('redux-store')) : {}
export default () => {
  // let store = createStore(rootReducer, initState, applyMiddleware(thunk))
  let store = createStore(rootReducer, initState, composeEnhancers(applyMiddleware(thunk)))
  return { store }
}