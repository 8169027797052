import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withPreLoader from './withPreLoader'
import { colors } from '../init/colors'

import BeatLoader from "react-spinners/BeatLoader"

const withAdminControl = (WrappedComponent) => {

    class withAdminControlComponent extends React.Component
    {
        constructor(props)
        {
            super(props)
            this.state = {
                adminChecked: false,
                admin: false
            }
        }
        async componentDidMount()
        {
            let admin = false
            // console.log(this.props?.auth?.role === 0)
            if(this.props?.auth?.role === 0)
            {
                // console.log(this.props)
                admin=true
            }
            this.setState({
                adminChecked: true,
                admin:admin
            })
        }

        render()
        {
            if(!this.state.adminChecked)
            {
                return <React.Fragment>
                <div style={{ width: '100vw', height: '100vh' }} className='d-flex justify-content-center align-items-center flex-column'>
                    <h2 className='white-title'>Please wait while the system is loading the data</h2>
                    <BeatLoader
                        // css={override}
                        css={`size: 15;`}
                        size={15}
                        color={colors.lightYellow}
                        loading={true}
                    />
                </div>
                </React.Fragment>
            }
            // console.log(this.state.admin)
            if(!this.state.admin)
            {
                return <React.Fragment>
                <div style={{ width: '100vw', height: '100vh' }} className='d-flex justify-content-center align-items-center flex-column'>
                    <h2 className='white-title'>Access denied. You do not have admin permission</h2>

                </div>
                </React.Fragment>
            }

            return <WrappedComponent {...this.props} isAdmin={this.state.admin} />

        }
    }

    // const mapStateToProps = state => {
    //     return {
    //         auth: state.userReducer.auth,
    //         dict: state.configReducer.lang.dict,
    //         lang: state.configReducer.lang.lang,
    //         accountInfo: state.userReducer.accountInfo,
    //         currentDay: state.daysReducer.currentDay,
    //         activityLogs: state.daysReducer.activityLogs
    //     }
    // }
    
    // const mapDispatchToProps = dispatch => 
    //     ({
    //         changeLang: (lang = defaultLang) => dispatch(changeLang(lang)),
    //         getCurrentDayLogs: userId => dispatch(getCurrentDayLogs(userId)),
    //         loadActivityLog: (userId, dayLogId) => dispatch(loadActivityLog(userId, dayLogId)),
    //         getCurrentJournalDay: (usersId, dayLogsId, day) => dispatch(getCurrentJournalDay(usersId, dayLogsId, day)),
    //         // upsertConfig: (userId, param) => dispatch(upsertConfig(userId, param)),
    //         enableResetConfig: reset => dispatch(enableResetConfig(reset)),
    //     })
    return withRouter(withPreLoader(withAdminControlComponent))
}

export default withAdminControl