import imageCompression from 'browser-image-compression'
import heic2any from "heic2any"

const fs = require('fs')
const ip = require('ip')

class GeneralHelper {
    constructor()
    {

    }

    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    localStoragePolyfill() {
        window.localStorage = {
          getItem: function (sKey) {
            if (!sKey || !this.hasOwnProperty(sKey)) { return null; }
            return unescape(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
          },
          key: function (nKeyId) {
            return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, "").split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
          },
          setItem: function (sKey, sValue) {
            if(!sKey) { return; }
            document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
            this.length = document.cookie.match(/\=/g).length;
          },
          length: 0,
          removeItem: function (sKey) {
            if (!sKey || !this.hasOwnProperty(sKey)) { return; }
            document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            this.length--;
          },
          hasOwnProperty: function (sKey) {
            return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
          }
        };
        window.localStorage.length = (document.cookie.match(/\=/g) || window.localStorage).length;
        return;
    }

    makeid(length = 20) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      
        for (var i = 0; i < length; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text;
    }

    hash(limit = 6)
    {
        const date = new Date();
        const year = String(date.getFullYear());
        const month = String(date.getMonth());
        const day = String(date.getDay());
        const sec = String(date.getMilliseconds());
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        for (let i = 0; i < limit; i++){
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        const str = year + month + day + sec + "-" + text;
        return str;
    }

    randomNo(limit = 4)
    {
      let text = ""
      const possible = "0123456789"
      for (let i = 0; i < limit; i++){
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text
    }

    parseUrlQuery(targetParam = '', promise = false) {
        const urlParams = new URLSearchParams(window.location.search);
        if(promise)
        {
          if(targetParam)
          {
            return urlParams.get(targetParam);
          }
          return new Promise(res => {
            return res(urlParams);
          });
        }
        if(targetParam)
        {
          return urlParams.get(targetParam);
        }
        return urlParams;
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
      
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
      
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
      
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
      
            var byteArray = new Uint8Array(byteNumbers);
      
            byteArrays.push(byteArray);
        }
      
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    base64ToImage(img) {
        // strip off the data: url prefix to get just the base64-encoded bytes
        const ext = img.includes('image/jpeg') ? '.jpeg' : '.png';
        var data = img.replace(/^data:image\/\w+;base64,/, "");
        var buf = new Buffer(data, 'base64');
        fs.writeFile(`image${ext}`, buf);
    }

    dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
    }
    
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
      
        bytes.forEach((b) => binary += String.fromCharCode(b));
      
        return window.btoa(binary);
    }

    getIp()
    {
        return ip.address()
    }

    trimText(input = '')
    {
      switch(typeof input)
      {
        case 'object':
          let newObj = {}
          Object.keys(input).map(key => {
            const targetValue = input[key].trim()
            newObj[key] = targetValue
          })
          return newObj
        case 'array':
          let newArr = []
          input.forEach(item => {
            newArr.push(item.trim())
          })
          return newArr
        case 'string':
        default:
          return input.trim()
      }
    }

    /*
    capitalizeFirstLetter(input) { 
      const newArr = input.split(' ')
      let finalArr = []
      newArr.forEach(item => {
        const firstLetter = item[0].toUpperCase()
        const restLetter = item.slice(1).toLowerCase()
        const newStr = firstLetter+restLetter
        finalArr.push(newStr)
      })
      return finalArr.join('')
    }
    */
    capitalizeSentenceFirstLetter(input) {
      const firstLetter = input[0].toUpperCase()
      const restLetter = input.slice(1).toLowerCase()
      // const newStr = 
      return firstLetter + restLetter
    }

    async compressImage(file)
    {
      const options = { 
        maxSizeMB: 1.5,          // (default: Number.POSITIVE_INFINITY)
        maxWidthOrHeight: 768,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
        useWebWorker: true,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
        maxIteration: 10        // optional, max number of iteration to compress the image (default: 10)
      }

      try
      {
        const compressedFile = await imageCompression(file, options)
        return compressedFile
      }
      catch(err)
      {
        return false
      }
    }

    async convertHEICtoJpeg(file) {
      let inputBlob = await heic2any({
        // required: the HEIF blob file
        blob: file,
        // (optional) MIME type of the target file
        // it can be "image/jpeg", "image/png" or "image/gif"
        // defaults to "image/png"
        toType: "image/jpeg",
        // conversion quality
        // a number ranging from 0 to 1
        quality: 1
      })
      inputBlob.name = file.name
      inputBlob.lastModifiedDate = new Date()
      return new File([inputBlob], file.name, {type: 'image/jpeg', lastModified: Date.now()})
    }

    async resetOrientation(file, name) {
      const srcOrientation = await imageCompression.getExifOrientation(file).then(r => r).catch(e => false)
      const srcBase64 = await imageCompression.getDataUrlFromFile(file).then(r => r).catch(e => false)
      if(!srcOrientation || !srcBase64) return false

      var img = new Image();    
    
      img.onload = async () => {
        var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d");
    
        // set proper canvas dimensions before transform & export
        if (4 < srcOrientation && srcOrientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }
    
        // transform context before drawing image
        switch (srcOrientation) {
          case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
          case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
          case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
          case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
          case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
          case 7: ctx.transform(0, -1, -1, 0, height, width); break;
          case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
          default: break;
        }
    
        // draw image
        ctx.drawImage(img, 0, 0);
    
        // export base64
        return canvas.toDataURL()
      };
      
      return img.src = this.dataURLtoFile(srcBase64, name)
    }

    async convertBlobToBase64(blob)
    {
      const fr = new FileReader()
      // console.log("convert blob to file")
      fr.readAsDataURL(blob)
      fr.onload = (e) => {
          // console.log(e.target.result)
          return e.target.result
      }
    }

    fileToBase64(file)
    {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    }

    fileToBuffer(file)
    {
      return new Promise((res, rej) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => res(reader.result)
        reader.onerror = err => rej(err)
      })
    }

    // map the key/value pair of two JSON objects, and update the old object with the given new key/value pair
    joinObjs(toUpdate, newObj)
    {
      let newToUpdate = {...toUpdate}
      Object.keys(newObj).map((k, i) => {
        newToUpdate[k] = newObj[k]
      })
      return newToUpdate
    }
  
  /** Enter the timestamp to compare dates, if d2 is newer, return true, or false */
  compareDate(d1, d2, hrs) {
    let date1 = new Date(parseInt(d1, 10));
    let date2 = new Date(parseInt(d2, 10));
    // compare year
    if( date1.getFullYear() > date2.getFullYear() )
    {
      return false; // date1 year is larger than date2
    }
    // if date1 year is smaller or the same than date2, check the month
    // compare month
    else if( date1.getMonth() > date2.getMonth() ) 
    {
      return false;
    }
    // compare date
    else if( date1.getDate() > date2.getDate() )
    {
      return false;
    }
    // compare hour
    else if( date1.getHours() > date2.getHours() )
    {
      return false;
    }
    else if( date2.getHours() - date1.getHours() < hrs  )
    {
      return false;
    }
    return true;
  }
  
  /** Calculate the duration of between two time stamps */
  calcDuration(t1, t2) {
    let h,m,s;
    // parse the time and get the timestring in milliseconds .getTime()
    const t1Str = new Date(t1);
    const t2Str = new Date(t2);
    // minuse the time
    let result = t2Str.getTime() - t1Str.getTime();
    // get the time in format (h:m:s) - 1 second is 1000 milliseconds
    //Step1: check if it's over 1 hour - very rare, but if it's over 1 hour, then divide it by 3600000
    //Step2: check if it's over 1 minute - rare, divide it by 60000
    //Step3: if it's over 1 second - divide it by 1000
    switch(result)
    {
      case result >= 3600000:
        h = result / 3600000;
        var hrmd = result%3600000;
        m = hrmd/60000;
        var mrmd = hrmd%60000;
        s = mrmd/1000;
        return `${h}:${m}:${s}`;
      case result >= 60000 & result < 3600000:
        h = 0;
        m = result/60000;
        var mrmd = result%60000;
        s = mrmd/1000;
        return `${h}:${m}:${s}`;
      case result >= 1000 & result < 60000:
        h = 0; m = 0;
        s = result/1000;
        return `${h}:${m}:${s}`;
      default:
        h = 0; m = 0;
        s = result/1000;
        return `${h}:${m}:${s}`;
    }
    
  }
  
  // check if drag and drop is supported in the browser
  validateDragNDropCompatibility() {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  }
  
  postMessage(command, details = {}) {
    window.postMessage({
        origin: 'canvas',
        for: command,
        details: details
    }, '*');
  }
  
  fetchS3Img(getImgUrl, key) {
    const param = '/' + key;
    return fetch(getImgUrl + param, {
        mode: 'cors',
        headers: new Headers({
            'Access-Control-Allow-Origin': '*'
        }),
        method: 'GET',
    })
    .then(response => response.json())
    .then(responseJson => {
      return responseJson;
    })
    .catch(err => {
      return false;
    });
  }
  
  /**
   * retrieve static images from s3 bucket, return the base64 encoded data
   * @param {string} filename 
   * usage:
      fetchStaticImg('your_filename')
      .then(file => {
        if(!file)
        {
          // do something
        }
        // do something
      });
   */
  
  fetchStaticImg(filename) {
    return fetch(`/v1/getS3StaticImages/${filename}`)
    .then(response => response.json())
    .then(base64data => {
      if(!base64data.success)
      {
        return false;
      }
      return base64data.data;
    });
  }
  
  /**
   * capitalize the first letter of a string
   * param {string} string
   * usage:
    var modifiedStr = capitalizeFirstLetter('example');
  */
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  /**
   * convert the results (array) retrieved from MySQL database into an object with the certain column as index key
   * return a Promise
   * @param {string} nameOfTheKey 
   * usage:
    // each item in the array needs to contain the needle, such as "journal" as a needle:
    // ex: [
    //   {
    //     message_group: 'journal',
    //     message: 'hello, welcome to your journal'
    //   },
    //   {
    //     message_group: 'journal',
    //     message: 'Thank you for filling in the journal.'
    //   }
    // ]
  
    var message_key = 'journal';
    arrToObjWithColumnKey(message_key)
    .then(obj => {
      // do something
    });
   */
  arrToObjWithColumnKey(arr, needle)
  {
    var obj = {};
    var promiseArr = [];
    arr.forEach(item => {
      const loopPromise = new Promise((res) => {
        obj[item[needle]] = item;
        res();
      });
      promiseArr.push(loopPromise);
    });
    return Promise.all(promiseArr)
    .then(() => {
      return obj;
    });
  }

}
export default new GeneralHelper()