import React, { useEffect, useState } from 'react'
import GetImg from '../../../assets/GetImg'
import DropDown from '../../../assets/v2/DropDown'
import { defaultIconSets } from '../../../init/initApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { colors } from '../../../init/colors'
import ReactTooltip from "react-tooltip"

import './MemorialSpace.scss'

const iconSets = defaultIconSets()

const ChooseDecorate = props => {
    const [displayCompleteBtn, updateDisplayCompeteBtn] = useState(true)

    const [useTemplate, updateUseTemplate] = useState(false)
    const [displayOptions, updateDisplayOptions] = useState(false)
    const [flowerOption, updateFlowerOption] = useState(false)
    const [photoOption, updatePhotoOption] = useState(false)

    const lang = props.dict.memorial.arrange
    const { 
        currentDay,
        addActivityLog,
        auth,
        decorate,
        handleOffering,
        disableCanvasInteraction,
        warningBeforeEnablingCanvasInteraction
    } = props

    let activityLogs = {}
    // console.log(props)
    if(currentDay.activity_logs !== null)
    {
        activityLogs = typeof currentDay.activity_logs === 'string' ? JSON.parse(currentDay.activity_logs) : currentDay.activity_logs
    }

    // const optionsArr = [
    //     {
    //         href:"#",
    //         title: lang.fromTemplate,
    //         handleOptionClick: () => {
    //             // props.handleOffering('flowerHydrangea', 'predefinedOffering', false)
    //             updateUseTemplate(true)
    //             updateDisplayOptions(false)
    //             props.decorate(lang.fromTemplate)
    //         }
    //     },
    //     {
    //         href:"#",
    //         title: lang.decorate,
    //         handleOptionClick: () => {
    //             updateUseTemplate(false)
    //             updateDisplayOptions(true)
    //             props.decorate(lang.decorate)
    //         }
    //     }
    // ]
    const handleOptionClick = (templateNo, triggerWarning = true) => {
        // console.log('template no '+templateNo)
        decorate(lang.templates[templateNo]['value'], () => {
            handleOffering(lang.templates[templateNo]['key'], 'predefinedOffering', false)
            updateUseTemplate(false)
        }, triggerWarning)

    }

    // user choose to use the template
    const furtherOptionsArr = [
        {
            href:"#",
            title: lang.templates[0]['value'],
            handleOptionClick: () => {
                // console.log('template 1 clicked')
                addActivityLog(auth.id, currentDay.id, currentDay.day, 'Decorate using template', lang.templates[0]['value'], {})
                handleOptionClick(0)
            }
        },
        {
            href:"#",
            title: lang.templates[1]['value'],
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, 'Decorate using template', lang.templates[1]['value'], {})
                handleOptionClick(1)
            }
        },
        {
            href:"#",
            title: lang.templates[2]['value'],
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, 'Decorate using template', lang.templates[2]['value'], {})
                handleOptionClick(2)
            }
        },
        {
            href:"#",
            title: lang.templates[3]['value'],
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, 'Decorate using template', lang.templates[3]['value'], {})
                handleOptionClick(3)
            }
        },
        {
            href:"#",
            title: lang.templates[4]['value'],
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, 'Decorate using template', lang.templates[4]['value'], {})
                handleOptionClick(4)
            }
        }
    ]

    // user choose to decorate by themselves
    const decorateOptions = [
        {
            href:"#",
            title: lang.decorateItems.candle,
            handleOptionClick: () => {
                // props.decorate(lang.decorateItems.candle)
                if(!disableCanvasInteraction)
                {
                    addActivityLog(auth.id, currentDay.id, currentDay.day, 'DIY candle', lang.decorateItems.candle, {})
                    handleOffering(iconSets[0]['btnValue'], 'currentOffering', iconSets[0]['triggerOptions'])
                    updateDisplayOptions(false)
                    return
                }
                warningBeforeEnablingCanvasInteraction(() => {
                    addActivityLog(auth.id, currentDay.id, currentDay.day, 'DIY candle', lang.decorateItems.candle, {})
                    handleOffering(iconSets[0]['btnValue'], 'currentOffering', iconSets[0]['triggerOptions'])
                    updateDisplayOptions(false)
                })
            },
            img: {
                src: iconSets[0]['imgSrc'],
                alt: lang.decorateItems.candle
            }
        },
        {
            href:"#",
            title: lang.decorateItems.flower,
            handleOptionClick: () => {
                if(!disableCanvasInteraction)
                {
                    updateFlowerOption(true)
                    return
                    // addActivityLog(auth.id, currentDay.id, currentDay.day, 'DIY candle', lang.decorateItems.candle, {})
                    // handleOffering(iconSets[0]['btnValue'], 'currentOffering', iconSets[0]['triggerOptions'])
                }
                warningBeforeEnablingCanvasInteraction(() => {
                    updateFlowerOption(true)
                })
                // props.handleOffering(iconSets[1]['btnValue'], 'currentOffering', iconSets[1]['triggerOptions'])
            },
            img: {
                src: iconSets[1]['imgSrc'],
                alt: lang.decorateItems.flower
            }
        },
        {
            href:"#",
            title: lang.decorateItems.album,
            handleOptionClick: () => {
                if(!disableCanvasInteraction)
                {
                    updatePhotoOption(true)
                    return
                }
                warningBeforeEnablingCanvasInteraction(() => {
                    updatePhotoOption(true)
                })
                // props.handleOffering(iconSets[2]['btnValue'], 'currentOffering', iconSets[2]['triggerOptions'])
                
            },
            img: {
                src: iconSets[2]['imgSrc'],
                alt: lang.decorateItems.album
            }
        }
    ]

    const flowerOptions = [
        {
            href:"#",
            title: lang.flowers.orchid.title,
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, `DIY ${lang.flowers.orchid.title}`, lang.flowers.orchid.title, {})
                handleOffering('flowerOrchid', 'currentOffering', false)
                updateFlowerOption(false)
                updateDisplayOptions(false)
            },
            img: {
                src: require('../../../../img/orchid_icon-md.png'),
                srcset: {
                    sm: require('../../../../img/orchid_icon-sm.png'),
                    md: require('../../../../img/orchid_icon-md.png'),
                    lg: require('../../../../img/orchid_icon-md.png'),
                    xl: require('../../../../img/orchid_icon-md.png')
                },
                alt: lang.flowers.orchid.title
            }
        },
        {
            href:"#",
            title: lang.flowers.rose.title,
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, `DIY ${lang.flowers.rose.title}`, lang.flowers.rose.title, {})
                handleOffering('flowerRose', 'currentOffering', false)
                updateFlowerOption(false)
                updateDisplayOptions(false)
            },
            img: {
                src: require('../../../../img/rose_icon-md.png'),
                srcset: {
                    sm: require('../../../../img/rose_icon-sm.png'),
                    md: require('../../../../img/rose_icon-md.png'),
                    lg: require('../../../../img/rose_icon-md.png'),
                    xl: require('../../../../img/rose_icon-md.png')
                },
                alt: lang.flowers.rose.title
            }
        },
        {
            href:"#",
            title: lang.flowers.lily.title,
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, `DIY ${lang.flowers.lily.title}`, lang.flowers.lily.title, {})
                handleOffering('flowerLily', 'currentOffering', false)
                updateFlowerOption(false)
                updateDisplayOptions(false)
            },
            img: {
                src: require('../../../../img/lily_icon-md.png'),
                srcset: {
                    sm: require('../../../../img/lily_icon-sm.png'),
                    md: require('../../../../img/lily_icon-md.png'),
                    lg: require('../../../../img/lily_icon-md.png'),
                    xl: require('../../../../img/lily_icon-md.png')
                },
                alt: lang.flowers.lily.title
            }
        },
        {
            href:"#",
            title: lang.flowers.hydrangea.title,
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, `DIY ${lang.flowers.hydrangea.title}`, lang.flowers.lily.hydrangea, {})
                handleOffering('flowerHydrangea', 'currentOffering', false)
                updateFlowerOption(false)
                updateDisplayOptions(false)
            },
            img: {
                src: require('../../../../img/hydran_icon-md.png'),
                srcset: {
                    sm: require('../../../../img/hydran_icon-sm.png'),
                    md: require('../../../../img/hydran_icon-md.png'),
                    lg: require('../../../../img/hydran_icon-md.png'),
                    xl: require('../../../../img/hydran_icon-md.png')
                },
                alt: lang.flowers.hydrangea.title
            }
        },
        {
            href:"#",
            title: lang.flowers.chrysanthemums.title,
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, `DIY ${lang.flowers.chrysanthemums.title}`, lang.flowers.lily.chrysanthemums, {})
                handleOffering('flowerChrysan', 'currentOffering', false)
                updateFlowerOption(false)
                updateDisplayOptions(false)
            },
            img: {
                src: require('../../../../img/chrys_icon-md.png'),
                srcset: {
                    sm: require('../../../../img/chrys_icon-sm.png'),
                    md: require('../../../../img/chrys_icon-md.png'),
                    lg: require('../../../../img/chrys_icon-md.png'),
                    xl: require('../../../../img/chrys_icon-md.png')
                },
                alt: lang.flowers.chrysanthemums.title
            }
        }
    ]

    const albumOptions = [
        {
            href:"#",
            title: lang.horizontal,
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, `DIY ${lang.horizontal}`, lang.horizontal, {})
                handleOffering('photoFrameH', 'currentOffering', true)
                updatePhotoOption(false)
                updateDisplayOptions(false)
            },
            img: {
                src: require('../../../../img/photo_frame_h-sm.png'),
                alt: lang.horizontal
            }
        },
        {
            href:"#",
            title: lang.vertical,
            handleOptionClick: () => {
                addActivityLog(auth.id, currentDay.id, currentDay.day, `DIY ${lang.vertical}`, lang.vertical, {})
                handleOffering('photoFrameV', 'currentOffering', true)
                updatePhotoOption(false)
                updateDisplayOptions(false)
            },
            img: {
                src: require('../../../../img/photo_frame_v-sm.png'),
                alt: lang.vertical
            }
        }
    ]


    useEffect(() => {
        if(activityLogs !== null && activityLogs !== undefined && activityLogs.hasOwnProperty('decorate') && activityLogs.decorate === lang.fromTemplate)
        {
            updateUseTemplate(true)
        }
        if(activityLogs !== null && activityLogs !== undefined && activityLogs.hasOwnProperty('decorate') && activityLogs.decorate !== lang.fromTemplate)
        {
            // Object.keys()
            lang.templates.map((t, idx) => {
                if(t.value === activityLogs['decorate'])
                {
                    // console.log('value equals')
                    handleOptionClick(idx, false)
                }
                // console.log(idx)
            })
            // updateUseTemplate(true)
        }
    }, [])

    return <React.Fragment>
    <div className='d-flex operation-panel flex-sm-column flex-md-row'>
    {/*<div className='decorate-panel p-sm-2 p-md-3 p-lg-4'>
        <div className='row'>
            <div className='col-sm-12'>
            <h6 className='m-0 memory-home-subtitle'>{lang['title']}</h6>
            <div data-tip="Reset space" onClick={props.resetOffering} className='memorial-reset-btn'>
            <FontAwesomeIcon style={{ width: '30px' }} className='cursor-pointer' icon={faUndoAlt} />
            </div>
            </div>
            <hr />
            <div className='col-sm-12 d-flex justify-content-center'>
                <DecorateIcon 
                    text={lang['decorate'].toUpperCase()}
                    alt={lang['decorate'].toUpperCase()}
                    img={require('../../../../img/diy_icon.svg')}
                    activeImg={require('../../../../img/diy_icon_selected.svg')}
                    onClick={() => {
                        updateDisplayCompeteBtn(true)
                        const d = displayOptions
                        updateDisplayOptions(!d)
                        updateUseTemplate(false)
                    }}
                />
                <DecorateIcon 
                    text={lang['fromTemplate'].toUpperCase()}
                    alt={lang['fromTemplate'].toUpperCase()}
                    img={require('../../../../img/template_icon.svg')}
                    activeImg={require('../../../../img/template_icon_selected.svg')}
                    onClick={() => {
                        updateDisplayCompeteBtn(true)
                        const t = useTemplate
                        updateUseTemplate(!t)
                        updateDisplayOptions(false)
                    }}
                />
            </div>
        </div>
        {displayCompleteBtn ?<div data-tip={lang['completeBtnTip']} className='w-100 complete-button p-3 text-center' onClick={props.complete}>
            <FontAwesomeIcon style={{ width: '30px' }} className='cursor-pointer' icon={faCheckCircle} />
            <span>Complete</span>
        </div>:null}
    </div>*/}
    <div className='decorate-panel'>
        <div className='p-2 text-center decorate-title'>
            <h6 className='m-0 memory-home-subtitle'>{lang['title']}</h6>
        </div>
        <hr className='d-none d-lg-block' />
        <div className='d-flex justify-content-between decorate-icon-bar'>
            <DecorateIcon 
                text={lang['decorate'].toUpperCase()}
                alt={lang['decorate'].toUpperCase()}
                img={require('../../../../img/diy_icon.svg')}
                activeImg={require('../../../../img/diy_icon_selected.svg')}
                onClick={() => {
                    updateDisplayCompeteBtn(true)
                    const d = displayOptions
                    updateDisplayOptions(!d)
                    updateUseTemplate(false)
                }}
            />
            <DecorateIcon 
                text={lang['fromTemplate'].toUpperCase()}
                alt={lang['fromTemplate'].toUpperCase()}
                img={require('../../../../img/template_icon.svg')}
                activeImg={require('../../../../img/template_icon_selected.svg')}
                onClick={() => {
                    updateDisplayCompeteBtn(true)
                    const t = useTemplate
                    updateUseTemplate(!t)
                    updateDisplayOptions(false)
                }}
            />

            <DecorateIcon 
                text={lang['reset'].toUpperCase()}
                alt={lang['reset'].toUpperCase()}
                withIcon={faUndoAlt}
                img={require('../../../../img/template_icon.svg')}
                activeImg={require('../../../../img/template_icon_selected.svg')}
                onClick={props.resetOffering}
            />
            <DecorateIcon 
                text={lang['complete'].toUpperCase()}
                alt={lang['complete'].toUpperCase()}
                withIcon={faCheckCircle}
                img={require('../../../../img/diy_icon.svg')}
                activeImg={require('../../../../img/diy_icon_selected.svg')}
                onClick={props.complete}
            />
        </div>
    </div>

    {useTemplate ? 
        <DropDown
            id='use-template'
            title={lang.selectTemplate}
            options={furtherOptionsArr}
            className='steps-dropdown'
        />
        :null
    }

    {displayOptions ? 
        <DropDown 
            id='custom-decorate'
            title={lang.selectDecorate}
            options={decorateOptions}
            className='steps-dropdown'
        />
        :null
    }

    {flowerOption ?
        <DropDown 
            id='flower-option'
            title={lang.selectFlower}
            options={flowerOptions}
            className='steps-dropdown'
        />
        :null
    }

    {photoOption ?
        <DropDown 
            id='photo-option'
            title={lang.selectAlbum}
            options={albumOptions}
            className='steps-dropdown'
        />
        :null
    }
    </div>
    <ReactTooltip />
    </React.Fragment>
}

const DecorateIcon = props => {
    const [active, setActive] = useState(false)

    const { 
        withIcon,
        onClick,
        activeImg,
        img,
        alt,
        text
    } = props

    return (<div 
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            onClick={onClick}
            className='d-flex flex-column justify-content-center align-items-center decorate-icon py-2'>
                {withIcon ? 
                    <div className={active ? 'decorate-icon-container decorative-icon-container-active':'decorate-icon-container'}>
                        <FontAwesomeIcon className={active ? 'mb-2 decorate-icon-active w-100':'mb-2 w-100'} icon={withIcon} />
                    </div>
                :
                <div className={active ? 'decorate-icon-container decorative-icon-container-active':'decorate-icon-container'}>
                    <GetImg 
                        local
                        className={['mb-2', 'w-100']}
                        onClick={() => null}
                        imageUrl={active ? activeImg: img} // "name-of-the-img.jpg"
                        srcset={{
                            sm: active ? activeImg: img,
                            md: active ? activeImg: img,
                            lg: active ? activeImg: img,
                            xl: active ? activeImg: img
                        }}
                        alt={alt}
                    />
                </div>
                }
                <p className='m-0'>{text}</p>
    </div>)
}

export default ChooseDecorate