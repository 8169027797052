import React from 'react'

const LowRiskAnswers = () => {
    return {
        "DEMO": {
          "DEMO-01": {
            "answer": "Female",
            "value": "2"
          },
          "DEMO-02": {
            "answer": "Heterosexual/Straight",
            "value": "3"
          },
          "DEMO-03": {
            "answer": "No",
            "value": "2"
          },
          "DEMO-04": {
            "answer": "Asian",
            "value": "2"
          },
          "DEMO-05": {
            "answer": "Graduate/Professional",
            "value": "5"
          },
          "DEMO-06": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-07": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-08": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-09": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-10": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-11": {
            "answer": "Manhattan",
            "value": "Manhattan"
          },
          "DEMO-12": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-13": {
            "answer": "33",
            "value": "33"
          },
          "DEMO-14": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-15": {
            "answer": "No",
            "value": "2"
          },
          "DEMO-16": {
            "answer": "Yes",
            "value": "1"
          },
          "DEMO-18": {
            "answer": "No",
            "value": "2"
          },
          "DEMO-19": {
            "answer": "Researchmatch.org",
            "value": "1"
          }
        },
        "CDD": {
          "CDD-01": {
            "answer": "Grandparent",
            "value": "4"
          },
          "CDD-02": {
            "answer": "28",
            "value": "28"
          },
          "CDD-03": {
            "answer": "Home",
            "value": "1"
          },
          "CDD-04": {
            "answer": "No",
            "value": "2"
          },
          "CDD-05": {
            "answer": "Natural anticipated death (e.g. terminal illness)",
            "value": "1"
          }
        },
        "PG13": {
          "PG-01": {
            "answer": "Yes",
            "value": "1"
          },
          "PG-02": {
            "answer": "36",
            "value": "36"
          },
          "PG-03": {
            "answer": "Not at all",
            "value": "1"
          },
          "PG-04": {
            "answer": "Slightly",
            "value": "2"
          },
          "PG-05": {
            "answer": "Slightly",
            "value": "2"
          },
          "PG-06": {
            "answer": "Not at all",
            "value": "1"
          },
          "PG-07": {
            "answer": "Not at all",
            "value": "1"
          },
          "PG-08": {
            "answer": "Not at all",
            "value": "1"
          },
          "PG-09": {
            "answer": "Slightly",
            "value": "2"
          },
          "PG-10": {
            "answer": "Slightly",
            "value": "2"
          },
          "PG-11": {
            "answer": "Not at all",
            "value": "1"
          },
          "PG-12": {
            "answer": "Slightly",
            "value": "2"
          },
          "PG-13": {
            "answer": "No",
            "value": "2"
          }
        },
        "CSSRS": {
          "CSSRS-01": {
            "answer": "No",
            "value": "2"
          },
          "CSSRS-02": {
            "answer": "No",
            "value": "2"
          },
          "CSSRS-03": {
            "answer": "No",
            "value": "2"
          },
          "CSSRS-ineligible": {}
        }
      }
}

export default LowRiskAnswers