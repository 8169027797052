import methods from '../methods'
import { combineReducers } from 'redux'
import { langPack, defaultLang } from '../components/lang/init'

const publicUrl = (state = '/', action) => {
    switch(action.type)
    {
        case methods.PUBLIC_URL:
            return action.payload
        default:
            return state
    }
}

const systemFeedback = (state = '', action) => {
    switch(action.type)
    {
        case methods.ADD_SYSTEM_FEEDBACK:
            return action.payload
        default: return state
    }
}

const initConfirmObj = {
    title: '',
    value: ''
}
const confirmMessage = (state = initConfirmObj,  action) => {
    switch(action.type)
    {
        case methods.INIT_CONFIRM:
            return action.payload
        default: return state
    }
}

const toConfirmPayload = (state = {}, action) => {
    switch(action.type)
    {
        case methods.STORE_PAYLOAD_BEFORE_CONFIRM:
            return action.payload
        default: return state
    }
}

const instructions = (state = [], action) => {
    let newInstructions = [...state]
    switch(action.type)
    {
        case methods.ADD_INSTRUCTION:
            newInstructions.push(action.payload)
            return newInstructions
        case methods.LOAD_INSTRUCTION:
            return action.payload
        case methods.REMOVE_INSTRUCTION:
            // console.log(newInstructions)
            newInstructions.forEach((instruction, idx) => {
            // console.log(action.payload)

                if(instruction.key === action.payload.key)
                {
                    newInstructions.splice(idx, 1)
                }
            })
            return newInstructions
        default: return state
    }
}

const defaultSetting = {
    lang: defaultLang,
    dict: langPack[defaultLang]
}

const lang = (state = defaultSetting, action) => {
    switch(action.type)
    {
        case methods.LANG:
            return {
                lang: action.payload,
                dict: langPack[action.payload]
            }
        default: return state
    }
}

const resetConfig = (state = false, action) => {
    switch(action.type)
    {
        case methods.ENABLE_RESET_CONFIG:
            return action.payload
        default: return state
    }
}

const adminboardPage = (state = 'prescreening', action) => {
    switch(action.type)
    {
        case methods.LOAD_ADMINBOARD_PAGE:
            return action.payload
        default: return state
    }
}

const simpleDataFetch = (state = true, action) => {
    switch(action.type)
    {
        case methods.UPDATE_DISPLAY_PREFERENCE:
            return action.payload
        default: return state
    }
}

export default combineReducers({ 
    lang, 
    publicUrl, 
    systemFeedback, 
    confirmMessage, 
    toConfirmPayload, 
    instructions, 
    resetConfig,
    adminboardPage,
    simpleDataFetch
})