import jstz from 'jstz';
// import Localstorage from './LocalStorage';
import { sessionKeys, tbs } from '../../init/initApp';
// import { arrToObjWithColumnKey, hash } from '../../init/general';
import EH from './EmailHelper'
// import DB from './DatabaseHelper'
import momentjs from 'moment'
import FetchWrapper from './FetchWrapper';

const Fetch = new FetchWrapper()
const moment = require('moment-timezone');

class SystemTimeManagement {
    constructor() {
        this.timezone = jstz.determine();
    }

    getCurrentLocalTimestamp(promise = false) {
        if(!promise)
        {
            return this.formatLocalTime('', false);
        }
        return this.formatLocalTime('', true);
    }
    getCurrentUTCTimestamp(promise = false) {
        if(!promise)
        {
            return this.formatUTCTime('', false);
        }
        return this.formatUTCTime('', true);
    }

    getTimezoneName(promise = true) {
        if(promise)
        {
            return new Promise(res => {
                return res(this.timezone.name());
            });
        }
        return this.timezone.name();
    }

    // get the local time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatLocalTime(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = momentjs(timestamp)
            const y = now.year()
            const m = now.month() < 9 ? "0" + (now.month() + 1) : now.month() + 1;
            var d = now.date() < 10 ? "0" + now.date() : now.date();
            var h = now.hour() < 10 ? "0" + now.hour() : now.hour();
            var mm = now.minute() < 10 ? "0" + now.minute() : now.minute();
            var s = now.second() < 10 ? "0" + now.second() : now.second();
            const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = moment()
        const y = now.year()
        const m = now.month() < 9 ? "0" + (now.month() + 1) : now.month() + 1;
        var d = now.date() < 10 ? "0" + now.date() : now.date();
        var h = now.hour() < 10 ? "0" + now.hour() : now.hour();
        var mm = now.minute() < 10 ? "0" + now.minute() : now.minute();
        var s = now.second() < 10 ? "0" + now.second() : now.second();
        const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }

    // get the local time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatLocalDate(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = moment(timestamp)
            // var now = new Date(timestamp);
            var y = now.year()
            var m = now.month() < 9 ? "0" + (now.month() + 1) : now.month() + 1;
            var d = now.date() < 10 ? "0" + now.date() : now.date();
            const completeStr = `${y}-${m}-${d}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = moment(moment())
        var y = now.year()
        var m = now.month() < 9 ? "0" + (now.month() + 1) : now.month() + 1;
        var d = now.date() < 10 ? "0" + now.date() : now.date();
        const completeStr = `${y}-${m}-${d}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }


    // get the current time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatUTCTime(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = momentjs.utc(timestamp)
            const y = now.year()
            const m = now.month() < 9 ? "0" + (now.month() + 1) : now.month() + 1;
            var d = now.date() < 10 ? "0" + now.date() : now.date();
            var h = now.hour() < 10 ? "0" + now.hour() : now.hour();
            var mm = now.minute() < 10 ? "0" + now.minute() : now.minute();
            var s = now.second() < 10 ? "0" + now.second() : now.second();
            const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = momentjs.utc()
        const y = now.year()
        const m = now.month() < 9 ? "0" + (now.month() + 1) : now.month() + 1;
        var d = now.date() < 10 ? "0" + now.date() : now.date();
        var h = now.hour() < 10 ? "0" + now.hour() : now.hour();
        var mm = now.minute() < 10 ? "0" + now.minute() : now.minute();
        var s = now.second() < 10 ? "0" + now.second() : now.second();
        const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }

    /**
     * convert the local timestring to UTC timestring
     * @param {timestring} LocalTime 
     * usage:
        import { SystemTimeManagement } from './assets/systemConfig';
        var STM = SystemTimeManagement;
        STM.convertLocaltoUTC("2019-02-05T14:48:37-05:00")
        .then(UTCTime => {
            // do something
            // if you want UTC time string
            STM.formatUTCTime(UTCTime);
        });
     */
    convertLocaltoUTC(LocalTime, promise = false) {
        const timezone = jstz.determine();
        const tzName = timezone.name(); 
        var convert = moment(LocalTime).tz(tzName).utc().format();
        if(promise)
        {
            return new Promise(res => {
                return res(convert);
            });
        }
        return convert;
    }

    /**
     * convert the UTC timestring to local timestring
     * @param {timestring} UTC 
     * usage:
        import { SystemTimeManagement } from './assets/systemConfig';
        var STM = SystemTimeManagement;
        STM.convertUTCtoLocal("2019-02-05T14:48:37-05:00")
        .then(LocalTime => {
            // do something
            // if you want UTC time string
            STM.formatLocalTime(LocalTime);
        });
     */
    convertUTCtoLocal(UTC, promise = false) {
        const timezone = jstz.determine();
        const tzName = timezone.name(); 
        var convert = moment(UTC).tz(tzName).format();
        if(promise)
        {
            return new Promise(res => {
                return res(convert);
            });
        }
        return convert;
    }
    /**
     * It's always a good practice to convert the time to unix timestring when storing in the MySQL database
     * @param {str} timestamp
     * @param {func} onResults
     * usage:
        var timestamp = '2019-02-26 03:12:06';

        // optional: combine with getting the timezone of localtime
        const timezone = STM.getTimezoneName(false); // do not return a promise
        timestamp += ` ${timezone}`;

        STM.convertToUnixTime(timestamp, unixTime => {
            console.log(unixTime); // 1551150726
        });

     */
    convertToUnixTime(timestamp = '', onResults) {
        if(!timestamp) return onResults(false);
        let day = new Date(timestamp);
        day = Math.floor(day.getTime() / 1000);
        return onResults(day);
    }
    /**
     * convert the unix timestring to timestamp for MySQL database
     * @param {int} unixTime 
     * @param {str} type ('Local' or 'UTC')
     * @param {func} onResults 
     * usage:
        STM.convertUnixTimeToTimestamp(unixTime, 'UTC', timestamp => {
            console.log(timestamp); // 2019-02-25 22:12:06
        });
     */
    convertUnixTimeToTimestamp (unixTime = null, type = 'UTC',onResults) {
        if(!unixTime) return onResults(false);
        let day = new Date(unixTime * 1000);
        switch(type)
        {
            case 'Local':
                day = this.formatLocalTime(day);
                return onResults(day);
            case 'UTC':
            default:
                day = this.formatUTCTime(day);
                return onResults(day);
        }
    }

    /**
     * return true if day 2 is {howmany} hours behind day 1
     * @param {timestamp} d1 
     * @param {timestamp} d2 
     * @param {int} hrs 
     * usage:
        let d1 = '2018-09-20 16:08:06';
        let d2 = '2018-09-20 20:55:16';
        let result = STM.compareDate(d1, d2, 3);
        console.log(result) // true
    */
    compareDate(d1, d2, hrs = 12) {
        let date1 = new Date(d1).getTime();
        let date2 = new Date(d2).getTime();
        var diff = (date2 - date1) / 1000 / 60 / 60; // convert the time to hours
        if(diff < hrs) // day 2 time is before day 1
        {
            return false;
        }
        return true;
    }
    /** Calculate the duration of between two time stamps */
    calcDuration(t1, t2) {
        let h,m,s;
        // parse the time and get the timestring in milliseconds .getTime()
        const t1Str = new Date(t1);
        const t2Str = new Date(t2);
        // minuse the time
        let result = t2Str - t1Str;
        // get the time in format (h:m:s) - 1 second is 1000 milliseconds
        //Step1: check if it's over 1 hour - very rare, but if it's over 1 hour, then divide it by 3600000
        //Step2: check if it's over 1 minute - rare, divide it by 60000
        //Step3: if it's over 1 second - divide it by 1000
        if(result >= 3600000)
        {
            h = Math.floor(result / 3600000)
            var hrmd = result%3600000
            m = Math.floor(hrmd/60000)
            var mrmd = hrmd%60000
            s = Math.floor(mrmd/1000)
            return `${h}:${m}:${s}`
        }
        if(result >= 60000 & result < 3600000)
        {
            h = 0
            m = Math.floor(result/60000)
            var mrmd = result%60000
            s = Math.floor(mrmd/1000)
            return `${h}:${m}:${s}`
        }
        if(result >= 1000 & result < 60000)
        {
            h = 0; m = 0
            s = Math.floor(result/1000)
            return `${h}:${m}:${s}`
        }
        h = 0; m = 0
        s = Math.floor(result/1000)
        return `${h}:${m}:${s}`
    }
    calcDaysOfVisit(since) {
        const from = moment(since);
        const now = moment(new Date());
        return now.diff(from, 'days');
    }

    async createNewDayEntry(usersId, timestamp, onResults)
    {
        const newDayCreated = await Fetch.post('/activity/v2/addDay', {
            usersId: usersId,
            loginTime: timestamp
        })
        // const newDayCreated = await fetch('/activity/v2/addDay', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //     'Access-Control-Allow-Origin': '*',
        //     'Content-Type': 'application/json',
        //     'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
        //         usersId: usersId,
        //         loginTime: timestamp
        //     })
        // })
        .then(r => r.json()).then(response => {
            if(!response.success)
            {
                throw response
            }
            // do something
            // return onResults(true)
            // console.log(response.results[0])
            return response.results[0]
        })
        .catch(e => {
            return e
        })
        return onResults(newDayCreated)
    }

    shouldSetNewDay(oldTimestamp)
    {
        const currentUTCTimestamp = this.getCurrentUTCTimestamp()
        const oldUTCTimestamp = this.formatLocalTime(oldTimestamp)
        const isNewDay = this.compareDate(oldUTCTimestamp, currentUTCTimestamp, 12)
        return isNewDay
    }

    /**
     * determine if the system should set a new day for the visitor or extend the expiration of previous visit
     * @param {timestamp} timestamp // has to be UTC timestamp
     * @param {string} username
     * @param {integer} usersId
     * @param {function} onResults 
     */
    async setNewDay(usersId, onResults)
    {
        const currentUTCTimestamp = this.getCurrentUTCTimestamp();
        const isNewDay = await this.getCurrentDayData(usersId, (e, data) => {
            console.log("is new day ", data)
            if(e)
            {
                return true
            }
            // got the old time, use the format time function to compare the two times
            const oldUTCTimestamp = this.formatLocalTime(data.created_at);
            const isNewDay = this.compareDate(oldUTCTimestamp, currentUTCTimestamp, 12);
            if(isNewDay)
            {
                return true
            }
            return false
        })
        console.log(`is a new day ${isNewDay}`)
        if(isNewDay)
        {
            try
            {
                const createNewDay = await this.createNewDayEntry(usersId, currentUTCTimestamp, (r) => r)
                if(!createNewDay.hasOwnProperty('code')) return onResults(createNewDay)
                throw createNewDay
            }
            catch(e)
            {
                // console.log(e)
                await EH.sendEmail('', 'FAILED_TO_CREATE_NEW_DAY', `The system failed to create a new day entry for user ${usersId}`)
                return onResults({
                    code: 'FAILED_TO_CREATE_NEW_DAY',
                    message: `The system failed to create a new day entry for user ${usersId}`
                })
            }
        }
        try
        {
            const setSameDay = await this.setSameDay(usersId, '', r => r)
            if(setSameDay) return onResults(setSameDay)
            throw setSameDay
        }
        catch(e)
        {
            await EH.sendEmail('', 'FAILED_TO_SET_SAME_DAY', `The system failed to set a same day for user ${usersId}`)
            return onResults({
                code: 'FAILED_TO_SET_SAME_DAY',
                message: `The system failed to set a same day for user ${usersId}`
            })
        }
    }
    /**
     * create a new entry for the same day
     * @param {int} usersId 
     * @param {func} onResults 
     * usage:
        STM.setSameDay(1, result => {
            if(!result) return;

        });
     */
    async setSameDay(usersId, oldDayHash, onResults)
    {
        console.log('set same day called')
        if(!usersId) return onResults(false); // failed to enter the user's id, should not set any same day
        if(!oldDayHash) // user has not entered the old day data, try to get it from the usersId first
        {
            return this.getCurrentDayData(usersId, (err, data) => {
                if(err) return onResults(false);
                // got the previous day data, use it to set the same day
                return Fetch.post('/activity/v1/addCurrentDay', {
                    usersId: usersId,
                    dayHash: data.day_hash,
                    loginTime: this.getCurrentUTCTimestamp()
                })
                // return fetch('/activity/v1/addCurrentDay', {
                //     method: 'POST',
                //     mode: 'cors',
                //     headers: new Headers({
                //     'Access-Control-Allow-Origin': '*',
                //     'Content-Type': 'application/json',
                //     'Accept': 'application/json'
                //     }),
                //     body: JSON.stringify({
                        // usersId: usersId,
                        // dayHash: data.day_hash,
                        // loginTime: this.getCurrentUTCTimestamp()
                //     })
                // })
                .then(r => r.json())
                .then(result => {
                    if(!result.success) return onResults(false);
                    return onResults(true);
                });
            });
        }
        // return fetch('/activity/v1/addCurrentDay', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //     'Access-Control-Allow-Origin': '*',
        //     'Content-Type': 'application/json',
        //     'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
                // usersId: usersId,
                // dayHash: oldDayHash,
                // loginTime: this.getCurrentUTCTimestamp()
        //     })
        // })
        return Fetch.post('/activity/v1/addCurrentDay', {
            usersId: usersId,
            dayHash: oldDayHash,
            loginTime: this.getCurrentUTCTimestamp()
        })
        .then(r => r.json())
        .then(result => {
            if(!result.success) return onResults(false);
            return onResults(true);
        });
    }
    /**
     * check if 12 hours has passed since last visit
     * @param {string} timestamp (in UTC format!)
     * @param {function} cb
     */
    checkIsNewDay(timestamp = '', usersId, cb) {
        const hourThreashold = 12;
        // get the local timestamp, store the UTC timestamp
        const UTC = this.getCurrentUTCTimestamp();
        if(timestamp) // user has specified the time
        {
            if(this.compareDate(timestamp, UTC, hourThreashold)) // it's a new day
            {
                // new day is true
                return cb(true);
            }
            return cb(false);
        }
        this.getCurrentDayData(usersId, (err, data) => {
            if(err) // user might not have record in the database
            {
                return cb(true);
            }
            return cb(false); // new day is false
        });
    }
    /**
     * Get the latest log in day of a user
     * @param {str} username 
     * @param {func} onResults 
     * usage:
        getCurrentDayData('username-hash', (err, data) => {
            if(err) return false;
            console.log(data);
        });
     */
    getCurrentDayData(usersId = null, onResults) {
        console.log('get current day data called, user id: '+usersId)
        //fetch the latest day
        if(!usersId) return onResults({
            message: `User's id is not entered`
        }, null)
        if(!usersId) return onResults({
            message: `User's id is not entered`
        }, null);
        try
        {
            return Fetch.get(`/activity/v2/getCurrentDayData/${usersId}`)
            .then(r => r.json())
            .then(data => {
                console.log("get answer", data)
                return onResults(null, data[0]);
            })
            .catch(err => {
                console.log("get error ", err)
                // return this.setNewDay(usersId, (r) => {
                //     if(r)
                //     {
                //         return Fetch.get(`/activity/v2/getCurrentDayData/${usersId}`)
                //         .then(r => r.json())
                //         .then(r => onResults(r, null))
                //         .catch(e => onResults(null, e))
                //     }
                //     // if(r) return DB.getLastRows(tbs.dayLogs, { users_id: usersId }, 1, (err, data) => {
                //     //     return data
                //     // })
                //     return onResults(r, null)
                // })
                // go ahead and set a new day
                return onResults(err, null);
            })
        }
        catch(e)
        {
            return onResults({
                errCode: 'FAILED_TO_QUERY_MH_DAY_LOGS',
                message: 'The system failed to query your Memory Home visit history.'
            }, null);
        }

        // return DB.getLastRows(tbs.dayLogs, { users_id: usersId }, 1, (err, data) => {
        //     // console.log(err)
        //     // console.log(data)
        //     if(err)
        //     {
        //         return this.setNewDay(usersId, (r) => {
        //             if(r) return DB.getLastRows(tbs.dayLogs, { users_id: usersId }, 1, (err, data) => {
        //                 return data
        //             })
        //             return onResults(r, null)
        //         })
        //     }
        //     else if(Array.isArray(data) && data.length > 0)
        //     {
        //         // console.log('got data')
        //         return onResults(null, data[0]);
        //     }
        //     else if(Object.keys(data).length > 0)
        //     {
        //         // console.log('should return normal data')
        //         return onResults(null, data)
        //     }
        //     return onResults({
        //         errCode: 'FAILED_TO_QUERY_MH_DAY_LOGS',
        //         message: 'The system failed to query your Memory Home visit history.'
        //     }, null);
        // });
    }
    /**
     * update the day log when user adds a new offering or log out
     * @param {int} usersId 
     * @param {obj} fieldData 
     * @param {func} onResults 
     * usage:
        const fieldData = {
            logout_time: '2019-02-26 06:33:32',
            offering_data: JSON.stringify({
                offerings: ['candle-light', 'candle-unlight']
            })
        }
        STM.updateDayLogs('your-day-hash', fieldData, (err, data) => {
            if(err) return console.log(err.errCode);
            // do something
        });
     */
    updateDayLogs(dayHash, fieldData, onResults) {
        // return fetch('/activity/v1/updateDay', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
                // dayHash: dayHash,
                // data: fieldData
        //     })
        // })
        return Fetch.post('/activity/v1/updateDay', {
            dayHash: dayHash,
            data: fieldData
        })
        .then(r => r.json())
        .then(result => {
            if(!result.success) return onResults({ errCode: 'FAILED_TO_UPDATE_DAY', message: 'The system failed to update the day record' }, null);
            return onResults(null, result.data);
        });
    }
    /**
     * retrieve the latest entry of the day logs of a certain user
     * @param {int} usersId 
     * @param {func} onResults 
     * usage:
        STM.getLatestDayLog(1, (err, data) => {
            if(err) return err.errCode;
        });
     */
    getLatestDayLog(usersId, onResults) {
        console.log('get latest day called')
        // return fetch(`/activity/v1/getLatestDay/${usersId}`)
        return Fetch.get(`/activity/v1/getLatestDay/${usersId}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults({
                errCode: response.errCode,
                message: response.message
            }, null);
            if(response.data.length > 1)
            {
                return onResults(null, response.data[response.data.length - 1])
            }
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults({
                errCode: 'FAILED_TO_QUERY_LATEST_DAY_LOG',
                message: 'The system failed to query your latest day log.'
            }, null);
        })
    }
    /**
     * retrieve the latest entry of the day logs with offering data
     * @param {int} usersId 
     * @param {func} onResults 
     * usage:
        STM.getLatestDayLogWithOfferingData(1, (err, data) => {
            if(err) return err.errCode;
            return data;
        });
     */
    getLatestDayLogWithOfferingData(usersId, onResults) {
        // return fetch(`/activity/v2/getLatestDayLogWithOfferingData/${usersId}`)
        return Fetch.get(`/activity/v2/getLatestDayLogWithOfferingData/${usersId}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults({
                errCode: response.errCode,
                message: response.message
            }, null);
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults({
                errCode: 'FAILED_TO_QUERY_LATEST_DAY_LOG',
                message: 'The system failed to query your latest day log with offering data.'
            });
        });
    }
}

export default new SystemTimeManagement()