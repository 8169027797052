import STM from './SystemTimeManage'
import OF from './OfferingHelper'
// import DH from './DatabaseHelper'
import EH from './ErrorHelper'
import GH from './GeneralHelper'
// import AH from './AmplifyHelper'
// import { tbs } from '../../init/initApp'
// import { hash } from '../../init/general'
import questionPrompts from './questionPrompts'

import FetchWrapper from './FetchWrapper'
const Fetch = new FetchWrapper()

/**
 * usage:
 * import { Journals } from 'components/classes/journals';
 * const JE = new Journals();
 */
// const STM = new SystemTimeManagement()
class Journals {
    constructor() {

    }

    determineTypeOfQuestion(category = 'stories', key = 'firstMemory', lang = 'en')
    {
        const promptsLanged = questionPrompts(lang)
        if(key === 'constant') return `[${promptsLanged['imaginedDialogue']}] ${promptsLanged['constantName']}`
        let narrativeNotesKeys = Object.keys(promptsLanged.types)
        if(narrativeNotesKeys.indexOf(category) >= 0)
        {
            // it's a narrative note
            return `[${promptsLanged['narrativeNotes']}] ${promptsLanged['types'][category]}`
        }
        return `[${promptsLanged['imaginedDialogue']}] ${category}`
    }

    getNarrativeNoteQuestionsGivenCatNKey(category = 'stories', key = 'firstMemory', lang = 'en')
    {
        // console.log("questions" , questionPrompts(lang))
        const promptsLanged = questionPrompts(lang)
        if(key === 'constant')
        {
            return promptsLanged['constant']
        }
        // determine if the question belongs to imagined dialogues or narrative notes
        let narrativeNotesKeys = Object.keys(promptsLanged.types)
        if(narrativeNotesKeys.indexOf(category) >= 0)
        {
            // it's a narrative note
            
            return promptsLanged['categories'][category][key]
        }
        return promptsLanged['imaginedDialoguesCategories'][category][key]
    }

    /**
     * fetch all the journal entries from the database that has REDCap ID
     * @returns 
        {
            "success": true,
            "data": [{journalObj},{journalObj}]
        }
     */
    async retrieveAllJournalEntries()
    {
        return Fetch.get(`/journal/v2/getAllJournalData`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) throw response.message;
            console.log(response.data)
            // return onResults(null, response.data);
            return response.data
        })
        .catch(err => {
            return false
            // return onResults('The system failed to get all the dialogues. We apologize for the inconvenience.', null);
        })

    }


    /**
     * check how many days of journal have been entered
     * @param {int} usersId
     * @param {str} questionDayKey
     * @param {str} questionKey
     * @param {func} onResults
     * usage:
        JE.getCurrentJournalDay(1, (err, currentJournalDay) => {
            if(err) return console.log(`failed to get the current journal day`);
            console.log(currentJournalDay); // 1
        });
     */
    getCurrentJournalDay(usersId, dayLogsId, day, onResults) {
        // console.log(`get request for journal day using ${usersId} with day logs id ${dayLogsId} on ${day} day`)
        // get the latest entry
        return Fetch.get(`/journal/v2/checkCurrentJournalDay/${usersId}/${dayLogsId}/${day}`)
        // return fetch(`/journal/v2/checkCurrentJournalDay/${usersId}/${dayLogsId}/${day}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) throw response.message;
            // console.log(response.data)
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults('The system failed to get all the dialogues. We apologize for the inconvenience.', null);
        });
    }
    /**
     * get the previous 7 journal entries from the database based on the current journal day
     * usage:
        return JE.getJournalEntriesByRange(userId, [0, currentJournalDay], (err, entries) => {
            if(err) return err;
            return this.setState({
                entries: entries
            });
        });
     */
    getJournalEntriesByRange(usersId, range = [1], onResults) {
        return Fetch.post('/journal/v1/getEntriesByRange', {
            usersId: usersId,
            valueArr: range, // [beginDay, endDay]
        })
        // return fetch('/journal/v1/getEntriesByRange', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
        //         usersId: usersId,
        //         valueArr: range, // [beginDay, endDay]
        //     })
        // })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults(response.message, null);
            }
            // const returnData = this.converEntryToObj(response.data);
            // console.log(returnData)
            // console.log(response)
            // const returnData = this.convertEntryToMap(response.data);
            // console.log(returnData)
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults(err, null);
        });
    }
    convertEntryToSortedObj(entry = {})
    {
        let newObj = {}
        entry.map(e => {
            const jdKey = e.journal_day
            if(newObj.hasOwnProperty(jdKey))
            {
                newObj[jdKey].push(e)
            }
            else
            {
                newObj[jdKey] = []
                newObj[jdKey].push(e)
            }
        })
        //sort
        const orderedObj = Object.keys(newObj).sort().reduce((obj, k) => {
            obj[k] = newObj[k]
            return obj
        },
        {})
        // console.log(orderedObj)
        return orderedObj
    }
    /**
     * get the previous 7 journal entries from the database based on the current journal day
     */
    getJournalLogsByRangeObj(usersId, range = [1], onResults) {
        return Fetch.post('/journal/v1/getEntriesByRange', {
            usersId: usersId,
            valueArr: range, // ***the first value should be userId!!!!!!!
        })
        // return fetch('/journal/v1/getEntriesByRange', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
        //         usersId: usersId,
        //         valueArr: range, // ***the first value should be userId!!!!!!!
        //     })
        // })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults(response.message, null);
            }
            const returnData = this.converEntryToObj(response.data);
            // const returnData = this.convertEntryToMap(response.data);
            // console.log(returnData)
            return onResults(null, returnData);
        })
        .catch(err => {
            return onResults(err, null);
        });
    }

    /**
     * convert the retrieved journal entries into the object needed to feed the diary
     * @param {obj} json 
     */
    converEntryToObj(json = {}) {
        let resultObj = {};
        json.forEach(entry => {
            if(!resultObj.hasOwnProperty(entry.question_day_key))
            {
                resultObj[entry.question_day_key] = {
                    values: {}
                }
                let valueObj = {};
                valueObj[entry.question_key] = {
                    "value": entry.journal_entry,
                    "journal_entry_hash": entry.journal_entry_hash,
                };
                resultObj[entry.question_day_key]["day_logs_id"] = resultObj[entry.question_day_key]["day_logs_id"] ? resultObj[entry.question_day_key]["day_logs_id"] : entry.day_logs_id;
                resultObj[entry.question_day_key]["journal_day"] = resultObj[entry.question_day_key]["journal_day"] ? resultObj[entry.question_day_key]["journal_day"] : entry.journal_day;
                resultObj[entry.question_day_key]["values"] = valueObj;
                resultObj[entry.question_day_key]["question_day_key"] = resultObj[entry.question_day_key]["question_day_key"] ? resultObj[entry.question_day_key]["question_day_key"] : entry.question_day_key;
                resultObj[entry.question_day_key]["journal_day_hash"] = resultObj[entry.question_day_key]["journal_day_hash"] ? resultObj[entry.question_day_key]["journal_day_hash"] : entry.journal_day_hash;
                return;
            }
            let valueObj = resultObj[entry.question_day_key]["values"];
            valueObj[entry.question_key] = {
                "value": entry.journal_entry,
                "journal_entry_hash": entry.journal_entry_hash,
            };
            resultObj[entry.question_day_key]["day_logs_id"] = resultObj[entry.question_day_key]["day_logs_id"] ? resultObj[entry.question_day_key]["day_logs_id"] : entry.day_logs_id;
            resultObj[entry.question_day_key]["journal_day"] = resultObj[entry.question_day_key]["journal_day"] ? resultObj[entry.question_day_key]["journal_day"] : entry.journal_day;
            resultObj[entry.question_day_key]["values"] = valueObj;
            resultObj[entry.question_day_key]["question_day_key"] = resultObj[entry.question_day_key]["question_day_key"] ? resultObj[entry.question_day_key]["question_day_key"] : entry.question_day_key;
            resultObj[entry.question_day_key]["journal_day_hash"] = resultObj[entry.question_day_key]["journal_day_hash"] ? resultObj[entry.question_day_key]["journal_day_hash"] : entry.journal_day_hash;
            return;
        });
        return resultObj;
    }
    /**
     * convert the retrieved journal entries into the map needed to feed the diary
     * @param {obj} json 
     */
    convertEntryToMap(json = {}) {
        let resultMap = new Map();
        json.forEach(entry => {
            // use journal_day_hash and question_day_key as the map key
            const mapKey = String([entry.journal_day, entry.journal_day_hash, entry.question_day_key]);
            if(!resultMap.has(mapKey)) // create a new map entry
            {
                let newMapValue = {
                    "values": {},
                    "journal_entry_hash": entry.journal_entry_hash,
                    "question_day_key": entry.question_day_key
                }
                // let valueObj = {};
                // valueObj[entry.question_key] = {
                //     "value": entry.journal_entry,
                //     "journal_entry_hash": entry.journal_entry_hash
                // };
                newMapValue['values'][entry.question_key] = {
                    "value": entry.journal_entry,
                    "journal_entry_hash": entry.journal_entry_hash
                }
                newMapValue['journal_day'] = entry.journal_day;
                newMapValue['question_day_key'] = entry.question_day_key;
                newMapValue['day_logs_id'] = entry.day_logs_id;
                resultMap.set(mapKey, newMapValue);
            }
            let prevValue = resultMap.get(mapKey);
            prevValue['values'][entry.question_key] = {
                "value": entry.journal_entry,
                "journal_entry_hash": entry.journal_entry_hash
            }
            prevValue['journal_day'] = entry.journal_day;
            prevValue['question_day_key'] = entry.question_day_key;
            prevValue['day_logs_id'] = entry.day_logs_id;
            resultMap.set(mapKey, prevValue);
        });
        return resultMap;
    }
    /**
     * create a new journal entry in the database
     * @param {int} usersId 
     * @param {int} dayLogsId 
     * @param {str} questionKey 
     * @param {str} questionDayKey 
     * @param {str} journalEntry 
     * @param {str} promptId // array to string 
     * @param {func} onResults 
     * usage:
        const JE = new Journals();
        JE.insertEntry(1, 1, 1, 'q1', 'day1', 'The first entry', '', (err, data) => {
            if(err)
            {
                return console.log(err);
            }
            return data;
        });
     */
    async insertEntry(usersId, dayLogsId, day, journalDay, questionDayKey, journalDayHash, questionKey, journalEntry, journalEntryHash, onResults) {
        // console.log(`the journal day hash is ${journalDayHash}`)
        const dateStr = STM.getCurrentLocalTimestamp(false)
        const fieldData = {
            users_id: usersId,
            day_logs_id: dayLogsId,
            journal_day: journalDay,
            question_day_key: questionDayKey,
            journal_day_hash: journalDayHash,
            question_key: questionKey,
            journal_entry: journalEntry,
            journal_entry_hash: journalEntryHash,
            last_edited: dateStr
        }
        // console.log('to insert entry', fieldData)
        try
        {
            const fetch = await Fetch.post('/journal/v2/upsertJournalEntry', {
                usersId: usersId,
                fieldData: fieldData
            })
            .then(r => r.json())
            .then(result => {
                if(!result.success) throw result.message
                return result.results[0]
            })
            .catch(err => {
                throw err
                // return onResults('The dialogue entry cannot be stored currently, please try again later.', null);
            });
            return fetch
        }
        catch(e)
        {
            return false
        }

    }
    /**
     * update the journal entry given userId, question key, question day key to query the log
     * @param {int} usersId 
     * @param {str} questionKey 
     * @param {str} questionDayKey 
     * @param {str} journalEntry 
     * @param {func} onResults 
     * usage:
        const JE = new Journals();
        JE.updateEntry(1, 'q1', 'day1', 'This is a new journal entry', (err, results) => {
            if(err) console.log(err);
            // updated
        });
     */
    updateEntry(usersId, questionKey, questionDayKey, journalEntry, onResults) {
        const dateStr = STM.getCurrentLocalTimestamp(false)
        const activityObj = {
            users_id: usersId,
            activity_details: journalEntry,
            end_time: dateStr
        }
        const whereObj = {
            users_id: usersId,
            activity_key: `${questionDayKey}-${questionKey}`
        }
        OF.updateActivityLog(activityObj, whereObj, (err, results) => {

        })
        return Fetch.post('/journal/v1/updateJournalEntry', {
            usersId: usersId,
            questionKey: questionKey,
            questionDayKey: questionDayKey,
            fieldData: {
                journal_entry: journalEntry,
                last_edited: dateStr
            }
        })
        // return fetch('/journal/v1/updateJournalEntry', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
        //         usersId: usersId,
        //         questionKey: questionKey,
        //         questionDayKey: questionDayKey,
        //         fieldData: {
        //             journal_entry: journalEntry,
        //             last_edited: dateStr
        //         }
        //     })
        // })
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults(response.message, null);
            return onResults(null, response.data);
        });
    }
    /**
     * Retrieve the next ten journal entries given the begin day
     * @param {int} usersId 
     * @param {int} beginJournalDay 
     * @param {func} onResults 
     * usage:
        JE.getNextTenJournalEntries(1, 11, (err, data) => {
            if(err || data.length <= 0) return false;
            return this.setState({
                entries: data
            });
        });
     */
    getNextTenJournalEntries(usersId, beginJournalDay, onResults) {
        return Fetch.get(`/v1/getNextTenEntries/${usersId}/${beginJournalDay}`)
        // return fetch(`/v1/getNextTenEntries/${usersId}/${beginJournalDay}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults(response.message, null);
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults('The system failed to query the database for the 10 next posts.', null);
        });
    }

    /**
     * get the prompts set given the day key and question array
     */
    getPromptsArr(questionArr, dayKey, onResults) {
        return questionArr.forEach(qSet => {
            if(qSet.key === dayKey)
            {
                return onResults(qSet.prompts);
            }
        });
    }

    async upsertJournalEntry(id = null, usersId, dayLogId, journalDay, typeKey, qKey, journalEntry = '', journalEntryHash = '')
    {
        // console.log(`receive data ${dayLogId}, ${journalDay}, ${typeKey}, ${qKey}, ${journalEntryHash}`)
        if(!dayLogId || !journalDay || !typeKey || !qKey || !journalEntryHash) return false
        // console.log("all data is passed")
        let fieldData = {
            users_id: usersId,
            day_logs_id: dayLogId,
            question_day_key: typeKey,
            question_key: qKey,
            journal_day: journalDay,
            journal_entry_hash: journalEntryHash
            // journal_entry: journalEntry
        }
        if(id)
        {
            fieldData['id'] = id
        }
        if(journalEntry !== '')
        {
            fieldData['journal_entry'] = journalEntry
        }

        try
        {
            // console.log("should upsert journal entry")
            // console.log(fieldData)

            const results = await Fetch.post('/journal/v2/upsertJournalEntry', {
                usersId: usersId,
                fieldData: fieldData
            })
            .then(r => r.json())
            .then(r => r?.results[0])
            .catch(e => {throw e})

            return results
        }
        catch(e)
        {
            const err = {
                user_id: usersId,
                hash: GH.hash(20),
                code: 'FAILED_TO_GET_JOURNAL_ENTRY',
                message: `Could not upsert the journal entry of user id ${usersId} on day ${dayLogId}, journal day ${journalDay}. The content is: ${journalEntry}`
            }
            try
            {
                const result = await EH.addError(err)
            }
            catch(e)
            {
                return false
            }
            return false
        }

    }

    async loadDayJournalEntries(usersId, dayLogId, journalDay) {
        if(!usersId || !dayLogId || !journalDay) return false

        try
        {
            const results = await Fetch.post('/journal/v2/loadDayJournalEntry', {
                "usersId": usersId,
                "dayLogsId": dayLogId,
                "journalDay": journalDay
            })
            .then(r => r.json())
            .then(r => {
                if(!r.success) throw r
                return r?.results
            })
            .catch(e => {throw e})

            return results
        }
        catch(e)
        {
            const err = {
                user_id: usersId,
                hash: GH.hash(20),
                code: 'FAILED_TO_LOAD_JOURNAL_ENTRY',
                message: `Could not load the journal entries of user id ${usersId} on day ${dayLogId}, journal day ${journalDay}.`
            }
            try
            {
                const result = await EH.addError(err)
            }
            catch(e)
            {
                return false
            }
            return false
        }
    }

    async deleteJournalEntry(entryId, usersId) {
        try
        {
            const results = Fetch.delete(`/journal/v2/deleteJournalEntry/${entryId}/${usersId}`)
            .then(r => r.json())
            .then(r => {
                return true
            })
            .catch(e => {throw e})
            return results
        }
        catch(e)
        {
            const err = {
                user_id: usersId,
                hash: GH.hash(20),
                code: 'FAILED_TO_DELETE_JOURNAL_ENTRY',
                message: `Could not delete the journal entry with id ${entryId}.`
            }
            try
            {
                const result = await EH.addError(err)
            }
            catch(e)
            {
                return false
            }
            return false
        }
    }
    // DEPRECATED
    async getJournalEntriesGivenJournalDay(usersId, dayLogsId, journalDay)
    {
        try
        {
            const r = await Fetch.post('/journal/v2/loadDayJournalEntry', {
                usersId: usersId,
                dayLogsId: dayLogsId,
                journalDay: journalDay
            })
            .then(r => r.json())
            .then(r => r)
            .catch(e => {throw e})
            // const r = await DH.get(tbs.journalEntries, where, (e, res) => {
            //     if(e) throw e
            //     return res
            // })
            if(r.success)
            {
                return r.results
            }
            throw r
        }
        catch(e)
        {
            const err = {
                user_id: usersId,
                hash: GH.hash(20),
                code: 'FAILED_TO_GET_JOURNAL_ENTRY',
                message: `Could not get the journal entry of user id ${usersId} on day ${dayLogsId}, journal day ${journalDay}`
            }
            try
            {
                const result = await EH.addError(err)
            }
            catch(e)
            {
                return false
            }
            return false
            // return false
        }
    }

    async getJournalEntriesGivenREDCapId(redcapId = null)
    {
        if(!redcapId) throw Error({
            message: 'REDCap ID is not specified'
        })
        try
        {
            const r = await Fetch.get('/journal/v2/getJournalEntries', [redcapId])
            .then(r => r.json())
            .then(r => {
                if(!r.success) throw r.err
                return r.data
            })
            return r
        }
        catch(e)
        {
            return e           
        }
    }
}

export default new Journals()