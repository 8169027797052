import React, { Component, useState, useEffect } from 'react'
import SystemMessage from '../assets/v2/SystemMessage'
import ConfirmBox from '../assets/v2/ConfirmBox'
import DragDropModal from '../assets/v2/DragNDrop'
import ReactTooltip from "react-tooltip"
// import defaultOffering from './defaultOffering'
import PreloadedDefaultOffering from './MemorialSpace/preloadedDefaultOffering'
// import SetIntention from './MemorialSpace/SetIntention'
// import Decorate from './MemorialSpace/Decorate'
// import Meditate from './MemorialSpace/Meditate'
// import HidePanel from './MemorialSpace/HidePanel'
import NavBar from '../containers/NavBar'
import { Footer } from '../assets/footer'
import Toast from '../assets/v2/Toast'
import decoratePresets from './MemorialSpace/decoratePresets'
import config from '../init/config'
import LoadingBlock from '../assets/v2/LoadingBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import VideoWrapper from '../assets/v2/VideoWrapper'

// import Sketch, { W_H_RATIO, WINDOW_PADDING, P5Wrapper } from '../assets/sketch'
import Sketch, { W_H_RATIO, WINDOW_PADDING, P5Wrapper } from './MemorialSpace/sketch'
// import configuration data that will be used to initiate the app
import { defaultViewsObj, defaultIconSets, stepsOfOffering, sessionKeys, activitiesLog } from '../init/initApp'
// import general functions that will be used to perform some basic functions in the app
import { postMessage, calcDuration, hash } from '../init/general'
import ChooseDecorate from './MemorialSpace/v2/ChooseDecorate'
import About from './MemorialSpace/v2/About'

// classes
import STM from '../classes/v2/SystemTimeManage'
import OF from '../classes/v2/OfferingHelper'
// import UH from '../classes/v2/UserHelper'
import AH from '../classes/v2/AmplifyHelper'
import ALH from '../classes/v2/ActivityLogHelper'
import { Localstorage } from '../classes/localstorage'
import { S3Wrapper } from '../classes/s3bucket'
import { SystemLogs } from '../classes/systemConfig'
// import JE from '../classes/v2/JournalHelper'

import '../../scss/main-app.scss'
import '../../scss/main-app-query.scss'
import m from 'gm'

const LS = new Localstorage()
const S3B = new S3Wrapper()
const SLG = new SystemLogs()
/**
 * Set storage to store the data
 */
let systemMsgInit, currentStep, photoUploadListenerAdded = false;

// define the functions to store the offering panel screenshot to a base64 url
function storeUrl(dataUrl) {
    LS.set(sessionKeys.canvasUrl, dataUrl);
}
async function waitToStore(dataUrl) {
    try
    {
        let stored = await storeUrl(dataUrl);
    }
    catch(err)
    {
        throw new Error('failed to store URL');
    }
}

class MemorialSpace extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
            loggedIn: false,
            selectHouse: this.props.accountInfo.house || '',
            selectView: this.props.accountInfo.view || '',
            windowHeight: window.innerHeight * 0.89,
            currentOffering: null,
            modifyOffering: null,
            targetAssetKey: null,
            positioningOffering: false,
            operationPanelDisplay: true,
            optionsPanelDisplay: true,
            displayModal: false,
            currentStep: currentStep,
            optionsArr: [],
            messageKeys: [],
            displayFeedback: '',
            loadingMsg: '',
            displayAbout: false,
            disableCanvasInteraction: false
        }
        // this.checkCurrentVisitDay = this.checkCurrentVisitDay.bind(this)
        this.initSystemMsg = this.initSystemMsg.bind(this)
        this.updateLoadingMsg= this.updateLoadingMsg.bind(this)
        this.updateDimensions = this.updateDimensions.bind(this)
        this.handleOffering = this.handleOffering.bind(this)
        this.setOptions = this.setOptions.bind(this)
        this.setIntention = this.setIntention.bind(this)
        this.decorate = this.decorate.bind(this)
        this.completeMeditation = this.completeMeditation.bind(this)
        this.completeMemorial = this.completeMemorial.bind(this)
        this.toggleOption = this.toggleOption.bind(this)
        this.warningBeforeEnablingCanvasInteraction = this.warningBeforeEnablingCanvasInteraction.bind(this)
        // this.togglePropBoolean = this.togglePropBoolean.bind(this);
        this.enableState = this.enableState.bind(this);
        this.disableState = this.disableState.bind(this)
        this.resetOffering = this.resetOffering.bind(this)
        // this.getMHConfig = this.getMHConfig.bind(this);
        this.updateActivity = this.updateActivity.bind(this)
        this.performMessage = this.performMessage.bind(this)
        this.closeSystemMsg = this.closeSystemMsg.bind(this)
        this.addAsset = this.addAsset.bind(this)
        this.removeAsset = this.removeAsset.bind(this)
        this.saveCanvas = this.saveCanvas.bind(this)
        this.getFeedback = this.getFeedback.bind(this)
    }

    async componentDidMount() {
        this.props.changeLang(this.props.match.params.lang);

        var self = this;
        window.addEventListener('resize', self.updateDimensions);
        window.addEventListener('displayOptions', self.setOptions)
        window.addEventListener('message', self.performMessage)

        let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        
        // console.log(activityLog)
        // console.log(this.props)
        // check if the current day is less or equal to 1, this is the first time users use the app. and if the activity logs has been used
        if(this.props.currentDay.day <= 1 && Object.keys(activityLog).length <= 0)
        {
            // first time user
            // console.log('first time user')
            this.setState({
                messageKeys: ['start-add-offering', {
                    message:  'invite-to-start-add-offering',
                    callback: () => this.setState({
                        displayTutorial: true,
                        messageKeys: []
                    })
                }]
            })
        }

        // if activity log is empty, try to retrieve the last activity log
        if(Object.keys(activityLog).length <= 0)
        {
            // console.log('get last activity log')
            // console.log(this.props)
            try
            {
                let lastActivityLog = await ALH.getLastMemorialLog(this.props.auth.id)
                .then(r => r.json())
                .then(r => r)
                .catch(e => {throw e})
                if(lastActivityLog.success && Object.keys(lastActivityLog.data).length > 0)
                {
                    activityLog = JSON.parse(lastActivityLog.data.activity_logs)
                    // console.log(activityLog)
                }
            }
            catch(e)
            {
                // activityLog
                // console.log(e)
            }
        }

        // console.log(activityLog.hasOwnProperty('complete'))
        let retrievedOffering = {}
        if(activityLog.hasOwnProperty('assets') && Object.keys(activityLog['assets']).length > 0)
        {
            // console.log('found retrieved offering')
            retrievedOffering['retrievedOffering'] = activityLog['assets']
            // console.log(retrievedOffering)
            this.setState(retrievedOffering)
            const updateMemorialLog = await self.props.updateMemorialStepLog(self.props.currentDay.id, activityLog)
        }
        if(activityLog.hasOwnProperty('complete'))
        {
            this.setState({
                disableCanvasInteraction: true
            })
        }
        
        // =============================================================
        // ======================  DEPRECATED  =========================
        // =============================================================
        // if(activityLog.hasOwnProperty('complete'))
        // {
            // await this.enableState('disableCanvasInteraction')
            // console.log(`disable state in parent component ${this.state.disableCanvasInteraction}`)
            // activityLog['complete'] = null
            // delete activityLog['complete']
            // const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
        // }
        

        this.setState({
            operationPanelDisplay: activityLog.hasOwnProperty('complete') ? false:true
        })
    }

    // initiate system message, take an array input for multiple messages
    /**
     * usageL
        this.initSystemMsg([{
            message: 'reset-house-complete',
            callback: e => {
                self.props.enableResetConfig(false)
                self.setState({
                    messageKeys: []
                })
                if(self.props.accountInfo.view !== null)
                {
                    self.props.history.push(config.paths.dashboard)
                }
            }
        }])
     */
    initSystemMsg(arr = [])
    {
        if(arr.length < 1)
        {
            return this.setState({
                messageKeys: []
            });
        }
        return this.setState({
            messageKeys: arr
        })
    }

    toggleOption()
    {
        this.setState({
            operationPanelDisplay: !this.state.operationPanelDisplay
        })
    }

    enableState(state)
    {
        // console.log('enable state called')
        // console.log(state)
        let stateObj = {};
        stateObj[state] = true;
        // console.log(stateObj)
        this.setState(stateObj);
    }

    disableState(state) {
        let stateObj = {};
        stateObj[state] = false;
        this.setState(stateObj);
    }

    updateLoadingMsg(msg = '')
    {
        // console.log(`update loading msg ${msg}`)
        this.setState({
            loadingMsg: msg
        })
    }

    async setIntention(intention = '')
    {
        const fieldData = {
            activity_details: intention
        }
        try
        {
            const add = await this.props.addActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, 'set intention', '', fieldData)
            // console.log(add)
            let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
            activityLog['intention'] = intention
            // console.log(activityLog)
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            // console.log(updateMemorialLog)
        }
        catch(e)
        {
            const self = this
            this.setState({
                confirmMessage: this.props.dict.mainapp.errors.cannotSaveIntention,
                toConfirmAction: e => {
                    self.setState({
                        confirmMessage: ''
                    })
                },
                toRejectAction: e => {
                    self.setState({
                        confirmMessage: ''
                    })
                }
            })
        }
    }

    setOptions(e) {
        // display options
        this.setState({
            optionsArr: e.detail.optionsArr,
            targetAssetKey: e.detail.key,
            optionsPanelDisplay: true,
        })
    }

    performMessage(e) {
        // console.log(e)
        const self = this
        if( e.origin !== window.location.origin ) return; // security check, if the post origin isn't in the same window, reject it

        const action = e.data.for;
        // console.log(`receive request to ${action}`)
        switch(action) {
            case 'enterMemoryHome':
                //TODO: log the time the user enters the memory home
                break;
            case 'exitMemoryHome':
                //TODO: log the time the user exits the memory home
                break;
            case 'saveCanvas':
                const canvasEl = document.getElementById('viewCanvas');
                return canvasEl.toBlob((blob) => {
                    return S3B.uploadBlob(blob, 'image/jpeg', 'ritual_imgs', (err, results) => {
                        if(err)
                        {
                            // store the data in the localstorage for display
                            const canvasUrl = canvasEl.toDataURL();
                            return SLG.recordErr(self.state.sessionAccessToken, 'FAILED_TO_STORE_CANVASURL', '', (err, stored) => {
                                return LS.set(sessionKeys.canvasUrl, canvasUrl)
                                    .then(() => {
                                        return window.location.assign('/diary');
                                    });
                            });
                        }
                        // store the new filename in the database for retrieve
                        const canvasKey = results.data.key;
                        return OF.storeCanvasURL(self.state.userData.id, self.state.dayLogs.id, canvasKey, (err, stored) => {
                            if(err)
                            {
                                return LS.set(sessionKeys.canvasUrl, canvasKey)
                                    .then(() => {
                                        return window.location.assign('/diary');
                                    });
                            }
                            return window.location.assign('/diary');
                        });
                    });
                }, 'image/jpeg')
            /**
             * Post a message to log activity
             * postMessage("activityInit", activityLogArr);
             */
            case 'activityInit':
                var activityLogArr = e.data.details;
                return
                // memorize this activity in a temporary variable to wait until it's completed

                // return LS.set('activityLogTemp', activityLogArr)
                //     .then(stored => {
                //         /** activity object
                //         {
                //             "timeInit": "Tue, 12 Mar 2019 18:54:14 GMT",
                //             "activityKey": "candleDim",
                //             "type": "offer"
                //         }
                //          */
                    // })
            case 'activityCancel': // it's better to separate activity cancel and complete in case there should be more interactions in the future
                return
                /*
                return LS.get('activityLogTemp')
                    .then(arr => {
                        var activityLogArr = arr || {};
                        LS.remove('activityLogTemp');
                        for( var key in e.data.details )
                        {
                            if(e.data.details.hasOwnProperty(key)) activityLogArr[key] = e.data.details[key];
                        }
                        activityLogArr["timeComplete"] = e.data.details.timeComplete;
                        // calculate duration of activity
                        var duration = calcDuration(activityLogArr.timeInit, activityLogArr.timeComplete);
                        activityLogArr["duration"] = duration;
                        const activityLogObj = {
                            users_id: self.state.userData.id,
                            day_logs_id: self.state.dayLogs.id,
                            day: self.state.dayLogs.day,
                            activity_hash: activityLogArr.hash,
                            activity_key: activityLogArr.activityKey,
                            start_time: STM.formatLocalTime(activityLogArr.timeInit, false),
                            end_time: STM.formatLocalTime(activityLogArr.timeComplete, false),
                            type: activityLogArr.type,
                            duration: activityLogArr.duration
                        }
                        return self.updateActivity(activityLogArr, activityLogObj);
                    })
                */
            case 'activityComplete': // only store the item to localstorage in activity complete and activity cancel
                return
                /**
                 const activityObj = {
                    users_id: 1,
                    day_logs_id: 60, // the day logs id for the activity
                    day: 21,
                    activity_hash: 'your-activity-hash', // a unique hash to differentiate each activity
                    activity_key: 'your-activity-key', // refer to the activity feedback key
                    start_time: '2019-03-11 10:00:00',
                    end_time: '2019-03-11 10:00:01',
                    duration: 0,
                    type: 'remove'
                }
                */

                /*
                return LS.get('activityLogTemp')
                    .then(results => {
                        var activityLogArr = results || {};
                        LS.remove('activityLogTemp');
                        for( var key in e.data.details )
                        {
                            if(e.data.details.hasOwnProperty(key)) activityLogArr[key] = e.data.details[key];
                        }
                        activityLogArr["timeComplete"] = e.data.details.timeComplete;
                        // calculate duration of activity
                        var duration = calcDuration(activityLogArr.timeInit, activityLogArr.timeComplete);
                        activityLogArr["duration"] = duration;
                        activityLogArr["hash"] = hash();
                        const activityLogObj = {
                            users_id: self.state.userData.id,
                            day_logs_id: self.state.dayLogs.id,
                            day: self.state.dayLogs.day,
                            activity_hash: activityLogArr.hash,
                            activity_key: activityLogArr.activityKey,
                            start_time: STM.formatLocalTime(activityLogArr.timeInit, false),
                            end_time: STM.formatLocalTime(activityLogArr.timeComplete, false),
                            type: activityLogArr.type,
                            duration: activityLogArr.duration
                        }
                        return self.updateActivity(activityLogArr, activityLogObj);
                    })
                */
            case 'addAsset':
                // console.log('call space to add assets')
                const assets = e.data.details
                // console.log(assets)
                this.addAsset(assets)
                // console.log(e.data.details)
                return
            case 'removeAsset':
                // console.log('call space to add assets')
                const assetKey = e.data.details
                this.removeAsset(assetKey)
                // console.log(e.data.details)
                return
            case 'offeringRetrieved':
                // console.log('remove retrieved offering')
                this.setState({
                    currentOffering: null,
                    predefinedOffering: null,
                    retrievedOffering: null
                })
                return
            case 'uploadPhoto':
                // console.log(`upload photo called ${e.data.details.assetKey}`)
                // console.log(e.data.details)
                var originalKey = e.data.details.assetKey
                // console.log(`upload photo original key is ${originalKey}`)
                const respondCanvasMsg = function(e) {
                    // console.log(`photo uploaded`)
                    // console.log(e.detail)
                    if(photoUploadListenerAdded) return
                    // console.log(`photoUploadListenerAdded: ${photoUploadListenerAdded}`)
                    photoUploadListenerAdded = true;
                    if(!e.detail.uploaded)
                    {
                        //TODO: upload failed, ask user to try again
                        return
                    }
                    self.disableState('displayModal');
                    // console.log(e.detail)
                    let filePath = e.detail.filePath;
                    const fileName = e.detail.fileName;
                    const originalName = e.detail.originalName;
                    const base64Str = e.detail.data;
                    // display the file after user uploads it
                    const msgToCanvas = new CustomEvent('msgToCanvas', {
                        detail: {
                            origin: 'window',
                            for: 'displayPhoto',
                            details: {
                                assetKey: originalKey,
                                filePath: filePath,
                                fileName: fileName,
                                originalName: originalName,
                                base64Str: base64Str
                            }
                        }
                    })
                    window.dispatchEvent(msgToCanvas)
                    window.removeEventListener("photoUploaded", respondCanvasMsg)
                    photoUploadListenerAdded = false
                    return
                }
                var initTimestamp = new Date();
                postMessage('activityInit', {
                    timeInit: initTimestamp.toUTCString(),
                    activityKey: action,
                    type: "offer",
                });
                // open the drag and drop modal to receive the data
                self.enableState('displayModal');
                window.addEventListener("photoUploaded", respondCanvasMsg);
                break
        }
    }

    saveCanvas()
    {
        this.updateLoadingMsg(this.props.dict.memorial['saveCanvas'])
        // delete the existing image first
        if(this.props.currentDay.hasOwnProperty('offering_img'))
        {
            // console.log(this.props.currentDay.offering_img)
            S3B.removeObject(this.props.currentDay.offering_img, 'ritual_imgs', (e, r) => {
                // console.log(e)
                // console.log(r)
            })
        }
        // console.log('save canvas called')
        const self = this
        const canvasEl = document.getElementById('viewCanvas')
        return canvasEl.toBlob((blob) => {
            // console.log(blob)
            return S3B.uploadBlob(blob, 'image/jpeg', 'ritual_imgs', (err, results) => {
                if(err)
                {
                    // console.log(err)
                    // store the data in the localstorage for display
                    const canvasUrl = canvasEl.toDataURL()
                    this.updateLoadingMsg('')
                    // TODO: error control
                    return this.initSystemMsg(['failed-to-store-canvas'])
                }
                // console.log(results)
                // store the new filename in the database for retrieve
                const canvasKey = results.data.key
                this.props.getCurrentDayLogs(this.props.auth.id)
                return OF.storeCanvasURL(self.props.auth.id, self.props.currentDay.id, canvasKey, (err, stored) => {
                    this.updateLoadingMsg('')
                    /*
                    console.log(err)
                    console.log(stored)
                    */
                    if(err)
                    {
                        // TODO: error control
                        return this.initSystemMsg(['failed-to-store-canvas'])
                    }
                    // return window.location.assign('/diary')
                    this.initSystemMsg(['canvas-saved'])
                    return 
                })
            })
        }, 'image/jpeg')
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
        window.removeEventListener('displayOptions', this.setOptions)
        window.removeEventListener("photoUploaded", null)
        window.removeEventListener('message', this.performMessage)
    }
    updateDimensions() {
        this.setState({
            windowHeight: window.innerHeight,
        })
    }

    closeSystemMsg() {
        this.setState({
            systemMsg: false,
            messageKeys: []
        })
    }

    async decorate(template = '', toConfirmUsage = () => null, triggerWarning = true)
    {
        // console.log('decorate called')
        // console.log(toConfirmUsage)
        // console.log(`trigger warning ${triggerWarning}`)
        const fieldData = {
            activity_details: template
        }
        // const fieldData = {
        //     activity_details: intention
        // }

        try
        {
            // const add = await this.props.addActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, 'decorate memorial space', '',fieldData)
            // console.log(add)
            let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
            
            activityLog['decorate'] = template
            // if the activity logs has assets then warn the users that the assets will be cleared first
            if(triggerWarning && activityLog.hasOwnProperty('assets') && activityLog.assets !== null && Object.keys(activityLog.assets).length > 1 )
            {
                // console.log('should display warning')
                //display warning
                this.setState({
                    confirmMessage: this.props.dict['memorial']['confirmUseTemplate'],
                    toConfirmAction: async () => {
                        // reset the canvas
                        let offeringObj = {}
                        offeringObj['predefinedOffering'] = {}
                        this.setState(offeringObj)
                        let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
                        delete activityLog['decorate']
                        if(activityLog.hasOwnProperty('assets'))
                        {
                            delete activityLog['assets']
                        }
                        // console.log(`update activity log`)
                        // console.log(activityLog)
                        const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
                        // execute confrimed action
                        toConfirmUsage()
                        this.setState({ confirmMessage: '', toConfirmAction: () => null, toRejectAction: () => null })
                    },
                    toRejectAction: () => {
                        this.setState({ confirmMessage: '', toConfirmAction: () => null, toRejectAction: () => null })
                    }
                })
                return
            }
            toConfirmUsage()
        //     // console.log(activityLog)
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
        //     // console.log(updateMemorialLog)
        }
        catch(e)
        {
            // console.log(e)
            const self = this
            this.setState({
                confirmMessage: this.props.dict.memorial.errors.cannotSaveDecoration,
                toConfirmAction: e => {
                    self.setState({
                        confirmMessage: ''
                    })
                },
                toRejectAction: e => {
                    self.setState({
                        confirmMessage: ''
                    })
                }
            })
        }
    }

    warningBeforeEnablingCanvasInteraction(toConfirmUsage = null)
    {
        this.setState({
            confirmMessage: this.props.dict['memorial']['confirmEnableCanvas'],
            toConfirmAction: async () => {
                // reset the canvas
                this.setState({
                    disableCanvasInteraction: false
                })
                // let offeringObj = {}
                // offeringObj['predefinedOffering'] = {}
                // this.setState(offeringObj)
                // let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
                // delete activityLog['decorate']
                // if(activityLog.hasOwnProperty('assets'))
                // {
                //     delete activityLog['assets']
                // }
                // // console.log(`update activity log`)
                // // console.log(activityLog)
                // const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
                // // execute confrimed action
                if(toConfirmUsage)
                {
                    toConfirmUsage()
                }
                // toConfirmUsage()
                this.setState({ confirmMessage: '', toConfirmAction: () => null, toRejectAction: () => null })
            },
            toRejectAction: () => {
                this.setState({ confirmMessage: '', toConfirmAction: () => null, toRejectAction: () => null })
            }
        })
    }

    async addAsset(assets = {})
    {
        // console.log(`add Asset called`)
        // console.log(assets)

        try
        {
            let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
            activityLog['assets'] = activityLog.hasOwnProperty('assets') ? activityLog['assets'] : {}
            // console.log(activityLog['assets'])
            Object.keys(assets).map(k => {
                activityLog['assets'][k] = assets[k]
            })
            // console.log(activityLog['assets'])
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            return true
        }
        catch(e)
        {
            // console.log(e)
            return false
        }
    }

    async removeAsset(assetKey = '')
    {
        if(assetKey === '') return false
        try
        {
            let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
            activityLog['assets'] = activityLog.hasOwnProperty('assets') ? activityLog['assets'] : {}
            // console.log(activityLog['assets'])
            // Object.keys(assets).map(k => {
            //     activityLog['assets'][k] = assets[k]
            // })
            // console.log(activityLog['assets'])
            if(activityLog['assets'].hasOwnProperty(assetKey))
            {
                delete activityLog['assets'][assetKey]
            }
            // console.log(activityLog)
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            return true
        }
        catch(e)
        {
            // console.log(e)
            return false
        }
    }

    async completeMeditation(meditate = true)
    {
        const fieldData = {
            activity_details: meditate ? 'complete meditation' : 'skip meditation'
        }
        try
        {
            const add = await this.props.addActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, 'Setup the memorial space', '', fieldData)
            // console.log(add)
            let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
            activityLog['meditate'] = meditate
            // console.log(activityLog)
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            // console.log(updateMemorialLog)
        }
        catch(e)
        {
            // console.log(e)
        }
    }

    async getFeedback(obj = {})
    {
        // console.log(obj)
        try
        {
            if(obj.hasOwnProperty('memorialFeedback'))
            {
                // store the feedback
                const fieldData = {
                    activity_details: 'Memorial Feedback'
                }
                const add = await this.props.addActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, 'Add feedback for the memorial session', '', fieldData)
                // console.log(add)
                let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
                activityLog['memorialFeedback'] = obj['memorialFeedback']
                const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            }
        }
        catch(e)
        {

        }
    }

    async completeMemorial()
    {
        // console.log('complete memorial called')
        const self = this
        await this.saveCanvas()
        // console.log('canvas saved')
        // do something
        const fieldData = {
            activity_details: 'Complete Memorial'
        }
        try
        {
            const add = await this.props.addActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, 'decorate memorial space', '', fieldData)
            let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
            activityLog['complete'] = STM.formatUTCTime()
            // console.log(activityLog)
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            // await this.toggleOption()
            // console.log(updateMemorialLog)
            await this.enableState('disableCanvasInteraction')
            const self = this
            // console.log('display message')
            this.setState({
                messageKeys: ['complete-memorial', {
                    'message': 'complete-memorial-feedback',
                    'callback': (e) => {
                        self.getFeedback(e)
                    }
                }, {
                    'message': 'complete-memorial-redirect-to-writing-desk',
                    'callback': () => {
                        // self.props.history.push(config.paths.diary)
                    }
                }]
            })
            
        }
        catch(e)
        {
            // console.log(e)
            // return this.props.history.push(`/${this.props.match.params.lang}${config.paths.diary}`)
        }
    }

    /**
     * There are three offeringTypes:
     * 1. currentOffering: trigger the process of generating the offering object on the canvas
     * 2. modifyOffering: modify the existing offering object with the new item
     * 3. triggerOptions: when an offering option has multiple items to choose
     */
    async handleOffering( value, offeringType = 'currentOffering', options = false ) { // options controls if the option panel will be hidden after one click
        let offeringObj = {};
        offeringObj[offeringType] = value;
        let nullObj = {};
        nullObj[offeringType] = null;
        var initTimestamp = new Date();
        switch(offeringType) {
            case 'predefinedOffering':
                // console.log('predefined offering '+value)
                // console.log(decoratePresets[value])
                // console.log(decoratePresets)
                offeringObj[offeringType] = decoratePresets[value]
                // console.log(offeringObj)
                this.setState(offeringObj)
                // console.log(this.state)
            case 'currentOffering':
                // console.log(value)
                // const add = await this.props.addActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, offeringType, value, {})
                // simply load the offering opjects if there are no options for select (e.g., landscape or portrait albums)
                if(!options)
                {
                    // send an update to the props of the sketch and trigger the relevant function
                    this.setState(offeringObj, () => {
                        // only trigger the offering once, then set it back to null

                        this.setState(nullObj, () => {
                            postMessage('activityInit', {
                                timeInit: initTimestamp.toUTCString(),
                                activityKey: value,
                                type: "offer",
                            });
                        });
                    });
                }
                else
                {
                    nullObj['optionsPanelDisplay'] = false;
                    this.setState(offeringObj, () => {
                        this.setState(nullObj, () => {
                            postMessage('activityInit', {
                                timeInit: initTimestamp.toUTCString(),
                                activityKey: value,
                                type: "offer",
                            });
                        });
                    });
                }
                break;
            case 'modifyOffering':
                // simply load the offering opjects
                if(!options)
                {
                    // send an update to the props of the sketch and trigger the relevant function
                    this.setState(offeringObj, () => {
                        // only trigger the offering once, then set it back to null
                        this.setState(nullObj);
                    });
                }
                else
                {
                    nullObj['optionsPanelDisplay'] = false;
                    this.setState(offeringObj, () => {
                        this.setState(nullObj);
                    });
                }
                break;
            case 'triggerOptions':
                // allow users to select the offering items then add the offering obj
                let displayOptions = new CustomEvent('displayOptions', {
                    detail: {
                        key: value,
                        optionsArr: [
                            {
                                btnValue: 'photoFrameH',
                                imgSrc: require('../../img/photo_frame_h-sm.png'),
                                alt: 'horizontal photo frame',
                                iconTitle: this.props.dict.mainapp.horizontal,
                                triggerOffering: true,
                            },
                            {
                                btnValue: 'photoFrameV',
                                imgSrc: require('../../img/photo_frame_v-sm.png'),
                                alt: 'vertical photo frame',
                                iconTitle: this.props.dict.mainapp.vertical,
                                triggerOffering: true,
                            }
                        ],
                    }
                });
                window.dispatchEvent(displayOptions);
                break;
            default:
        }
    }
    /**
     * Reset the offering data, but do not remove the activity
     */
    resetOffering() {
        const self = this
        this.setState({
            confirmMessage: this.props.dict.memorial.system.confirmReset,
            toConfirmAction: async e => {
                let offeringObj = {}
                offeringObj['predefinedOffering'] = {}
                self.setState(offeringObj)
                let activityLog = self.props.currentDay.activity_logs !== null ? typeof self.props.currentDay.activity_logs === 'string' ? JSON.parse(self.props.currentDay.activity_logs) : self.props.currentDay.activity_logs : {}
                delete activityLog['decorate']
                if(activityLog.hasOwnProperty('assets'))
                {
                    delete activityLog['assets']
                }
                if(activityLog.hasOwnProperty('complete'))
                {
                    delete activityLog['complete']
                }
                const add = await self.props.addActivityLog(self.props.auth.id, self.props.currentDay.id, self.props.currentDay.day, 'Reset Memorial Space', '', {})
                // console.log(`update activity log`)
                // console.log(activityLog)
                const updateMemorialLog = await self.props.updateMemorialStepLog(self.props.currentDay.id, activityLog)
                self.setState({
                    confirmMessage: ''
                })
                window.location.reload()
            },
            toRejectAction: e => {
                self.setState({
                    confirmMessage: ''
                })
            }
        })

    }

    /**
     * update the activity logs and offering logs in the database
     */
    updateActivity(activityLogArr, activityLogObj) {
        var self = this;
        return LS.get(sessionKeys.activityLogs)
            .then(results => {
                var activityLogs = results || [];
                activityLogs.push(activityLogArr);
                // store the activity in the activity_logs table
                return OF.storeActivityLog(activityLogObj, (err, stored) => {
                    return LS.get(sessionKeys.offeringObjs)
                        .then(offeringObjs => {
                            return OF.storeOfferingData(self.state.userData.id, self.state.dayLogs.id, JSON.stringify(offeringObjs), (err, results) => {
                                // store the activity log array to the localstorage
                                return LS.set(sessionKeys.activityLogs, activityLogs)
                                    .then(() => {
                                        return OF.storeActivityLogObj(self.state.userData.id, self.state.dayLogs.day_hash, JSON.stringify(activityLogs), (err, data) => {
                                        });
                                    });
                            });
                        });
                });
            });
    }
    
    render()
    {
        // console.log(this.props)
        if(this.props.accountInfo.house === null || this.props.accountInfo.view === null)
        {
            return (<React.Fragment>
            {this.state.messageKeys.length > 0 ?
                <SystemMessage
                    messageKeys={this.state.messageKeys || []}
                    messageObj={this.props.dict['systemMessages']}
                    deceased={this.props.accountInfo.deceased}
                    ctnStyle={{backgroundColor: 'transparent'}}
                /> : null}
            </React.Fragment>)
        }
        // const hideStyle = { visibility: 'hidden', opacity: 0 };
        const opStyle = {
            height: this.state.windowHeight - WINDOW_PADDING,
            width: this.state.windowHeight * W_H_RATIO - WINDOW_PADDING,
            transitionDelay: '0s',
            visibility: 'visible',
            opacity: 1,
            display: 'flex',
            maxHeight: '200px'
        }
        let dragDropArea;
        if(this.state.displayModal)
        {
            // console.log(`should display Modal`)
            dragDropArea =
                <DragDropModal
                    initState={this.state.displayModal ? 'focus':'hide'}
                    modalState='displayModal'
                    disableModal={this.disableState}
                    enableModal={this.enableState}
                    targetAssetKey={this.state.targetAssetKey}
                    uploadCallback={async () => {
                        try
                        {
                            const add = await this.props.addActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, 'Upload Photo', this.state.targetAssetKey, {})
                        }
                        catch(e) {}
                    }}
                />
        }
        else
        {
            dragDropArea = '';
        }
        // console.log(this.state.messageKeys)
        // console.log(`the loading msg is ${this.state.loadingMsg}`)
        return (<div className='root-wrapper'>
        <LoadingBlock 
            msg={this.state.loadingMsg}
            loading={this.state.loadingMsg !== ''}
        />
        {this.state.displayTutorial ? <VideoWrapper 
            youtubeID='z-dtf4dECOU'
            onClose={() => this.setState({displayTutorial: false})}
            title={this.props.dict['memorial']['videoMsg']}
        />:null}
        {this.state.messageKeys.length > 0 ?
            <SystemMessage
                // messageGroupsKey={['general', 'homebase']}
                messageKeys={this.state.messageKeys}
                messageObj={this.props.dict['systemMessages']}
                deceased={this.props.accountInfo.deceased}
                ctnStyle={{backgroundColor: 'transparent'}}
                // onInput={this.getFeedback}
            /> : null}
        {this.state.confirmMessage && this.state.confirmMessage !== '' ?
            <ConfirmBox
                confirmMessage={this.state.confirmMessage}
                reject={() => this.state.toRejectAction()}
                confirm={() => this.state.toConfirmAction()}
            />
        :null}
        {this.state.displayFeedback && this.state.displayFeedback !== '' ?
        <Toast
            message={this.state.displayFeedback}
            duration={3000}
        />
        :null}
        {dragDropArea}
        <NavBar {...this.props} isLoggedIn={this.props.loggedIn} withLogout={this.props.loggedIn} />

           
            <ViewCtn
                viewObj={defaultViewsObj()}
                viewBase={this.state.selectView}
                windowHeight = {this.state.windowHeight}
                handleOffering={this.handleOffering}
                {...this.state}
                // currentOffering={this.state.currentOffering}
                // modifyOffering={this.state.modifyOffering}
                // predefinedOffering={this.state.predefinedOffering}
                // retrievedOffering={this.state.retrievedOffering}
                // positioningOffering={this.state.positioningOffering}
                // currentStep={this.state.currentStep}
                // userData={this.state.userData}
                // dayLogs={this.state.dayLogs}
            >
                <ChooseDecorate 
                    {...this.props} 
                    decorate={this.decorate}
                    handleOffering={this.handleOffering}
                    resetOffering={this.resetOffering}
                    complete={this.completeMemorial}
                    disableCanvasInteraction={this.state.disableCanvasInteraction}
                    warningBeforeEnablingCanvasInteraction={this.warningBeforeEnablingCanvasInteraction}
                />
                <FontAwesomeIcon data-tip="How to use Memorial Space?" className='about-icon top-right-about-icon' icon={faInfoCircle} onClick={() => {
                    this.setState({
                        displayAbout: !this.state.displayAbout
                    })
                }} />
                <About 
                    display={this.state.displayAbout} 
                    dict={this.props.dict['memorial']}
                    deceased={this.props.accountInfo.deceased}
                    triggerVideo={() => this.setState({displayTutorial: true})}
                    onClose={() => this.setState({ displayAbout: false })}
                />
                <ReactTooltip />
                {/*this.state.operationPanelDisplay ?
                    <div className='operation-panel' id='operation-panel' style={opStyle}>
                        <SetIntention {...this.props} setIntention={this.setIntention} />
                        <Decorate 
                            {...this.props} 
                            decorate={this.decorate}
                            handleOffering={this.handleOffering}
                        />
                        <Meditate {...this.props} completeMeditation={this.completeMeditation} />
                        <HidePanel {...this.props} display={this.state.operationPanelDisplay} hidePanel={this.toggleOption} reset={this.resetOffering} completeMemorial={this.completeMemorial} />
                    </div>
                    :
                    <div className='operation-panel' id='operation-panel' style={opStyle}>
                        <HidePanel {...this.props} display={this.state.operationPanelDisplay} hidePanel={this.toggleOption} reset={this.resetOffering} completeMemorial={this.completeMemorial} />
                    </div>
                */}
            </ViewCtn>
        </div>)
    }
}

const ViewCtn = (props) => {

    // const [preloaded, updatePreloaded] = useState(false)
    // if(!preloaded)
    // {
    //     return (
    //         <div className='view-container' id='app-canvas'>
    //             {props.children}
    //             <PreloadedDefaultOffering loadComplete={() => updatePreloaded(true)} />
    //         </div>
    //     )
    // }

    return(
        <div className='view-container' id='app-canvas'>
            {props.children}
            <P5Wrapper 
                className='app-base'
                sketch={Sketch}
                {...props}
                // viewObj={props.viewObj}
                // viewBase={props.viewBase}
                // windowHeight={props.windowHeight}
                // predefinedOffering={props.predefinedOffering}
                // handleOffering={props.handleOffering}
                // currentOffering={props.currentOffering}
                // modifyOffering={props.modifyOffering}
                // systemMsg={props.systemMsg}
                // currentStep={props.currentStep}
                // userData={props.userData}
                // dayLogs={props.dayLogs}
            >
            </P5Wrapper>
        </div>
    );
}

const RoundBtn = (props) => {
    let modifyObj, typeOfOffering;
    if(!props.triggerOffering)
    {
        typeOfOffering = 'modifyOffering';
        modifyObj = {
            optionKey: props.btnValue,
            targetAssetKey: props.targetAssetKey
        };
    }
    else
    {
        modifyObj = props.btnValue;
        typeOfOffering = 'currentOffering';
    }
    return(
        <div className='operation-btn-ctn'>
            <button className='operation-btn' value={props.btnValue}
                    onClick={() => props.handleOffering(modifyObj, typeOfOffering, true)}
            >
                <img src={props.imgSrc} alt={props.alt} className='operation-btn-thumbnail' />
                {props.iconTitle}</button></div>
    );
}


// ====================================================================
// =========================   DEPRECATED   ===========================
// ====================================================================

const OptionsPanel = (props) => {
    let optionIconsArr = [];
    let dispPanel = props.optionsPanelDisplay;
    const hideOptionsPanel = {
        display: 'none',
    };
    const dispOptionsPanel = {
        display: 'flex',
    }
    if(props.optionsArr.length > 0)
    {
        dispPanel = true;
        props.optionsArr.forEach( (option, idx) => {
            optionIconsArr.push(
                <RoundBtn
                    key={idx}
                    btnValue={option.btnValue}
                    imgSrc={option.imgSrc}
                    alt={option.alt}
                    triggerOffering={option.triggerOffering || false}
                    iconTitle={option.iconTitle}
                    offerCallback={option.offerCallback}
                    handleOffering={props.handleOffering}
                    currentOffering={props.currentOffering}
                    targetAssetKey={props.targetAssetKey}
                />
            );
        });
    }
    else
    {
        dispPanel = false;
    }
    return (
        <div className='operation-panel horizontal' id='operation-panel'
             style={ props.optionsPanelDisplay ? dispOptionsPanel : hideOptionsPanel } >
            {optionIconsArr}
        </div>
    );
}

const IconBtn = (props) => {
    let typeOfOffering = props.triggerOptions ? 'triggerOptions' : 'currentOffering';
    return(
        <div className='operation-btn-ctn'>
            <button className='operation-btn' value={props.btnValue}
                    onClick={() => props.handleOffering(props.btnValue, typeOfOffering)}
            >
                <img src={props.imgSrc} alt={props.alt} />
                {props.iconTitle}</button></div>
    );
}


export default MemorialSpace