import React, { Component } from 'react'
import { iconImages } from '../../init/initApp'
import { arrayBufferToBase64, validateDragNDropCompatibility } from '../../init/general'
import { S3Wrapper } from '../../classes/s3bucket'
import $ from 'jquery'
import GH from '../../classes/v2/GeneralHelper'
const loadImage = require('blueimp-load-image')


const uploadUrl = '/s3/v1/uploadNReorientPhoto'
const dragInitMsg = 'Drag your photo here or click to select file (up to 5MB) to upload'
const S3B = new S3Wrapper()


// use example
// <DragDropModal 
    // initState='focus' or ['focus']
// />
export default class DragDropModal extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            closeRequested: false,
            photoDropped: false,
            hideState: '',
            name: '',
            uploaded: false,
            dropboxFeedback: dragInitMsg,
            isAdvancedUpload: validateDragNDropCompatibility() ? true: false
        }
        this.handleInputFile = this.handleInputFile.bind(this)
        this.handleUploaded = this.handleUploaded.bind(this);
        this.compressNReorient = this.compressNReorient.bind(this);
    }
    componentDidMount()
    {
        const self = this;
        self.setState({
            uploadUrl: self.props.uploadUrl !== undefined ? self.props.uploadUrl : uploadUrl
        });
        // check if drag and drop is supported in the browser, if not supported, then get the normal input function
        
        const $form = $('.image-form');
        const $dragArea = $('.drag-drop-block');
        if(self.state.isAdvancedUpload)
        {
            let droppedFiles = false;
            $form.addClass('has-advanced-upload');
            $('#drag-drop-file-input').on('change', (e) => {
                // console.log('using upload function to handle input file')
                // this.handleInputFile(e)
                
                let filesArr = e.originalEvent.target.files;
                if(filesArr.length <= 0)
                {
                    return self.setState({
                        dropboxFeedback: dragInitMsg,
                        photoDropped: false,
                    });
                }
                return self.setState({
                    dropboxFeedback: `uploading ${filesArr[0]['name']}`,
                    photoDropped: false
                }, () => {
                    $form.trigger('submit')
                });
                
            });
            $form.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
                e.preventDefault();
                e.stopPropagation();
            })
            .on('dragover dragenter', function() {
                $dragArea.addClass('is-dragover');
                self.setState({
                    dropboxFeedback: 'ready to upload you photo?'
                });
            })
            .on('dragleave dragend drop', function() {
                $dragArea.removeClass('is-dragover');
                self.setState({
                    dropboxFeedback: dragInitMsg,
                    photoDropped: false,
                })
            })
            .on('drop', function(e) {
                console.log('dropped')
                e.preventDefault()
                let orientation;
                droppedFiles = e.originalEvent.dataTransfer.files
                // console.log(e)
                // console.log(e.originalEvent.dataTransfer.files)
                return self.setState({
                    dropboxFeedback: `uploading ${droppedFiles[0]['name']}`,
                    photoDropped: true
                }, () => {
                    const image = droppedFiles[0]
                    let formData
                    const $form = $('.image-form');
                    const $dragArea = $('.drag-drop-block');
                    // const image = document.querySelector('[type=file]').files[0];
                    formData = new FormData();
                    // console.log(image)
                    return self.compressNReorient(image, (img) => {
                        // console.log(img)
                        if(!img)
                        {
                            // console.log('no image')
                            // Error handling: if the file upload failed
                            $dragArea.addClass('is-error');
                            self.setState({
                                uploaded: false,
                                filePath: '',
                                photoDropped: false,
                                dropboxFeedback: `The type of image is not supported by the upload engine. Please make sure to upload images in jpeg or png format.`
                            });
                            return;
                        }
                        // console.log(img)
                        const base64Url = img.toDataURL();
                        // console.log(base64Url)
                        return img.toBlob((blob) => {
                            formData.append('file', blob);
                            return S3B.putImage(formData, 'user_photos', (err, responseJson) => {
                                if(err)
                                {
                                    // console.log(err)
                                    // Error handling: if the file upload failed
                                    $dragArea.addClass('is-error');
                                    self.setState({
                                        uploaded: false,
                                        filePath: '',
                                        photoDropped: false,
                                        dropboxFeedback: `Error: ${responseJson.errMsg}`
                                    });
                                    return;
                                }
                                // console.log('uploaded')
                                responseJson['data'] = base64Url;
                                self.setState({
                                    dropboxFeedback: `thank you, your photo has been uploaded.`,
                                    uploaded: true,
                                    photoDropped: false,
                                    filePath: responseJson.preview,
                                    fileName: responseJson.fileName,
                                    originalName: responseJson.originalName
                                });
                                return self.handleUploaded(responseJson)
                            });
                        });
                    });
                })
            })
            $form.on('submit', this.handleInputFile)
            /*
            $form.on('submit', function(e) {
                // console.log("user upload normally")
                e.preventDefault();
                // detect if users is submitting by dragdrop or input
                let formData;

                if(!self.state.photoDropped) // means user is submitting data by clicking the form submission
                {
                    const image = document.querySelector('[type=file]').files[0];
                    formData = new FormData();
                    return self.compressNReorient(image[0], (img) => {
                        if(!img)
                        {
                            // Error handling: if the file upload failed
                            $dragArea.addClass('is-error');
                            self.setState({
                                uploaded: false,
                                filePath: '',
                                photoDropped: false,
                                dropboxFeedback: `The type of image is not supported by the upload engine. Please make sure to upload images in jpeg or png format.`
                            });
                            return;
                        }
                        const base64Url = img.toDataURL();
                        img.toBlob((blob) => {
                            formData.append('file', blob);
                            S3B.putImage(formData, 'user_photos', (err, responseJson) => {
                            if(err)
                            {
                                // Error handling: if the file upload failed
                                $dragArea.addClass('is-error');
                                self.setState({
                                    uploaded: false,
                                    filePath: '',
                                    photoDropped: false,
                                    dropboxFeedback: `Error: ${responseJson.errMsg}`
                                });
                                return;
                            }
                            responseJson['data'] = base64Url;
                            self.setState({
                                dropboxFeedback: `thank you, your photo has been uploaded.`,
                                uploaded: true,
                                photoDropped: false,
                                filePath: responseJson.preview,
                                fileName: responseJson.fileName,
                                originalName: responseJson.originalName
                            });
                            self.handleUploaded(responseJson);
                            });
                        });
                    });
                    return;
                }
                if ($dragArea.hasClass('is-uploading')) return false;
                
                $dragArea.addClass('is-uploading').removeClass('is-error');
                
                if (self.state.isAdvancedUpload && self.state.photoDropped) {
                    // generate a fetch post request to post the file to the server
                    formData = new FormData();
                    return self.compressNReorient(droppedFiles[0], (img) => {
                        if(!img)
                        {
                            // Error handling: if the file upload failed
                            $dragArea.addClass('is-error');
                            self.setState({
                                uploaded: false,
                                filePath: '',
                                photoDropped: false,
                                dropboxFeedback: `The type of image is not supported by the upload engine. Please make sure to upload images in jpeg or png format.`
                            });
                            return;
                        }
                        const base64Url = img.toDataURL();
                        img.toBlob((blob) => {
                            formData.append('file', blob)
                            S3B.putImage(formData, 'user_photos', (err, responseJson) => {
                            if(err)
                            {
                                // Error handling: if the file upload failed
                                $dragArea.addClass('is-error')
                                self.setState({
                                    uploaded: false,
                                    filePath: '',
                                    photoDropped: false,
                                    dropboxFeedback: `Error: ${responseJson.errMsg}`
                                })
                                return
                            }
                            responseJson['data'] = base64Url
                            self.setState({
                                dropboxFeedback: `thank you, your photo has been uploaded.`,
                                uploaded: true,
                                photoDropped: false,
                                filePath: responseJson.preview,
                                fileName: responseJson.fileName,
                                originalName: responseJson.originalName
                            });
                            self.handleUploaded(responseJson)
                            })
                        });
                    });
                } else {
                  //TODO: ajax for legacy browsers
                  
                }
            });
            */
        }

    }
    handleInputFile(e) {
        // console.log(e)
        e.preventDefault()
        // console.log("handle input file called")
        
        const self = this
        const image = document.querySelector('[type=file]').files[0]
        let formData
        const $form = $('.image-form');
        const $dragArea = $('.drag-drop-block');
        // const image = document.querySelector('[type=file]').files[0];
        formData = new FormData();
        // console.log(image)
        return self.compressNReorient(image, (img) => {
            // console.log(img)
            if(!img)
            {
                // console.log('no image')
                // Error handling: if the file upload failed
                $dragArea.addClass('is-error');
                self.setState({
                    uploaded: false,
                    filePath: '',
                    photoDropped: false,
                    dropboxFeedback: `The type of image is not supported by the upload engine. Please make sure to upload images in jpeg or png format.`
                });
                return
            }
            // console.log(img)
            const base64Url = img.toDataURL();
            // console.log(base64Url)
            return img.toBlob((blob) => {
                formData.append('file', blob);
                return S3B.putImage(formData, 'user_photos', (err, responseJson) => {
                    // console.log(responseJson)
                    // console.log(err)
                    if(err)
                    {
                        // console.log(err)
                        // Error handling: if the file upload failed
                        $dragArea.addClass('is-error');
                        self.setState({
                            uploaded: false,
                            filePath: '',
                            photoDropped: false,
                            dropboxFeedback: `Error: ${responseJson.hasOwnProperty("errMsg") ? responseJson.errMsg : 'The system failed to upload your photo'}`
                        });
                        return;
                    }
                    // console.log('uploaded')
                    responseJson['data'] = base64Url;
                    self.setState({
                        dropboxFeedback: `thank you, your photo has been uploaded.`,
                        uploaded: true,
                        photoDropped: false,
                        filePath: responseJson.preview,
                        fileName: responseJson.fileName,
                        originalName: responseJson.originalName
                    });
                    return self.handleUploaded(responseJson);
                });
            });
        });
        return;
    }
    async compressNReorient(file, onResults) {
        let defaultW = 400, defaultH = 400; // iphone photo ratio 800:600
        let isLandscape = true;
        let inputFile = file
        const newFileName = GH.makeid(10)
        // console.log(file)
        if(file.type.toUpperCase().includes('HEIC'))
        {
            inputFile = await GH.convertHEICtoJpeg(file)
            // console.log(inputFile)
        }
        inputFile = await GH.resetOrientation(inputFile, newFileName)
        let loadedImg = loadImage(inputFile,
            (img, data) => {
            if(!data) return onResults(false)
            // if(!data) return false
            if(data.originalWidth > data.originalHeight) // this is landscape
            {
                defaultW = 400;
                defaultH = 300;
                isLandscape = true;
            }
            else
            {
                defaultW = 300;
                defaultH = 400;
                isLandscape = false;
            }
            return true
        })
        // console.log(inputFile)
        if(loadedImg)
        {
            return loadImage(inputFile, 
            (img, data) => {
                // console.log(img)
                if(img.type === 'error') return onResults(false)
                return onResults(img);
            },{
                meta: true,
                maxWidth: defaultW,
                maxHeight: defaultH,
                orientation: true,
                canvas: true,
            });
        }
        return onResults(false);
        /*
        let loadedFile = file
        let loadedImg = await loadImage(file,
            (img, data) => {
            // if(!data) return onResults(false);
            if(!data) return false
            if(data.originalWidth > data.originalHeight) // this is landscape
            {
                defaultW = 400;
                defaultH = 300;
                isLandscape = true;
            }
            else
            {
                defaultW = 300;
                defaultH = 400;
                isLandscape = false;
            }
            return true
        })
        console.log(`loadedimg is ${loadedImg}`)
        console.log(loadedImg)
        if(!loadedImg)
        {
            loadedFile = await GH.convertHEICtoJpeg(file)
            console.log(loadedFile)
        }
        if(loadedImg)
        {
            return loadImage(loadedFile, 
            (img, data) => {
                console.log(img)
                if(img.type === 'error') return onResults(false)
                return onResults(img);
            },{
                meta: true,
                maxWidth: defaultW,
                maxHeight: defaultH,
                orientation: true,
                canvas: true,
            });
        }
        return onResults(false);
        */
    }
    handleInput(e) {
        this.setState({
            name: e.target.value
        });
    }
    handleUploaded(data) { // access the JSON data using e.data.details
        const photoUploadedEvt = new CustomEvent('photoUploaded', { detail: data });
        window.dispatchEvent(photoUploadedEvt);
        if(this.props.hasOwnProperty('uploadCallback'))
        {
            this.props.uploadCallback()
        }
    }
    render() {
        const classString = this.props.initState === undefined ? '' : this.props.initState;
        const imgPreview = this.state.uploaded ? <img src={this.state.filePath} /> : null;

        return(
            <div className={'drag-drop-wrapper ' + classString}>
                {/*imgPreview*/}
                <div className='close-btn-ctn' tabIndex="0" role="button" aria-pressed={this.state.closeRequested} onClick={() => this.props.disableModal(this.props.modalState)}>
                    <img className='close-btn' src={iconImages().closeBtn.imgSrc} alt={iconImages().closeBtn.alt} />
                </div>
                <form className='image-form' method="post" action={this.state.uploadUrl} encType="multipart/form-data" ref={(form) => this._form = form} >
                    <div className='drag-drop-title'>
                        <h3>Upload Your Photo</h3>
                    </div>
                    <div className='drag-drop-block'>
                        <label htmlFor="drag-drop-file-input">{this.state.dropboxFeedback}</label>
                        <input className='drag-drop-file-input inputfile' id='drag-drop-file-input' type="file" name="file" />
                    </div>
                    <input className={this.state.isAdvancedUpload ? 'advancedUpload' : 'notAdvancedUpload'} type="submit" value="Upload File" name="submit" />
                </form>
                {/*<div id='preview'></div>*/}
            </div>
        )
    }
}
// <h3 id='upload-file-feedback'>{this.state.dropboxFeedback}</h3>