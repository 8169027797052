import methods from '../methods'
import { combineReducers } from 'redux'

/**
 * When user tries to query the database, regulate the amount of entries in each query and store the cursor for next query
 * @param {int} state 
 * @param {obj} action 
 * @returns 
 */
const queryCursor = (state = 0, action) => {
    switch(action.type)
    {
        case methods.ADD_CURSOR:
            return action.payload
        default: return state
    }
}

const DEMOEntries = (state = [], action) => {
    switch(action.type)
    {
        case methods.LOAD_DEMO_ENTRIES:
            return action.payload
        case methods.CONCAT_DEMO_ENTRIES:
            const newEntries = [...state, action.payload]
            return newEntries
        default: return state
    }
}

const PG13Entries = (state = [], action) => {
    switch(action.type)
    {
        case methods.LOAD_PG13_ENTRIES:
            return action.payload
        case methods.CONCAT_PG13_ENTRIES:
            return [...state, action.payload]
        default: return state
    }
}

const CSSRSEntries = (state = [], action) => {
    switch(action.type)
    {
        case methods.LOAD_CSSRS_ENTRIES:
            return action.payload
        case methods.CONCAT_CSSRS_ENTRIES:
            return [...state, action.payload]
        default: return state
    }
}

const CDDEntries = (state = [], action) => {
    switch(action.type)
    {
        case methods.LOAD_CDD_ENTRIES:
            return action.payload
        case methods.CONCAT_CDD_ENTRIES:
            return [...state, action.payload]
        default: return state
    }
}

export default combineReducers({
    queryCursor, 
    DEMOEntries, 
    PG13Entries,
    CSSRSEntries,
    CDDEntries
})