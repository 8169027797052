import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import WritingDesk from '../ui/WritingDesk'
import withPreLoader from './withPreLoader'
import { enableResetConfig } from '../../actions/config'
import { upsertConfig } from '../../actions/index'
import { addActivityLog, updateMemorialStepLog, addDayJournalEntry, getCurrentJournalDay, removeJournalEntry, updateJournalEntries } from '../../actions/days'
// import { defaultLang } from '../lang/init'

const mapStateToProps = (state) =>
    ({
        resetConfig: state.configReducer.resetConfig,
        dayJournalEntries: state.daysReducer.dayJournalEntries,
        currentJournalDay: state.daysReducer.currentJournalDay
    })

const mapDispatchToProps = (dispatch) => 
    ({
        addDayJournalEntry: (id = null, usersId, dayLogId, journalDay, typeKey, qKey, journalEntry = '', juornalEntryHash) => dispatch(addDayJournalEntry(id, usersId, dayLogId, journalDay, typeKey, qKey, journalEntry, juornalEntryHash)),
        upsertConfig: (userId, param) => dispatch(upsertConfig(userId, param)),
        enableResetConfig: reset => dispatch(enableResetConfig(reset)),
        addActivityLog: (userId, dayLogId, day, type, fieldData) => dispatch(addActivityLog(userId, dayLogId, day, type, fieldData)),
        updateMemorialStepLog: (dayLogId, activityLogs) => dispatch(updateMemorialStepLog(dayLogId, activityLogs)),
        getCurrentJournalDay: (usersId, dayLogsId, day) => dispatch(getCurrentJournalDay(usersId, dayLogsId, day)),
        removeJournalEntry: (id, idx) => dispatch(removeJournalEntry(id, idx)),
        updateJournalEntries: (entries = []) => dispatch(updateJournalEntries(entries))
    })


const Container = connect(mapStateToProps, mapDispatchToProps)(WritingDesk)
export default withRouter(withPreLoader(Container))
