import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withPreLoader from './withPreLoader'
import MemorialSpace from '../ui/MemorialSpace'
import { changeLang, enableResetConfig } from '../../actions/config'
import { upsertConfig } from '../../actions/index'
import { getCurrentDayLogs, addActivityLog, loadActivityLog, updateMemorialStepLog } from '../../actions/days'
import { defaultLang } from '../lang/init'

const mapStateToProps = (state) =>
    ({
        // auth: state.userReducer.auth,
        // dict: state.configReducer.lang.dict,
        // lang: state.configReducer.lang.lang,
        // accountInfo: state.userReducer.accountInfo,
        // resetConfig: state.configReducer.resetConfig,
        // currentDay: state.daysReducer.currentDay,
        // activityLogs: state.daysReducer.activityLogs
    })

const mapDispatchToProps = (dispatch) => 
    ({
        // changeLang: (lang = defaultLang) => dispatch(changeLang(lang)),
        upsertConfig: (userId, param) => dispatch(upsertConfig(userId, param)),
        enableResetConfig: reset => dispatch(enableResetConfig(reset)),
        // getCurrentDayLogs: userId => dispatch(getCurrentDayLogs(userId)),
        addActivityLog: (userId, dayLogId, day, type, activityKey, fieldData) => dispatch(addActivityLog(userId, dayLogId, day, type, activityKey, fieldData)),
        // loadActivityLog: (userId, dayLogId) => dispatch(loadActivityLog(userId, dayLogId)),
        updateMemorialStepLog: (dayLogId, activityLogs) => dispatch(updateMemorialStepLog(dayLogId, activityLogs))
    })


const Container = connect(mapStateToProps, mapDispatchToProps)(MemorialSpace)
export default withRouter(withPreLoader(Container))
