import React, { Component, useState, useEffect, useRef }  from 'react';
import { hash } from '../init/general'
import { diaryQuestions, canvasDimensions, sessionKeys, journalTexts, returnQuestions, activitiesFeedback, s3folders, configs } from '../init/initApp'
import config from '../init/config'
import NavBar from '../containers/NavBar'
import ReactTooltip from 'react-tooltip'
import { Footer } from '../assets/footer'
import WritingProgress from './WritingDesk/WritingProgress'
// import WatercolorIcon from '../../img/watercolor_icon2.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faSave, faInfoCircle, faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import STM from '../classes/v2/SystemTimeManage'
import SystemMessage from '../assets/v2/SystemMessage'
import ConfirmBox from '../assets/v2/ConfirmBox'
import { FeedbackBox } from '../classes/systemMessage';
import JH from '../classes/v2/JournalHelper'
import EmailHelper from '../classes/v2/EmailHelper'
import EH from '../classes/v2/ErrorHelper'
import GH from '../classes/v2/GeneralHelper'
import About from './WritingDesk/About'
import Tip from './WritingDesk/Tip'
import { Localstorage } from '../classes/localstorage';
import VideoWrapper from '../assets/v2/VideoWrapper'
import './WritingDesk.scss'

import {langPack, defaultLang} from '../lang/init.js';

const lang = langPack[defaultLang];
const dict = lang.diary;

let journalEntries = [];
//TODO: populate deceased name

// get user's access token and retrieve the data
// const STM = new SystemTimeManagement();
const LS = new Localstorage();
// users should write at least 5 minutes. This will be implemented in the JournalTextArea
const minWritingTime = config.minWritingTime
// const minWritingTime = 1000*5
/**
 * data that should be retrieved from localStorage / MySQL database:
 * startDay: the starting day of the study (usually the day that user registers the study)
 * journalEntries: the previous journal entries in the past week
 */

/**
 * Declare the dimensions that are important to the canvas
 */
const W_H_RATIO = canvasDimensions.W_H_RATIO, WINDOW_PADDING = canvasDimensions.WINDOW_PADDING, HEIGHT_RATIO = canvasDimensions.HEIGHT_RATIO;
let HEIGHT = window.innerHeight * HEIGHT_RATIO - WINDOW_PADDING || 798,
    WIDTH = HEIGHT * W_H_RATIO,
    PADDING = WIDTH / 120,
    WIDTH_UNIT = WIDTH / 4 - PADDING * 5,
    HEIGHT_UNIT = HEIGHT / 5 - PADDING * 3;
let diaryWrapper = {
    width: WIDTH,
    height: HEIGHT,
}
let panelWrapper = {
    width: WIDTH / 2.5 - PADDING * 10,
    height: HEIGHT - PADDING * 15,
    marginLeft: ( window.innerWidth - WIDTH )/2 + PADDING * 16,
    marginTop: HEIGHT_UNIT
};
let logWrapper = {
    width: WIDTH / 2.5 - PADDING * 10,
    height: HEIGHT - PADDING * 15,
    marginRight: ( window.innerWidth - WIDTH )/2 + PADDING * 16,
    marginTop: HEIGHT_UNIT
}

//['journal-intro-one', 'journal-intro-two', 'journal-intro-three', 'end-msg']
class WritingDesk extends Component {
    constructor(props) {
        super(props);
        // console.log(this.props)
        this.state = {
            deceasedName: null,
            diaryWrapper: diaryWrapper,
            panelWrapper: panelWrapper,
            logWrapper: logWrapper,
            systemMsgArr: [],
            triggerSystemMsg: false,
            autosaved: false,
            displayFeedback: false,
            warning: false,
            loggedIn: false,
            today: STM.formatLocalDate(),
            displayImaginedDialogues: false,
            displayNarrativeNotes: false,
            prevJournalEntries: null,
            displayDay: null,
            completeRetrievingJournalDay: false,
            startTimer: false,
            timeup: false
        };
        this.initConfirmMessage = this.initConfirmMessage.bind(this)
        this.checkCurrentVisitDay = this.checkCurrentVisitDay.bind(this)
        this.updateDimensions = this.updateDimensions.bind(this);
        this.autoSaveEntry = this.autoSaveEntry.bind(this)
        this.saveEntry = this.saveEntry.bind(this)
        this.displaySaveFeedback = this.displaySaveFeedback.bind(this)
        this.initSystemMsg = this.initSystemMsg.bind(this)
        this.handleSelectWritingType = this.handleSelectWritingType.bind(this)
        this.changeDisplayDay = this.changeDisplayDay.bind(this)
        this.checkIfWritingExerciseIsCompleted = this.checkIfWritingExerciseIsCompleted.bind(this)
        this.onUpdateButtonClicked = this.onUpdateButtonClicked.bind(this)
        this.startWriting = this.startWriting.bind(this)
        this.completeWriting = this.completeWriting.bind(this)
    }

    async componentDidMount() {
        // console.log(`mount mounta`)
        // console.log(this.props)
        const self = this
        this.props.changeLang(this.props.match.params.lang);
        // generate the dimension for diary-wrapper
        this.updateDimensions()
        // console.log(this.props.currentDay)
        const jd = await this.props.getCurrentJournalDay(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day)
        console.log(`the current journal day is ${jd}`)
        // console.log('this part is blocking')
        console.log(this.props.dayJournalEntries)
        if(jd <= 1 && this.props.dayJournalEntries.length <= 0)
        {
            // console.log(`user never had entries before. this is the first day`)
            this.setState({
                systemMsgArr: ['desk-intro-one', 'desk-intro-two', 'desk-intro-three', {
                    message: 'desk-intro-four',
                    callback: () => this.setState({displayTutorial: true})
                }],
                displayDay: jd,
                completeRetrievingJournalDay: true
            })
        }
        if(this.props.dayJournalEntries.length > 0)
        {
            this.setState({
                displayImaginedDialogues: true,
                displayNarrativeNotes: true
            })
        }
        window.addEventListener("resize", this.updateDimensions)
        let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        console.log(activityLog)
        if(activityLog?.completeJournal)
        {
            // console.log('journal completed')
            this.setState({
                completed: true
            })
        }
        // get all of the journals for today
        // const dayJournalEntries = await JH.getJournalEntriesGivenJournalDay(this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay)
        // console.log(`user has at least one day entry already.`)
        if(jd > 1)
        {
            console.log(`journal day is more than 1: ${jd}, should retrieve previous journal entries`)
            try
            {
                // console.log('this part is none blocking')
                const prevJournalEntries = await JH.getJournalEntriesByRange(this.props.auth.id, [0, jd], async (e, d) => {
                    // console.log(e)
                    // console.log(d)
                    if(e) throw e
                    const orderedEntry = await JH.convertEntryToSortedObj(d)
                    return self.setState({
                        prevJournalEntries: orderedEntry,
                        displayDay: jd,
                        completeRetrievingJournalDay: true
                    })
                })
                // console.log(`the page should reload`)
                return
            }
            catch(e)
            {
                // console.log(`error happened when trying to retrieve previous entries`)
                // console.log(e)
                this.setState({
                    prevJournalEntries: {
                        1: this.props.dayJournalEntries
                    },
                    displayDay: jd,
                    completeRetrievingJournalDay: true
                })
                // console.log(`the page should reload`)
                return
            }
        }
        else
        {
            this.setState({
                prevJournalEntries: {
                    1: this.props.dayJournalEntries
                },
                displayDay: jd,
                completeRetrievingJournalDay: true
            })
            // console.log(`the page should reload`)
            return
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    async onUpdateButtonClicked()
    {
        // console.log("clicked update")
        // console.log(this.props)
        // check if the day is over 7 days
        const currentJournalDay = this.props.currentJournalDay > 7 ? true:false
        let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        console.log(activityLog)
        const self = this
        console.log(`current journal day ${currentJournalDay}`)
        if(currentJournalDay)
        {
            // user does not have to complete all of the sections
            // display thank message and save it to activity log
            this.props.dayJournalEntries.forEach(async (entry, idx) => {
                // console.log('save '+idx)
                const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
            })
            console.log('you should not see me before saving.')
            this.initSystemMsg([{
                message: 'update-writing-exercise-congrats',
                callback: e => {
                    // self.props.initSystemMsg([])
                    self.initSystemMsg([])
                }
            }])
            activityLog['completeJournal'] = STM.formatUTCTime()
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            return
        }

        const exerciseCompleted = this.checkIfWritingExerciseIsCompleted()
        console.log(exerciseCompleted)

        // const narrativeNotes = this.props.journalEntries.find(entry => entry.question_key === 'q1')                    
        // console.log(`narrative notes is complete ${narrativeNotesComplete}`)
        // display thank message and save it to activity log
        if(exerciseCompleted)
        {
            // console.log('user made it here')
            this.props.dayJournalEntries.forEach(async (entry, idx) => {
                // console.log('save '+idx)
                const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
            })
            let completeTime = STM.formatUTCTime()
            // for the user who completes the journal the first day, the research team should receive notifications.
            // console.log(`check current complete day ${this.props.currentJournalDay}`)
            // console.log(this.props?.accountInfo?.redcap_id)

            activityLog['completeJournal'] = completeTime
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            // console.log('you should not see me before saving.')
            this.initSystemMsg([{
                message: 'update-writing-exercise-congrats',
                callback: e => {
                    // self.props.initSystemMsg([])
                    self.initSystemMsg([])
                }
            }])
            // console.log('you should see me after initing the system message')
            return
        }
        else
        {
            this.props.dayJournalEntries.forEach(async (entry, idx) => {
                // console.log('save '+idx)
                const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
            })
            this.initSystemMsg([{
                message: 'please-complete-the-writing-exercise',
                callback: e => {
                    // self.props.initSystemMsg([])
                    self.initSystemMsg([])
                }
            }])
            return
        }
    }

    initConfirmMessage(title='', text='', confirmFunction = null, rejectFunction = null, confirmText = '', cancelText = '')
    {
        const confirmMessage = {
            title: title,
            text: text,
            confirmText: confirmText,
            cancelText: cancelText
        }

        let toConfirmFunction = confirmFunction
        if(toConfirmFunction === null)
        {
            toConfirmFunction = () => this.setState({
                confirmMessage: null
            })
        }
        else
        {
            toConfirmFunction = () => {
                confirmFunction()
                this.setState({
                    confirmMessage: null
                })
            }
        }
        let toRejectFunction = rejectFunction
        if(toRejectFunction === null)
        {
            toRejectFunction = () =>  this.setState({
                confirmMessage: null
            })
        }
        else
        {
            toConfirmFunction = () => {
                rejectFunction()
                this.setState({
                    confirmMessage: null
                })
            }
        }
        // console.log('the reject function')
        // console.log(toRejectFunction)
        this.setState({
            confirmMessage,
            toConfirmFunction,
            toRejectFunction
        })
    }

    autoSaveEntry(id, journalEntry)
    {
        var self = this
        if(!this.state.autosaved) // to initiate a new autosave
        {
            this.setState({
                autosaved: true
            }, () => {
                setTimeout(function() {
                    try
                    {
                        self.saveEntry(id, journalEntry);
                        self.setState({
                            autosaved: false
                        })
                    }
                    catch(e)
                    {

                    }
                }, configs.autosave)
            })
        }
        // autosave has been initiated, so do nothing
    }

    async saveEntry()
    {
        console.log(`to save journal entry `)
        const self = this
        console.log(this.props.dayJournalEntries)
        let err = []
        const loop = await GH.asyncForEach(this.props.dayJournalEntries, async (input, idx) => {
            console.log("input is ", input)
            if(!input?.id)
            {
                console.log("It's a new entry we should insert it", this.props.currentDay)
                const insert = await JH.insertEntry(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, this.props.currentJournalDay, input.question_day_key, this.props.currentDay?.day_hash || GH.hash(), input?.question_key, input?.journal_entry, input?.journal_entry_hash || GH.hash())
                console.log(insert)
                return
            }
            console.log("you should not see me")
            const save = await JH.upsertJournalEntry(input?.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, input.question_day_key, input.question_key, input?.journal_entry, input?.journal_entry_hash)
            console.log(save)
            return 
        })
        // let arr = [...this.props.dayJournalEntries]
        // let idx = 0
        // arr.forEach((j, ix) => {
        //     if(j.id === id)
        //     {
        //         idx = ix
        //     }
        // })
        // let journal = arr[idx]
        // journal['journal_entry'] = journalEntry
        // arr[idx] = journal
        // const updateReducer = await this.props.updateJournalEntries(arr)
        // // console.log(updateReducer)
        // const save = await JH.upsertJournalEntry(id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, journal.question_day_key, journal.question_key, journalEntry)
        // // console.log(save)
        // // display feedback
        // this.setState({
        //     displayFeedback: true
        // }, () => {
        //     setTimeout(function() {
        //         try
        //         {
        //             self.setState({
        //                 displayFeedback: false
        //             });
        //         }
        //         catch(e)
        //         {

        //         }
        //     }, configs.feedbackFade);
        // })
    }
    
    displaySaveFeedback(warning = false)
    {
        const self = this
        // display feedback
        this.setState({
            displayFeedback: true,
            warning: warning
        }, () => {
            setTimeout(function() {
                try
                {
                    self.setState({
                        displayFeedback: false,
                        warning: false
                    });
                }
                catch(e) {}

            }, configs.feedbackFade);
        })
    }

    checkIfWritingExerciseIsCompleted()
    {
        let completed=0
        const allCheckboxes = document.getElementsByClassName('section-checkbox-input')
        // console.log(allCheckboxes)
        for(let i = 0; i < allCheckboxes.length ; i++)
        {
            // console.log(allCheckboxes[i])
            // console.log(allCheckboxes[i].checked)
            if(allCheckboxes[i].checked)
            {
                completed+=1
            }
        }
        // console.log(`found ${completed} completed`)
        if(completed === allCheckboxes.length)
        {
            return true
        }
        return false
    }

    displayWelcomeMsg()
    {
        if(this.state.displayDay === 1)
        {
            const self = this
            return this.setState({
                systemMsgArr: ['desk-intro-one', 'desk-intro-two', 'desk-intro-three', {
                    message: 'desk-intro-four',
                    callback: () => {
                        self.setState({
                            systemMsgArr: []
                        })
                    }
                }]
            });
        }
    }
    initSystemMsg(msgArr = [])
    {
        this.setState({
            systemMsgArr: msgArr
        })
    }

    updateDimensions() {
        HEIGHT = window.innerHeight * HEIGHT_RATIO - WINDOW_PADDING || 798;
        WIDTH = HEIGHT * W_H_RATIO;
        PADDING = WIDTH / 120;
        WIDTH_UNIT = WIDTH / 6 - PADDING * 5;
        HEIGHT_UNIT = HEIGHT / 5 - PADDING * 3;
        diaryWrapper = {
            minWidth: '1400px',
            minHeight: '760px',
            width: WIDTH,
            height: HEIGHT,
        }
        panelWrapper = {
            // minWidth: '32%',
            // maxWidth: '45%',
            // minHeight: '650px',
            // width: WIDTH_UNIT * 3.2,
            // height: WIDTH_UNIT * 3,
            // right:'11%',
            // marginTop: HEIGHT_UNIT - PADDING * 6
        };
        logWrapper = {
            minWidth: '26%',
            maxWidth: '45%',
            minHeight: '650px',
            width: WIDTH_UNIT * 2.8,
            height: WIDTH_UNIT * 3,
            marginLeft: '11%',
            marginTop: HEIGHT_UNIT - PADDING * 6
        }
        this.setState({
            diaryWrapper: diaryWrapper,
            panelWrapper: panelWrapper,
            logWrapper: logWrapper
        });
    }


    async checkCurrentVisitDay()
    {
        // console.log('check current visit day called')
        if(Object.keys(this.props.currentDay).length <= 0 || this.props.currentDay === {})
        {
            // console.log('check the current day and see if a new day should be set')
            // load the current day into the reducer
            const currentDay = await this.props.getCurrentDayLogs(this.props.auth.id)
            if(currentDay) // set a new day
            {
                this.setState({
                    displayFeedback: this.props.lang['memorial']['newVisit']
                })
            }

            // console.log(this.props.currentDay)
            // console.log(`is a new day ${currentDay}`)
        }
        else
        {
            // there are previous day logs, determine if a new day should be initiated
            const shouldSetNewDay = STM.shouldSetNewDay(this.props.currentDay.login_time)
            if(shouldSetNewDay)
            {
                // console.log('you should never see me')
                const setNewDay = await this.props.getCurrentDayLogs(this.props.auth.id)
                if(setNewDay)
                {
                    this.setState({
                        displayFeedback: this.props.lang['memorial']['newVisit']
                    })
                }
            }
            // console.log(this.props.currentDay)
            // console.log(`should set a new day new day ${shouldSetNewDay}`)
        }
        return true
    }

    handleSelectWritingType(type = 'imaginedDialogues')
    {
        switch(type)
        {
            case 'imaginedDialogues':
                this.setState({
                    displayImaginedDialogues: true,
                    displayNarrativeNotes: false
                })
                return
            case 'narrativeNotes':
                this.setState({
                    displayImaginedDialogues: false,
                    displayNarrativeNotes: true
                })
                return
        }
    }

    changeDisplayDay(d = 1)
    {
        // use the day to track the date
        try
        {
            let entries = {...this.state.prevJournalEntries}
            const targetEntry = entries[d].find(e => e.question_key === 'constant')
            const today = STM.formatLocalDate(targetEntry.created_at)
            this.setState({ today })
            // console.log(today)
        }
        catch(e)
        {
            this.setState({
                today: `Day ${d}`
            })
        }

        this.setState({
            displayDay: d
        })
    }

    startWriting()
    {
        return this.setState({ 
            startTimer: true,
            displayImaginedDialogues: true,
            displayNarrativeNotes: true
        })
    }

    async completeWriting()
    {
        console.log("complete writing clicked")
        console.log(this.props.dayJournalEntries)
        // check if the day is over 7 days
        const currentJournalDay = this.props.currentJournalDay > 7 ? true:false
        // console.log(`completed ${this.props.currentJournalDay}`)
        let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        // console.log(activityLog)
        const self = this
        if(currentJournalDay)
        {
            // user does not have to complete all of the sections
            // display thank message and save it to activity log
            this.props.dayJournalEntries.forEach(async (entry, idx) => {
                // console.log('save '+idx)
                const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
            })
            // console.log('you should not see me before saving.')
            this.initSystemMsg([{
                message: 'complete-writing-exercise-congrats',
                callback: e => {
                    // self.props.initSystemMsg([])
                    self.initSystemMsg([])
                }
            }])
            activityLog['completeJournal'] = STM.formatUTCTime()
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            this.setState({
                completed: true
            })
            return
        }
        const exerciseCompleted = this.checkIfWritingExerciseIsCompleted()
        // console.log(exerciseCompleted)

        if(exerciseCompleted)
        {
            let completeTime = STM.formatUTCTime()
            // for the user who completes the journal the first day, the research team should receive notifications.
            if(this.props.currentJournalDay === 1 && this.props?.accountInfo?.redcap_id)
            {
                // console.log('completed the first day')
                const sendEmail = await EmailHelper.sendEmail(config.emailList[config.currentEnv], `Living Memory Home User with REDCap ID: ${this.props.accountInfo.redcap_id} has completed the first day writing exercise`, `The time of completion is EST ${STM.formatLocalTime()}. Please do not forget to schedule the follow up surveys. Thank you.`)
                // console.log(sendEmail)
            }
            this.props.dayJournalEntries.forEach(async (entry, idx) => {
                // console.log('save '+idx)
                const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
            })
            activityLog['completeJournal'] = completeTime
            const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
            // console.log('you should not see me before saving.')

            this.initSystemMsg([{
                message: 'complete-writing-exercise-congrats',
                callback: e => {
                    self.initSystemMsg([])
                }
            }])
            this.setState({
                completed: true
            })
            // console.log('you should see me after initing the system message')
            return
        }
        else
        {
            console.log("exercise not completed")
            this.props.dayJournalEntries.forEach(async (entry, idx) => {
                // console.log('save '+idx)
                const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
            })
            this.initSystemMsg([{
                message: 'please-complete-the-writing-exercise',
                callback: e => {
                    self.initSystemMsg([])
                }
            }])
            
            return
        }
    }

    render() {
        const reviewMode = this.props.currentJournalDay !== this.state.displayDay
        return(
            <React.Fragment>
                {this.state.displayTutorial ? <VideoWrapper 
                    youtubeID='Ut4oh6oCZQg'
                    onClose={() => this.setState({displayTutorial: false})}
                    title={this.props.dict['memorial']['videoMsg']}
                />:null}
                <NavBar {...this.props} isLoggedIn={this.props.loggedIn} withLogout={this.props.loggedIn} />
                <About 
                    display={this.state.displayAbout} 
                    dict={this.props.dict['diary']}
                    deceased={this.props.accountInfo.deceased}
                    triggerVideo={() => this.setState({displayTutorial: true})}
                    onClose={() => this.setState({ displayAbout:false })}
                />
                <FontAwesomeIcon data-tip="How to complete everyday writing exercise?" className='about-icon top-right-about-icon writing-desk-about-icon' icon={faInfoCircle} onClick={() => {
                    this.setState({
                        displayAbout: !this.state.displayAbout
                    })
                }} />
                <div className='diary-root-wrapper row'>


                    <div className='diary-wrapper' 
                        // style={window.innerWidth >= 1024 ? this.state.diaryWrapper : {}}
                    >

                        {<div className='row mb-2 journal-activity-title-container'>

                        {this.state.prevJournalEntries !== null ? 
                        <div className='select-date-section'>
                            <FontAwesomeIcon icon={faCaretLeft} className={'journal-browsing-icon active'} onClick={() => {
                                if(this.state.displayDay > 1)
                                {
                                    this.setState({
                                        displayImaginedDialogues: true,
                                        displayNarrativeNotes: true
                                    })
                                    return this.changeDisplayDay(this.state.displayDay - 1)
                                }
                                // TODO: warn user that this is the first day
                                const self = this
                                this.initSystemMsg([{
                                    message: "already-first-journal-day",
                                    callback: e => {
                                        // self.props.initSystemMsg([])
                                        self.initSystemMsg([])
                                    }
                                }])
                            }} />
                                <h4 className='journal-activity-title'>
                                {this.state.today}{' '}
                                </h4>
                            <FontAwesomeIcon icon={faCaretRight} className={this.state.reverse === 0 ? 'journal-browsing-icon': 'journal-browsing-icon active' }onClick={() => {
                                if(this.state.displayDay < this.props.currentJournalDay)
                                {
                                    this.setState({
                                        displayImaginedDialogues: true,
                                        displayNarrativeNotes: true
                                    })
                                    return this.changeDisplayDay(this.state.displayDay + 1)
                                }
                                // console.log("last day")
                                this.setState({
                                    displayImaginedDialogues: false,
                                    displayNarrativeNotes: false
                                })
                                // TODO: warn user that this is the last day
                                const self = this
                                this.initSystemMsg([{
                                    message: "already-last-journal-day",
                                    callback: e => {
                                        // self.props.initSystemMsg([])
                                        self.initSystemMsg([])
                                    }
                                }])

                            }} />
                        </div>    
                            :
                        <h4 className='journal-activity-title'>
                        {this.state.today}{' '}
                        </h4>}
                        {this.state.startTimer ? <WritingProgress progress={100} timeupCallback={() => {
                            this.setState({ timeup: true })
                        }} />:null}
                        <div className='writing-desk-date-wrapper'>
                        
                        {/** If user has not clicked on start writing */}
                        {!this.state.startTimer && !reviewMode ? 
                            <button className={reviewMode ? 'lmh-primary-btn d-none':'lmh-primary-btn'} onClick={this.startWriting}>
                            {this.props.dict['diary']['startWriting']}
                            </button>
                            :
                            
                            this.state.completed ?
                            
                            <button className={reviewMode ? 'lmh-primary-btn d-none':'lmh-primary-btn'} onClick={this.onUpdateButtonClicked}>{this.props.dict['diary']['update']}</button>
                            :
                            <button className={reviewMode ? 'lmh-primary-btn d-none': this.state.timeup ? 'lmh-primary-btn':'lmh-primary-btn-disabled'} onClick={async () => {
                                // console.log(this.props)

                                // check if the day is over 7 days
                                const currentJournalDay = this.props.currentJournalDay > 7 ? true:false
                                // console.log(`completed ${this.props.currentJournalDay}`)
                                let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
                                // console.log(activityLog)
                                const self = this
                                if(currentJournalDay)
                                {
                                    // user does not have to complete all of the sections
                                    // display thank message and save it to activity log
                                    this.props.dayJournalEntries.forEach(async (entry, idx) => {
                                        // console.log('save '+idx)
                                        const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
                                    })
                                    // console.log('you should not see me before saving.')
                                    this.initSystemMsg([{
                                        message: 'complete-writing-exercise-congrats',
                                        callback: e => {
                                            // self.props.initSystemMsg([])
                                            self.initSystemMsg([])
                                        }
                                    }])
                                    activityLog['completeJournal'] = STM.formatUTCTime()
                                    const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
                                    this.setState({
                                        completed: true
                                    })
                                    return
                                }
                                // console.log("user has to complete all the things")
                                /**
                                // check if the constant is completed
                                const constantComplete = this.props.dayJournalEntries.find(entry => entry.question_key === 'constant')
                                // check if the imagined dialogue is completed
                                const imaginedDialogue = this.props.dayJournalEntries.find(entry => entry.question_key === 'q1')
                                // console.log(imaginedDialogue)
                                const dialogueComplete = imaginedDialogue && imaginedDialogue.hasOwnProperty('journal_entry') && imaginedDialogue.journal_entry !== null
                                // check if the narrative notes is completed
                                const typeOfCategories = Object.keys(this.props.dict['writingCategories']['categories'])
                                const narrativeNotes = this.props.dayJournalEntries.find(entry => typeOfCategories.includes(entry.question_day_key))
                                // console.log(narrativeNotes)
                                const narrativeNotesComplete = narrativeNotes && narrativeNotes.hasOwnProperty('journal_entry') && narrativeNotes.journal_entry !== null
                                // const narrativeNotes = this.props.journalEntries.find(entry => entry.question_key === 'q1')                    
                                // console.log(`narrative notes is complete ${narrativeNotesComplete}`)
                                // display thank message and save it to activity log
                                */
                                const exerciseCompleted = this.checkIfWritingExerciseIsCompleted()
                                // console.log(exerciseCompleted)

                                if(exerciseCompleted)
                                {
                                    let completeTime = STM.formatUTCTime()
                                    // for the user who completes the journal the first day, the research team should receive notifications.
                                    if(this.props.currentJournalDay === 1 && this.props?.accountInfo?.redcap_id)
                                    {
                                        // console.log('completed the first day')
                                        const sendEmail = await EmailHelper.sendEmail(config.emailList[config.currentEnv], `Living Memory Home User with REDCap ID: ${this.props.accountInfo.redcap_id} has completed the first day writing exercise`, `The time of completion is EST ${STM.formatLocalTime()}. Please do not forget to schedule the follow up surveys. Thank you.`)
                                        // console.log(sendEmail)
                                    }
                                    this.props.dayJournalEntries.forEach(async (entry, idx) => {
                                        // console.log('save '+idx)
                                        const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
                                    })
                                    activityLog['completeJournal'] = completeTime
                                    const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLog)
                                    // console.log('you should not see me before saving.')
        
                                    this.initSystemMsg([{
                                        message: 'complete-writing-exercise-congrats',
                                        callback: e => {
                                            self.initSystemMsg([])
                                        }
                                    }])
                                    this.setState({
                                        completed: true
                                    })
                                    // console.log('you should see me after initing the system message')
                                    return
                                }
                                else
                                {
                                    // console.log("exercise not completed")
                                    this.props.dayJournalEntries.forEach(async (entry, idx) => {
                                        // console.log('save '+idx)
                                        const s = await JH.upsertJournalEntry(entry.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, entry.question_day_key, entry.question_key, entry.journal_entry)
                                    })
                                    this.initSystemMsg([{
                                        message: 'please-complete-the-writing-exercise',
                                        callback: e => {
                                            self.initSystemMsg([])
                                        }
                                    }])
                                    
                                    return
                                }
            
                            }}>{this.props.dict['diary']['complete']}</button>
        
                        }
        
                        </div>
                        </div>}
                        
                        {!this.state.startTimer&&!this.state.displayImaginedDialogues&&!this.state.displayNarrativeNotes ? <div  className=' d-flex d-sm-flex diary-section-row' style={{ maxWidth: '50%' }}>
                        <Tip dict={this.props.dict['diary']['tip']} defaultDisplay={this.state.currentJournalDay === 1 || (!this.state.displayImaginedDialogues&&!this.state.displayNarrativeNotes)} />
                        </div>:null}

                        <div className=' d-flex d-sm-flex d-lg-none diary-section-row'>
                            <div className='diary-section-btn-wrapper'>
                                <button onClick={() => this.handleSelectWritingType('imaginedDialogues')} className={this.state.displayImaginedDialogues ? `diary-section-btn selected`:`diary-section-btn`}>{this.props.dict['writingCategories']['imaginedDialogues']}</button>
                            </div>
                            <div className='diary-section-btn-wrapper'>
                                <button onClick={() => this.handleSelectWritingType('narrativeNotes')} className={this.state.displayNarrativeNotes ? `diary-section-btn selected`:`diary-section-btn`}>{this.props.dict['writingCategories']['narrativeNotes']}</button>
                            </div>
                        </div>

                        {this.state.displayNarrativeNotes && this.state.completeRetrievingJournalDay ? 
                        <QuestionPanel
                            panelWrapper={this.state.panelWrapper}
                            daysOfVisit={this.state.daysOfVisit || 1}
                            currentJournalDay={this.state.currentJournalDay || 1}
                            displayDay={this.state.displayDay || 0}
                            dayJournalEntries={this.props.dayJournalEntries}
                            prevJournalEntries={this.state.prevJournalEntries || {}}
                            userData={this.state.userData}
                            dayLogs={this.state.dayLogs}
                            changeDay={this.changeDay}
                            updatePrevJournalEntries={this.updatePrevJournalEntries}
                            autoSaveEntry={this.autoSaveEntry}
                            saveEntry={this.saveEntry}
                            displaySaveFeedback={this.displaySaveFeedback}
                            initConfirmMessage={this.initConfirmMessage}
                            {...this.props}
                        />:
                        null}
                        {this.state.displayImaginedDialogues && this.state.completeRetrievingJournalDay ? 
                        <ActivityLogPanel
                            // logWrapper={this.state.logWrapper}
                            userData={this.state.userData}
                            dayLogs={this.state.dayLogs}
                            daysOfVisit={this.state.daysOfVisit || 1}
                            displayDay={this.state.displayDay || 0}
                            currentJournalDay={this.state.currentJournalDay || 1}
                            dayJournalEntries={this.props.dayJournalEntries}
                            prevJournalEntries={this.state.prevJournalEntries || {}}
                            autoSaveEntry={this.autoSaveEntry}
                            saveEntry={this.saveEntry}
                            displaySaveFeedback={this.displaySaveFeedback}
                            initConfirmMessage={this.initConfirmMessage}
                            initSystemMsg={this.initSystemMsg}
                            unfoldTutorial={!this.state.displayImaginedDialogues&&!this.state.displayNarrativeNotes}
                            {...this.props}
                        />:
                        null
                    
                        }

                    </div>
                    <Footer />

                </div>
                {/*<div className='diary-operation-panel' >
                    <IconBtnPlain
                        btnValue='save'
                        handleClick={this.handleSave}
                        imgSrc={require('../../img/save_icon.svg')}
                        alt='save'
                        iconTitle={this.props.dict.diary.Diary.save}
                        ctnStyle={btnStyle}
                    />
                </div>*/}
                {this.state.displayFeedback ?
                    <FeedbackBox
                        message= {this.props.dict.diary.Diary.entrySaved}
                    />
                    :
                    null
                }
                {this.state.systemMsgArr.length > 0 ?
                    <SystemMessage
                        messageObj={this.props.dict['systemMessages']}
                        ctnStyle={{backgroundColor: 'transparent'}}
                        messageKeys={this.state.systemMsgArr}
                        deceased={this.props.accountInfo.deceased}
                    />
                    : null
                }
                {this.state.hasOwnProperty('confirmMessage') && this.state.confirmMessage !== null ?
                <ConfirmBox 
                    messageTitle={this.state.confirmMessage.hasOwnProperty('title') ? this.state.confirmMessage.title : ''} // or any alternative
                    confirmMessage={this.state.confirmMessage.text}
                    reject={this.state.toRejectFunction}
                    confirm={this.state.toConfirmFunction}
                    cancelText={this.state.confirmMessage.hasOwnProperty('cancelText') ? this.state.confirmMessage.cancelText : this.props.dict['diary']['cancel']}
                    confirmText={this.state.confirmMessage.hasOwnProperty('confirmText') ? this.state.confirmMessage.confirmText : this.props.dict['diary']['confirm']}
                />:null}
            </React.Fragment>

        )
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Diary);
export default WritingDesk



/**
 * The left panel that contains the constant question and imagined dialogue
 * usage:
 <ActivityLogPanel
    logWrapper={this.state.logWrapper}
    userData={this.state.userData}
    dayLogs={this.state.dayLogs}
 />
 */
class ActivityLogPanel extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            canvasUrl: null,
            activityLogs: null,
            today: STM.formatLocalDate(),
            diaryTitle: this.props.dict.diary.default,
            feedback: '',
            autosaveTriggered: false,
            dayJournalEntries: {},
            completed: false,
            constantCompleted: false,
            imaginedDialogueCompleted: false
        }
        this.onJournalEntryChanged = this.onJournalEntryChanged.bind(this)
        this.handleValueChange = this.handleValueChange.bind(this);
        this.saveEntry = this.saveEntry.bind(this)
        this.handleJournalEntryChange = this.handleJournalEntryChange.bind(this)
        this.updateAutosave = this.updateAutosave.bind(this)
        this.getQuestionsByDay = this.getQuestionsByDay.bind(this)
        this.checkComplete = this.checkComplete.bind(this)
    }
    handleValueChange(e) {
        this.setState({
            diaryTitle: e.target.value
        });
    }
    /**
     * Sync the journal entry in the state, redux and database (if it's the first time user inputs content in the textarea)
     * @param {str} typeKey 
     * @param {str} qKey 
     * @param {str} value 
     */
    async onJournalEntryChanged(typeKey, qKey, value)
    {
        // console.log(`the type key is ${typeKey}`)
        // console.log('on journal entry changed called')
        // console.log(`the qkey is ${qKey}`)
        let oldArr = [...this.props.dayJournalEntries]
        // console.log(oldArr)
        let targetEntry = oldArr.find(e => e.question_key === qKey)
        // console.log(targetEntry)
        if(!targetEntry)
        {
            // console.log("should create a new entry")
            let newEntry = {
                users_id: this.props?.auth?.id,
                day_logs_id: this.props?.currentDay?.id,
                journal_day: this.props?.currentJournalDay,
                question_day_key: typeKey,
                question_key: qKey,
                journal_entry_hash: GH.hash(),
                journal_day_hash: this.props.currentDay?.day_hash
            }
            newEntry['journal_entry'] = value
            targetEntry = newEntry
            // user has never entered anything today, add the question in the database immediately
            // targetEntry = await JH.upsertJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, typeKey, qKey, value)
            // console.log(targetEntry)
        }
        else
        {
            targetEntry['journal_entry'] = value
        }
        const dayJournalEntries = await this.props.updateJournalEntries(targetEntry)
        // console.log(dayJournalEntries)
        let stateEntries = {...this.state.dayJournalEntries}
        stateEntries[qKey] = value
        this.setState({ dayJournalEntries: stateEntries })
    }
    async saveEntry() {
        // console.log(this.props.currentDay)
        // console.log(this.state.feedback)
        // console.log(`to save ${typeKey}/${qKey} with value: ${value}`)
        // console.log("top save entry called")
        let dayJournalEntries = this.props.dayJournalEntries
        // console.log(dayJournalEntries)
        try
        {
            dayJournalEntries.forEach(async e => {
                const typeKey = e.question_day_key, 
                qKey = e.question_key, 
                value = e.journal_entry
                // console.log(`save value "${value}" of ${typeKey}/${qKey} `)
                const r = await JH.upsertJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, typeKey, qKey, value)
            })
        }
        catch(e)
        {
            const err = {
                user_id: this.props.auth.id,
                hash: GH.hash(20),
                code: 'FAILED_TO_SAVE_ENTRY',
                message: `Could not save the journal entry of user id ${this.props.auth.id} on day ${this.props.currentDay.id}, journal day ${this.props.currentJournalDay}`
            }
            try
            {
                const result = await EH.addError(err)
            }
            catch(e)
            {
                return false
            }
        }
        return false
    }

    async saveEntryBK(typeKey, qKey, value) {
        // console.log(this.props.currentDay)
        // console.log(this.state.feedback)
        // console.log(`to save ${typeKey}/${qKey} with value: ${value}`)
        try
        {
            const r = await JH.upsertJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, typeKey, qKey, value)
            // console.log(r)
            const update = await this.props.updateJournalEntries(r)
            let dayJournalEntries = {...this.state.dayJournalEntries}
            dayJournalEntries[qKey] = value
            this.setState({dayJournalEntries})
            // console.log(update)
            if(r)
            {
                return true
            }
            throw r
        }
        catch(e)
        {
            const err = {
                user_id: this.props.auth.id,
                hash: GH.hash(20),
                code: 'FAILED_TO_SAVE_ENTRY',
                message: `Could not save the journal entry of user id ${this.props.auth.id} on day ${this.props.currentDay.id}, journal day ${this.props.currentJournalDay}`
            }
            try
            {
                const result = await EH.addError(err)
            }
            catch(e)
            {
                return false
            }
            // console.log(e)
        }
        return false
    }
    handleJournalEntryChange(id, value)
    {
        // console.log(`update for id ${id} with value ${value}`)
        // let newDayJournalObj = {...this.state.dayJournalEntries}
        // newDayJournalObj[id] = value
        // this.setState({
        //     dayJournalEntries: newDayJournalObj
        // })
    }

    async onChangeQuestionHandler(e, id, idx)
    {
        // console.log(">>>")
        const newQKey = e.target.value
        // console.log(this.props.dayJournalEntries)
        // console.log(`the id is ${id} and the question key is ${newQKey} and the idx is ${idx}`)
        let newEntry = [...this.props.dayJournalEntries]
        let targetEntry = newEntry[idx]
        targetEntry['question_key'] = newQKey
        newEntry[idx] = targetEntry
        // console.log(newEntry[idx])
        try
        {
            const r = await JH.upsertJournalEntry(id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, targetEntry['question_day_key'], newQKey, '')
            const update = await this.props.updateJournalEntries(newEntry)
        }
        catch(e)
        {
            // console.log(e)
            return e
        }
    }



    // handleFeedbackChange(e) {
    //     const value = e.target.value
    //     // console.log(`set value to ${value}`)
    //     this.setState({
    //         feedback: value
    //     })
    // }
    updateAutosave(state = true)
    {
        this.setState({
            autosaveTriggered: state
        })
    }

    getQuestionsByDay(currentJournalDay = 1, inputJournalEntry = null, useInRender = false)
    {
        let dayJournalEntries = []
        const journalKey = currentJournalDay % 7 !== 0 ? `day${(currentJournalDay % 7)}`:`day7`
        // const journalKey = currentJournalDay > 7 ? `day${Math.floor(Math.random() * Math.floor(7))+1}` : `day${currentJournalDay}`
        if(!inputJournalEntry)
        {
            if(currentJournalDay === this.props.currentJournalDay && this.props.dayJournalEntries)
            {
                // console.log('display current journal day')
                dayJournalEntries = this.props.dayJournalEntries
            }
            else if(this.props.prevJournalEntries && this.props.prevJournalEntries.hasOwnProperty(currentJournalDay))
            {
                // console.log(`should not see me!`)
                dayJournalEntries = this.props.prevJournalEntries[currentJournalDay]
            }
            else
            {
                // error control: loading or message
            }
        }
        else
        {
            dayJournalEntries = inputJournalEntry
        }
        // console.log(dayJournalEntries)
        if(dayJournalEntries)
        {
            // console.log(`journal key is ${journalKey}`)
            const writingTypes = this.props['dict']['writingCategories']['imaginedDialoguesCategories'][journalKey]
            let keys = Object.keys(writingTypes).sort()
            keys.unshift('constant')
            let newQuestionObj = [], newDayJournalObj = {}
            keys.map(async (k, idx) => {

                const r = dayJournalEntries.find(e => e.question_key === k)

                let newObj = {}
                newObj['key'] = k
                newObj['reveal'] = idx < 1
                newObj['value'] = writingTypes[k]
                
                newQuestionObj.push(newObj)
                // console.log(newQuestionObj)
                if(r !== undefined)
                {
                    newDayJournalObj[k] = r.journal_entry === null ? '':r.journal_entry
                    newObj['reveal'] = r.journal_entry !== null & r.journal_entry !== ''
                }
                else
                {
                    newObj['reveal'] = newObj['reveal']
                    newDayJournalObj[k]=''
                }
            })


            if(!useInRender)
            {
                // let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
                return this.setState({
                    journalKey: journalKey,
                    questionObj: newQuestionObj,
                    dayJournalEntries: newDayJournalObj,
                    // completed: activityLog.hasOwnProperty('completeJournal')
                })
            }
            return {
                journalKey: journalKey,
                questionObj: newQuestionObj,
                dayJournalEntries: newDayJournalObj,
            }
        }
        // if(!useInRender)
        // {
        //     // let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        //     return this.setState({
        //         journalKey: journalKey,
        //         questionObj: [],
        //         dayJournalEntries: {},
        //         // completed: activityLog.hasOwnProperty('completeJournal')
        //     })
        // }
        return {
            journalKey: journalKey,
            questionObj: this.state.questionObj,
            dayJournalEntries: this.state.dayJournalEntries,
        }
    }
    /**
     * Check if a writing section is completed by two conditions:
     * 1. words limit over 100 (or set the minimum in the config file)
     * 2. writing time over 5 mins
     * @param {str} type 
     */
    checkComplete(type = 'constant', initialCheck = false)
    {
        // console.log(`get type ${type} to check id imagined-dialogue-${journalKey}-key-q1`)
        const getData = this.getQuestionsByDay(this.props.displayDay, null, true)
        // console.log(getData)
        const { questionObj, journalKey, dayJournalEntries } = getData
        let wordLimitValid = false, timeLimitValid = false
        switch(type)
        {
            case 'constant':
                wordLimitValid = dayJournalEntries.hasOwnProperty('constant') && dayJournalEntries['constant'] && dayJournalEntries['constant'] && dayJournalEntries['constant'].length >= config.minJournalEntry
                const constantEntry = document.getElementById(`imagined-dialogue-${journalKey}-key-constant`)
                // console.log(constantEntry.parentNode.classList)
                if(!initialCheck)// if it's constant check then add the timer limit criteria
                {
                    timeLimitValid = constantEntry.parentNode.classList.contains('timer-completed')
                }
                else
                {
                    timeLimitValid = true
                }
                // console.log(`the time limit for constant ${timeLimitValid}`)
                this.setState({
                    constantComplete: wordLimitValid&timeLimitValid
                })
                // console.log(constantEntry.id)
                // console.log(constantEntry.parentNode)
                // console.log(constantEntry.parentNode.classList.contains('timer-completed'))
            break
            case 'imaginedDialogues':
                // only checks the first question in imagined dialogues because it's a mandatory question
                wordLimitValid = dayJournalEntries.hasOwnProperty('q1') && dayJournalEntries['q1'] !== null  && dayJournalEntries['q1'] !== "" && dayJournalEntries['q1'].length >= config.minJournalEntry
                const imaginedDialogueEntry = document.getElementById(`imagined-dialogue-${journalKey}-key-q1`) //imagined-dialogue-day7-key-q1
                if(!initialCheck)
                {
                    timeLimitValid = imaginedDialogueEntry.parentNode.classList.contains('timer-completed')
                }
                else
                {
                    timeLimitValid = true
                }
                this.setState({
                    imaginedDialogueCompleted: wordLimitValid&timeLimitValid
                })
                // console.log(imaginedDialogueEntry.id)
                // console.log(imaginedDialogueEntry.parentNode)
                // console.log(imaginedDialogueEntry.parentNode.classList.contains('timer-completed'))
            break
        }
        return wordLimitValid&timeLimitValid
    }

    async componentDidMount() {
        // console.log(this.props)
        // console.log(`you should not see me before the page loaded`)
        const currentJournalDay = this.props.currentJournalDay

        // get all of the journals
        const dayJournalEntries = this.props.dayJournalEntries
        // console.log(this.props.dayJournalEntries)
        this.getQuestionsByDay(currentJournalDay, dayJournalEntries)

        let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}

        const checkConstantComplete = this.checkComplete('constant', true)
        const checkImaginedDialogues = this.checkComplete('imaginedDialogues', true)
        this.setState({
            completed: activityLog.hasOwnProperty('completeJournal'),
            constantComplete: checkConstantComplete,
            imaginedDialogueCompleted: checkImaginedDialogues
        })
        
    }

    render() {
        // console.log('test')
        // console.log(this.state.dayJournalEntries)
        // console.log(this.state.dayJournalEntries)
        // console.log(`journal has completed ${this.state.completed}`)

        const getData = this.getQuestionsByDay(this.props.displayDay, null, true)
        // console.log(getData)
        const { questionObj, journalKey, dayJournalEntries } = getData

        let logsArr = [], lang = this.props.dict['diary'], intention = lang.default, reviewMode = this.props.displayDay !== this.props.currentJournalDay
        // console.log(`the review mode is ${reviewMode}`)
        
        const activityLogs = this.props.currentDay.hasOwnProperty('activity_logs') && this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        // console.log(activityLogs)
        if(activityLogs !== undefined)
        {
            intention = activityLogs.hasOwnProperty('intention') ? activityLogs.intention : lang['default']
            // console.log(`intention ${intention}`)
        }
        let entriesArr = []
        // console.log(this.state.questioHow do I complete the writing exercisesnObj)
        if(questionObj)
        {
            const qs = questionObj
            // const keys = Object.keys(qs).sort()
            const writingTypes = this.props['dict']['writingCategories']['imaginedDialoguesCategories'][journalKey]
            let keys = Object.keys(writingTypes).sort()
            // console.log('sorted array')

            // console.log(qs)
            // render the imagined dialogues
            qs.map((k, idx) => {
                if(keys.includes(k.key))
                {
                    // console.log(`the question key is ${k.key} and the idx is ${idx}`)
                    const targetObj = {...k}
                    const key = targetObj['key']
                    // console.log(`look for key ${key}`)
                    entriesArr.push(<JournalTextarea 
                        key={`imagined-dialogue-${journalKey}-key-${key}`}
                        id={`imagined-dialogue-${journalKey}-key-${key}`}
                        qKey={key}
                        question={targetObj['value']}
                        questionPlaceholder={k.key !== 'q1' ? this.props.dict['writingCategories']['questionPlaceholderOptional'] : this.props.dict['writingCategories']['questionPlaceholder']}
                        answer={dayJournalEntries.hasOwnProperty(key) ? dayJournalEntries[key]:''}
                        journalKey={journalKey}
                        reveal={true} // targetObj['reveal']
                        saveText={this.props.dict['writingCategories']['save']}
                        onJournalEntryChanged={(typeKey, qKey, value) => {
                            // console.log(`the index is ${idx}`)
                            if(idx === 1) // first question is mandatory, check if it meets the completion criteria
                            {
                                // console.log("it's Q1")
                                this.checkComplete('imaginedDialogues')
                            }
                            this.onJournalEntryChanged(typeKey, qKey, value)
                        }}
                        saveEntry={this.saveEntry}
                        savedCallback={this.props.displaySaveFeedback}
                        reviewMode={reviewMode}
                    />)
                }

            })
        }
    //  console.log(this.state.dayJournalEntries)
    // console.log(`constant complete ${this.state.constantComplete}`)
    // console.log(`default display ${this.props.currentJournalDay === 1 || this.props.unfoldTutorial}`)
    //  render the constant question and imagined dialogues together
        return(
            <div className='journal-activity-panel col-sm-12 col-md-12' > {/* style={window.innerWidth >= 1024 ? this.props.logWrapper : {}} */}
                <Tip dict={lang['tip']} defaultDisplay={this.props.currentJournalDay === 1 || this.props.unfoldTutorial} />
                <div style={{ position: 'relative' }}>
                    <SectionCheckboxTitle 
                        title={this.props.dict['writingCategories']['dailyCheckin']}
                        checked={this.state.constantComplete || false}
                        // checked={dayJournalEntries.hasOwnProperty('constant') && dayJournalEntries['constant'] && dayJournalEntries['constant'] && dayJournalEntries['constant'].length >= config.minJournalEntry}
                        saveText={this.props.dict['writingCategories']['save']}
                        saveEntry={this.props.saveEntry}
                        savedCallback={this.props.displaySaveFeedback}
                        reviewMode={reviewMode}
                    />
                    {/*<label htmlFor='memorial-feedback' className='w-100 memorial-feedback-label'>{lang.memorialFeedback}</label>*/}
                    <JournalTextarea 
                        key={`imagined-dialogue-${journalKey}-key-constant`}
                        qKey={'constant'}
                        id={`imagined-dialogue-${journalKey}-key-constant`}
                        question={reviewMode ? this.props.dict['writingCategories']['dailyCheckin']:''}
                        questionPlaceholder={this.props.dict['writingCategories']['questionPlaceholder']}
                        answer={this.state.hasOwnProperty('dayJournalEntries') && dayJournalEntries.hasOwnProperty('constant') ? dayJournalEntries['constant'] : ''}
                        journalKey={journalKey}
                        reveal={true}
                        saveText={this.props.dict['writingCategories']['save']}
                        onJournalEntryChanged={(typeKey, qKey, value) => {
                            this.onJournalEntryChanged(typeKey, qKey, value)
                            this.checkComplete('constant')
                        }}
                        saveEntry={this.props.saveEntry}
                        savedCallback={this.props.displaySaveFeedback}
                        reviewMode={reviewMode}
                    />
                </div>

                <div className='mt-2' style={{ position: 'relative' }}>
                    <SectionCheckboxTitle 
                        title={this.props.dict['writingCategories']['imaginedDialogue']} 
                        checked={dayJournalEntries.hasOwnProperty('q1') && dayJournalEntries['q1'] !== null  && dayJournalEntries['q1'] !== "" && dayJournalEntries['q1'].length >= config.minJournalEntry}
                        saveText={this.props.dict['writingCategories']['save']}
                        saveEntry={this.props.saveEntry}
                        savedCallback={this.props.displaySaveFeedback}
                        reviewMode={reviewMode}
                    />
                    {entriesArr}

                </div>
            </div>
        )
    }
}
/**
 * The area for entering journals
 * @param {*} props 
    usage:
    <JournalTextarea 
        qKey={"q1"}
        question={"What you never understand was..."}
        answer={"User's answer"}
        journalKey={"day1"}
        reveal={true|false}
        saveText={this.props.dict['writingCategories']['save']}
        saveEntry={function}
        savedCallback={function}
    />
 */
const JournalTextarea = props => {
    const _isMounted = useRef(true)
    const { journalKey, saveEntry, savedCallback, reveal, question, questionPlaceholder, answer, qKey, reviewMode, onJournalEntryChanged } = props

    const [value, updateValue] = useState(answer)
    const [autosave, updateAutosave] = useState(false)
    const [display, updateDisplay] = useState(reveal)
    const [timerBegun, setBeginTimer] = useState(false)
    const [timeup, updateTimeup] = useState(true)

    // console.log(props)
    useEffect(() => {
        // console.log('rerender')
        return () => { // ComponentWillUnmount in Class Component
            _isMounted.current = false;
        }
    }, [])
    // const { reviewMode } = props

    if(reviewMode)
    {
        if(value)
        {
            return (<div key={`imagined-dialogue-${journalKey}-key-${qKey}`} 
                // data-tip={!display ? 'Click to add the question':null}
                style={{ position: 'relative' }} onClick={() => updateDisplay(true)} 
                className='imagined-dialogue-text-block'>
                    <p className='m-0 p-0 imagined-dialogue-question'>{question}</p>
                    <hr />
                    {/*<FontAwesomeIcon className='journal-textarea-save-icon' data-tip={saveText || 'Save'} icon={faSave} onClick={(e) => {
                        // console.log(`manual save`)
                        // console.log(value)
                        saveEntry(journalKey, qKey, value)
                        // this.handleJournalEntryChange(k, savedValue)
                        savedCallback()
                    }} style={{ width: '30px', height: '30px' }}  />*/}
                    <p className='m-0 p-0 imagined-dialogue-answer'>{value}</p>
            </div>)
        }
        return null
    }

    const beginTimer = () => {
        setBeginTimer(true)
        /** check if the timer is functioning well, uncomment it to do console log



        */

         let start = minWritingTime
         const countdown = setInterval(() => {
             if(start > 0)
             {
                 start -= 1000
                //  console.log(`still ${(start-1000)/1000} seconds`)
                 return
             }
             return clearInterval(countdown)
         }, 1000)

        // check if 5 minutes have passsed in the writing area and send the message to the parent DOM to indicate completion
        setTimeout(() => {
            // console.log("5 minutes reached")
            updateTimeup(true)
        }, minWritingTime)
    }
    

    return (
    <div key={`imagined-dialogue-${journalKey}-key-${qKey}`} 
        data-tip={!display ? 'Click to add the question':null}
        style={{ position: 'relative' }} onClick={() => updateDisplay(true)} 
        className={display ? `imagined-dialogue-mandatory ${timeup ? 'timer-completed':''}` : `imagined-dialogue-extra ${timeup ? 'timer-completed':null}` }>
            <p className='m-0 p-0 imagined-dialogue-question'>{question}</p>
            {/*<FontAwesomeIcon className='journal-textarea-save-icon' data-tip={saveText || 'Save'} icon={faSave} onClick={(e) => {
                // console.log(`manual save`)
                // console.log(value)
                saveEntry(journalKey, qKey, value)
                // this.handleJournalEntryChange(k, savedValue)
                savedCallback()
            }} style={{ width: '30px', height: '30px' }}  />*/}
            <textarea id={props.id} className='w-100 mt-2 imagined-dialogues-textarea' rows='5' value={value} onChange={(e) => {
                e.persist()
                if(!timerBegun)
                {
                    // console.log("begin counting down")
                    // set the timer to check if users write continuously 5 minutes
                    beginTimer()
                }
                // console.log('changed')
                // const savedValue = e.target.value
                onJournalEntryChanged(journalKey, qKey, e.target.value)
                updateValue(e.target.value)
                // saveEntry(journalKey, qKey, value)
                if(!autosave) // update the entry in the database and turn off autosave later 
                {
                    // console.log('init auto save, will save in '+ config.autosave+' millisecond')
                    // saveEntry(journalKey, qKey, e.target.value)
                    // this.handleJournalEntryChange(k, savedValue)
                    const targetId = props.id
                    updateAutosave(true)
                    try
                    {
                        setTimeout(() => {
                            if(_isMounted)
                            {
                                try
                                {
                                    // console.log(value)
                                    updateAutosave(false)
                                    const v = document.getElementById(targetId).value
                                    // console.log(`auto save value ${v}`)
                                    saveEntry(journalKey, qKey, v)
                                    // this.handleJournalEntryChange(k, savedValue)
                                    savedCallback()
                                }
                                catch(e) {}
                            }

                        }, config.autosave)
                    }
                    catch(e)
                    {
                        // console.log(e)
                    }
                }
            }} placeholder={questionPlaceholder} />
    </div>)

}

/**
 * The right section on the diary, hosts everyday diary prompts, propagate the current day's JSON data to the component
 * usage:
 <QuestionPanel
 />
 */
let questionArr = [];
class QuestionPanel extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props)
        this.state = {
            activeAccord: '',
            hoverAccord: '',
            questions: [],
            checked: false,
            dayJournalEntries: {},
            narrativeNotesCompleted: false
        }
        this.toggleTextField = this.toggleTextField.bind(this)
        this.mouseEnterHandler = this.mouseEnterHandler.bind(this)
        this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this)
        this.focusHandler = this.focusHandler.bind(this)
        this.blurHandler = this.blurHandler.bind(this)
        this.getQuestionsByDay =  this.getQuestionsByDay.bind(this)
        this.onChangeHandler = this.onChangeHandler.bind(this)
        this.onRemoveHandler = this.onRemoveHandler.bind(this)
        this.addNewQuestion = this.addNewQuestion.bind(this)
        this.saveEntry = this.saveEntry.bind(this)
        this.onEntryChangeHandler = this.onEntryChangeHandler.bind(this)
        this.checkComplete = this.checkComplete.bind(this)
    }
    async componentDidMount() {
        // add a new random question to the day
        const findIfExist = await this.onChangeHandler()

        if(!findIfExist)
        {
            // console.log(`no entries exist`)
            const typeOfCategories = Object.keys(this.props.dict['writingCategories']['types'])
            const randFirst = Math.floor(Math.random() * typeOfCategories.length)
            const type = typeOfCategories[randFirst]
            const categories = this.props.dict['writingCategories']['categories'][type]
            const questionKeys = Object.keys(categories)
            const randSecond = Math.floor(Math.random() * questionKeys.length)
            const targetKey = questionKeys[randSecond]
            this.addNewQuestion(this.props.dict['writingCategories']['narrativeNotes'], type, targetKey)
            this.setState({
                checked: false
            })
            return
        }
        // console.log(`previous entries found`)
        // get all of the journals
        const dayJournalEntries = await this.getQuestionsByDay(this.props.currentJournalDay, findIfExist)
        // console.log(dayJournalEntries)
        const noteKeys = Object.keys(this.props['dict']['writingCategories']['categories'])
        // // console.log(noteKeys)
        let notes = [], stateJournalEntries = {}
        if(dayJournalEntries)
        {
            dayJournalEntries.forEach(e => {
                if(noteKeys.includes(e.question_day_key))
                {
                    const entryType = e.question_day_key
                    const v = e.journal_entry || ''
                    notes.push(e)
                    stateJournalEntries[entryType] = v
                }
            })
        }

        let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        this.checkComplete(true)
        this.setState({
            dayJournalEntries: stateJournalEntries,
            completed: activityLog.hasOwnProperty('completeJournal')
        })
    }

    getQuestionsByDay(currentJournalDay = 1, inputJournalEntry = null, useInRender = false)
    {
        // console.log(this.props.prevJournalEntries)
        // console.log(this.props.dayJournalEntries)

        let dayJournalEntries = []
        if(!inputJournalEntry)
        {
            if(currentJournalDay === this.props.currentJournalDay && this.props.dayJournalEntries)
            {
                dayJournalEntries = this.props.dayJournalEntries
            }
            else if(this.props.prevJournalEntries && this.props.prevJournalEntries.hasOwnProperty(currentJournalDay))
            {
                dayJournalEntries = this.props.prevJournalEntries[currentJournalDay]
            }
            else
            {
                // error control: loading or message
            }
        }
        else
        {
            dayJournalEntries = inputJournalEntry
        }
        // error control. Before dayJournalEntries are passed
        // console.log(dayJournalEntries)
        if(dayJournalEntries)
        {
            // const dayJournalEntries = this.props.dayJournalEntries
            // const journalKey = currentJournalDay > 7 ? `day${Math.floor(Math.random() * Math.floor(7))+1}` : `day${currentJournalDay}`

            const keys = Object.keys(this.props.dict['writingCategories']['types'])
            // console.log(keys)
            // keys.unshift('constant')
            let newQuestionObj = [], newDayJournalObj = []

            dayJournalEntries.forEach((entry, idx) => {
                const categoryKey = entry.question_day_key
                const questionKey = entry.question_key
                const categories = this.props.dict['writingCategories']['categories'][categoryKey]
                // console.log(categories)
                if(categories) // if the entry belongs to narrative notes category
                {
                    const questionKeys = Object.keys(categories)
                    // console.log(questionKeys)
                    if(questionKeys.includes(questionKey))
                    {
                        // console.log('found this question')
                        newDayJournalObj.push(entry)
                    }
                }

            })
            /** DEPRECATED, it does not loop based on the entry sequence.
            keys.map(async (k, idx) => {

                const categories = this.props.dict['writingCategories']['categories'][k]
                const questionKeys = Object.keys(categories)
                // get all the questions that has the same key
                const r = dayJournalEntries.filter(e => questionKeys.includes(e.question_key))
                
                r.map(item => {
                    const qKey = item['question_day_key']
                    if(item !== undefined)
                    {
                        newDayJournalObj.push(item)
                    }
                })
            })
            */
            // console.log(newDayJournalObj)
            // console.log(newQuestionObj)
            
            if(!useInRender)
            {
                // let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
                this.setState({
                    // journalKey: journalKey,
                    // questionObj: newQuestionObj,
                    dayJournalEntries: newDayJournalObj,
                    // completed: activityLog.hasOwnProperty('completeJournal')
                })
                return newDayJournalObj
            }
            return {
                // journalKey: journalKey,
                // questionObj: newQuestionObj,
                dayJournalEntries: newDayJournalObj
            }
        }
        // if(!useInRender)
        // {
        //     // let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
        //     return this.setState({
        //         // journalKey: journalKey,
        //         // questionObj: newQuestionObj,
        //         dayJournalEntries: [],
        //         // completed: activityLog.hasOwnProperty('completeJournal')
        //     })
        // }
        // return {
        //     // journalKey: journalKey,
        //     // questionObj: newQuestionObj,
        //     dayJournalEntries: []
        // }

    }

    toggleTextField(key) {
        // change the active status of the id field based on the original active status
        if(this.state.activeAccord === key)
        {
            this.setState({
                activeAccord: ''
            });
            return;
        }
        this.setState({
            activeAccord: key
        });
        return;
    }
    mouseEnterHandler(key) {
        this.setState({
            hoverAccord: key
        });
    }
    mouseLeaveHandler() {
        this.setState({
            hoverAccord: ''
        });
    }
    focusHandler(key) {
        this.setState({
            activeAccord: key
        });
    }
    blurHandler() {
        LS.set(sessionKeys.journalEntry, journalEntries);
    }
    /** onChangeHandler
     * Check if the section is completed
     * @param {event} e
     */
    async onChangeHandler() {
        // console.log('changed')
        const typeOfCategories = Object.keys(this.props.dict['writingCategories']['types'])
        // If user does not have any entries of Narrative Notes today, then create a random one.
        // const findIfExist = this.props.dayJournalEntries.find(entry => typeOfCategories.includes(entry.question_day_key))
        const findIfExist = this.props.dayJournalEntries.filter(entry => typeOfCategories.includes(`${entry.question_day_key}`))
        // console.log(findIfExist)
        if(!findIfExist || findIfExist.length <= 0)
        {
            this.setState({
                checked: false
            })
            return false
        }
        if(Array.isArray(findIfExist))
        {
            let checked = false
            findIfExist.map(v => {
                if(v.hasOwnProperty('journal_entry') && v.journal_entry !== null && v.journal_entry.length >= config.minJournalEntry)
                {
                    // console.log('should be true')
                    checked = true
                    // return findIfExist
                }
            })
            this.setState({
                checked
            })
        }

        return findIfExist
    }
    async addNewQuestion(type, qKey, targetQuestionKey = '') {
        // console.log('add new question called')
        const self = this
        let newQuestions = [...this.state.questions]
        let dayJournalEntries = [...this.props.dayJournalEntries]
        const currentJournalDay = this.props.displayDay
        switch(type)
        {
            case 'Imagined Dialogues':
                // console.log("add new imagined dialogues")
                if(qKey !== 'intro')
                {
                    const data = this.props.dict['writingCategories']['imaginedDialoguesCategories'][qKey]
                    
                    Object.keys(data).map(async (k, idx) => {
                        // console.log(this.props)
                        const r = await this.props.addDayJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, qKey, k, '')
                        if(!r.success)
                        {
                            // return the result that the journal cannot be initiated
                            return
                        }
                    })
                }
                break
            case 'Narrative Notes':
                // console.log('add narrative notes')
                const data = this.props.dict['writingCategories']['categories'][qKey]
                // console.log(data)
                let dropdownData = [], firstK = targetQuestionKey
                Object.keys(data).map((k, idx) => {
                    if(idx === 0 && firstK === '')
                    {
                        firstK = k
                    }
                    dropdownData[k] = {
                        text: data[k],
                        value: k
                    }
                })
                try
                {
                    // console.log(`the question key is ${firstK} and the day key is ${qKey}`)   
                    let inputEntries = []
                    const r = await JH.insertEntry(this.props.auth?.id, this.props.currentDay?.id, this.props.currentDay?.day, this.props.currentJournalDay, qKey, this.props.currentDay?.day_hash, firstK, '', GH.hash())
                    // console.log(r)
                    if(r?.id)
                    {
                        // console.log(`to pass this id ${r.id}`)
                        const reducerUpdate = await this.props.addDayJournalEntry(r.id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, qKey, firstK, '', r?.journal_entry_hash)
                        // console.log(reducerUpdate)
                    }
                    //in case an error happened, show message saying it can't be added.

                    // // const r = await this.props.addDayJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, qKey, firstK, '')
                    // console.log(r)
                    // if(!r)
                    // {
                    //     // return the result that the journal cannot be initiated
                    //     throw r
                    // }
                    // console.log(r)
                    /**
                     * TODO: review this function again.
                    inputEntries.push(r)
                    let dayJournalEntries = await this.getQuestionsByDay(this.props.displayDay, inputEntries)
                     */

                    // console.log(dayJournalEntries)
                }
                catch(e)
                {
                    // console.log(e)
                }
                break
        }
    }
    async onChangeQuestionHandler(e, id, idx)
    {
        const newQKey = e.target.value
        // console.log(this.props.dayJournalEntries)
        // console.log(`the id is ${id} and the question key is ${newQKey} and the idx is ${idx}`)
        let newEntry = [...this.props.dayJournalEntries]
        let targetEntry = newEntry[idx]
        // console.log("target entry", targetEntry)
        const hash = targetEntry['journal_entry_hash']
        targetEntry['question_key'] = newQKey
        newEntry[idx] = targetEntry
        // console.log(newEntry[idx])
        try
        {
            // console.log(`the necessary data is day logs id: ${this.props.currentDay.id},
            //     current journal day: ${this.props.currentJournalDay},
            //     qdaykey: ${targetEntry['question_day_key']},
            //     new question key: ${newQKey},
            //     and journal entry hash: ${hash}
            // `)
            const r = await JH.upsertJournalEntry(id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, targetEntry['question_day_key'], newQKey, targetEntry['journal_entry'] || '',hash)
            // console.log("update result ", r)
            const update = await this.props.updateJournalEntries(newEntry)
        }
        catch(e)
        {
            // console.log(e)
            return e
        }
    }

    async onEntryChangeHandler(typeKey, qKey, value, entryHash)
    {
        // console.log('on journal entry called')
        // console.log(`the qkey is ${qKey}`)
        let oldArr = [...this.props.dayJournalEntries]
        // console.log(oldArr)
        let targetEntry = oldArr.find(e => e.journal_entry_hash === entryHash)
        // console.log(targetEntry)

        if(!targetEntry)
        {
            // user has never entered anything today, add the question in the database immediately
            targetEntry = await JH.upsertJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, typeKey, qKey, value, GH.hash())
            // console.log(targetEntry)
        }
        else
        {
            targetEntry['journal_entry'] = value
        }
        // console.log("target entry",targetEntry )
        const dayJournalEntries = await this.props.updateJournalEntries(targetEntry)
        // console.log(dayJournalEntries)

        let stateEntries = {...this.state.dayJournalEntries}
        stateEntries[qKey] = value
        this.setState({ dayJournalEntries: stateEntries })
    }
    async onRemoveHandler(id, idx)
    {
        // console.log(`remove journal entry with id ${id} and at the index ${idx}`)
        const removeResult = await JH.deleteJournalEntry(id, this.props.auth.id)
        // const removeResult = true
        // console.log(removeResult)
        if(removeResult) // if successfully removed in the database then update in the front end
        {
            const removeR = await this.props.removeJournalEntry(id, idx)
        }
        // console.log(removeR)
    }
    async saveEntry()
    {
        // console.log(this.props.currentDay)
        // console.log(this.state.feedback)
        // console.log(`to save ${typeKey}/${qKey} with value: ${value}`)
        let dayJournalEntries = this.props.dayJournalEntries
        // console.log(dayJournalEntries)
        try
        {
            dayJournalEntries.forEach(async e => {
                const typeKey = e.question_day_key, 
                qKey = e.question_key, 
                value = e.journal_entry
                // console.log(`save value "${value}" of ${typeKey}/${qKey} `)
                const r = await JH.upsertJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, typeKey, qKey, value)
            })
        }
        catch(e)
        {
            const err = {
                user_id: this.props.auth.id,
                hash: GH.hash(20),
                code: 'FAILED_TO_SAVE_ENTRY',
                message: `Could not save the journal entry of user id ${this.props.auth.id} on day ${this.props.currentDay.id}, journal day ${this.props.currentJournalDay}`
            }
            try
            {
                const result = await EH.addError(err)
            }
            catch(e)
            {
                return false
            }
        }
        return false
    }
    /**
     * Get the first entry DOM element for narrative notes and check if the word limit and timer limit are both fulfilled
     * If user has previous entries and the wordlimit meets the criteria, then don't check time limit
     * @param {boolean} initialCheck 
     * @returns 
     */
    checkComplete(initialCheck = false)
    {
        let timeLimitValid = false, wordLimitValid = false
        try
        {
            const targetElement = document.getElementsByClassName('journal-section')[0]
            timeLimitValid = targetElement.classList.contains('timer-complete')

            const id = targetElement.id
            const targetTextEntry = document.getElementById(`${id}-journal-entry`)
            
            // console.log(`check ${id}-journal-entry`)
            // console.log(targetTextEntry.value)
            wordLimitValid = targetTextEntry.value.length >= config.minJournalEntry
            // console.log(journalSections)
            // console.log(wordLimitValid)
            // console.log(`completed ${timeLimitValid&wordLimitValid}`)
            this.setState({
                narrativeNotesCompleted: initialCheck ? wordLimitValid:timeLimitValid&wordLimitValid
            })
            return initialCheck ? wordLimitValid:timeLimitValid&wordLimitValid
        }
        catch(e)
        {
            // console.log(e)
            return false
        }
    }

    render() {
        // console.log(this.props.dict['writingCategories'])
        // console.log(`the display day is ${this.props.displayDay}`)
        // get the diary question and loop through it to display
        let dialogueOptions = [], questions = []
        Object.keys(this.props.dict['writingCategories']['imaginedDialoguesTypes']).map(k => {
            dialogueOptions.push(<option key={`imagined-dialogue-type-${k}`} value={k}>{this.props.dict['writingCategories']['imaginedDialoguesTypes'][k]}</option>)
        })
        const reviewMode = this.props.currentJournalDay !== this.props.displayDay
        const getData = this.getQuestionsByDay(this.props.displayDay, null, true)
        // console.log(getData)
        const { dayJournalEntries } = getData

        if(dayJournalEntries.length > 0)
        {
            // console.log(dayJournalEntries)
            dayJournalEntries.forEach((entry, idx) => {
                // console.log(`entry id ${entry.id}`,entry)
                // console.log(`the target idx is ${idx}`)
                let selectedValue = ''
                const qid = entry.id, targetIdx = idx.toString()
                // console.log(`the target idx is ${targetIdx}`)
                let dropdownData = [], type = entry.question_day_key, qKey = entry.question_key
                let entryHash = entry.journal_entry_hash
                const key = `narrative-notes-${qid}-${entryHash}`
                // console.log(`the generated key is ${key}`)

                if(!type.includes('day') && type !== undefined && type !== null && type)
                {
                    const cat = Object.keys(this.props.dict['writingCategories']['categories'][type])
                    cat.map(k => {
                        dropdownData.push({
                            qid: qid,
                            entryHash: entryHash,
                            text: this.props.dict['writingCategories']['categories'][type][k],
                            value: k
                        })
                    })
                    selectedValue = qKey
                    questions.push(<SectionAccordion 
                        key={key}
                        id={qid}
                        idx={idx}
                        active={true}
                        textareaPlaceholder={this.props.dict['writingCategories']['questionPlaceholder']}
                        value={selectedValue}
                        typeKey={type}
                        type={this.props.dict['writingCategories']['types'][type]}
                        question={this.props.dict['writingCategories']['categories'][type][qKey]}
                        entryValue={entry.journal_entry}
                        entryHash={entryHash}
                        dropdownData={dropdownData}
                        reviewMode={reviewMode}
                        // textareaPlaceholder={this.props.dict['writingCategories']['textareaPlaceholder']}
                        onChangeQuestionHandler={(e, id, idx) => this.onChangeQuestionHandler(e, id, idx)}
                        onChangeHandler={() => {
                            this.onChangeHandler()
                            this.checkComplete(false)
                        }}
                        onTextInputHandler={this.onEntryChangeHandler}
                        removeQuestion={async () => this.onRemoveHandler(qid, targetIdx)}
                        saveEntry={this.props.saveEntry}
                        {...this.props}
                    />)
                }

            })
        }

        // return null
        return (
            <div className={this.state.activeAccord ? `journal-prompts-panel active ${this.state.narrativeNotesCompleted ? 'journal-completed':''}` : `journal-prompts-panel ${this.state.narrativeNotesCompleted ? 'journal-completed':''}`} style={window.innerWidth >= 1024 ? this.props.panelWrapper : {}}>

                <SectionCheckboxTitle 
                    title={this.props.dict['writingCategories']['narrativeNotes']} 
                    checked={this.state.narrativeNotesCompleted || false}
                    saveText={this.props.dict['writingCategories']['save']}
                    saveEntry={this.props.saveEntry}
                    savedCallback={this.props.displaySaveFeedback}
                    reviewMode={reviewMode}
                />
                {!reviewMode ? 
                    <React.Fragment>
                    <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'stories')}>{this.props.dict['writingCategories']['types']['stories']}</button>
                    <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'dreams')}>{this.props.dict['writingCategories']['types']['dreams']}</button>
                    <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'touchstones')}>{this.props.dict['writingCategories']['types']['touchstones']}</button>
                    <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'reflections')}>{this.props.dict['writingCategories']['types']['reflections']}</button>
                    </React.Fragment>
                :null}


                {questions}
            </div>
        )
    }
}



const SectionCheckboxTitle = props => {
    const { title, saveText, saveEntry, savedCallback, checked, reviewMode } = props
    // console.log(`this checkbox is ${props.checked}`)
    // const [checked, updateChecked] = useState(false)
    if(reviewMode)
    {
        return null
    }

    return (<div className='text-center section-checkbox-wrapper' style={{ position: 'relative' }}>

        <label className='diaryFont p-0 m-0 section-checkbox-label'>
            {title ? title : 'Title'}
            <input className='section-checkbox-input' style={{ position: 'absolute', right: '10px' }} type="checkbox" checked={checked} disabled />
            <span className="section-checkmark"></span>
        </label>
        <FontAwesomeIcon className='journal-textarea-save-icon' data-tip={saveText || 'Save'} icon={faSave} 
            onClick={(e) => {
            // console.log(`manual save`)
            // console.log(value)
                saveEntry()
            // // this.handleJournalEntryChange(k, savedValue)
            savedCallback()
        }} />
    </div>)
}

// const QuestionPanel = connect(mapStateToProps, mapDispatchToProps)(UnconnectedQuestionPanel);
// <ImageBtn
// btnValue='prevPage'
// handleClick={() => this.changeDay(1)}
// imgSrc={require('img/arrow_right.svg')}
// alt='previous day'
// btnTitle='next'
// classArr={['right-arrow']}
// />

/**
 * The Narrative Note Unit
 * usage:
    <SectionAccordion 
        id={str}
        active={boolean}
        dropdownData={obj}
        textareaPlaceholder={str}
        onChangeHandler={e => function}
        onTextInputHandler={e => function}
    />
 */
const SectionAccordion = props => {

    // console.log(props)
    // console.log(props.entryValue)
    const [selectedSubject, updateSelectedSubject] = useState(props.value)
    const [value, updateValue] = useState(props.entryValue || '')
    const [toggle, updateToggle] = useState(props.active)
    const [autosaveTriggered, updateAutosave] = useState(false)

    const [timerBegun, setBeginTimer] = useState(false)
    const [timeup, updateTimeup] = useState(true)

    // console.log('section accordion called')
    // console.log(props.dropdownData)
    // const fetchData = async () => {
    //     // retrieve the journal that will be controlled by this section
    //     try
    //     {
    //         const jd = await props.getCurrentJournalDay(props.auth.id, props.currentDay.id, props.currentDay.day)
    //         // console.log(jd)
    //         // console.log(props.dayJournalEntries)
    //     }
    //     catch(e)
    //     {
    //         // console.log(e)
    //     }
    // }

    useEffect(() => {
        // fetchData()
    }, [])

    if(!props.hasOwnProperty('dropdownData') && Object.keys(props.dropdownData).length <= 0) return null
    if(props.id === null || props.id === undefined || !props.id) return null

    const { journalKey, question, qKey, reviewMode, updateDisplay } = props


    if(reviewMode)
    {
        if(value)
        {
            return (<div key={`imagined-dialogue-${journalKey}-key-${qKey}`} 
                // data-tip={!display ? 'Click to add the question':null}
                style={{ position: 'relative' }} onClick={() => updateDisplay(true)} 
                className='imagined-dialogue-text-block'>
                    <p className='m-0 p-0 imagined-dialogue-question'>{question}</p>
                    <hr />
                    {/*<FontAwesomeIcon className='journal-textarea-save-icon' data-tip={saveText || 'Save'} icon={faSave} onClick={(e) => {
                        // console.log(`manual save`)
                        // console.log(value)
                        saveEntry(journalKey, qKey, value)
                        // this.handleJournalEntryChange(k, savedValue)
                        savedCallback()
                    }} style={{ width: '30px', height: '30px' }}  />*/}
                    <p className='m-0 p-0 imagined-dialogue-answer'>{value}</p>
                <ReactTooltip />
            </div>)
        }
        return null
    }

    const saveEntry = async (id, value) => {
        // console.log(`to update journal entry ${value}`)
        const self = this
        // console.log(`update id ${id}`)
        // console.log(props.dayJournalEntries)
        let arr = [...props.dayJournalEntries]
        let idx = 0
        // console.log(arr)
        arr.forEach((j, ix) => {
            if(parseInt(j.id, 10) === parseInt(id,10))
            {
                // console.log(`the id is ${id}`)
                idx = ix
            }
        })
        let journal = arr[idx]
        journal['journal_entry'] = value
        arr[idx] = journal
        const updateReducer = await props.updateJournalEntries(arr)
        // console.log(updateReducer)
        const save = await JH.upsertJournalEntry(id, props.auth.id, props.currentDay.id, props.currentJournalDay, journal.question_day_key, journal.question_key, value, journal.journal_entry_hash)
        // console.log(save)
        // display feedback
        /*
        this.setState({
            displayFeedback: true
        }, () => {
            setTimeout(function() {
                self.setState({
                    displayFeedback: false
                });
            }, configs.feedbackFade);
        })
        */
    }
    // console.log('passed')
    let subjectOptions = []
    Object.keys(props.dropdownData).map(k => {
        const t = props.dropdownData[k]
        // console.log(t)
        subjectOptions.push(<option key={`subject-option-${k}`} value={t.value}>{t.text.replace('[deceased]', props.accountInfo.deceased)}</option>)
    })

    const beginTimer = () => {
        setBeginTimer(true)
        /** check if the timer is functioning well, uncomment it to do console log */

        let start = minWritingTime
        const countdown = setInterval(() => {
            if(start > 0)
            {
                start -= 1000
                // console.log(`still ${(start-1000)/1000} seconds`)
                return
            }
            return clearInterval(countdown)
        }, 1000)

        

        // check if 5 minutes have passsed in the writing area and send the message to the parent DOM to indicate completion
        setTimeout(() => {
            // console.log("5 minutes reached")
            updateTimeup(true)
        }, minWritingTime)
    }
    
    
    // console.log(`the selected value is ${selectedSubject}`)
    // console.log(props)
    const placeholder = props.textareaPlaceholder
    // console.log(placeholder)
    return (<div key={props.id} id={props.id} className={toggle ? `journal-section active ${timeup ? 'timer-complete':''}` : `journal-section blur  ${timeup ? 'timer-complete':''}`}>
        <div key={props.id+'-question-ctn'} className='journal-prompts-question-ctn'
            // onMouseEnter={() => this.props.mouseEnterHandler(this.props.id)}
            // onMouseLeave={() => this.props.mouseLeaveHandler(this.props.id)}
            // onClick={() => updateToggle(!toggle)}
            >
            <select className='writing-subject-select' onChange={e => {
                updateSelectedSubject(e.target.value)
                props.onChangeQuestionHandler(e, props.id, props.idx)
            }} value={selectedSubject}>{subjectOptions}</select>
            {/*<p className='journal-prompts-question'>{'* '}{this.props.question}</p>*/}
            {/*<EditIcon classes={toggle ? 'active' : this.props.hoverAccord === this.props.id ? 'hover' : '' } />*/}
            {/*<EditIcon classes={toggle ? 'active' : '' } />*/}
            
            <div style={{ right: 10, top: '.6em', cursor: 'pointer', position: 'absolute' }}>
            {/*props.dict['writingCategories']['save']*/}
            {/*<FontAwesomeIcon className='writing-desk-save-icon' data-tip={props.dict['writingCategories']['save']} icon={faSave} onClick={(e) => {
                saveEntry(props.id, value)
                props.displaySaveFeedback()
            }} style={{ width: '30px', height: '30px' }}  />*/}
            <FontAwesomeIcon data-tip={props.dict['writingCategories']['delete']}className='writing-desk-save-icon' onClick={() => {
                // confirm if the user wants to remove this question
                props.initConfirmMessage('You are about to delete the content', 'You will lose your narrative notes, are you sure?', () => {
                    props.removeQuestion()

                }, null, 'Yes, remove it', 'Cancel')
            }} icon={faTrashAlt} style={{ width: '30px', height: '30px', right: 10, top: 10, cursor: 'pointer' }} />

            </div>
        
        </div>
        <div className='m-0 p-0' style={{ position: 'relative' }}>
        <div className='narrative-notes-tag'>{props.type}</div>
        <textarea id={props.id+'-journal-entry'} className='narrative-notes-textarea w-100 mt-2' placeholder={placeholder ? placeholder.replace('[deceased]', props.accountInfo.deceased) : ''} style={{ background: 'transparent' }} rows='5' 
        value={value} 
        onChange={e => {
            // console.log("changed: ", e.target.value)
            // updateValue(e.target.value)
            
            if(!timerBegun)
            {
                // console.log("begin counting down")
                // set the timer to check if users write continuously 5 minutes
                beginTimer()
            }
            // console.log('text area added')
            let journalEntryValue = e.target.value

            // const typeKey = 
            props.onTextInputHandler(props.typeKey, props.value, e.target.value, props.entryHash)
            updateValue(journalEntryValue) // this is to display the entered text immediately

            if(!autosaveTriggered) // update the entry in the database and turn off autosave later 
            {
                // console.log('init auto save, will save in '+ config.autosave+' millisecond')
                saveEntry(props.id, value)
                updateAutosave(true)
                setTimeout(() => {
                    // console.log(`to be saved value ${value}`)
                    // console.log(value)
                    try
                    {
                        const t = document.getElementById(props.id+'-journal-entry')
                        const newValue = t.value
                        updateAutosave(false)
                        saveEntry(props.id, newValue)
                        props.displaySaveFeedback()
                    }
                    catch(e)
                    {

                    }

                }, config.autosave)
            }
            props.onChangeHandler(e)
        }} />


        </div>

        {/*<div key={props.id+'-entry-ctn'} className={toggle ? 'journal-prompts-entry-ctn active' : 'journal-prompts-entry-ctn'} style={{ position: 'relative' }}>
        <textarea type='text' id={props.id+'-journal-entry'} placeholder={props.textareaPlaceholder}
                // data-questionkey={this.props.questionKey}
                // data-daykey={this.props.dayKey}
                // data-entryhash={this.props.entryHash}
                // data-dayhash={this.props.dayHash}
                value={value}
                // onFocus={() => { this.props.focusHandler(this.props.id); }}
                // onBlur={() => { this.props.blurHandler(); }}
                onChange={e => {
                    let journalEntryValue = e.target.value
                    updateValue(journalEntryValue) // this is to display the entered text immediately
                    if(!autosaveTriggered) // update the entry in the database and turn off autosave later 
                    {
                        // console.log('init auto save, will save in '+ config.autosave+' millisecond')
                        saveEntry(props.id, value)
                        updateAutosave(true)
                        setTimeout(() => {
                            // console.log(value)
                            updateAutosave(false)
                            saveEntry(props.id, value)
                            props.displaySaveFeedback()
                        }, config.autosave)
                    }
                }}
                // ref={textInput => this._journal = textInput}
        >
        
        </textarea>
        <div style={{ right: 10, top: 10, cursor: 'pointer', position: 'absolute' }}>

        <FontAwesomeIcon className='writing-desk-save-icon' data-tip={props.dict['writingCategories']['save']} icon={faSave} onClick={() => {
            // console.log('clicked')
            saveEntry(props.id, value)
            props.displaySaveFeedback()
        }} style={{ width: '30px', height: '30px' }}  />
        </div>

        </div>*/}
        <ReactTooltip />
    </div>)
}




// =========================== DEPRECATED ============================
// 4 questions every day
// class UnconnectedJournalAccordion extends React.Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             value: this.props.answer,
//         }
//         this.onTextInput = this.onTextInput.bind(this);
//     }
//     componentDidMount() {
//         if(parseInt(this.props.idx, 10) === 0) this._journal.focus();
//     }
//     // save the textinput in the localstorage
//     onTextInput(e) {
//         this.props.onChangeHandler(e);
//     }
//     render() {
//         return (
//             <div key={this.props.id} className={this.props.activeAccord === this.props.id ? 'journal-section active' : 'journal-section blur'}>
//                 <div key={this.props.id+'-question-ctn'} className='journal-prompts-question-ctn'
//                      onMouseEnter={() => this.props.mouseEnterHandler(this.props.id)}
//                      onMouseLeave={() => this.props.mouseLeaveHandler(this.props.id)}
//                      onClick={() => this.props.toggleTextField(this.props.id)}>
//                     <p className='journal-prompts-question'>{'* '}{this.props.question}</p>
//                     <EditIcon classes={this.props.activeAccord === this.props.id ? 'active' : this.props.hoverAccord === this.props.id ? 'hover' : '' } />
//                 </div>
//                 <div key={this.props.id+'-entry-ctn'} className={this.props.activeAccord === this.props.id ? 'journal-prompts-entry-ctn active' : this.props.hoverAccord === this.props.id ? 'journal-prompts-entry-ctn hover' : 'journal-prompts-entry-ctn'}>
//                 <textarea type='text' id={this.props.id+'-journal-entry'} placeholder={this.props.dict.diary.QuestionPanel.clickHereToStartTyping}
//                           data-questionkey={this.props.questionKey}
//                           data-daykey={this.props.dayKey}
//                           data-entryhash={this.props.entryHash}
//                           data-dayhash={this.props.dayHash}
//                           value={this.props.answer}
//                           onFocus={() => { this.props.focusHandler(this.props.id); }}
//                           onBlur={() => { this.props.blurHandler(); }}
//                           onChange={this.onTextInput}
//                           ref={textInput => this._journal = textInput}
//                 ></textarea>
//                 </div>
//             </div>
//         )
//     }
// }

// ============================= DEPRECATED ==============================
/**
 * The left panel that contains all the activity logs
 * usage:
 <ActivityLogPanel
 logWrapper={this.state.logWrapper}
 userData={this.state.userData}
 dayLogs={this.state.dayLogs}
 />
 */
// class ActivityLogPanelBK extends Component {
//     constructor(props)
//     {
//         super(props)
//         this.state = {
//             canvasUrl: null,
//             activityLogs: null,
//             today: STM.formatLocalDate(),
//             diaryTitle: this.props.dict.diary.default,
//             feedback: '',
//             autosaveTriggered: false
//         }
//         this.handleValueChange = this.handleValueChange.bind(this);
//         this.getLatestOfferingImg = this.getLatestOfferingImg.bind(this);
//         this.saveEntry = this.saveEntry.bind(this)
//         this.handleFeedbackChange = this.handleFeedbackChange.bind(this)
//         this.updateAutosave = this.updateAutosave.bind(this)
//     }
//     handleValueChange(e) {
//         this.setState({
//             diaryTitle: e.target.value
//         });
//     }

//     // ============================= DEPRECATED ============================
//     async getLatestOfferingImg()
//     {
//         const defaultImgUrl = await OF.returnOfferingDefaultImg(this.props.accountInfo.view)

//         try
//         {
//             const offeringImg = await OF.getLastOfferingImg(this.props.auth.id, (e, d) => {
//                 if(e) return false
//                 return d.offering_img
//             })
//             // console.log(offeringImg)
            
//             if(offeringImg === undefined || offeringImg === null || !offeringImg)
//             {
//                 return defaultImgUrl
//             }
//             // console.log(`get offering img key: ${offeringImg}`)
//             return S3B.getImage(offeringImg, 'ritual_imgs', (err, data) => {
//                 // console.log(err)
//                 if(err)
//                 {
//                     return defaultImgUrl
//                 }
//                 // console.log(data)
//                 return data
//             })
//         }
//         catch(e)
//         {
//             // console.log(e)
//             return defaultImgUrl
//         }
//     }

//     async saveEntry(value) {
//         // console.log(this.props.currentDay)
//         // console.log(this.state.feedback)
//         // try
//         // {
//         //     let activityLogs = this.props.currentDay.hasOwnProperty('activity_logs') && this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
//         //     // console.log(activityLogs)
//         //     activityLogs['feedback'] = value
//         //     const updateMemorialLog = await this.props.updateMemorialStepLog(this.props.currentDay.id, activityLogs)
//         //     // this.props.displaySaveFeedback()
//         // }
//         // catch(e)
//         // {
//         //     console.log(e)
//         // }
//     }

//     handleFeedbackChange(e) {
//         const value = e.target.value
//         // console.log(`set value to ${value}`)
//         this.setState({
//             feedback: value
//         })
//     }
//     updateAutosave(state = true)
//     {
//         this.setState({
//             autosaveTriggered: state
//         })
//     }

//     async componentDidMount() {
//         var self = this
//         const activityLogs = this.props.currentDay.hasOwnProperty('activity_logs') && this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
//         if(activityLogs !== undefined)
//         {
//             let intention = activityLogs.hasOwnProperty('intention') ? activityLogs.intention : this.props.dict['diary']['title']
//             let feedback = activityLogs.hasOwnProperty('feedback') ? activityLogs.feedback : ''
//             this.setState({ intention, feedback })

//         }
//         const canvasUrl = await this.getLatestOfferingImg()
        
//         this.setState({
//             canvasUrl
//         })
//     }

//     render() {
//         let logsArr = [], lang = this.props.dict['diary'], intention = lang.default
//         const activityLogs = this.props.currentDay.hasOwnProperty('activity_logs') && this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
//         // console.log(activityLogs)
//         if(activityLogs !== undefined)
//         {
//             intention = activityLogs.hasOwnProperty('intention') ? activityLogs.intention : lang['default']
//             // console.log(`intention ${intention}`)
//         }
//         /*
//         if(this.props.dayLogs)
//         {
//             const offeringData = JSON.parse(this.props.dayLogs.offering_data) || {};
//             Object.keys(offeringData).forEach((key, idx) => {
//                 const targetKey = offeringData[key].originKey;
//                 if(activitiesFeedback()[targetKey])
//                 {
//                     logsArr.push(<li key={idx}>{activitiesFeedback()[targetKey]}</li>);
//                 }
//             });
//         }
//         if(this.state.activityLogs)
//         {
//             this.state.activityLogs.forEach(log => {
//                 // check the type of log, if it's remove, then do not include, and remove the one with the same key
//                 if(log.type === "remove") return;
//                 // construct a new array to store the activity.
//                 logsArr.push(<li key={log.hash}>{activitiesFeedback()[log.activityKey]}</li>);
//             });
//         }
//         */
//         return(
//             <div className='journal-activity-panel col-sm-12 col-md-12' > {/* style={window.innerWidth >= 1024 ? this.props.logWrapper : {}} */}
//                 <div className='row mb-2 journal-activity-title-container'>
//                 <h4 className='journal-activity-title'>
//                 {this.state.today}{' '}
//                     {/*<input
//                         className='journal-activity-customize-title'
//                         type='text'
//                         placeholder='Enter your title'
//                         value={this.state.diaryTitle}
//                         onChange={this.handleValueChange}
//                     />*/}
//                 </h4>
//                 {/*<div className='d-flex flex-row w-100'>
//                     <label htmlFor='lmg-journal-subject'>{lang.title} </label><input id='lmg-journal-subject' className='ml-3 w-100' type='text' placeholder={lang.titlePlaceholder} value={intention} onChange={this.handleValueChange} />
//                 </div>*/}

//                 </div>
//                 <div className='' style={{ position: 'relative' }}>
//                     <SectionCheckboxTitle title={this.props.dict['writingCategories']['dailyCheckin']} />
//                     {/*<label htmlFor='memorial-feedback' className='w-100 memorial-feedback-label'>{lang.memorialFeedback}</label>*/}
//                     <textarea id='memorial-feedback' className='w-100 mt-2' rows='5' value={this.state.feedback} onChange={(e) => {
//                         this.handleFeedbackChange(e)
//                         if(!this.state.autosaveTriggered) // update the entry in the database and turn off autosave later 
//                         {
//                             // console.log('init auto save, will save in '+ config.autosave+' millisecond')
//                             this.saveEntry(this.state.feedback)
//                             this.updateAutosave(true)
//                             setTimeout(() => {
//                                 // console.log(value)
//                                 try
//                                 {
//                                     this.updateAutosave(false)
//                                     this.saveEntry(this.state.feedback)
//                                     this.props.displaySaveFeedback()
//                                 }
//                                 catch(e)
//                                 {

//                                 }

//                             }, config.autosave)
//                         }
//                     }} placeholder={this.props.dict['writingCategories']['writeFree']} />
//                     <div style={{ left: '0.2em', top: '0.1em', cursor: 'pointer', position: 'absolute' }}>
//                     {/*this.props.dict['writingCategories']['save']*/}
//                     <FontAwesomeIcon className='writing-desk-save-icon' data-tip={this.props.dict['writingCategories']['save']} icon={faSave} onClick={(e) => {
//                         this.saveEntry(this.state.feedback)
//                         this.props.displaySaveFeedback()
//                     }} style={{ width: '30px', height: '30px' }}  />
//                     </div>
//                 </div>

//                 <div className='mt-2' style={{ position: 'relative' }}>
//                     <SectionCheckboxTitle title={this.props.dict['writingCategories']['imaginedDialogue']} />
//                     {/*<label htmlFor='memorial-feedback' className='w-100 memorial-feedback-label'>{lang.memorialFeedback}</label>*/}

//                     <textarea id='memorial-feedback' className='w-100 mt-2' rows='5' value={this.state.feedback} onChange={(e) => {
//                         this.handleFeedbackChange(e)
//                         if(!this.state.autosaveTriggered) // update the entry in the database and turn off autosave later 
//                         {
//                             // console.log('init auto save, will save in '+ config.autosave+' millisecond')
//                             this.saveEntry(this.state.feedback)
//                             this.updateAutosave(true)
//                             setTimeout(() => {
//                                 // console.log(value)
//                                 try
//                                 {
//                                     this.updateAutosave(false)
//                                     this.saveEntry(this.state.feedback)
//                                     this.props.displaySaveFeedback()
//                                 }
//                                 catch(e)
//                                 {

//                                 }
//                             }, config.autosave)
//                         }
//                     }} placeholder={lang.memorialFeedbackPlaceholder} />
//                     <div style={{ left: '0.2em', top: '0.1em', cursor: 'pointer', position: 'absolute' }}>
//                     {/*this.props.dict['writingCategories']['save']*/}
//                     <FontAwesomeIcon className='writing-desk-save-icon' data-tip={this.props.dict['writingCategories']['save']} icon={faSave} onClick={(e) => {
//                         this.saveEntry(this.state.feedback)
//                         this.props.displaySaveFeedback()
//                     }} style={{ width: '30px', height: '30px' }}  />
//                     </div>

//                 </div>
//                 {/*this.state.canvasUrl === null ? 
//                     <div className='skeleton-loading' style={{ width: '100%', height: HEIGHT * 0.4 }}></div>
//                     :
//                     <img className='journal-screen-shot' src={this.state.canvasUrl !== null ? this.state.canvasUrl : '' } alt='offering log' />
//                 */}
                

//                 {/*<ul className='journal-activity-list pt-3'>
//                     {logsArr}
//                 </ul>*/}
//             </div>
//         )
//     }
// }

/**
 * usage:
    <SectionAccordion 
        id={str}
        active={boolean}
        dropdownData={obj}
        textareaPlaceholder={str}
        onChangeHandler={e => function}
        onTextInputHandler={e => function}
    />
 */
// const SectionAccordionBK = props => {
//     // console.log(props)
//     // console.log(props.entryValue)
//     const [selectedSubject, updateSelectedSubject] = useState(props.value)
//     const [value, updateValue] = useState(props.entryValue || '')
//     const [toggle, updateToggle] = useState(props.active)
//     const [autosaveTriggered, updateAutosave] = useState(false)

//     // console.log('section accordion called')
//     // console.log(props.dropdownData)
//     const fetchData = async () => {
//         // retrieve the journal that will be controlled by this section
//         try
//         {
//             const jd = await props.getCurrentJournalDay(props.auth.id, props.currentDay.id, props.currentDay.day)
//             // console.log(jd)
//             // console.log(props.dayJournalEntries)
//         }
//         catch(e)
//         {
//             // console.log(e)
//         }
//     }
//     useEffect(() => {
//         // fetchData()
//     }, [])
//     if(!props.hasOwnProperty('dropdownData') && Object.keys(props.dropdownData).length <= 0) return null
//     if(props.id === null || props.id === undefined || !props.id) return null



//     const saveEntry = async (id, value) => {
//         // console.log(`to update journal entry ${value}`)
//         // const self = this
//         // console.log(`update id ${id}`)
//         let arr = [...props.dayJournalEntries]
//         let idx = 0
//         // console.log(arr)
//         arr.forEach((j, ix) => {
//             if(parseInt(j.id, 10) === parseInt(id,10))
//             {
//                 // console.log(`the id is ${id}`)
//                 idx = ix
//             }
//         })
//         let journal = arr[idx]
//         journal['journal_entry'] = value
//         arr[idx] = journal
//         const updateReducer = await props.updateJournalEntries(arr)
//         // console.log(updateReducer)
//         const save = await JH.upsertJournalEntry(id, props.auth.id, props.currentDay.id, props.currentJournalDay, journal.question_day_key, journal.question_key, value)
//         // console.log(save)
//         // display feedback
//         /*
//         this.setState({
//             displayFeedback: true
//         }, () => {
//             setTimeout(function() {
//                 self.setState({
//                     displayFeedback: false
//                 });
//             }, configs.feedbackFade);
//         })
//         */
//     }
//     // console.log('passed')
//     let subjectOptions = []
//     Object.keys(props.dropdownData).map(k => {
//         const t = props.dropdownData[k]
//         // console.log(t)
//         subjectOptions.push(<option key={`subject-option-${k}`} value={t.value}>{t.text.replace('[deceased]', props.accountInfo.deceased)}</option>)
//     })

    
//     // console.log(`the selected value is ${selectedSubject}`)
//     return (<div key={props.id} id={props.id} className={toggle ? 'journal-section active' : 'journal-section blur'}>
//         <div key={props.id+'-question-ctn'} className='journal-prompts-question-ctn'
//             // onMouseEnter={() => this.props.mouseEnterHandler(this.props.id)}
//             // onMouseLeave={() => this.props.mouseLeaveHandler(this.props.id)}
//             // onClick={() => updateToggle(!toggle)}
//             >
//             <select className='writing-subject-select' onChange={e => {
//                 updateSelectedSubject(e.target.value)
//                 props.onChangeQuestionHandler(e, props.id, props.idx)
//             }} value={selectedSubject}>{subjectOptions}</select>
//             {/*<p className='journal-prompts-question'>{'* '}{this.props.question}</p>*/}
//             {/*<EditIcon classes={toggle ? 'active' : this.props.hoverAccord === this.props.id ? 'hover' : '' } />*/}
//             {/*<EditIcon classes={toggle ? 'active' : '' } />*/}
            
//             <FontAwesomeIcon data-tip={props.dict['writingCategories']['delete']}className='writing-desk-save-icon' onClick={() => props.removeQuestion()} icon={faTrashAlt} style={{ width: '30px', height: '30px', right: 10, top: 10, cursor: 'pointer' }} />
//         </div>
//         <div key={props.id+'-entry-ctn'} className={toggle ? 'journal-prompts-entry-ctn active' : 'journal-prompts-entry-ctn'} style={{ position: 'relative' }}>
//         <textarea type='text' id={props.id+'-journal-entry'} placeholder={props.textareaPlaceholder}
//                 // data-questionkey={this.props.questionKey}
//                 // data-daykey={this.props.dayKey}
//                 // data-entryhash={this.props.entryHash}
//                 // data-dayhash={this.props.dayHash}
//                 value={value}
//                 // onFocus={() => { this.props.focusHandler(this.props.id); }}
//                 // onBlur={() => { this.props.blurHandler(); }}
//                 onChange={e => {
//                     let journalEntryValue = e.target.value
//                     updateValue(journalEntryValue) // this is to display the entered text immediately
//                     if(!autosaveTriggered) // update the entry in the database and turn off autosave later 
//                     {
//                         // console.log('init auto save, will save in '+ config.autosave+' millisecond')
//                         saveEntry(props.id, value)
//                         updateAutosave(true)
//                         setTimeout(() => {
//                             // console.log(value)
//                             try
//                             {
//                                 updateAutosave(false)
//                                 saveEntry(props.id, value)
//                                 props.displaySaveFeedback()
//                             }
//                             catch(e)
//                             {

//                             }
//                         }, config.autosave)
//                     }
//                 }}
//                 // ref={textInput => this._journal = textInput}
//         >
        
//         </textarea>
//         <div style={{ right: 10, top: 10, cursor: 'pointer', position: 'absolute' }}>
//         {/*props.dict['writingCategories']['save']*/}
//         <FontAwesomeIcon className='writing-desk-save-icon' data-tip={props.dict['writingCategories']['save']} icon={faSave} onClick={() => {
//             // console.log('clicked')
//             saveEntry(props.id, value)
//             props.displaySaveFeedback()
//         }} style={{ width: '30px', height: '30px' }}  />
//         </div>

//         </div>
//         <ReactTooltip />
//     </div>)
// }
// =========================== DEPRECATED ============================
// <InformationPanel deceasedName={this.state.deceasedName === null ? 'your loved one': this.state.deceasedName} />

/**
 * The information panel that describes what diary page is for
 * @param {*} props
 * usage:
 <InformationPanel
 deceasedName={this.state.deceasedName === null ? 'your loved one': this.state.deceasedName}
 />
 */
// function InformationPanel(props) {
//     return (
//         <div className='information-panel-wrapper'>
//             <div className='information-text-block'>
//                 <h5 className='information-title'>{journalTexts().title}</h5>
//                 <p className='information-text'>
//                     {journalTexts().content}
//                 </p>
//             </div>
//         </div>
//     );
// }
// const JournalAccordion = connect(mapStateToProps, mapDispatchToProps)(UnconnectedJournalAccordion);

// // =========================== DEPRECATED ============================
// /**
//  * The right section on the diary, hosts everyday diary prompts, propagate the current day's JSON data to the component
//  * usage:
//  <QuestionPanel
//  />
//  */
// class QuestionPanelBK extends Component {
//     constructor(props) {
//         super(props)
//         // console.log(this.props)
//         this.state = {
//             activeAccord: '',
//             hoverAccord: '',
//             questions: [],
//             checked: false,
//             dayJournalEntries: {}
//         }
//         this.toggleTextField = this.toggleTextField.bind(this)
//         this.mouseEnterHandler = this.mouseEnterHandler.bind(this)
//         this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this)
//         this.focusHandler = this.focusHandler.bind(this)
//         this.blurHandler = this.blurHandler.bind(this)
//         this.getQuestionsByDay =  this.getQuestionsByDay.bind(this)
//         this.onChangeHandler = this.onChangeHandler.bind(this)
//         this.onRemoveHandler = this.onRemoveHandler.bind(this)
//         this.addNewQuestion = this.addNewQuestion.bind(this)
//         this.saveEntry = this.saveEntry.bind(this)
//         this.onEntryChangeHandler = this.onEntryChangeHandler.bind(this)
//     }
//     async componentDidMount() {
//         // add a new random question to the day
//         // if(this.props.dayJournalEntries.length > 0)
//         // {
//         // If user does not have any entries of Narrative Notes today, then create a random one.
//         // const findIfExist = this.props.dayJournalEntries.find(entry => typeOfCategories.includes(entry.question_day_key))
//         // console.log(findIfExist)
        

//         const findIfExist = await this.onChangeHandler()
//         if(!findIfExist)
//         {
//             const typeOfCategories = Object.keys(this.props.dict['writingCategories']['types'])
//             const randFirst = Math.floor(Math.random() * typeOfCategories.length)
//             const type = typeOfCategories[randFirst]
//             const categories = this.props.dict['writingCategories']['categories'][type]
//             const questionKeys = Object.keys(categories)
//             const randSecond = Math.floor(Math.random() * questionKeys.length)
//             const targetKey = questionKeys[randSecond]
//             this.addNewQuestion('Narrative Notes', type, targetKey)
//             this.setState({
//                 checked: false
//             })
//             return
//         }
//         // console.log(`the props passed current journal day as ${this.props.currentJournalDay}`)
//         const currentJournalDay = this.props.displayDay
//         // console.log(`the current journal day is ${currentJournalDay}`)
//         // get all of the journals
//         // const dayJournalEntries = await JH.getJournalEntriesGivenJournalDay(this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay)
//         // console.log(dayJournalEntries)
//         // const entry = await this.getQuestionsByDay(this.props.currentJournalDay, findIfExist)
//         // console.log(entry)
//         // const noteKeys = Object.keys(this.props['dict']['writingCategories']['categories'])
//         // // console.log(noteKeys)
//         // let notes = [], stateJournalEntries = {}
//         // if(dayJournalEntries)
//         // {
//         //     dayJournalEntries.forEach(e => {
//         //         if(noteKeys.includes(e.question_day_key))
//         //         {
//         //             const entryType = e.question_day_key
//         //             const v = e.journal_entry || ''
//         //             notes.push(e)
//         //             stateJournalEntries[entryType] = v
//         //         }
//         //     })
//         // }
//         // console.log(stateJournalEntries)
//         // console.log(notes)
//         // let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
//         // this.setState({
//         //     // journalKey: journalKey,
//         //     // questionObj: newQuestionObj,
//         //     dayJournalEntries: stateJournalEntries,
//         //     completed: activityLog.hasOwnProperty('completeJournal')
//         // })
//     }

//     getQuestionsByDay(currentJournalDay = 1, inputJournalEntry = null, useInRender = false)
//     {
//         // console.log('function called')
//         // console.log(inputJournalEntry)
//         let dayJournalEntries = []
//         if(!inputJournalEntry)
//         {
//             dayJournalEntries = this.props.prevJournalEntries[currentJournalDay]
//         }
//         else
//         {
//             dayJournalEntries = inputJournalEntry
//         }
//         console.log(dayJournalEntries)

//         // const dayJournalEntries = this.props.dayJournalEntries
//         // const journalKey = currentJournalDay > 7 ? `day${Math.floor(Math.random() * Math.floor(7))+1}` : `day${currentJournalDay}`
//         // console.log(`journal key is ${journalKey}`)
//         const writingTypes = Object.keys(this.props.dict['writingCategories']['types'])
//         // console.log(writingTypes)

//         let keys = Object.keys(writingTypes).sort()
//         // keys.unshift('constant')
//         let newQuestionObj = [], newDayJournalObj = {}
//         keys.map(async (k, idx) => {

//             const r = dayJournalEntries.find(e => e.question_key === k)

//             let newObj = {}
//             newObj['key'] = k
//             newObj['reveal'] = idx < 1
//             newObj['value'] = writingTypes[k]
            
//             newQuestionObj.push(newObj)
//             // console.log(newQuestionObj)
//             if(r !== undefined)
//             {
//                 newDayJournalObj[k] = r.journal_entry === null ? '':r.journal_entry
//                 newObj['reveal'] = r.journal_entry !== null & r.journal_entry !== ''
//             }
//             else
//             {
//                 newObj['reveal'] = newObj['reveal']
//                 newDayJournalObj[k]=''
//             }
//         })


//         if(!useInRender)
//         {
//             // let activityLog = this.props.currentDay.activity_logs !== null ? typeof this.props.currentDay.activity_logs === 'string' ? JSON.parse(this.props.currentDay.activity_logs) : this.props.currentDay.activity_logs : {}
//             return this.setState({
//                 // journalKey: journalKey,
//                 questionObj: newQuestionObj,
//                 dayJournalEntries: newDayJournalObj,
//                 // completed: activityLog.hasOwnProperty('completeJournal')
//             })
//         }
//         return {
//             // journalKey: journalKey,
//             questionObj: newQuestionObj,
//             dayJournalEntries: newDayJournalObj
//         }
//     }

//     toggleTextField(key) {
//         // change the active status of the id field based on the original active status
//         if(this.state.activeAccord === key)
//         {
//             this.setState({
//                 activeAccord: ''
//             });
//             return;
//         }
//         this.setState({
//             activeAccord: key
//         });
//         return;
//     }
//     mouseEnterHandler(key) {
//         this.setState({
//             hoverAccord: key
//         });
//     }
//     mouseLeaveHandler() {
//         this.setState({
//             hoverAccord: ''
//         });
//     }
//     focusHandler(key) {
//         this.setState({
//             activeAccord: key
//         });
//     }
//     blurHandler() {
//         LS.set(sessionKeys.journalEntry, journalEntries);
//     }
//     /** onChangeHandler
//      * Check if the section is completed
//      * @param {event} e
//      */
//     async onChangeHandler() {
//         // console.log('changed')
//         const typeOfCategories = Object.keys(this.props.dict['writingCategories']['types'])
//         // If user does not have any entries of Narrative Notes today, then create a random one.
//         const findIfExist = this.props.dayJournalEntries.find(entry => typeOfCategories.includes(entry.question_day_key))

//         if(!findIfExist)
//         {
//             this.setState({
//                 checked: false
//             })
//             return false
//         }
//         if(findIfExist.hasOwnProperty('journal_entry') && findIfExist.journal_entry !== null && findIfExist.journal_entry.length >= config.minJournalEntry)
//         {
//             // console.log('should be true')
//             this.setState({
//                 checked: true
//             })
//             // return findIfExist
//         }
//         return findIfExist
//     }
//     async addNewQuestion(type, qKey, targetQuestionKey = '') {
//         // console.log('add new question called')
//         const self = this
//         let newQuestions = [...this.state.questions]
//         let dayJournalEntries = [...this.props.dayJournalEntries]
//         switch(type)
//         {
//             case 'Imagined Dialogues':
//                 if(qKey !== 'intro')
//                 {
//                     const data = this.props.dict['writingCategories']['imaginedDialoguesCategories'][qKey]
                    
//                     Object.keys(data).map(async (k, idx) => {
//                         // console.log(this.props)
//                         const r = await this.props.addDayJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, qKey, k, '')
//                         if(!r.success)
//                         {
//                             // return the result that the journal cannot be initiated
//                             return
//                         }
//                     })
//                 }
//                 break
//             case 'Narrative Notes':
//                 const data = this.props.dict['writingCategories']['categories'][qKey]
//                 let dropdownData = [], firstK = targetQuestionKey
//                 Object.keys(data).map((k, idx) => {
//                     if(idx === 0 && firstK === '')
//                     {
//                         firstK = k
//                     }
//                     dropdownData[k] = {
//                         text: data[k],
//                         value: k
//                     }
//                 })
//                 const r = await this.props.addDayJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, qKey, firstK, '')
//                 if(!r.success)
//                 {
//                     // return the result that the journal cannot be initiated
//                     return
//                 }
//                 /*
//                 const qid = r.results[0]['id']
//                 // const qid = `${type}-${qKey}-${newQuestions.length}`
//                 newQuestions.push(<SectionAccordion 
//                     key={qid}
//                     id={qid}
//                     active={true}
//                     dropdownData={dropdownData}
//                     textareaPlaceholder={this.props.dict['writingCategories']['textareaPlaceholder']}
//                     onChangeHandler={e => console.log(e.target.value)}
//                     onTextInputHandler={e => console.log(e.target.value)}
//                     removeQuestion={() => {
//                         // console.log(this.state.questions)
//                         let qs = [...self.state.questions]
//                         qs.forEach((q, idx) => {
//                             console.log(q.props.id)
//                             if(q.props.id === qid)
//                             {
//                                 qs.splice(idx, 1)
//                             }
//                         })
//                         self.setState({
//                             questions: qs
//                         }, () => console.log('set'))
//                     }}
//                     {...this.props}
//                 />)
                
//                 this.setState({
//                     questions: newQuestions
//                 })
//                 */
//                 break
//         }
//     }
//     async onChangeQuestionHandler(e, id, idx)
//     {
//         const newQKey = e.target.value
//         // console.log(this.props.dayJournalEntries)
//         // console.log(`the id is ${id} and the question key is ${newQKey} and the idx is ${idx}`)
//         let newEntry = [...this.props.dayJournalEntries]
//         let targetEntry = newEntry[idx]
//         targetEntry['question_key'] = newQKey
//         newEntry[idx] = targetEntry
//         // console.log(newEntry[idx])
//         try
//         {
//             const r = await JH.upsertJournalEntry(id, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, targetEntry['question_day_key'], newQKey, '')
//             const update = await this.props.updateJournalEntries(newEntry)
//         }
//         catch(e)
//         {
//             // console.log(e)
//             return e
//         }
//     }

//     async onEntryChangeHandler(typeKey, qKey, value)
//     {
//         // console.log('on journal entry called')
//         // console.log(`the qkey is ${qKey}`)
//         let oldArr = [...this.props.dayJournalEntries]
//         // console.log(oldArr)
//         let targetEntry = oldArr.find(e => e.question_key === qKey)
//         // console.log(targetEntry)
//         if(!targetEntry)
//         {
//             // user has never entered anything today, add the question in the database immediately
//             targetEntry = await JH.upsertJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, typeKey, qKey, value)
//             // console.log(targetEntry)
//         }
//         else
//         {
//             targetEntry['journal_entry'] = value
//         }
//         const dayJournalEntries = await this.props.updateJournalEntries(targetEntry)
//         // console.log(dayJournalEntries)
//         let stateEntries = {...this.state.dayJournalEntries}
//         stateEntries[qKey] = value
//         this.setState({ dayJournalEntries: stateEntries })
//     }
//     async onRemoveHandler(id, idx)
//     {
//         const removeR = await this.props.removeJournalEntry(id, idx)
//         // console.log(removeR)
//     }
//     async saveEntry()
//     {
//         // console.log(this.props.currentDay)
//         // console.log(this.state.feedback)
//         // console.log(`to save ${typeKey}/${qKey} with value: ${value}`)
//         let dayJournalEntries = this.props.dayJournalEntries
//         // console.log(dayJournalEntries)
//         try
//         {
//             dayJournalEntries.forEach(async e => {
//                 const typeKey = e.question_day_key, 
//                 qKey = e.question_key, 
//                 value = e.journal_entry
//                 // console.log(`save value "${value}" of ${typeKey}/${qKey} `)
//                 const r = await JH.upsertJournalEntry(null, this.props.auth.id, this.props.currentDay.id, this.props.currentJournalDay, typeKey, qKey, value)
//             })
//         }
//         catch(e)
//         {
//             const err = {
//                 user_id: this.props.auth.id,
//                 hash: GH.hash(20),
//                 code: 'FAILED_TO_SAVE_ENTRY',
//                 message: `Could not save the journal entry of user id ${this.props.auth.id} on day ${this.props.currentDay.id}, journal day ${this.props.currentJournalDay}`
//             }
//             try
//             {
//                 const result = await EH.addError(err)
//             }
//             catch(e)
//             {
//                 return false
//             }
//         }
//         return false
//     }
//     render() {
//         // console.log(this.props.dict['writingCategories'])
//         // console.log(`the display day is ${this.props.displayDay}`)
//         // get the diary question and loop through it to display
//         let dialogueOptions = [], questions = []
//         Object.keys(this.props.dict['writingCategories']['imaginedDialoguesTypes']).map(k => {
//             dialogueOptions.push(<option key={`imagined-dialogue-type-${k}`} value={k}>{this.props.dict['writingCategories']['imaginedDialoguesTypes'][k]}</option>)
//         })

//         const { dayJournalEntries } = this.state

//         if(dayJournalEntries.length > 0)
//         {
//             dayJournalEntries.forEach((entry, idx) => {
//                 // console.log(`the target idx is ${idx}`)
//                 let selectedValue = ''
//                 const qid = `${entry.id}`, targetIdx = idx.toString()
//                 // console.log(`the target idx is ${targetIdx}`)
//                 let dropdownData = [], type = entry.question_day_key, qKey = entry.question_key

//                 if(!type.includes('day') && type !== undefined && type !== null && type)
//                 {
//                     const cat = Object.keys(this.props.dict['writingCategories']['categories'][type])
//                     cat.map(k => {
//                         dropdownData.push({
//                             text: this.props.dict['writingCategories']['categories'][type][k],
//                             value: k
//                         })
//                     })
//                     selectedValue = qKey
//                     questions.push(<SectionAccordion 
//                         key={`narrative-notes-${qid}`}
//                         id={qid}
//                         idx={idx}
//                         active={true}
//                         // textareaPlaceholder={this.props.dict['writingCategories']['textareaPlaceholder']}
//                         value={selectedValue}
//                         typeKey={type}
//                         type={this.props.dict['writingCategories']['types'][type]}
//                         entryValue={entry.journal_entry}
//                         dropdownData={dropdownData}
//                         // textareaPlaceholder={this.props.dict['writingCategories']['textareaPlaceholder']}
//                         onChangeQuestionHandler={(e, id, idx) => this.onChangeQuestionHandler(e, id, idx)}
//                         onChangeHandler={this.onChangeHandler}
//                         onTextInputHandler={this.onEntryChangeHandler}
//                         removeQuestion={async () => this.onRemoveHandler(qid, targetIdx)}
//                         saveEntry={this.props.saveEntry}
//                         {...this.props}
//                     />)
//                 }

//             })
//         }

//         return (
//             <div className={this.state.activeAccord ? 'journal-prompts-panel active' : 'journal-prompts-panel'} style={window.innerWidth >= 1024 ? this.props.panelWrapper : {}}>

//                 <SectionCheckboxTitle 
//                     title={this.props.dict['writingCategories']['narrativeNotes']} 
//                     checked={this.state.checked || false}
//                     saveText={this.props.dict['writingCategories']['save']}
//                     saveEntry={this.saveEntry}
//                     savedCallback={this.props.displaySaveFeedback}
//                 />
//                 <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'stories')}>{this.props.dict['writingCategories']['types']['stories']}</button>
//                 <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'dreams')}>{this.props.dict['writingCategories']['types']['dreams']}</button>
//                 <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'touchstones')}>{this.props.dict['writingCategories']['types']['touchstones']}</button>
//                 <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'reflections')}>{this.props.dict['writingCategories']['types']['reflections']}</button>
                
//                 {/*<div className=''>{this.props.dict['writingCategories']['select']}</div>
//                 <div>
//                     <select className='writing-subject-select' onChange={e => {
//                         // console.log(e.target.value)
//                         if(e.target.value !== 'intro')
//                         {
//                             this.addNewQuestion('Imagined Dialogues', e.target.value)
//                         }
//                         // this.addNewQuestion('Narrative Notes', 'stories')
//                         // updateSelectedSubject(e.target.value)
//                         // props.onChangeHandler(e)
//                     }} value={this.props.dict['writingCategories']['imaginedDialogues']}>{dialogueOptions}</select>
//                     <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'stories')}>{this.props.dict['writingCategories']['types']['stories']}</button>
//                     <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'dreams')}>{this.props.dict['writingCategories']['types']['dreams']}</button>
//                     <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'touchstones')}>{this.props.dict['writingCategories']['types']['touchstones']}</button>
//                     <button className='writing-type-btn' onClick={() => this.addNewQuestion('Narrative Notes', 'reflections')}>{this.props.dict['writingCategories']['types']['reflections']}</button>
//                 </div>*/}
//                 {questions}
//             </div>
//         )
//     }
// }
