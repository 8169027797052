import Amplify, { Auth, Storage } from 'aws-amplify'
import init from '../../../config/init'
var jwtDecode = require('jwt-decode')

const config = init.config
Amplify.configure(config.aws)
// console.log(config)
class AmplifyHelper {
    constructor()
    {
        // console.log("amplify helper called")
    }

    async getUsername()
    {
        if(this.username) return this.username
        try
        {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => {
                this.username = user.username
                return user.username
            })
            .catch(err => err );
            return user
        }
        catch(e)
        {
            return false
        }

    }

    async signUp(auth)
    {
        try
        {
            let signupResult = await Auth.signUp({
                username: auth.email,
                password: auth.password
            })
            return signupResult
        }
        catch(err)
        {
            return err
        }
    }

    signIn(auth)
    {
        return Auth.signIn({
            username: auth.username, // Required, the username
            password: auth.password, // Optional, the password
        })
        .then(data => {
            // console.log(data)
            return data
        })
        .catch(err => {
            // console.error(err)
            throw err
        })
    }

    signOut(global = false)
    {
        if(!global)
        {
            return Auth.signOut()
                .then(data => {
                    // console.log(data)
                    return data
                })
                .catch(err => {
                    // console.error(err)
                    return err
                })
        }
        // By doing this, you are revoking all the auth tokens(id token, access token and refresh token)
        // which means the user is signed out from all the devices
        // Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
        return Auth.signOut({ global: true })
                .then(data => {
                    // console.log(data)
                    return data
                })
                .catch(err => {
                    // console.error(err)
                    return err
                })
    }

    legacySignOut(onResults)
    {
        // By doing this, you are revoking all the auth tokens(id token, access token and refresh token)
        // which means the user is signed out from all the devices
        // Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
        return Auth.signOut({ global: true })
        .then(data => {
            // console.log(data)
            return onResults(null, data)
        })
        .catch(err => {
            // console.error(err)
            return onResults(err, null)
        })
    }
    
    async getSession()
    {
        try
        {
            const s = await Auth.currentSession().then(d => d).catch(e => {throw e})
            return s
        }
        catch(e)
        {
            return e
        }
        /*
        return Auth.currentSession()
        .then(data => {
            // console.log(data)
            return data
        })
        .catch(err => {
            // console.error(err)
            return err
        })
        */
    }

    async getSessionAccessToken(onResults)
    {
        try
        {
            const s = await Auth.currentSession().then(d => d?.accessToken?.jwtToken).catch(e => {throw e})
            return onResults(null, s)
        }
        catch(e)
        {
            return onResults(e, null)
        }
    }

    getUser()
    {
        return Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
            this.username = user.username
            return user
        })
        .catch(err => err );
    }

    async resendVerificationCode(username)
    {
        try
        {
            const resendResult = await Auth.resendSignUp(username)
            if(resendResult.hasOwnProperty("CodeDeliveryDetails")) return true
            throw false
        }
        catch(err)
        {
            return err
        }
    }

    async confirmSignUp(username, code)
    {
        try
        {
            const confirmResult = await Auth.confirmSignUp(username, code, {
                // Optional. Force user confirmation irrespective of existing alias. By default set to True.
                forceAliasCreation: true    
            })
            if(confirmResult.hasOwnProperty("code"))
            {
                throw confirmResult
            }
            return confirmResult
        }
        catch(err)
        {
            return err
        }
    }

    async forgotPassword(email = '')
    {
        if(!email) return false
        try
        {
            const r = await Auth.forgotPassword(email)
            .then(data => data)
            .catch(err => { throw err })
            return r
        }
        catch(e)
        {
            return e
        }
    }

    async resetPassword(username = '', code = '', newPass = '')
    {
        if(!username || !code || !newPass) return false

        try
        {
            const r = await Auth.forgotPasswordSubmit(username, code, newPass)
            .then(data => data)
            .catch(err => { throw err })
            if(r === undefined) return true
            throw r
        }
        catch(e)
        {
            return e
        }
    }

    async changePassword(oldPass, newPass)
    {
        try
        {
            const currentUser = await Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => user)
            .catch(err => err );
            // console.log(currentUser)
            const passChange = await Auth.changePassword(currentUser, oldPass, newPass)
            // console.log(passChange)
            return passChange
        }
        catch(e)
        {
            return e
        }
    }
    // parse the token received in the login
    parseToken(accessToken = '')
    {
        if(!accessToken) return false;
        return jwtDecode(accessToken);
    }
    /**
     * use the access token and id token to generate the session
     * @param {string/object} accessToken 
     * @param {function} onResults
     */
     getUsernameFromAccessToken(accessToken = '', onResults)
     {
         if(!accessToken) return onResults(false);
         let at = typeof accessToken === 'string' ? this.parseToken(accessToken) : accessToken;
         return onResults(at['username']);
     }
}

export default new AmplifyHelper()