import React, { Component } from 'react';
import { colors } from '../init/colors';

/**
 * usage:
    import { Footer } from './assets/footer';
    <Footer />
 */
export class Footer extends Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        const linkStyles = {
            textDecoration: 'none',
            color: colors.lightGreen
        }
        return(
            <footer className="page-footer mh-paragraph-white blue px-2 py-3 justify-content-center" style={{width: '100%'}}>
                <div className="footer-copyright text-center py-3">
                <p>
                © {new Date().getFullYear()} Copyright:
                    <a style={linkStyles} href="https://endoflife.weill.cornell.edu/"> {' '}Center for Research on End of Life Care, Weill Cornell Medicine</a>
                </p>
                </div>
            </footer>
        )
    }
}