import React from 'react'

const tutorial = dict => ({

    m1: {
        title: dict['m1title'],
        paragraph: dict['m1description'],
        img: [
            {
                url: require("../../../../img/ms_intro_1_select-md.png"),
                srcset: {
                    sm: require("../../../../img/ms_intro_1_select-sm.png"),
                    md: require("../../../../img/ms_intro_1_select-md.png"),
                    lg: require("../../../../img/ms_intro_1_select-lg.png"),
                    xl: require("../../../../img/ms_intro_1_select-xl.png")
                }
            }
        ]
    },
    m2: {
        title: dict['m2title'],
        paragraph: dict['m2description'],
        img: [
            {
                url: require("../../../../img/ms_intro_2_selectitem-md.png"),
                srcset: {
                    sm: require("../../../../img/ms_intro_2_selectitem-sm.png"),
                    md: require("../../../../img/ms_intro_2_selectitem-md.png"),
                    lg: require("../../../../img/ms_intro_2_selectitem-lg.png"),
                    xl: require("../../../../img/ms_intro_2_selectitem-xl.png")
                }
            }
        ]
    },
    m3: {
        title: dict['m3title'],
        paragraph: dict['m3description'],
        img: [
            {
                url: require("../../../../img/ms_intro_3_selecttemplate-md.png"),
                srcset: {
                    sm: require("../../../../img/ms_intro_3_selecttemplate-sm.png"),
                    md: require("../../../../img/ms_intro_3_selecttemplate-md.png"),
                    lg: require("../../../../img/ms_intro_3_selecttemplate-lg.png"),
                    xl: require("../../../../img/ms_intro_3_selecttemplate-xl.png")
                }
            }
        ]
    },
    m4: {
        title: dict['m4title'],
        paragraph: dict['m4description'],
        img: [
            // {
            //     url: require("../../../../img/ms_intro_5_example-md.png"),
            //     srcset: {
            //         sm: require("../../../../img/ms_intro_5_example-sm.png"),
            //         md: require("../../../../img/ms_intro_5_example-md.png"),
            //         lg: require("../../../../img/ms_intro_5_example-lg.png"),
            //         xl: require("../../../../img/ms_intro_5_example-xl.png")
            //     }
            // },
            {
                url: require("../../../../img/ms_intro_4_complete-md.png"),
                srcset: {
                    sm: require("../../../../img/ms_intro_4_complete-sm.png"),
                    md: require("../../../../img/ms_intro_4_complete-md.png"),
                    lg: require("../../../../img/ms_intro_4_complete-lg.png"),
                    xl: require("../../../../img/ms_intro_4_complete-xl.png")
                }
            }
        ]
    },
    m5: {
        title: dict['m5title'],
        paragraph: dict['m5description'],
        img: [
            {
                url: require("../../../../img/ms_intro_6_uploadphoto-md.png"),
                srcset: {
                    sm: require("../../../../img/ms_intro_6_uploadphoto-sm.png"),
                    md: require("../../../../img/ms_intro_6_uploadphoto-md.png"),
                    lg: require("../../../../img/ms_intro_6_uploadphoto-lg.png"),
                    xl: require("../../../../img/ms_intro_6_uploadphoto-xl.png")
                }
            },
            // {
            //     url: require("../../../../img/ms_intro_7_uploadwindow-md.png"),
            //     srcset: {
            //         sm: require("../../../../img/ms_intro_7_uploadwindow-sm.png"),
            //         md: require("../../../../img/ms_intro_7_uploadwindow-md.png"),
            //         lg: require("../../../../img/ms_intro_7_uploadwindow-lg.png"),
            //         xl: require("../../../../img/ms_intro_7_uploadwindow-xl.png")
            //     }
            // }
        ]
    },
    m6: {
        title: dict['m6title'],
        paragraph: dict['m6description'],
        img: [
            {
                url: require("../../../../img/ms_into_8_remove-md.png"),
                srcset: {
                    sm: require("../../../../img/ms_into_8_remove-sm.png"),
                    md: require("../../../../img/ms_into_8_remove-md.png"),
                    lg: require("../../../../img/ms_into_8_remove-lg.png"),
                    xl: require("../../../../img/ms_into_8_remove-xl.png")
                }
            },
            // {
            //     url: require("../../../../img/ms_intro_7_uploadwindow-md.png"),
            //     srcset: {
            //         sm: require("../../../../img/ms_intro_7_uploadwindow-sm.png"),
            //         md: require("../../../../img/ms_intro_7_uploadwindow-md.png"),
            //         lg: require("../../../../img/ms_intro_7_uploadwindow-lg.png"),
            //         xl: require("../../../../img/ms_intro_7_uploadwindow-xl.png")
            //     }
            // }
        ]
    }
})


export default tutorial