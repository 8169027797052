import FetchWrapper from './FetchWrapper'

const Fetch = new FetchWrapper()

class EmailHelper {
    constructor()
    {
        
    }
    /**
     * send email to one or multiple recipient
     * @param {arr} recipientArr 
     * @param {str} subject 
     * @param {str} html 
        usage:
        const sendResult = await EmailHelper.sendEmail('', 'Your Subject', '<p>Your message</p>')
     */
    async sendEmail(recipientArr = ['wjs2004@med.cornell.edu'], subject, html)
    {
        // console.log('send email called')
        let recipients
        if(!Array.isArray(recipientArr) && typeof recipientArr === 'string' && recipientArr !== '')
        {
            recipients = recipientArr
        }
        else if(!Array.isArray(recipientArr) && typeof recipientArr === 'string' && recipientArr === '')
        {
            recipients = 'wjs2004@med.cornell.edu'
        }
        else if(recipientArr.length <= 0)
        {
            recipients = 'wjs2004@med.cornell.edu'
        }
        else if(recipientArr === null || recipientArr === undefined)
        {
            recipients = 'wjs2004@med.cornell.edu'
        }
        else
        {
            recipients = recipientArr.join(', ')
        }
        // console.log(recipientArr)

        try
        {
            const url = '/api/v2/sendEmail'
            const body = {
                email: recipients,
                subject: subject,
                html: html
            }
            // const sent = await Fetch.post(url, body)
            const sent = await fetch('/api/v2/sendEmail', {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
                body: JSON.stringify({
                    email: recipients,
                    subject: subject,
                    html: html
                })
            })
            .then(r => r.json()).then(response => {
                return response
            })
            .catch(e => {
                throw e
            })
            return sent
        }
        catch(e)
        {
            return e
        }
    }
}

export default new EmailHelper()