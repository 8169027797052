import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Auth from '../ui/Auth'
import { changeLang } from '../../actions/config'
import { getCurrentDayLogs } from '../../actions/days'
import { authUser, addLoginErr, addRegisterFeedback, signup, confirmSignUp, sendForgotPassCode } from '../../actions/index'
import { defaultLang } from '../lang/init'

const mapStateToProps = (state) =>
    ({
        dict: state.configReducer.lang.dict,
        lang: state.configReducer.lang.lang,
        loginErr: state.userReducer.loginErr,
        registerFeedback: state.userReducer.registerFeedback,
        auth: state.userReducer.auth,
        accountInfo: state.userReducer.accountInfo,
        currentDay: state.daysReducer.currentDay
    })

const mapDispatchToProps = (dispatch) => 
    ({
        changeLang: (lang = defaultLang) => dispatch(changeLang(lang)),
        authUser: inputAuth => dispatch(authUser(inputAuth)),
        addLoginErr: err => dispatch(addLoginErr(err)),
        addRegisterFeedback: feedback => dispatch(addRegisterFeedback(feedback)),
        signup: (email, password, deceasedName, firstName, fieldData = {}) => dispatch(signup(email, password, deceasedName, firstName, fieldData)),
        confirmSignUp: (username, code) => dispatch(confirmSignUp(username, code)),
        sendForgotPassCode: (email) => dispatch(sendForgotPassCode(email)),
        getCurrentDayLogs: userId => dispatch(getCurrentDayLogs(userId))
    })


const Container = connect(mapStateToProps, mapDispatchToProps)(Auth)
export default withRouter(Container)
