import defaultOffering from './defaultOffering'

const presets = {
    "sincereLove": {
        "sl1": {
            startX: 0.1,
            originKey: defaultOffering.flowerRose.originKey, 
            url: defaultOffering.flowerRose.url, 
            widthUnitRatio: defaultOffering.flowerRose.widthUnitRatio, 
            heightFilling: defaultOffering.flowerRose.heightFilling, 
            padding: defaultOffering.flowerRose.padding, 
            callback: defaultOffering.flowerRose.callback
        },
        "sl2": {
            startX: 0.4,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        },
        "sl3": {
            startX: 0.9,
            originKey: defaultOffering.photoFrameV.originKey, 
            url: defaultOffering.photoFrameV.url, 
            widthUnitRatio: defaultOffering.photoFrameV.widthUnitRatio, 
            heightFilling: defaultOffering.photoFrameV.heightFilling, 
            padding: defaultOffering.photoFrameV.padding, 
            callback: defaultOffering.photoFrameV.callback
        },
        "sl4": {
            startX: 1.6,
            originKey: defaultOffering.flowerRose.originKey, 
            url: defaultOffering.flowerRose.url, 
            widthUnitRatio: defaultOffering.flowerRose.widthUnitRatio, 
            heightFilling: defaultOffering.flowerRose.heightFilling, 
            padding: defaultOffering.flowerRose.padding, 
            callback: defaultOffering.flowerRose.callback
        }
    },
    "heartfeltGratitude": {
        "hg1": {
            startX: 0.1,
            originKey: defaultOffering.flowerHydrangea.originKey, 
            url: defaultOffering.flowerHydrangea.url, 
            widthUnitRatio: defaultOffering.flowerHydrangea.widthUnitRatio, 
            heightFilling: defaultOffering.flowerHydrangea.heightFilling, 
            padding: defaultOffering.flowerHydrangea.padding, 
            callback: defaultOffering.flowerHydrangea.callback
        },
        "hg2": {
            startX: 0.4,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        },
        "hg3": {
            startX: 0.7,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        },
        "hg4": {
            startX: 1.6,
            originKey: defaultOffering.flowerHydrangea.originKey, 
            url: defaultOffering.flowerHydrangea.url, 
            widthUnitRatio: defaultOffering.flowerHydrangea.widthUnitRatio, 
            heightFilling: defaultOffering.flowerHydrangea.heightFilling, 
            padding: defaultOffering.flowerHydrangea.padding, 
            callback: defaultOffering.flowerHydrangea.callback
        }
    },
    "theBeautifulMemory": {
        "bm1": {
            startX: 0.45,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        },
        "bm2": {
            startX: 0.2,
            originKey: defaultOffering.photoFrameH.originKey, 
            url: defaultOffering.photoFrameH.url, 
            widthUnitRatio: defaultOffering.photoFrameH.widthUnitRatio, 
            heightFilling: defaultOffering.photoFrameH.heightFilling, 
            padding: defaultOffering.photoFrameH.padding, 
            callback: defaultOffering.photoFrameH.callback
        },
        "bm3": {
            startX: 1.2,
            originKey: defaultOffering.photoFrameH.originKey, 
            url: defaultOffering.photoFrameH.url, 
            widthUnitRatio: defaultOffering.photoFrameH.widthUnitRatio, 
            heightFilling: defaultOffering.photoFrameH.heightFilling, 
            padding: defaultOffering.photoFrameH.padding, 
            callback: defaultOffering.photoFrameH.callback
        },
        "bm4": {
            startX: 1,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        }
    },
    "honorTheMissed": {
        "hm1": {
            startX: 0.2,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        },
        "hm2": {
            startX: 0.5,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        },
        "hm3": {
            startX: 1.1,
            originKey: defaultOffering.photoFrameV.originKey, 
            url: defaultOffering.photoFrameV.url, 
            widthUnitRatio: defaultOffering.photoFrameV.widthUnitRatio, 
            heightFilling: defaultOffering.photoFrameV.heightFilling, 
            padding: defaultOffering.photoFrameV.padding, 
            callback: defaultOffering.photoFrameV.callback
        }
    },
    "tranquilNight": {
        "tn1": {
            startX: 0.1,
            originKey: defaultOffering.flowerLily.originKey, 
            url: defaultOffering.flowerLily.url, 
            widthUnitRatio: defaultOffering.flowerLily.widthUnitRatio, 
            heightFilling: defaultOffering.flowerLily.heightFilling, 
            padding: defaultOffering.flowerLily.padding, 
            callback: defaultOffering.flowerLily.callback
        },
        "tn2": {
            startX: 0.4,
            originKey: defaultOffering.candleLight.originKey, 
            url: defaultOffering.candleLight.url, 
            widthUnitRatio: defaultOffering.candleLight.widthUnitRatio, 
            heightFilling: defaultOffering.candleLight.heightFilling, 
            padding: defaultOffering.candleLight.padding, 
            callback: defaultOffering.candleLight.callback
        },
        "tn3": {
            startX: 1.1,
            originKey: defaultOffering.photoFrameH.originKey, 
            url: defaultOffering.photoFrameH.url, 
            widthUnitRatio: defaultOffering.photoFrameH.widthUnitRatio, 
            heightFilling: defaultOffering.photoFrameH.heightFilling, 
            padding: defaultOffering.photoFrameH.padding, 
            callback: defaultOffering.photoFrameH.callback
        },
        "tn4": {
            startX: 1.6,
            originKey: defaultOffering.flowerLily.originKey, 
            url: defaultOffering.flowerLily.url, 
            widthUnitRatio: defaultOffering.flowerLily.widthUnitRatio, 
            heightFilling: defaultOffering.flowerLily.heightFilling, 
            padding: defaultOffering.flowerLily.padding, 
            callback: defaultOffering.flowerLily.callback
        }
    }
}

export default presets