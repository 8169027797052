import React, { useEffect, useState } from 'react'
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const WritingProgress = (props) => {
    const [value, setValue] = useState(100)
    const [time, setTime] = useState(15*60)


    useEffect(() => {
        let unmounted = false
        // set a timer to count down 15 minutes
        let totalTime = 15 * 60, maxTime = 15*60
        const timerItv = setInterval(() => {
            if(totalTime > 0 && !unmounted)
            {
                totalTime -= 1
                // console.log(`the remaining time is ${totalTime} seconds`)
                // convert value to percent
                const percent = Math.round(totalTime/maxTime * 100, 10)
                setValue(percent)
                // console.log(`the percent ${percent}`)
                const minString = Math.floor(totalTime/60)
                const secString = totalTime%60
                // console.log(`time is ${minString}min${secString}sec`)
                setTime(totalTime)
                return
            }
            
            timeupCallback()
            clearInterval(timerItv)
            return
        }, 1000)
        return () => { 
            clearInterval(timerItv)
            unmounted = true 
        }
    }, [])

    const timeupCallback = () => props.timeupCallback() || null
    
    return <div style={{ width: 60, height: 60 }}>
    <CircularProgressbarWithChildren value={value} maxValue={100} minValue={0} >
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        fontSize: '0.4em'
    }}>
        <span>{Math.floor(time/60) > 0 ?`${Math.floor(time/60)}m`: ``}</span>
        <span>{time%60 > 0 ? `${time%60}s`: `0s`}</span>
    </div>
    </CircularProgressbarWithChildren>

    </div>
}

export default WritingProgress