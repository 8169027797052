import React, { Component } from 'react';
import store from '../../store';
import config from './config'

//All elements that utilize the languagepack have been turned into functons so that whenever the
//element gets used it gets is lang info from the updated redux store
/**
 * Define default values for homebase
 */

export const houses = [
    {
        key: 'house1',
        src: require('../../img/house1-md.jpg'),
        srcset: {
            sm: require('../../img/house1-sm.jpg'),
            md: require('../../img/house1-md.jpg'),
            lg: require('../../img/house1-lg.jpg'),
            xl: require('../../img/house1-xl.jpg')
        }
    },
    {
        key: 'house2',
        src: require('../../img/house2-md.jpg'),
        srcset: {
            sm: require('../../img/house2-sm.jpg'),
            md: require('../../img/house2-md.jpg'),
            lg: require('../../img/house2-lg.jpg'),
            xl: require('../../img/house2-xl.jpg')
        }
    },
    {
        key: 'house3',
        src: require('../../img/house3-md.jpg'),
        srcset: {
            sm: require('../../img/house3-sm.jpg'),
            md: require('../../img/house3-md.jpg'),
            lg: require('../../img/house3-lg.jpg'),
            xl: require('../../img/house3-xl.jpg')
        }
    },
    {
        key: 'house4',
        src: require('../../img/house4-md.jpg'),
        srcset: {
            sm: require('../../img/house4-sm.jpg'),
            md: require('../../img/house4-md.jpg'),
            lg: require('../../img/house4-lg.jpg'),
            xl: require('../../img/house4-xl.jpg')
        }
    },
    {
        key: 'house5',
        src: require('../../img/house5-md.jpg'),
        srcset: {
            sm: require('../../img/house5-sm.jpg'),
            md: require('../../img/house5-md.jpg'),
            lg: require('../../img/house5-lg.jpg'),
            xl: require('../../img/house5-xl.jpg')
        }
    },
    {
        key: 'house6',
        src: require('../../img/house6-md.jpg'),
        srcset: {
            sm: require('../../img/house6-sm.jpg'),
            md: require('../../img/house6-md.jpg'),
            lg: require('../../img/house6-lg.jpg'),
            xl: require('../../img/house6-xl.jpg')
        }
    }
]

export const views = [
    {
        key: 'view1',
        src: require('../../img/scene1-md.jpg'),
        srcset: {
            sm: require('../../img/scene1-sm.jpg'),
            md: require('../../img/scene1-md.jpg'),
            lg: require('../../img/scene1-lg.jpg'),
            xl: require('../../img/scene1-xl.jpg')
        }
    },
    {
        key: 'view2',
        src: require('../../img/scene2-md.jpg'),
        srcset: {
            sm: require('../../img/scene2-sm.jpg'),
            md: require('../../img/scene2-md.jpg'),
            lg: require('../../img/scene2-lg.jpg'),
            xl: require('../../img/scene2-xl.jpg')
        }
    },
    {
        key: 'view3',
        src: require('../../img/scene3-md.jpg'),
        srcset: {
            sm: require('../../img/scene3-sm.jpg'),
            md: require('../../img/scene3-md.jpg'),
            lg: require('../../img/scene3-lg.jpg'),
            xl: require('../../img/scene3-xl.jpg')
        }
    },
    {
        key: 'view4',
        src: require('../../img/scene4-md.jpg'),
        srcset: {
            sm: require('../../img/scene4-sm.jpg'),
            md: require('../../img/scene4-md.jpg'),
            lg: require('../../img/scene4-lg.jpg'),
            xl: require('../../img/scene4-xl.jpg')
        }
    },
    {
        key: 'view5',
        src: require('../../img/scene5-md.jpg'),
        srcset: {
            sm: require('../../img/scene5-sm.jpg'),
            md: require('../../img/scene5-md.jpg'),
            lg: require('../../img/scene5-lg.jpg'),
            xl: require('../../img/scene5-xl.jpg')
        }
    },
    {
        key: 'view6',
        src: require('../../img/scene6-md.jpg'),
        srcset: {
            sm: require('../../img/scene6-sm.jpg'),
            md: require('../../img/scene6-md.jpg'),
            lg: require('../../img/scene6-lg.jpg'),
            xl: require('../../img/scene6-xl.jpg')
        }
    }
]

export function defaultHomeBaseObj(){
            const defaultHomeBaseObj = {currentHouse: 'house1',
                intro: store.getState().dict.init.initApp.step1,
                imgSrcs: {
                    'house1': {
                        title: store.getState().dict.init.initApp.house1.number ,
                        url: require('../../img/house1-sm.jpg'),
                        url_md: require('../../img/house1-md.jpg'),
                        url_lg: require('../../img/house1-lg.jpg'),
                        desc: store.getState().dict.init.initApp.house1.name,
                        details: store.getState().dict.init.initApp.house1.disc,
                    },
                    'house2': {
                        title: store.getState().dict.init.initApp.house2.number,
                        url: require('../../img/house2-sm.jpg'),
                        url_md: require('../../img/house2-md.jpg'),
                        url_lg: require('../../img/house2-lg.jpg'),
                        desc: store.getState().dict.init.initApp.house2.name,
                        details: store.getState().dict.init.initApp.house2.disc,
                    },
                    'house3': {
                        title: store.getState().dict.init.initApp.house3.number,
                        url: require('../../img/house3-sm.jpg'),
                        url_md: require('../../img/house3-md.jpg'),
                        url_lg: require('../../img/house3-lg.jpg'),
                        desc: store.getState().dict.init.initApp.house3.name,
                        details: store.getState().dict.init.initApp.house3.disc,
                    },
                    'house4': {
                        title: store.getState().dict.init.initApp.house4.number,
                        url: require('../../img/house4-sm.jpg'),
                        url_md: require('../../img/house4-md.jpg'),
                        url_lg: require('../../img/house4-lg.jpg'),
                        desc: store.getState().dict.init.initApp.house4.name,
                        details: store.getState().dict.init.initApp.house4.disc,
                    },
                    'house5': {
                        title: store.getState().dict.init.initApp.house5.number,
                        url: require('../../img/house5-sm.jpg'),
                        url_md: require('../../img/house5-md.jpg'),
                        url_lg: require('../../img/house5-lg.jpg'),
                        desc: store.getState().dict.init.initApp.house5.name,
                        details: store.getState().dict.init.initApp.house5.disc,
                    },
                    'house6': {
                        title: store.getState().dict.init.initApp.house6.number,
                        url: require('../../img/house6-sm.jpg'),
                        url_md: require('../../img/house6-md.jpg'),
                        url_lg: require('../../img/house6-lg.jpg'),
                        desc: store.getState().dict.init.initApp.house6.name,
                        details: store.getState().dict.init.initApp.house6.disc,
                    },
                },
            }
    return  defaultHomeBaseObj;

}

export function defaultViewsObj(){
    const defaultViewsObj = {
        currentHouse: 'view1',
        intro: store.getState().dict.init.initApp.step2,
        imgSrcs: {
            'view1': {
                title: store.getState().dict.init.initApp.view1.number,
                url: require('../../img/scene1-sm.jpg'),
                url_md: require('../../img/scene1-md.jpg'),
                url_lg: require('../../img/scene1-lg.jpg'),
                desc: store.getState().dict.init.initApp.view1.name,
                details: store.getState().dict.init.initApp.view1.disc,
            },
            'view2': {
                title: store.getState().dict.init.initApp.view2.number,
                url: require('../../img/scene2-sm.jpg'),
                url_md: require('../../img/scene2-md.jpg'),
                url_lg: require('../../img/scene2-lg.jpg'),
                desc: store.getState().dict.init.initApp.view2.name,
                details: store.getState().dict.init.initApp.view2.disc,
            },
            'view3': {
                title: store.getState().dict.init.initApp.view3.number,
                url: require('../../img/scene3-sm.jpg'),
                url_md: require('../../img/scene3-md.jpg'),
                url_lg: require('../../img/scene3-lg.jpg'),
                desc: store.getState().dict.init.initApp.view3.name,
                details: store.getState().dict.init.initApp.view3.disc,
            },
            'view4': {
                title: store.getState().dict.init.initApp.view4.number,
                url: require('../../img/scene4-sm.jpg'),
                url_md: require('../../img/scene4-md.jpg'),
                url_lg: require('../../img/scene4-lg.jpg'),
                desc: store.getState().dict.init.initApp.view4.name,
                details: store.getState().dict.init.initApp.view4.disc,
            },
            'view5': {
                title: store.getState().dict.init.initApp.view5.number,
                url: require('../../img/scene5-sm.jpg'),
                url_md: require('../../img/scene5-md.jpg'),
                url_lg: require('../../img/scene5-lg.jpg'),
                desc: store.getState().dict.init.initApp.view5.name,
                details: store.getState().dict.init.initApp.view5.disc,
            },
            'view6': {
                title: store.getState().dict.init.initApp.view6.number,
                url: require('../../img/scene6-sm.jpg'),
                url_md: require('../../img/scene6-md.jpg'),
                url_lg: require('../../img/scene6-lg.jpg'),
                desc: store.getState().dict.init.initApp.view6.name,
                details: store.getState().dict.init.initApp.view5.disc
            },
        },
    }
    return defaultViewsObj;
};

export function defaultIconSets(){
    const defaultIconSets = [
        {
            btnValue: 'candleLight',
            imgSrc: require('../../img/candle_icon.svg'),
            alt: 'offer a candle',
            iconTitle: store.getState().dict.init.initApp.icons.candle,
            triggerOptions: false,
        },
        {
            btnValue: 'flowerVase',
            imgSrc: require('../../img/flower_icon.svg'),
            alt: 'offer a flower',
            iconTitle: store.getState().dict.init.initApp.icons.flower,
            triggerOptions: false,
        },
        {
            btnValue: 'albumEmpty',
            imgSrc: require('../../img/album_icon.svg'),
            alt: 'offer an album',
            iconTitle: store.getState().dict.init.initApp.icons.album,
            triggerOptions: true,
        },
    ]
    return defaultIconSets;
}

export function iconImages(){
    const iconImages = {
        'closeBtn': {
            btnValue: 'cancel',
            imgSrc: require('../../img/close_btn.svg'),
            alt: 'close the box',
            iconTitle: store.getState().dict.init.initApp.icons.close,
            triggerOptions: false,
        }
    }
    return iconImages;
}

export function stepsOfOffering(){
    const stepsOfOffering = [
        {
            systemMsg: 'Welcome to your memory home.',
            display: true,
        },
        {
            systemMsg: 'Now that you’ve found your special spot, it’s time to decorate it.',
            display: true,
        },
        {
            systemMsg: 'On the left panel, you can see meaningful things that will help you focus on your intentions of memorializing and remembering.',
            display: true,
        },
        {
            systemMsg: 'You might decide to add a candle to burn both in memory of ______and in bearing witness.',
            display: true,
        },
        {
            systemMsg: 'You can also add a flower arrangement to remember your loved one. When choosing flowers, you can pick an arrangement that tells the same story as the relationship you had with the person. ',
            display: true,
        },
        {
            systemMsg: `There’s also space to include a picture of _____ 
        in a special frame as the centerpiece. Your special spot memorializes _____, 
        acknowledging their value and importance in your life. `,
            display: true,
        },
        {
            systemMsg: '',
            display:false,
        }
    ]
    return stepsOfOffering;
};

export function journalTexts(){
    const journalTexts = {
        title: store.getState().dict.init.initApp.journalTexts.title,
        content: store.getState().dict.init.initApp.journalTexts.content
    }
    return journalTexts;
}
// constant: What has been on your mind today?
export function diaryQuestions(){
    const diaryQuestions = [
        {// day 1
            key: 'day1',
            prompts: [
                {
                    key: "q1",
                    prompt: store.getState().dict.init.initApp.day1.q1,
                    require: true
                },
                {
                    key: "q2",
                    prompt: store.getState().dict.init.initApp.day1.q2,
                    require: false,
                },
                {
                    key: "q3",
                    prompt: store.getState().dict.init.initApp.day1.q3,
                    require: false,
                }
            ]
        },
        {// day 2
            key: 'day2',
            prompts: [
                {
                    key: "q1",
                    prompt: store.getState().dict.init.initApp.day2.q1,
                    require: true
                },
                {
                    key: "q2",
                    prompt: store.getState().dict.init.initApp.day2.q2,
                    require: false,
                },
                {
                    key: "q3",
                    prompt: store.getState().dict.init.initApp.day2.q3,
                    require: false,
                }
            ]
        },
        {// day 3
            key: 'day3',
            prompts: [
                {
                    key: "q1",
                    prompt: store.getState().dict.init.initApp.day3.q1,
                    require: true
                },
                {
                    key: "q2",
                    prompt: store.getState().dict.init.initApp.day3.q2,
                    require: false,
                },
                {
                    key: "q3",
                    prompt: store.getState().dict.init.initApp.day3.q3,
                    require: false,
                }
            ]
        },
        {// day 4
            key: 'day4',
            prompts: [
                {
                    key: "q1",
                    prompt: store.getState().dict.init.initApp.day4.q1,
                    require: true
                },
                {
                    key: "q2",
                    prompt: store.getState().dict.init.initApp.day4.q2,
                    require: false,
                },
                {
                    key: "q3",
                    prompt: store.getState().dict.init.initApp.day4.q3,
                    require: false,
                }
            ]
        },
        {// day 5
            key: 'day5',
            prompts: [
                {
                    key: "q1",
                    prompt: store.getState().dict.init.initApp.day5.q1,
                    require: true
                },
                {
                    key: "q2",
                    prompt: store.getState().dict.init.initApp.day5.q2,
                    require: false,
                },
                {
                    key: "q3",
                    prompt: store.getState().dict.init.initApp.day5.q3,
                    require: false,
                }
            ]
        },
        {// day 6
            key: 'day6',
            prompts: [
                {
                    key: "q1",
                    prompt: store.getState().dict.init.initApp.day6.q1,
                    require: true
                },
                {
                    key: "q2",
                    prompt: store.getState().dict.init.initApp.day6.q2,
                    require: false,
                },
                {
                    key: "q3",
                    prompt: store.getState().dict.init.initApp.day6.q3,
                    require: false,
                }
            ]
        },
        {// day 7
            key: 'day7',
            prompts: [
                {
                    key: "q1",
                    prompt: store.getState().dict.init.initApp.day7.q1,
                    require: true
                },
                {
                    key: "q2",
                    prompt: store.getState().dict.init.initApp.day7.q2,
                    require: false,
                },
                {
                    key: "q3",
                    prompt: store.getState().dict.init.initApp.day7.q3,
                    require: false,
                }
            ]
        },
    ]
    return diaryQuestions;
}

export function returnQuestions(day) {
    let dayObject = diaryQuestions()[day];
    let questionsOfTheDay = dayObject.prompts;
    questionsOfTheDay.unshift({
        key: "constant",
        prompt: store.getState().dict.init.initApp.returnQuestions.q1,
        require: true
    });
    return questionsOfTheDay;
}

export const canvasDimensions = {
    W_H_RATIO: 1.82,
    WINDOW_PADDING: 0,
    HEIGHT_RATIO: 0.88 // this is the ratio relative to window height
}

export const sessionKeys = {
    startDay: 'start-day',
    expiry: 'expiry',
    step: 'step',
    canvasUrl: 'canvas-url',
    activityLogs: 'activity-logs',
    offeringObjs: 'offering-objects',
    journalEntry: 'journal-entry',
    dayLogs: 'day-logs',
    MHConfig: 'config',
    systemMessagesHouse: 'system-messages-house',
    systemMessagesOffering: 'system-messages-offering',
    systemMessagesJournal: 'system-messages-journal',
    tempHomebase: 'temp-homebase',
    lastLoginTime: 'last-login-time'
}

// generate a log whne user interacts with the panel
export function activitiesFeedback(){
    const activitiesFeedback = {
        cancelPlaceOffering: store.getState().dict.init.initApp.activitiesFeedback.cancelPlaceOffering,
        removeOffering: store.getState().dict.init.initApp.activitiesFeedback.removeOffering,
        candleDim: store.getState().dict.init.initApp.activitiesFeedback.candleDim,
        candleLight: store.getState().dict.init.initApp.activitiesFeedback.candleLight,
        flowerVase: store.getState().dict.init.initApp.activitiesFeedback.flowerVase,
        flowerLily: store.getState().dict.init.initApp.activitiesFeedback.lowerLily,
        flowerRose: store.getState().dict.init.initApp.activitiesFeedback.flowerRose,
        flowerOrchid: store.getState().dict.init.initApp.activitiesFeedback.flowerOrchid,
        flowerChrysan: store.getState().dict.init.initApp.activitiesFeedback.flowerChrysan,
        flowerHydrangea: store.getState().dict.init.initApp.activitiesFeedback.flowerHydrangea,
        photoFrameH: store.getState().dict.init.initApp.activitiesFeedback.photoFrameH,
        photoFrameV: store.getState().dict.init.initApp.activitiesFeedback.photoFrameV,
        uploadPhoto: store.getState().dict.init.initApp.activitiesFeedback.uploadPhoto
    }
    return activitiesFeedback;
}


export function flowerImplications(){
    const flowerImplications = {
        lily: store.getState().dict.init.initApp.flowerImplications.lily,
        rose: store.getState().dict.init.initApp.flowerImplications.rose,
        orchid: store.getState().dict.init.initApp.flowerImplications.orchid,
        chrysan: store.getState().dict.init.initApp.flowerImplications.chrysan,
        hydrangea: store.getState().dict.init.initApp.flowerImplications.hydrangea,
    }
    return flowerImplications;
}

export function activitiesUser() {
    const activitiesUser = {
        lightCandle: '',
        offerLily: flowerImplications().lily,
        offerRose: flowerImplications().rose,
        offerOrchid: flowerImplications().orchid,
        offerChrysan: flowerImplications().chrysan,
        offerhydrangea: flowerImplications().hydrangea,
        offerPhotoFrame: '',
    }
    return activitiesUser

}

export const tbs = {
    users: 'users',
    userRoles: 'user_roles',
    profiles: 'user_profiles',
    systemMessages: 'system_messages',
    sessions: 'sessions',
    offeringLogs: 'offering_logs',
    config: 'mh_configs',
    loginLogoutRecords: 'login_logout_records',
    journalEntries: 'journal_entries',
    errorLogs: 'error_logs',
    dayLogs: 'day_logs',
    activityLogs: 'activity_logs'
}

export const s3folders = {
    ritualImgs: 'ritual_imgs',
    userPhotos: 'user_photos',
    root: '/',
}

export function NavItems(){
    const navItems = [
        {
            key: 'homebase',
            title: store.getState().dict.init.initApp.navItems.homebase,
            url: config.paths.dashboard
        },
        {
            key: 'space',
            title: store.getState().dict.init.initApp.navItems.memorialSpace,
            url: config.paths.memorial
        },
        {
            key: 'desk',
            title: store.getState().dict.init.initApp.navItems.writingDesk,
            url: config.paths.diary
        },
        {
            key: 'resources',
            title: store.getState().dict.init.initApp.navItems.griefResources,
            url: config.paths.resources,
            target: '_blank'
        },
    ]
    return navItems;
}

export function breakPoint(px) {
    switch(px) {
        case px <= 348:
            return "sm";
        case px <= 720 && px > 348:
            return "md";
        case px <= 1024 && px > 720:
            return "lg";
        case px > 1024:
            return "xl";
        default: return "lg";
    }
    return "lg";
}

export const configs = {
    autosave: 8000,
    feedbackFade: 3000
}

export const defaultVal = {
    homeOwner: 'Visitor',
    deceasedName: 'The Deceased'
}