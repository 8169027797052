export const colors = {
    darkGreen: '#262D20',
    darkGreenOpa: 'rgba(38, 45, 32, .8)',
    lightGreen: '#B6BA9F',
    lightGreenOpa: 'rgba(182, 186, 159, .8)',
    midGreen: '#536337',
    lightPink: '#fdc5c4',
    midPink: '#fb6a65',
    darkPink: '#644857',
    darkPinkOpa: 'rgba(100,72,87, 0.8)',
    lightYellow: '#FCC87F',
    lightYellowOpa4: 'rgba(252, 200, 127, 0.4)',
    lightYellowOpa2: 'rgba(252, 200, 127, 0.2)',
    darkBlue: '#003366',
    white: '#FFFFFF',
    whiteOpa8: 'rgba(255,255,255, .8)',
    whiteOpa6: 'rgba(255,255,255, .6)',
    whiteOpa4: 'rgba(255,255,255, .4)',
    whiteOpa2: 'rgba(255,255,255, .2)'
}