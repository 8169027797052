// import jstz from 'jstz'
import User from './UserHelper'
// import Localstorage from './LocalStorage'
import FetchWrapper from './FetchWrapper'

const Fetch = new FetchWrapper()
// const moment = require('moment-timezone')

/**
 * usage:
    import { SystemLogs } from './classes/systemConfig';
    const SLG = new SystemLogs();
 */
class SystemLogs {
    constructor()
    {
    }

    async recordLogGivenUsername(username = '', login = true) {
        if(username === '' || username === undefined) return false
        // const bodyData = JSON.stringify({
        //     username: username,
        //     login: login
        // });

        const url = '/activity/v1/recordLog'
        const body = {
            username: username,
            login: login
        }

        const logResult = await Fetch.post(url, body)
        .then(r => r.json())
        .then(results => {
            if(!results.success)
            {
                throw results
                // return onResults(results.message, null);
            }
            console.log("login result", results)
            return results.message?.results[0]
            // return onResults(null, results.message)
        })
        .catch(err => {
            return err
            // return onResults('The system failed to store the log', null);
        })
        /* unauthorized fetches
        const logResult = await fetch('/activity/v1/recordLog', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            body: bodyData
        })
        .then(r => r.json())
        .then(results => {
            if(!results.success)
            {
                throw results
                // return onResults(results.message, null);
            }
            return results.message
            // return onResults(null, results.message)
        })
        .catch(err => {
            return err
            // return onResults('The system failed to store the log', null);
        })
        */

        return logResult
    }
    
    /**
     * add error and send message to notify the developer
     * @param {*} username 
     * @param {*} errCode 
     * usage:
        const addErr = await SLG.recordErrGivenUsername(null, 'TEST_ERROR')
    */
    async recordErrGivenUsername(username, errCode)
    {
        let usersId = await User.returnUserId(username, u => u).catch(e => false)
        if(!usersId) usersId = null

        const url = '/activity/v1/error'
        const body = {
            usersId: usersId,
            errCode: errCode
        }
        
        const r = await Fetch.post(url, body)
        .then(r => r.json())
        .then(response => response)
        .catch(async e => {
            // notify the developer that store error failed
            const emailDev = await Fetch.post('/api/v2/sendEmail', {
                email: 'wjs2004@med.cornell.edu',
                subject: 'System failed to store error',
                html: `<h1>${errCode}</h1>
                <p>The system has failed to store this error</p>
                <p>The user id is: ${usersId}</p>
                <p>error: ${e}</p>
                `
            })
        })
        /* unauthorized fetches
        const r = await fetch('/activity/v1/error', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({
                usersId: usersId,
                errCode: errCode
            })
        })
        .then(r => r.json()).then(response => response)
        .catch(async e => {
            // notify the developer that store error failed
            const emailDev = await fetch('/api/v1/sendEmail', {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
                body: JSON.stringify({
                    email: 'wjs2004@med.cornell.edu',
                    subject: 'System failed to store error',
                    html: `<h1>${errCode}</h1>
                    <p>The system has failed to store this error</p>
                    <p>The user id is: ${usersId}</p>
                    `
                })
            })
        })*/

        return r
    }
}

export default new SystemLogs()