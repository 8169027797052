import React, { Component } from 'react';
import NavBar from '../containers/NavBar'

import '../../scss/index.scss';
import '../../scss/index-query.scss';

// import connect from "react-redux/es/connect/connect";
// import {mapDispatchToProps, mapStateToProps} from "./lang/langRedux";


class Error extends Component {
    componentWillMount() {
        //TOOD: get the url query and display it on the panel
        const urlParams = new URLSearchParams(window.location.search)
        const title = urlParams.get('title') || this.props.dict.error.systemError
        const message = urlParams.get('message')
        this.setState({
            title: title,
            message: message
        });
    }
    componentDidMount() {
        this.props.changeLang(this.props.match.params.lang);
    }
    render() {
        const fill = {
            minHeight: '100%',
            height: '100%'
        }
        return(
            <div className="container pb-5" style={fill}>
                <NavBar />
                <div className='error-container p-5'>
                    <div className="row alert justify-content-center" role="alert">
                        <h4 className="alert-heading mx-auto">{this.state.title}</h4>
                    </div>
                    <div className='row alert justify-content-center p-5' role="alert">
                        <p>{
                            this.props.errMsg ?
                                this.props.errMsg
                                :
                                this.state.message ?
                                    this.state.message
                                    :
                                    this.props.dict.error.clickToReturnToHomepage
                        }</p>
                    </div>
                    <div className='row alert justify-content-center' role="alert">
                        <button type="button" className="btn btn-info btn-lg mx-auto my-2" onClick={() => { window.location.assign("/login") }}>{this.props.dict.error.returnToMainPage}</button>
                    </div>
                </div>

            </div>
        )
    }
}
//                    <img src={require('img/fail_icon.png')} alt="error image" />

// export default connect(mapStateToProps, mapDispatchToProps)(Error);
export default Error