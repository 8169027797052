// import { Database } from '../db'
// import DH from './DatabaseHelper'
// import { tbs } from '../../init/initApp'
import EH from './ErrorHelper'
import GH from './GeneralHelper'
import FetchWrapper from './FetchWrapper'
const Fetch = new FetchWrapper()

// const DB = new Database()

class UserHelper {
    constructor()
    {

    }
    /**
     * Create a new user in the database, reutnr the userdata
     * @param {str} username 
     * @param {str} email 
     * @param {func} onResults 
     * usage:
        User.storeUserInDB('username', 'user@email.com', (err, result) => {
            if(err) return; // error code FAILED_TO_STORE_USER_DATA
            // do something
        });
     */
    storeUserInDB(username, email, onResults)
    {

        return fetch('/user/v2/registerUser', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({
                username: username,
                email: email
            })
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults('FAILED_TO_STORE_USER_DATA', null);
            }
            return onResults(null, response.result);
        })
        .catch(err => {
            return onResults('FAILED_TO_STORE_USER_DATA', null);
        });
    }
    async insertUser(fieldData = {})
    {
        const r = await Fetch.post('/user/v2/insertUser', {
            table: 'users',
            fieldData: fieldData,
            where: {}
        })
        .then(r => r.json())
        .then(r => {
            if(!r.success) throw r
            return r.results
        })
        .catch(e => e)
        // console.log("insert user result",r)
        if(Array.isArray(r) && r.length > 0) return r[0]

        return r
    }

    async getUserIdGivenUsername(username = '')
    {
        if(!username || username === '' || username === null || username === undefined) return false
        const d = await this.getUserData(username)
        if(!d) return false
        if(Array.isArray(d)) return d[0].id
        return d.id
    }

    async getUserData(username = '')
    {
        console.log(`get user data called with ${username}`)
        if(!username || username === '' || username === null || username === undefined) return false
        try
        {
            const userData = await Fetch.get('/user/v2/getUserData')
            .then(r => r.json())
            .then(r => r)
            .catch(e => e)
            if(!userData.success)
            {
                throw {
                    code: 'FAILED_TO_QUERY_USER',
                    message: `The system failed while performing the query for following username: ${username}`
                }
            }
            if(userData.success && userData.results.length <= 0)
            {
                throw {
                    code: 'USER_NOT_FOUND',
                    message: `The system cannot find ${username}`
                }
            }
            return userData.results
            // console.log("got user data ",userData)
            // const userData = await Fetch.get('/user/v2/getUserData', (err, r) => {
            //     console.log(err)
            //     console.log(r)
            //     if(err) throw err
            //     if(!r.success || r.results.length <= 0) throw {
            //         code: 'USER_NOT_FOUND',
            //         message: `The system cannot find ${username}`
            //     }
            //     return r.results
            // })
            // return userData
        }
        catch(e)
        {
            await EH.addError(null, 'USER_NOT_FOUND', `The system cannot find ${username}`)
            return false
        }
    }

    async getRedcapIds()
    {
        try
        {
            const result = await Fetch.get(`/user/v2/getAllREDCapId`, [], async (e, r) => {
                // console.log(err)
                // console.log(r)
                if(e) throw e
                return r
            })
            .then(r => r.json())
            .then(async r => {
                if(!r.success || r.data.length <= 0) throw {
                    code: 'REDCAP_ID_QUERY_FAILED',
                    error: r
                }
                let newArr = r.data
                let resultArr = []
                await GH.asyncForEach(newArr, d => {
                    resultArr.push(d.redcap_id)
                })
                // console.log("redcap id result ",resultArr)
                return resultArr
            })
            return result
        }
        catch(e)
        {
            await EH.addError(null, 'REDCAP_ID_QUERY_FAILED', JSON.stringify(e))
            return false
        }

    }

    async getDailyUseStatsGivenREDCapId(redcapId = '')
    {
        let id = redcapId.trim()
        if(redcapId.trim() === '' || redcapId === null || redcapId === undefined) return false
        try
        {
            const r = Fetch.get(`/api/v2/getDailyStatsData/${id}`, [])
            .then(r => r.json())
            .then(r => {
                if(!r.success) throw r?.error
                return r.data
            })
            .catch(e => {throw e})
            return r
        }
        catch(e)
        {
            return false
        }
    }

    async getAllUserStats()
    {
        try
        {
            const r = Fetch.get(`/api/v2/getGroupStatsData`, [])
            .then(r => r.json())
            .then(r => {
                if(!r.success) throw r?.error
                return r.data
            })
            .catch(e => {throw e})
            return r
        }
        catch(e)
        {
            return false
        }
    }

    returnUserData(onResults)
    {
        return Fetch.get(`/user/v2/getUserId`)
        .then(response => response.json())
        .then(userData => {
            if(userData.results.length > 0)
            {
                return onResults(userData.results[0]);
            }
            return onResults(false);
        })
        .catch(err => {
            return onResults(false);
        });
    }

    /**
     * get user's id with username
     * @param {str} username 
     * @param {func} onResults 
     * usage:
        User.returnUserId('your-username', (userId) => {
            if(!userId) return;
            // do something
        })
     */
    returnUserId(onResults) {
        return Fetch.get(`user/v2/getUserId`)
        .then(response => response.json())
        .then(userData => {
            if(userData.results.length > 0)
            {
                return onResults(userData.results[0].id);
            }
            return onResults(false);
        })
        .catch(err => {
            return onResults(false);
        });
    }
}

export default new UserHelper()