const mapStateToProps = state => {
    // console.log("mapStateToProps");
    return{
        dict: state.dict,
        lang: state.lang
    }
}

const mapDispatchToProps = dispatch => {
    // console.log("mapDispatchToProps");
    return {
        changeLang: lang => {
            // console.log("changeDict")
            // console.log("dict lang to change " + lang)
            return dispatch({type: 'LANG', value: lang}
            )
        }

    }
}

module.exports.mapStateToProps = mapStateToProps;
module.exports.mapDispatchToProps = mapDispatchToProps;