import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { changeLang, enableResetConfig } from '../../actions/config'
import { getCurrentDayLogs, loadActivityLog, getCurrentJournalDay } from '../../actions/days'
import BeatLoader from "react-spinners/BeatLoader"
import SystemMessage from '../assets/v2/SystemMessage'
import { colors } from '../init/colors'
import { upsertConfig, logout } from '../../actions/index'

import AH from '../classes/v2/AmplifyHelper'
import ALH from '../classes/v2/ActivityLogHelper'
import STM from '../classes/v2/SystemTimeManage'
import { defaultLang } from '../lang/init'
import config from '../init/config'

const withPreLoader = (WrappedComponent) => {
    class withPreLoaderComponent extends React.Component {
        constructor(props)
        {
            super(props)
            this.state = {
                messageKeys: [],
                loggedIn: false,
                preloaded: false
            }
            this.checkCurrentVisitDay = this.checkCurrentVisitDay.bind(this)
        }

        async componentDidMount()
        {
            console.log("preloader called")
            var self = this;
            await this.props.changeLang(this.props.match.params.lang)
            
            // check if user is logged in
            let loggedIn = await AH.getSession().then(r => r).catch(e => false)
            console.log("logged in ", loggedIn)
            if(!loggedIn || loggedIn === 'No current user') return this.props.history.push(`/${this.props.match.params.lang}${config.paths.auth}`) // users need to be redirected to login page
            
            loggedIn = true
            // console.log(`logged in is ${loggedIn}`)

            // check if house and view are both set
            if(this.props.accountInfo.house === null || this.props.accountInfo.view === null)
            {
                // not set, redirect back to the config page
                this.setState({
                    loggedIn,
                    messageKeys: [{
                        message: 'redirect-to-select-house-or-view',
                        callback: e => {
                            // console.log('redirect in preloader called')
                            self.props.enableResetConfig(true)
                            self.props.history.push(config.paths.config)
                        }
                    }]
                })
            }
            // console.log('checkpoint 1')
            try
            {
                // console.log('preloader triggered')
                // get the current day log to know how many days user has visited LMH
                const currentVisitDay = await this.checkCurrentVisitDay()
                // console.log(currentVisitDay)
                // initiate activity tracker to track how long user spends in the app
                ALH.initActivityAutoSave(() => ALH.updateLogoutTime(this.props.currentDay.id))
    
                this.setState({
                    // dayLogs: this.props.currentDay,
                    // userData: this.props.accountInfo,
                    loggedIn: loggedIn,
                    preloaded: true
                })
            }
            catch(e)
            {
                // console.log(e)
                this.setState({
                    // dayLogs: this.props.currentDay,
                    // userData: this.props.accountInfo,
                    loggedIn: false,
                    preloaded: true
                })
            }
        }

        async checkCurrentVisitDay()
        {
            // console.log('check current visit day called')
            if(Object.keys(this.props.currentDay).length <= 0 || this.props.currentDay === {})
            {
                // console.log('check the current day and see if a new day should be set')
                // load the current day into the reducer
                const currentDay = await this.props.getCurrentDayLogs(this.props.auth.id)
                // console.log(currentDay)
                if(currentDay) // set a new day
                {
                    // this.setState({
                    //     displayFeedback: this.props.lang['memorial']['newVisit']
                    // })
                }
            }
            else
            {
                // there are previous day logs, determine if a new day should be initiated
                const shouldSetNewDay = STM.shouldSetNewDay(this.props.currentDay.login_time)
                // console.log(shouldSetNewDay)
                if(shouldSetNewDay)
                {
                    // console.log('you should never see me')
                    const setNewDay = await this.props.getCurrentDayLogs(this.props.auth.id)
                    // if(setNewDay)
                    // {
                        // this.setState({
                        //     displayFeedback: this.props.lang['memorial']['newVisit']
                        // })
                    // }
                }
                // console.log(this.props.currentDay)
                // console.log(`should set a new day new day ${shouldSetNewDay}`)
            }
            return true
        }

        render()
        {
            if(!this.state.preloaded)
            {
                console.log("not preloaded")
                return <React.Fragment>
                <div style={{ width: '100vw', height: '100vh' }} className='d-flex justify-content-center align-items-center flex-column'>
                    <h2 className='white-title'>Please wait while the system is loading the data</h2>
                    <BeatLoader
                        // css={override}
                        css={`size: 15;`}
                        size={15}
                        color={colors.lightYellow}
                        loading={true}
                    />
                </div>
                </React.Fragment>
            }
            // console.log(this.props)
            if(this.props.accountInfo.house === null || this.props.accountInfo.view === null)
            {
                return (<React.Fragment>
                {this.state.messageKeys.length > 0 ?
                    <SystemMessage
                        messageKeys={this.state.messageKeys || []}
                        messageObj={this.props.dict['systemMessages']}
                        ctnStyle={{backgroundColor: 'transparent'}}
                    /> : null}
                </React.Fragment>)
            }
            return <WrappedComponent {...this.props} loggedIn={this.state.loggedIn} />
        }
    }
    const mapStateToProps = state => {
        return {
            auth: state.userReducer.auth,
            dict: state.configReducer.lang.dict,
            lang: state.configReducer.lang.lang,
            accountInfo: state.userReducer.accountInfo,
            currentDay: state.daysReducer.currentDay,
            activityLogs: state.daysReducer.activityLogs
        }
    }
    
    const mapDispatchToProps = dispatch => 
        ({
            changeLang: (lang = defaultLang) => dispatch(changeLang(lang)),
            getCurrentDayLogs: userId => dispatch(getCurrentDayLogs(userId)),
            loadActivityLog: (userId, dayLogId) => dispatch(loadActivityLog(userId, dayLogId)),
            getCurrentJournalDay: (usersId, dayLogsId, day) => dispatch(getCurrentJournalDay(usersId, dayLogsId, day)),
            // upsertConfig: (userId, param) => dispatch(upsertConfig(userId, param)),
            enableResetConfig: reset => dispatch(enableResetConfig(reset)),
            logout: () => dispatch(logout())
        })
    return withRouter(connect(mapStateToProps, mapDispatchToProps)(withPreLoaderComponent))
}


export default withPreLoader