import React, { Component } from 'react';
import { RectBtnPlain } from '../assets/buttons';
import { arrToObjWithColumnKey } from '../init/general';
import { sessionKeys, configs } from '../init/initApp';
import { Localstorage } from './localstorage';
import { BtnGroup, LoginBtn } from '../assets/buttons';
import { SystemLogs, SystemMessageManagement } from './systemConfig';
import {mapDispatchToProps, mapStateToProps} from "../lang/langRedux";
import connect from "react-redux/es/connect/connect";
import { colors } from '../init/colors'

// Store the queried messages in Localstorage to reduce the repetitive query
const LS = new Localstorage();
// Record the error happened during the navigation
const SLG = new SystemLogs();
const SMM = new SystemMessageManagement();

/**
 * a floating panel to display system message when user triggers a certain activity
 * usage:
    import { SystemMessage } from './assets/systemMessage';
    <SystemMessage 
        messageGroupsKey=['to_search_message_groups_for_the_page']
        messageKeys=['your_message_key']
        systemMsgSessionKey='the session key that store your system msg data'
    />
 */
class UnconnectedSystemMessage extends Component {
    // constructor(props)
    // {
    //     super(props);
    //     this.state = {
    //         active: false,
    //         msgObj: {},
    //         messageKeys: props.messageKeys
    //     }
    //     this.next = this.next.bind(this);
    //     this.displayMsg = this.displayMsg.bind(this);
    //     this.close = this.close.bind(this);
    // }
    constructor(props)
    {
        super(props);
        this.state = {
            active: false,
            msgObj: {},
        }
        this.next = this.next.bind(this);
        this.displayMsg = this.displayMsg.bind(this);
        this.close = this.close.bind(this);
    }
    componentDidMount() {
        this.getAndRenderMessage(this.props.messageKeys);
    }

    getAndRenderMessage(keys){
        let msgObj = this.props.dict.systemMessages.find((element) => {
            return element["message_key"] == keys[0];
        });
        if(msgObj){
            this.setState({
                msgObj: msgObj,
                active: true,
                messageKeys: keys,
            }, () => {
                this.displayMsg();
            });
        }
        else {
            return this.setState({
                active: false
            });
        }
    }

    next(e) {
        if(e.target.className.includes('system-message-text-container') || 
        e.target.className.includes('system-message-title') || e.target.className.includes('system-message-text')) return;
        if(this.state.messageKeys && this.state.messageKeys.length > 0)
        {
            const newMessageKeys = this.state.messageKeys.splice(1, this.state.messageKeys.length - 1);
            // console.log("next");
            return this.setState({
                messageKeys: newMessageKeys
            }, () => this.getAndRenderMessage(this.state.messageKeys));
        }
        return this.close();
    }
    displayMsg() {
        if(this.state.messageKeys.length > 0)
        {
            // const key = this.state.messageKeys[0];
            let msg =  this.state.msgObj.message.slice();
            let title =  this.state.msgObj.message_title.slice();
            return this.setState({
                active: true,
                currentMessage: msg || '',
                currentTitle: title || ''
            });
        }
        return this.close();
    }
    close() {
        return this.setState({
            messageKeys: [],
            active: false,
            currentMessage: '',
            currentTitle: ''
        });
    }
    render() {
        const systemMsgWrapperStyles = {
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: `rgba(0,0,0,0.6)`,
            zIndex: 999,
        }
        const systemMsgImgContStyles = {
            minWidth: '600px',
            width: '50%',
        }
        if(!this.state.messageKeys || this.state.messageKeys.length <= 0) // the system message panel isn't active
        {
            return (
                <div></div>
            );
        }
        // should display messages to user
        return (
            <div style={systemMsgWrapperStyles} className={this.state.active ? 'system-message-wrapper active' : 'system-message-wrapper'} onClick={this.next}>
                <div style={systemMsgImgContStyles} className='system-message-img-container'>
                    <div className='system-message-text-container'>
                        <h3 className='system-message-title'>{this.state.currentTitle ? this.state.currentTitle : ''}</h3>
                        <p className='system-message-text system-message'>{this.state.currentMessage}</p>
                        <RectBtnPlain
                            btnValue='close'
                            btnTitle='OK'
                            ctnStyle={{ backgroundColor: 'transparent' }}
                            btnStyle={{ marginTop: '20px', minWidth: '50%'}}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export const SystemMessage = connect(mapStateToProps, mapDispatchToProps)(UnconnectedSystemMessage);

/**
 * a floating panel to display system message when user triggers a certain activity
 * usage:
    import { ConfirmBox } from './assets/systemMessage';
    <ConfirmBox 
        confirmMessage='your message'
        reject={this.rejectFunc}
        confirm={this.confirmFunc}
    />
 */

class UnconnectedConfirmBox extends Component {
    constructor(props)
    {
        super(props);
        this.confirm = this.confirm.bind(this);
    }
    componentDidMount() {

    }
    confirm(t) {
        switch(t)
        {
            case 'No':
                return this.props.reject();
            case 'Confirm':
            default:
                return this.props.confirm();
        }
    }
    render() {
        const systemMsgWrapperStyles = {
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: `rgba(0,0,0,0.6)`,
            zIndex: 999,
        }
        const systemMsgImgContStyles = {
            minWidth: '600px',
            width: '50%',
        }
        if(this.props.confirmMessage === '')
        {
            return (
                <div></div>
            );
        }
        // should display messages to user
        return (
            <div style={systemMsgWrapperStyles} className={this.props.confirmMessage !== '' ? 'system-message-wrapper active' : 'system-message-wrapper'} onClick={this.close}>
                <div style={systemMsgImgContStyles} className='system-message-img-container'>
                    <div className='system-message-text-container'>
                        <h3 className='system-message-title'>{this.props.dict.confirmBox.systemMessage}</h3>
                        <p className='system-message'>{this.props.confirmMessage}</p>
                        <BtnGroup 
                            leftBtnTargetAction='No'
                            rightBtnTargetAction='Confirm'
                            handleClick={this.confirm}
                            leftBtnTitle={this.props.dict.confirmBox.no}
                            rightBtnTitle={this.props.dict.confirmBox.confirm}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export const ConfirmBox = connect(mapStateToProps, mapDispatchToProps)(UnconnectedConfirmBox);


/**
 * display a small square pop up from bottom or top to show the short feedback message when system has completed a command and need to inform user
 * usage:
    import { FeedbackBox } from './assets/systemMessage';
    <FeedbackBox
        location='bottom'
        message='entry saved'
        duration={3}
    />
 */

export class FeedbackBox extends Component {
    constructor(props)
    {
        super(props)
        this._isMounted = true
        this.state = {
            active: true
        }
        this.close = this.close.bind(this);
    }
    componentDidMount()
    {
        this._isMounted = true
        if(this._isMounted)
        {
            var self = this;
            setTimeout(function() {
                if(self._isMounted)
                {
                    self.setState({
                        active: false
                    });
                }
            }, configs.feedbackFade);
        }

    }
    componentWillUnmount()
    {
        this._isMounted = false
    }
    close() {
        this.setState({
            active: false
        });
    }
    render()
    {
        const warningStyle = {
            backgroundColor: colors.lightPink,
            color: colors.darkPink
        }
        const successStyle = {
            backgroundColor: colors.lightGreen,
            color: colors.darkGreen
        }
        if(this.state.active)
        {
            return (
                <div role="alert" aria-live="assertive" aria-atomic="true" className="toast show" data-autohide="false"  style={this.props.warning ? warningStyle:successStyle}>
                    <div className="toast-header" style={this.props.warning ? warningStyle:successStyle}>
                        <strong className="mr-auto">{this.props.message}</strong>
                        <button className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" onClick={this.close}>
                         <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <div role="alert" aria-live="assertive" aria-atomic="true" className="toast fade" data-autohide="false"  style={this.props.warning ? warningStyle:successStyle}>
                <div className="toast-header" style={this.props.warning ? warningStyle:successStyle}>
                    <strong className="mr-auto">{this.props.message}</strong>
                    <button className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" onClick={this.close}>
                     <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        );
    }
}