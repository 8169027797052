import FetchWrapper from './FetchWrapper'

const Fetch = new FetchWrapper()
/**
 * handle the error report
 * usage:
    //err format:
    const err = {
        user_id: 1,
        hash: GH.hash(20),
        code: 'FAILED_TO_ADD_USER',
        message: 'The system could not add the user.'
    }
    try
    {
        const result = await EH.addError(err)
        return result
    }
    catch(e)
    {
        return false
    }
 */
class ErrorHelper {
    constructor()
    {

    }

    // const errUpdate = await EH.addError(1, 'UNKNOWN_ERROR', 'An unknown error has happened')
    async addError(usersId = null, errCode = 'UNKNOWN_ERROR', message = '')
    {
        const toInsert = {
            usersId: usersId || null,
            errCode: errCode,
            message: message
        }
        try
        {
            const insert = await Fetch.post('/api/v2/addError', toInsert)
            .then(r => r.json())
            .then(r => {
                if(!r.success)
                {
                    throw r
                }
                return r
            })
            .catch(e => {throw e})
            // const insert = await DB.insert(tbs.errorLogs, toInsert, (e, r) => {
            //     if(e || !r.success)
            //     {
            //         throw e
            //     }
            //     return r
            // })
            return true
        }
        catch(e)
        {
            // await this.sendErrorEmail(usersId, errCode, message)
            return false
        }
    }

    async sendErrorEmail(usersId = null, errCode = 'UNKNOWN_ERROR', message = '')
    {
        // notify the developer that store error failed
        const emailDev = await fetch('/api/v2/sendEmail', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({
                email: 'wjs2004@med.cornell.edu',
                subject: 'System failed to store error',
                html: `<h1>${errCode}</h1>
                <p>The system has failed to store this error</p>
                <p>The user id is: ${usersId}</p>
                <p>Extra Message: ${message}</p>
                `
            })
        })
        return true
    }

    async sendErrorEmailToMulti(usersId = null, emailArr = [], errCode = 'UNKNOWN_ERROR', message = '')
    {
        // notify the developer that store error failed
        const emailDev = await fetch('/api/v2/sendEmail', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            body: JSON.stringify({
                email: `wjs2004@med.cornell.edu, ${emailArr.join(', ')}`,
                subject: 'System failed to store error',
                html: `<h1>${errCode}</h1>
                <p>The system has failed to store this error</p>
                <p>The user id is: ${usersId}</p>
                <p>Extra Message: ${message}</p>
                `
            })
        })
        return true
    }

}

export default new ErrorHelper()