// import DH from './DatabaseHelper'
// import DH from './DatabaseHelper'
// import GH from './GeneralHelper'
// import SH from './SurveyHelper'
// import initProps from '../config/props'
// import { tbs } from '../../init/initApp'
import EH from './ErrorHelper'
import FetchWrapper from './FetchWrapper'

// const tb = tbs.profiles
// const configtb = tbs.config
const Fetch = new FetchWrapper()

class ProfileHelper {
    constructor()
    {

    }

    async registerProfile(userId, param = {})
    {

        if(!userId) return null

        let toUpdateParam = {...param}
        toUpdateParam['users_id'] = userId
        try
        {
            const result = await Fetch.post('/user/v2/registerUserProfile', {
                usersId: userId,
                fieldData: toUpdateParam
            })
            .then(r => r.json())
            .then(r => r)
            .catch(e => {throw e})
            console.log(result)
            return result
        }
        catch(e)
        {
            const addErr = await EH.addError(userId || null, e.code, `The system failed to upsert profile for user ${userId}`)
            return e
        }
    }

    async upsertProfile(userId, param = {})
    {

        if(!userId) return null

        let toUpdateParam = {...param}
        toUpdateParam['users_id'] = userId
        try
        {
            const result = await Fetch.post('/user/v2/upsertUserProfile', {
                fieldData: toUpdateParam
            })
            .then(r => r.json())
            .then(r => r?.data)
            .catch(e => {throw e})
            return result
        }
        catch(e)
        {
            const addErr = await EH.addError(userId || null, e.code, `The system failed to upsert profile for user ${userId}`)
            return e
        }
    }

    /**
     * update the MH config
     * @param {int} userId 
     * @param {obj} param 
        usage:
        const r = await PH.upsertConfig(1, { house: 'house1' })
     */
    async upsertConfig(userId, param = {})
    {

        if(!userId) return null

        let toUpdateParam = {...param}
        toUpdateParam['users_id'] = userId

        try
        {
            const result = await Fetch.post('/mhconfig/v2/upsertConfig', {
                fieldData: toUpdateParam
            })
            .then(r => r.json())
            .then(r => {
                if(!r.success) throw r
                return r.data
            })
            .catch(e => {throw e})

            return result
        }
        catch(e)
        {
            const addErr = await EH.addError(userId || null, e.code, `The system failed to upsert LMH config for user ${userId}`)
            return e
        }
    }

    /**
     * remove a contact of a user
     * @param {int} userId
     * @param {str} contactKey
     * usage:
        let remove = await PH.removeContact(this.props.user.id, 'contact-key')
     */
    async removeContact(userId, contactKey = '')
    {
        /*
        if(!userId || !contactKey || contactKey === '') return false
        const whereObj = {
            user_id: userId,
            key: contactKey
        }
        const personTB = initProps.db.socialCompassContactPerson
        const removePerson = await DH.remove(personTB, whereObj)
        // console.log(removePerson)
        if(removePerson.success)
        {
            // continue remove the contact way
            const removeAll = await this.removeAllContactWay(userId, contactKey, 'all')
            // console.log(removeAll)
            return removeAll
        }
        return false
        // console.log(removePerson)
        // return removePerson
        */
    }

    async removeSingleContactWay(userId, contactWayId)
    {
        /*
        if(!userId || !contactWayId) return false

        const contactWayTB = initProps.db.socialCompassContactWay
        const whereObj = {
            id: contactWayId,
            user_id: userId
        }
        const remove = await DH.remove(contactWayTB, whereObj)
        if(remove.success) return true
        //TODO: add err message
        return false
        */
    }

    async removeAllContactWay(userId, contactKey, type = 'all')
    {
        /*
        if(!userId || !contactKey) return false
        const contactWayTB = initProps.db.socialCompassContactWay
        if(type === 'all')
        {
            const whereObj = {
                user_id: userId,
                contact_person_key: contactKey,
            }
            const removeAll = await DH.remove(contactWayTB, whereObj)
            // console.log(removeAll)
            if(removeAll.success)
            {
                return true
            }
            //TODO: add err message
            return false
        }
        const whereObj = {
            user_id: userId,
            contact_person_key: contactKey,
            type: type
        }
        const removeAll = await DH.remove(contactWayTB, whereObj)
        if(removeAll.success)
        {
            return true
        }
        //TODO: add err message
        return false
        */
    }


    validatePassword(pass) {
        if(pass === '' || pass === null) return false
        const myInput = pass
        let lowercase = false, uppercase = false, number = false, special = false, length = false
        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g
        if(myInput.match(lowerCaseLetters)) {
            lowercase = true            
        } else {
            lowercase = false
        }
    
        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if(myInput.match(upperCaseLetters)) {
            uppercase = true            
        } else {
            uppercase = false            
        }
    
        // Validate numbers
        var numbers = /[0-9]/g;
        if(myInput.match(numbers)) {
            number = true
        } else {
            number = false
        }
    
        var specialChar = /(?=.[!@#\$%\^&*])/;
        if(myInput.match(specialChar)) {
            special = true
        } else {
            special = false
        }
    
        // Validate length
        if(myInput.length >= 8) {
            length = true
        } else {
            length = false
        }
        return { lowercase, uppercase, number, length, special }
    
    }
}

export default new ProfileHelper()