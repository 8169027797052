import React from 'react'

const GetHelp = props => {
    
    return (<div style={{ width: '500px' }}>
        <div>Do you need help.</div>
    </div>)

}

export default GetHelp