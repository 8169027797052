import React, { Component, useEffect, useState } from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams,
	useRouteMatch
  } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { defaultHomeBaseObj, breakPoint } from '../init/initApp'
// import { capitalizeFirstLetter, hash, parseUrlQuery } from '../init/general'
import { UnconnectedNavBar } from '../assets/banner'
import { Footer } from '../assets/footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
// import { SystemLogs } from '../classes/systemConfig'
import SLG from '../classes/v2/SystemLogs'
import EH from '../classes/v2/ErrorHelper'
// import STM from '../classes/v2/SystemTimeManage'
import UH from '../classes/v2/UserHelper'
import AH from '../classes/v2/AmplifyHelper'
import PH from '../classes/v2/ProfileHelper'
// import { Database } from '../classes/db'
// import { Userdata } from '../classes/user'
import { colors } from '../init/colors'
import config from '../init/config'
import BeatLoader from "react-spinners/BeatLoader"
import PasswordFeedback from '../assets/v2/PasswordFeedback'
import './Auth.scss'

// let rambleItv, configs;
// get the config
// const STM = new SystemTimeManagement()
// const SLG = new SystemLogs()
// const SC = new SystemConfig()
const houseImgs = defaultHomeBaseObj().imgSrcs
// const DB = new Database()
// const User = new Userdata()


let Auth = (props) => {

	const [currentPanel, updateCurrentPanel] = useState("login")
	const [title, updateTitle] = useState("FINDING HOME")
	const [displayAbout, updateDisplayAboute] = useState(true)
	const [bgImgUrl, setBgImgUrl] = useState('')

	const {
		changeLang,
		match,
		history,
		dict,
		accountInfo,
		getCurrentDayLogs,
		signup,
		authUser,
		auth,
		confirmSignUp,
		registerFeedback,
		addRegisterFeedback,
		sendForgotPassCode,
		loginErr,
		addLoginErr
	} = props
	let { path, url } = useRouteMatch()
	// console.log(`component account info`, accountInfo)
	function rambleBgImg() {
		// var self = this;
		// get the image keys array
		const imgKeys = Object.keys(houseImgs);
		// set the start image (randomize the start image)
		let startImg = Math.floor(Math.random() * (imgKeys.length-1));
		// set the interval to change the image every 10 seconds
		// rambleItv = setInterval(returnBgImg, 5000);
		function returnBgImg() {
			startImg + 1 < imgKeys.length ? startImg += 1 : startImg = 0;
			const size = breakPoint(window.innerWidth);
			switch(size) {
				case "sm":
					setBgImgUrl(houseImgs[imgKeys[startImg]]["url"])
					/*
					self.setState({
						bgImgUrl: houseImgs[imgKeys[startImg]]["url"]
					});
					*/
					return;
				case "md":
					setBgImgUrl(houseImgs[imgKeys[startImg]]["url_md"])
					/*
					self.setState({
						bgImgUrl: houseImgs[imgKeys[startImg]]["url_md"]
					});
					*/
					return;
				case "lg":
				case "xl":
				default:
					setBgImgUrl(houseImgs[imgKeys[startImg]]["url_lg"])
					/*
					self.setState({
						bgImgUrl: houseImgs[imgKeys[startImg]]["url_lg"]
					});
					*/
					return;
			}
        }
        returnBgImg()
	}
	function handlePress(targetPanel) {
		const toTogglePanel = targetPanel;
		if(toTogglePanel === currentPanel)
		{
			return updateCurrentPanel('')
			/*
			return this.setState({
				currentPanel: ''
			});
			*/
		}
		return updateCurrentPanel(toTogglePanel)
		/*
		return this.setState({
			currentPanel: toTogglePanel
		});
		*/
	}

	function switchPage(next = 'login')
	{
		// console.log("switch page")
		// console.log(match.params.lang)
		// console.log(`next ${path}/${next}`)
		path = path.replace(':lang', match.params.lang)
		// console.log(`new path ${path}`)
		addLoginErr({})
		if(next === 'login')
		{
			return history.push(path)
		}
		return history.push(`${path}/${next}`)
		// history.push(path)
		// updateCurrentPanel(next)
		/*
		this.setState({
			currentPanel: next
		})
		*/
	}

	async function redirectAfterLogin()
	{
		console.log(props.accountInfo)
		console.log("time to redirect")
		// check if the user has setup the config, if not then redirect to config page
		if(props.accountInfo?.house === null || props.accountInfo?.view === null) return history.push(`/${match.params.lang}${config.paths.config}`)
		return history.push(`/${match.params.lang}${config.paths.dashboard}`)
	}
	async function checkIfLoggedIn()
	{
		const loggedIn = await AH.getSession().then(r => r).catch(e => false)
		if(!loggedIn || loggedIn === 'No current user') return // users don't need redirect
		// user has logged in, but has not set the LMH config, redirect to the config setting page
		if(props.accountInfo.house === null || props.accountInfo.view === null) return history.push(config.paths.config)
		// console.log("accnount information is missing")
		// user has logged in and completed the config setup
		return history.push(config.paths.dashboard)
	}

	useEffect(() => {
		changeLang(match.params.lang)
		rambleBgImg()
		addLoginErr({})
		// EH.sendErrorEmailToMulti(null, ['lavendershe2016@gmail.com', 'nilayya99@gmail.com'], 'FAILED_TO_INIT_USER_IN_DB', `The system failed to initiate the user's data in the database for user Lavender. Please double check with the user.`)
		/*
		Get user id
			const user = await AH.getUser().then(r => r).catch(e => e)
			const getData = await UH.getUserIdGivenUsername(AH.username).then(r => r).catch(e => e)
		*/
		// check if user has logged in and redirect
		checkIfLoggedIn()
	}, [])

	// let { path, url } = useRouteMatch()
	// console.log(match.params.lang)
	
	path = path.replace(':lang', match.params.lang)
	// console.log(path)
	const lang = dict.login
	const imgUrl = require(`../../img/house1-lg.jpg`);
	const loginWrapperStyle = {
		backgroundImage: bgImgUrl !== undefined ? `url(${bgImgUrl})` : `url(${imgUrl})`
	}
	// console.log(`current panel is ${this.state.currentPanel}`)
	return (
		<div className='row'>
			<UnconnectedNavBar {...props} isLoggedIn={false} withLogout={false} />
			<div className='col-12 d-flex login-wrapper flex-wrap'>
				<div className='col-sm-12 col-md-6 login-img-wrapper' style={loginWrapperStyle}>
					<FontAwesomeIcon className='about-icon' icon={faInfoCircle} onClick={() => {
						updateDisplayAboute(!displayAbout)
						/*
						this.setState({
							displayAbout: !displayAbout
						})
						*/
					}} />
					<div className={displayAbout ? `about-panel col-sm-12 col-md-10 p-4` : `about-panel col-sm-12 col-md-6 hide p-4`}>
						{/*<h4>ABOUT LIVING MEMORY HOME</h4>*/}
						<p className='m-0 p-0'>{lang['aboutDesc']}</p>
						<span id='lmh-version-indicator'>{config.version}</span>
						{/*<span className='about-panel-triangle'></span>*/}
					</div>
				</div>

				<div className='col-sm-12 col-md-6 p-4 d-flex justify-content-center align-items-center flex-wrap' style={{ backgroundColor: '#fff' }}>
				<Switch>
					<Route exact path={path}>
						<LoginPanel switchPage={switchPage} lang={lang} loginErr={loginErr} addLoginErr={addLoginErr} authUser={authUser} auth={auth} redirect={redirectAfterLogin} getCurrentDayLogs={getCurrentDayLogs} />
					</Route>
					<Route path={`${path}/register`}>
						<RegisterPanel switchPage={switchPage} lang={lang} signup={signup} registerErr={loginErr} feedback={registerFeedback} addRegisterFeedback={addRegisterFeedback} addLoginErr={addLoginErr} />
					</Route>
					<Route path={`${path}/confirmRegister`}>
						<ConfirmRegistration switchPage={switchPage} lang={lang.LoginRegisterPanel} addLoginErr={addLoginErr} registerErr={loginErr} auth={auth} confirmSignUp={confirmSignUp} />
					</Route>
					<Route path={`${path}/forgotPass`}>
						<ForgotPass switchPage={switchPage} lang={lang.LoginRegisterPanel} addLoginErr={addLoginErr} loginErr={loginErr} auth={auth} sendForgotPassCode={sendForgotPassCode} />
					</Route>
					<Route path={`${path}/setNewPass`}>
						<SetNewPass switchPage={switchPage} lang={lang} addLoginErr={addLoginErr} registerErr={loginErr} addFeedback={addRegisterFeedback} auth={auth} />
					</Route>
					<Route path={`${path}/feedback`}>
					<Feedback switchPage={switchPage} lang={lang} auth={auth} addLoginErr={addLoginErr} feedback={registerFeedback} />
					</Route>
				</Switch>
				</div>

				{/*<div className='col-sm-12 col-md-6 p-4 d-flex justify-content-center align-items-center flex-wrap' style={{ backgroundColor: '#fff' }}>
					{this.state.currentPanel === 'login' ? <LoginPanel switchPage={this.switchPage} lang={lang} loginErr={this.props.loginErr} addLoginErr={this.props.addLoginErr} authUser={this.props.authUser} auth={this.props.auth} redirect={this.redirectAfterLogin} getCurrentDayLogs={this.props.getCurrentDayLogs} />:null}
					{this.state.currentPanel === 'register' ? <RegisterPanel switchPage={this.switchPage} lang={lang} signup={this.props.signup} registerErr={this.props.loginErr} feedback={this.props.registerFeedback} addRegisterFeedback={this.props.addRegisterFeedback} addLoginErr={this.props.addLoginErr} />:null}
					{this.state.currentPanel === 'forgotPass' ? <ForgotPass switchPage={this.switchPage} lang={lang.LoginRegisterPanel} addLoginErr={this.props.addLoginErr} loginErr={this.props.loginErr} auth={this.props.auth} sendForgotPassCode={this.props.sendForgotPassCode} />:null}
					{this.state.currentPanel === 'confirmRegister' ? <ConfirmRegistration switchPage={this.switchPage} lang={lang.LoginRegisterPanel} addLoginErr={this.props.addLoginErr} registerErr={this.props.loginErr} auth={this.props.auth} confirmSignUp={this.props.confirmSignUp} />:null}
					{this.state.currentPanel === 'setNewPass' ? <SetNewPass switchPage={this.switchPage} lang={lang} addLoginErr={this.props.addLoginErr} registerErr={this.props.loginErr} addFeedback={this.props.addRegisterFeedback} auth={this.props.auth} />:null}
					{this.state.currentPanel === 'feedback' ? <Feedback switchPage={this.switchPage} lang={lang} auth={this.props.auth} addLoginErr={this.props.addLoginErr} feedback={this.props.registerFeedback} />:null}
				</div>*/}
			</div>
			{/*<div className="login-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-12" id="login-wrapper" style={loginWrapperStyle}>
				<div className='row'>
					<TitleWrapper
						title={this.state.title}
						handlePress={this.handlePress}
					/>
					<TogglePanel currentPanel={this.state.currentPanel} />
				</div>

			</div>*/}
			<Footer />
		</div>
	)
}

class AuthBK extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPanel: 'login', // initial value
			title: 'FINDING HOME',
			displayAbout: true
		};
		this.handlePress = this.handlePress.bind(this);
		this.rambleBgImg = this.rambleBgImg.bind(this);
		this.switchPage = this.switchPage.bind(this)
		this.redirectAfterLogin = this.redirectAfterLogin.bind(this)
	}
	rambleBgImg() {
		var self = this;
		// get the image keys array
		const imgKeys = Object.keys(houseImgs);
		// set the start image (randomize the start image)
		let startImg = Math.floor(Math.random() * (imgKeys.length-1));
		// set the interval to change the image every 10 seconds
		// rambleItv = setInterval(returnBgImg, 5000);
		function returnBgImg() {
			startImg + 1 < imgKeys.length ? startImg += 1 : startImg = 0;
			const size = breakPoint(window.innerWidth);
			switch(size) {
				case "sm":
					self.setState({
						bgImgUrl: houseImgs[imgKeys[startImg]]["url"]
					});
					return;
				case "md":
					self.setState({
						bgImgUrl: houseImgs[imgKeys[startImg]]["url_md"]
					});
					return;
				case "lg":
				case "xl":
				default:
					self.setState({
						bgImgUrl: houseImgs[imgKeys[startImg]]["url_lg"]
					});
					return;
			}
        }
        returnBgImg()
	}
	handlePress(targetPanel) {
		const toTogglePanel = targetPanel;
		if(toTogglePanel === this.state.currentPanel)
		{
			return this.setState({
				currentPanel: ''
			});
		}
		return this.setState({
			currentPanel: toTogglePanel
		});
	}

	switchPage(next = 'login')
	{
		this.props.addLoginErr({})
		this.setState({
			currentPanel: next
		})
	}

	async redirectAfterLogin()
	{
		// check if the user has setup the config, if not then redirect to config page
		if(this.props.accountInfo.house === null || this.props.accountInfo.view === null) return this.props.history.push(`/${this.props.match.params.lang}${config.paths.config}`)
		return this.props.history.push(`/${this.props.match.params.lang}${config.paths.dashboard}`)
	}

	async componentWillMount() {
		this.rambleBgImg()
		this.props.addLoginErr({})
		// EH.sendErrorEmailToMulti(null, ['lavendershe2016@gmail.com', 'nilayya99@gmail.com'], 'FAILED_TO_INIT_USER_IN_DB', `The system failed to initiate the user's data in the database for user Lavender. Please double check with the user.`)
		/*
		Get user id
			const user = await AH.getUser().then(r => r).catch(e => e)
			const getData = await UH.getUserIdGivenUsername(AH.username).then(r => r).catch(e => e)
		*/
		// check if user has logged in and redirect
		const loggedIn = await AH.getSession().then(r => r).catch(e => false)
		if(!loggedIn || loggedIn === 'No current user') return // users don't need redirect
		// user has logged in, but has not set the LMH config, redirect to the config setting page
		if(this.props.accountInfo.house === null || this.props.accountInfo.view === null) return this.props.history.push(config.paths.config)
		// user has logged in and completed the config setup
		return this.props.history.push(config.paths.dashboard)
	}
	componentDidMount() {
		this.props.changeLang(this.props.match.params.lang)
	}
	render() {
		// let { path, url } = useRouteMatch()
		// console.log(path)
        const lang = this.props.dict.login
		const imgUrl = require(`../../img/house1-lg.jpg`);
		const loginWrapperStyle = {
			backgroundImage: this.state.bgImgUrl !== undefined ? `url(${this.state.bgImgUrl})` : `url(${imgUrl})`
		}
		// console.log(`current panel is ${this.state.currentPanel}`)
		return (
			<div className='row'>
                <UnconnectedNavBar {...this.props} isLoggedIn={false} withLogout={false} />
                <div className='col-12 d-flex login-wrapper flex-wrap'>
					<div className='col-sm-12 col-md-6 login-img-wrapper' style={loginWrapperStyle}>
						<FontAwesomeIcon className='about-icon' icon={faInfoCircle} onClick={() => {
							this.setState({
								displayAbout: !this.state.displayAbout
							})
						}} />
						<div className={this.state.displayAbout ? `about-panel col-sm-12 col-md-10 p-4` : `about-panel col-sm-12 col-md-6 hide p-4`}>
							{/*<h4>ABOUT LIVING MEMORY HOME</h4>*/}
							<p className='m-0 p-0'>{lang['aboutDesc']}</p>
							<span id='lmh-version-indicator'>{config.version}</span>
							{/*<span className='about-panel-triangle'></span>*/}
						</div>
                    </div>

                    {/*<div className='col-sm-12 col-md-6 p-4 d-flex justify-content-center align-items-center flex-wrap' style={{ backgroundColor: '#fff' }}>
						{this.state.currentPanel === 'login' ? <LoginPanel switchPage={this.switchPage} lang={lang} loginErr={this.props.loginErr} addLoginErr={this.props.addLoginErr} authUser={this.props.authUser} auth={this.props.auth} redirect={this.redirectAfterLogin} getCurrentDayLogs={this.props.getCurrentDayLogs} />:null}
						{this.state.currentPanel === 'register' ? <RegisterPanel switchPage={this.switchPage} lang={lang} signup={this.props.signup} registerErr={this.props.loginErr} feedback={this.props.registerFeedback} addRegisterFeedback={this.props.addRegisterFeedback} addLoginErr={this.props.addLoginErr} />:null}
						{this.state.currentPanel === 'forgotPass' ? <ForgotPass switchPage={this.switchPage} lang={lang.LoginRegisterPanel} addLoginErr={this.props.addLoginErr} loginErr={this.props.loginErr} auth={this.props.auth} sendForgotPassCode={this.props.sendForgotPassCode} />:null}
						{this.state.currentPanel === 'confirmRegister' ? <ConfirmRegistration switchPage={this.switchPage} lang={lang.LoginRegisterPanel} addLoginErr={this.props.addLoginErr} registerErr={this.props.loginErr} auth={this.props.auth} confirmSignUp={this.props.confirmSignUp} />:null}
						{this.state.currentPanel === 'setNewPass' ? <SetNewPass switchPage={this.switchPage} lang={lang} addLoginErr={this.props.addLoginErr} registerErr={this.props.loginErr} addFeedback={this.props.addRegisterFeedback} auth={this.props.auth} />:null}
						{this.state.currentPanel === 'feedback' ? <Feedback switchPage={this.switchPage} lang={lang} auth={this.props.auth} addLoginErr={this.props.addLoginErr} feedback={this.props.registerFeedback} />:null}
                    </div>*/}
                </div>
				{/*<div className="login-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-12" id="login-wrapper" style={loginWrapperStyle}>
					<div className='row'>
						<TitleWrapper
							title={this.state.title}
							handlePress={this.handlePress}
						/>
						<TogglePanel currentPanel={this.state.currentPanel} />
					</div>

                </div>*/}
				<Footer />
			</div>
		);
	}
}


const LoginPanel = props => {
    const lang = props.lang
    const [username, updateUsername] = useState('')
    const [password, updatePassword] = useState('')
	const [loading, updateLoaindg] = useState(false)
	const [confirm, updateConfirm] = useState(false)
	// console.log(props)

    return (<div onSubmit={e => e.preventDefault()} className='col-sm-12'>
        <div className='col-sm-12 d-flex justify-content-center'><h3>{lang.logIn}</h3></div>
        <form className='col-sm-12 mt-4'>
            <div className="form-group">
                <label htmlFor="username">{lang.LoginRegisterPanel.username}</label>
                <input required type="email" value={username} onChange={e => updateUsername(e.target.value)} className="form-control" id="username" aria-describedby="emailHelp" placeholder={lang.LoginRegisterPanel.usernamePlaceholder} />
            </div>
            <div className="form-group">
                <label htmlFor="inputPassword">{lang.LoginRegisterPanel.password}</label>
                <input required type="password" value={password} onChange={e => updatePassword(e.target.value)} className="form-control" id="inputPassword" placeholder={lang.LoginRegisterPanel.passwordPlaceholder} />
            </div>
            <div>
                <p>{`${lang.LoginRegisterPanel.forgotPassword}\n`} <a className='lmh-href' href='#' onClick={() => props.switchPage('forgotPass')}>{lang.LoginRegisterPanel.clickHereReset}</a></p>
			</div>
            <div className='w-100 d-flex justify-content-between flex-column'>
                <p>{`${lang.LoginRegisterPanel.noAccount}\n`} <a className='lmh-href' href='#' onClick={() => props.switchPage('register')}>{lang.LoginRegisterPanel.register}</a></p>

				{loading ? 
					<>
					Loggin you in
					<BeatLoader
						// css={override}
						css={`size: 15;`}
						size={15}
						color={colors.lightYellow}
						loading={loading}
					/>
					</>
					:
					<button type="submit" className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
						if(!username || !password) return props.addLoginErr({
							code: 'NoUsernameOrPassword',
							message: lang.LoginRegisterPanel.doubleCheckUP
						})
							// console.log('ready to auth')
						const authResult = await props.authUser({
							username, password
						})
						updateLoaindg(true)
						
						if(authResult.hasOwnProperty('code'))
						{
							// insert error into the database
							// console.log(authResult)
							const addErr = await EH.addError(userId || null, props.loginErr.code, `The system can't process the login request of user ${username}`)
							switch(authResult.code)
							{
								case "UserNotConfirmedException":
									// display confirm user option
									// console.log('need to confirm user first')
									// updateConfirm(true)
									props.addLoginErr({})
									props.switchPage('confirmRegister')
								break
							}
							// const addErr = await SLG.recordErrGivenUsername(null, props.loginErr.code).then(r => r).catch(e => e)
							updateLoaindg(false)
							return
						}
						// updateLoaindg(false)
						props.addLoginErr({})

						// store login record
						const storeLogin = await SLG.recordLogGivenUsername(authResult, true).then(r => r).catch(e => e)
						console.log("store login", storeLogin)
						// initiate a new day
						const user = await AH.getUser().then(r => r).catch(e => e)
						// console.log(user)
						const userId = await UH.getUserIdGivenUsername(AH.username).then(r => r).catch(e => e)
						// const initNewDay = await STM.setNewDay(userId, r => r)
						const getDayLogs = await props.getCurrentDayLogs(userId)
						console.log("day logs",getDayLogs)

						if(!storeLogin.hasOwnProperty('id'))
						{
							// failed to store the login result, still can redirect, but file an error report to the system
							const addErr = await EH.addError(userId || null, 'FAILED_TO_STORE_LOGIN', `The system can't store login record for ${AH.username}`)
							// const addErr = await SLG.recordErrGivenUsername(AH.username || null, 'FAILED_TO_STORE_LOGIN').then(r => r).catch(e => e)
						}
						console.log("??? should redirect no?")
						// if(!initNewDay)
						// {
						// 	// Cannot initiate a new day, still can redirect, but add an error
						// 	const addErr = await EH.addError(userId || null, 'FAILED_TO_INIT_NEWDAY', `The system can't initiate a new day for ${AH.username}`)
						// }
						props.redirect()

				}}>{lang.logIn}</button>}

			</div>
			{props.loginErr.hasOwnProperty('message') ? <div className='w-100 mt-4 alert alert-danger'><p>{props.loginErr.message}</p></div> : null}

		</form>

		<div className="spinner-border text-secondary" role="status">
		<span className="sr-only">{lang.LoginRegisterPanel.loading}</span>
		</div>
		{confirm ? <div className='col-sm-12'><p><a className='lmh-href' href='#' onClick={() => {
			props.addLoginErr({})
			props.switchPage('confirmRegister')
		}}>{lang.LoginRegisterPanel.verifyMyEmail}</a></p></div>:null}
    </div>)
}

// const validatePassword = (pass) => {
// 	if(pass === '' || pass === null) return false
// 	const myInput = pass
// 	let lowercase = false, uppercase = false, number = false, special = false, length = false
// 	// Validate lowercase letters
// 	var lowerCaseLetters = /[a-z]/g
// 	if(myInput.match(lowerCaseLetters)) {
// 		lowercase = true
// 	} else {
// 		lowercase = false
// 	}

// 	// Validate capital letters
// 	var upperCaseLetters = /[A-Z]/g;
// 	if(myInput.match(upperCaseLetters)) {
// 		uppercase = true            
// 	} else {
// 		uppercase = false            
// 	}

// 	// Validate numbers
// 	var numbers = /[0-9]/g;
// 	if(myInput.match(numbers)) {
// 		number = true
// 	} else {
// 		number = false
// 	}

// 	var specialChar = /(?=.[!@#\$%\^&*])/;
// 	if(myInput.match(specialChar)) {
// 		special = true
// 	} else {
// 		special = false
// 	}

// 	// Validate length
// 	if(myInput.length >= 8) {
// 		length = true
// 	} else {
// 		length = false
// 	}
// 	return { lowercase, uppercase, number, length, special }

// }

const RegisterPanel = props => {
    const lang = props.lang
    const [username, updateUsername] = useState('')
	const [password, updatePassword] = useState('')
	const [confirmPassword, updateConfirmPassword] = useState('')
	const [firstName, updateFirstName] = useState('')
	const [deceasedName, updateDeceasedName] = useState('')
	const [redcapId, updateRedcapId] = useState('')
	const [loading, updateLoading] = useState(false)
	
	const [validateResult, updateValidateResult] = useState({})
	console.log(props)
	return (<div className='col-sm-12'>
		<div className='col-sm-12 d-flex justify-content-center'><h3>{lang.register}</h3></div>
		<div><p>{lang.LoginRegisterPanel.star}</p></div>
        <form onSubmit={e => e.preventDefault()} className='col-sm-12 mt-4'>
            <div required className="form-group">
                <label htmlFor="username">{`${lang.LoginRegisterPanel.username}*`}</label>
                <input required type="email" value={username} onChange={e => updateUsername(e.target.value)} className="form-control" id="username" aria-describedby="emailHelp" placeholder={lang.LoginRegisterPanel.usernamePlaceholder} />
            </div>
            <div className="form-group" >
                <label htmlFor="inputPassword">{`${lang.LoginRegisterPanel.password}*`}</label>
                <input required value={password}onChange={e => {
					let v = PH.validatePassword(e.target.value)
					updateValidateResult(v)
					updatePassword(e.target.value)
				}} type="password" className="form-control" id="inputPassword" placeholder={lang.LoginRegisterPanel.passwordPlaceholder} />
				<PasswordFeedback {...validateResult} />
            </div>
            <div className="form-group">
                <label htmlFor="confirmPassword">{`${lang.LoginRegisterPanel.confirmPassword}*`}</label>
                <input required type="password" value={confirmPassword} onChange={e => updateConfirmPassword(e.target.value)} className="form-control" id="confirmPassword" placeholder={lang.LoginRegisterPanel.confirmPasswordPlaceholder} />
			</div>
			<div className="form-group">
				<label htmlFor="firstName">{lang.LoginRegisterPanel.firstName}</label>
				<input type="text" value={firstName} onChange={e => updateFirstName(e.target.value)} className="form-control" id="firstName" placeholder={lang.LoginRegisterPanel.firstNamePlaceholder} />
			</div>
            <div className="form-group">
                <label htmlFor="deceasedName">{`${lang.LoginRegisterPanel.deceasedName}*`}</label>
                <input required type="text" value={deceasedName} onChange={e => updateDeceasedName(e.target.value)} className="form-control" id="deceasedName" placeholder={lang.LoginRegisterPanel.deceasedNamePlaceholder} />
            </div>
			<div className="form-group">
				<label htmlFor="deceasedName">{`${lang.LoginRegisterPanel.redcap}`}</label>
				<input type="text" value={redcapId} onChange={e => updateRedcapId(e.target.value)} className="form-control" id="redcapId" placeholder={lang.LoginRegisterPanel.enterRedcap} />
			</div>
            <div className='w-100 d-flex justify-content-between flex-column'>
				<p>{`${lang.LoginRegisterPanel.alreadyMember}\n`} <a className='lmh-href' href='#' onClick={() => props.switchPage('login')}>{lang.LoginRegisterPanel.clickHereLogin}</a></p>
	
				{loading ? 
					<>
					<BeatLoader
						css={`size: 15;`}
						size={15}
						color={colors.lightYellow}
						loading={loading}
					/>
					</>
					:
					<button type="submit" className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
						e.preventDefault()
						updateLoading(true)
						if(!username || !password || !confirmPassword || !deceasedName)
						{
							props.addLoginErr({
								code: 'MissingRequiredData',
								message: lang.LoginRegisterPanel.fillIn
							})
							return updateLoading(false)
						}

						if(!validateResult.lowercase || !validateResult.uppercase || !validateResult.number || !validateResult.length || !validateResult.special)
						{
							props.addLoginErr({
								code: 'PasswordNotValid',
								message: lang.LoginRegisterPanel.passwordNotValid
							})
							return updateLoading(false)
						}

						if(password !== confirmPassword)
						{
							props.addLoginErr({
								code: 'PasswordNotConfirmed',
								message: lang.LoginRegisterPanel.passwordNotConfirmed
							})
							return updateLoading(false)
						}
						let fieldData = {}
						fieldData['redcap_id'] = redcapId
						// allow to register
						const r = await props.signup(username, password, deceasedName, firstName, fieldData)
						console.log(r)
						if(!r)
						{
							return updateLoading(false)
						}
						updateLoading(false)
						props.switchPage('confirmRegister')
						// console.log(r)
	
					}}>{lang.register}</button>}

            </div>
		</form>
		{props?.registerErr?.hasOwnProperty('message') ? <div className='w-100 mt-4 alert alert-danger'><p>{props?.registerErr?.message}</p></div> : null}
    </div>)
}

const ConfirmRegistration = props => {
	// console.log(props)
	const lang = props.lang
	const [code, updateCode] = useState('')
	const [loading, updateLoading] = useState(false)
	const [codeResent, updateCodeResent] = useState(false)
	const [count, updateCount] = useState(0)
	
	return (<div className='col-sm-12'>
		<div className='col-sm-12 d-flex flex-wrap justify-content-center'>
			<h3>{lang.verifyMyEmail}</h3>
			<p>{lang.confirmationCodeSentToMailbox}</p>
		</div>
		<form onSubmit={e => e.preventDefault()} className='col-sm-12 mt-4'>
			<div required className="form-group">
				<label htmlFor="validCode">{lang.confirmationCode}</label>
				<input required type="text" value={code} onChange={e => updateCode(e.target.value)} className="form-control" id="validCode" placeholder={lang.yourConfirmationCode} />
			</div>
			<div className='w-100 d-flex justify-content-between flex-column'>
			{!codeResent ? 
				<><p>{`${lang.noConfirmationCode}\n`}<a className='lmh-href' href='#' onClick={async () => {

					const r = await AH.resendVerificationCode(props.auth.username)

					if(!r.hasOwnProperty('code'))
					{
						updateCodeResent(true)
						let c = 14
						updateCount(c)
						setTimeout(() => updateCodeResent(false), 15000)
						const timerItv = setInterval(() => {
							if(c > 0)
							{
								c -= 1
								return updateCount(c)
							}
							return clearInterval(timerItv)
						}, 1000)
					}
					return props.addLoginErr({
						code: r.code,
						message: r.message
					})
				}}>{lang.resendCode}</a></p>
				</>
				:
				null
			}
			{count > 0 ?
				<p>{lang.confirmCodeRequested.replace('[count]', count)}</p>
				:
				null
			}

				{loading ? 
					<>
					<BeatLoader
						css={`size: 15;`}
						size={15}
						color={colors.lightYellow}
						loading={loading}
					/>
					</>
					:
					<button type="submit" className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
						e.preventDefault()
						updateLoading(true)
						// console.log('submit result to confirm email')
						if(!code)
						{
							props.addLoginErr({
								code: 'MissingRequiredData',
								message: lang.ConfirmRegistration.enterConfirmationCode
							})
							return updateLoading(false)
						}

						try
						{
							// console.log(`validate user using code: ${code}`)
							const c = await props.confirmSignUp(props.auth.username, code)
							// console.log(c)
							if(!c.hasOwnProperty('code'))
							{
								updateLoading(false)
								return props.switchPage('login')
							}
							throw c
						}
						catch(e)
						{
							updateLoading(false)
							// console.log(e)
						}

					}}>{lang.confirmEmail}</button>}

			</div>
		</form>
		{props.registerErr.hasOwnProperty('message') ? <div className='w-100 mt-4 alert alert-danger'><p>{props.registerErr.message}</p></div> : null}
		<div className='col-sm-12'><p><a className='lmh-href' href='#' onClick={() => {
			props.addLoginErr({})
			props.switchPage('login')
		}}>{lang.returnToLogin}</a></p></div>
	</div>)
}

// const PasswordFeedback = props => {
// 	if(Object.keys(props).length < 4) return null
// 	if(props.lowercase && props.uppercase && props.number && props.special && props.length) return null
// 	return <React.Fragment>
// 		<p>Your password needs to contain:</p>
// 		<span className={`pass-validation ${props.lowercase ? "valid": "invalide"}`}>A <b>lowercase</b> letter</span><br/>
// 		<span className={`pass-validation ${props.uppercase ? "valid": "invalide"}`}>A <b>capital (uppercase)</b> letter</span><br/>
// 		<span className={`pass-validation ${props.number ? "valid": "invalide"}`}>A <b>number</b></span><br/>
// 		<span className={`pass-validation ${props.special ? "valid": "invalide"}`}>A <b>special</b> character (such as: !@#\$%\^&*)</span><br/>
// 		<span className={`pass-validation ${props.length ? "valid": "invalide"}`}>Minimum <b>8 characters</b></span>
// 	</React.Fragment>
// }

const ForgotPass = props => {
	// console.log(props)
	const lang = props.lang
	const [email, updateEmail] = useState('')
	const [loading, updateLoading] = useState(false)
	
	return (<div className='col-sm-12'>
		<div className='col-sm-12 d-flex flex-wrap justify-content-center'>
			<h3>{lang.forgotPassword}</h3>
			<p>{lang.enterEmailToValidateIdentity}</p>
		</div>
		<form onSubmit={e => e.preventDefault()} className='col-sm-12 mt-4'>
			<div required className="form-group">
				<label htmlFor="validCode">{lang.usernamePlaceholder}</label>
				<input required type="email" value={email} onChange={e => updateEmail(e.target.value)} className="form-control" id="validCode" placeholder={lang.username} />
			</div>
			<div className='w-100 d-flex justify-content-between flex-column'>
				{/*<p>{`${lang.gotVerficationCode}\n`}<a className='lmh-href' href='#' onClick={async () => {
					props.switchPage('/setNewPass')
				}}>{lang.resetMyPassword}</a></p>*/}
				{loading ? 
					<>
					<BeatLoader
						css={`size: 15;`}
						size={15}
						color={colors.lightYellow}
						loading={loading}
					/>
					</>
					:
					<button type="submit" className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
						e.preventDefault()
						updateLoading(true)
						if(!email)
						{
							props.addLoginErr({
								code: 'MissingRequiredData',
								message: lang.ForgotPass.missingRequired
							})
							return updateLoading(false)
						}

						try
						{
							props.sendForgotPassCode(email)
							const c = await AH.forgotPassword(email)
							// console.log(c)
							if(!c.hasOwnProperty('code') && !c.hasOwnProperty('message'))
							{
								updateLoading(false)
								return props.switchPage('setNewPass')
							}
							throw c
						}
						catch(e)
						{
							updateLoading(false)
							switch(e.code)
							{
								case "UserNotFoundException":
									return props.addLoginErr({
										code: e.code,
										message: lang.ForgotPass.notFound
									})
								default:
									return props.addLoginErr(e)
								
							}
							// console.log(e)
						}

					}}>{lang.requestVerificationCode}</button>}

			</div>
		</form>
		{props.loginErr.hasOwnProperty('message') ? <div className='w-100 mt-4 alert alert-danger'><p>{props.loginErr.message}</p></div> : null}
		<div className='col-sm-12'><p><a className='lmh-href' href='#' onClick={() => {
			props.addLoginErr({})
			props.switchPage('login')
		}}>{lang.returnToLogin}</a></p></div>
	</div>)
}


const SetNewPass = props => {
	const lang = props.lang
	const [code, updateCode] = useState('')
    const [password, updatePassword] = useState('')
    const [confirmPassword, updateConfirmPassword] = useState('')
	const [loading, updateLoading] = useState(false)
	const [codeResent, updateCodeResent] = useState(false)
	const [count, updateCount] = useState(0)
	
	const [validateResult, updateValidateResult] = useState({})
	// console.log(props)
	return (<div className='col-sm-12'>
		<div className='col-sm-12 d-flex flex-wrap justify-content-center'>
			<h3>{lang.LoginRegisterPanel.resetMyPassword}</h3>
			<p>{lang.LoginRegisterPanel.enterVerificationCode}</p>
		</div>
		<form onSubmit={e => e.preventDefault()} className='col-sm-12 mt-4'>
			<div required className="form-group">
				<label htmlFor="validCode">{lang.LoginRegisterPanel.verificationCode}</label>
				<input required type="text" value={code} onChange={e => updateCode(e.target.value)} className="form-control" id="validCode" placeholder={lang.LoginRegisterPanel.verificationCode} />
			</div>
            <div className="form-group" >
                <label htmlFor="inputPassword">{lang.LoginRegisterPanel.newPassword}</label>
                <input required value={password}onChange={e => {
					let v = PH.validatePassword(e.target.value)
					updateValidateResult(v)
					updatePassword(e.target.value)
				}} type="password" className="form-control" id="inputPassword" placeholder={lang.LoginRegisterPanel.newPassword} />
				<PasswordFeedback {...validateResult} />
            </div>
            <div className="form-group">
                <label htmlFor="confirmPassword">{lang.LoginRegisterPanel.confirmPassword}</label>
                <input required type="password" value={confirmPassword} onChange={e => updateConfirmPassword(e.target.value)} className="form-control" id="confirmPassword" placeholder={lang.LoginRegisterPanel.confirmPasswordPlaceholder} />
            </div>
            <div className='w-100 d-flex justify-content-between flex-column'>

				{!codeResent ? 
					<><p>{`${lang.LoginRegisterPanel.noConfirmationCode}\n`} <a className='lmh-href' href='#' onClick={async () => {
						const r = await AH.forgotPassword(props.auth.email)
	
						if(!r.hasOwnProperty('code'))
						{
							updateCodeResent(true)
							let c = 14
							updateCount(c)
							setTimeout(() => updateCodeResent(false), 15000)
							const timerItv = setInterval(() => {
								if(c > 0)
								{
									c -= 1
									return updateCount(c)
								}
								return clearInterval(timerItv)
							}, 1000)
						}
						return props.addLoginErr({
							code: r.code,
							message: r.message
						})
					}}>{lang.LoginRegisterPanel.requestVerificationCode}</a></p>
					</>
					:
					null
				}
				{count > 0 ?
					<p>{lang.LoginRegisterPanel.confirmCodeRequested.replace('[count]', count)}</p>
					:
					null
				}
				{loading ? 
					<>
					<BeatLoader
						css={`size: 15;`}
						size={15}
						color={colors.lightYellow}
						loading={loading}
					/>
					</>
					:
					<button type="submit" className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
						e.preventDefault()

						updateLoading(true)
						if(!code || !password || !confirmPassword)
						{
							updateLoading(false)
							props.addLoginErr({
								code: 'MissingRequiredData',
								message: lang.SetNewPass.missingData
							})
							return 
						}

						if(!validateResult.lowercase || !validateResult.uppercase || !validateResult.number || !validateResult.length || !validateResult.special)
						{
							props.addLoginErr({
								code: 'PasswordNotValid',
								message: lang.SetNewPass.passwordValid
							})
							return updateLoading(false)
						}

						if(password !== confirmPassword)
						{
							updateLoading(false)
							props.addLoginErr({
								code: 'PasswordNotConfirmed',
								message: lang.SetNewPass.passNotConfirmed
							})
							return 
						}

						const r = await AH.resetPassword(props.auth.email, code, password)
						// console.log(r)
						if(!r)
						{
							updateLoading(false)
							props.addLoginErr({
								code: 'WrongResetPassCode',
								message: lang.SetNewPass.wrongReset
							})
							return 
						}
						else if(r.hasOwnProperty('code'))
						{
							updateLoading(false)
							props.addLoginErr(r)
							return 
						}
						updateLoading(false)
						props.addFeedback(lang.LoginRegisterPanel.successfullyResetPassword)
						props.switchPage('feedback')
						return
					}}>{lang.LoginRegisterPanel.resetMyPassword}</button>}

            </div>
		</form>
		{props.registerErr.hasOwnProperty('message') ? <div className='w-100 mt-4 alert alert-danger'><p>{props.registerErr.message}</p></div> : null}
		<div className='col-sm-12'><p><a className='lmh-href' href='#' onClick={() => {
			props.addLoginErr({})
			props.switchPage('login')
		}}>{lang.LoginRegisterPanel.returnToLogin}</a></p></div>
    </div>)
}

const Feedback = props => {
	const lang = props.lang
	return (<div className='col-sm-12'>
		<div className='col-sm-12 d-flex flex-wrap justify-content-center'>
			<h3 className='w-100 text-center'>{lang.LoginRegisterPanel.systemMsg}</h3>
			<p className='w-100'>{props.feedback}</p>
		</div>
		<div className='col-sm-12'><p><a className='lmh-href' href='#' onClick={() => {
			props.addLoginErr({})
			props.switchPage('login')
		}}>{lang.LoginRegisterPanel.returnToLogin}</a></p></div>
	</div>)
}

export default Auth