import React from 'react'
import Menu from './AdminBoard/Menu'
import DisplayData from './AdminBoard/DisplayData'
import JournalEntryData from './AdminBoard/JournalEntryData'
import UserStats from './AdminBoard/UserStats'
// import SendEmail from './AdminBoard/SendEmail'
import NavBar from './NavBar'
import SurveyHelper from '../classes/v2/SurveyHelper'
import UserHelper from '../classes/v2/UserHelper'
import config from '../init/config'
import { Footer } from '../assets/footer'
/**
 * Download all of the data when component first loaded, and display them separately
 * Set a cursor to download 500 entries at the beginning to save loading time
 */

class AdminBoard extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            initSurvey: 'DEMO',
            displayTable: 'prescreening'
        }
        this.switchSurvey=this.switchSurvey.bind(this)
    }

    async componentDidMount()
    {
        // console.log("somehow mounted")
        // console.log(this._toggleBtn)
        const self = this

        // Fetch the questionnaire entries all in once
        const PG13Data = await SurveyHelper.fetchSurveyEntries(config.surveys.PG13.dbKey, 0)
        // console.log(PG13Data)
        //get the data to be displayed
        if(PG13Data)
        {
            this.setState({
                PG13Data: PG13Data
            })
        }

        const DEMOData = await SurveyHelper.fetchSurveyEntries(config.surveys.DEMO.dbKey, 0)

        // console.log(DEMOData)
        if(DEMOData)
        {
            this.setState({
                DEMOData: DEMOData
            })
        }

        const CSSRSData = await SurveyHelper.fetchSurveyEntries(config.surveys.CSSRS.dbKey, 0)
        // console.log(CSSRSData)
        if(CSSRSData)
        {
            this.setState({
                CSSRSData: CSSRSData
            })
        }

        const CDDData = await SurveyHelper.fetchSurveyEntries(config.surveys.CDD.dbKey, 0)
        // console.log(CDDData)
        if(CDDData)
        {
            this.setState({
                CDDData:CDDData
            })
        }

        // get the journal entry data for all redcap participants
        const redcapIds = await UserHelper.getRedcapIds()
        // console.log(redcapIds)
        if(redcapIds)
        {
            this.setState({
                redcapIds:redcapIds
            })
        }
        // this._toggleBtn.addEventListener('click', e => {
        //     const isOpen = self._canvas.classList.contains('open')
        //     if(!isOpen)
        //     {
        //         return self._canvas.classList.add('open')
        //     }
        //     return self._canvas.classList.remove('open')
        // })
    }

    switchSurvey(targetSurvey = 'PG13')
    {
        return this.setState({
            initSurvey: targetSurvey
        })
    }

    render()
    {
        // console.log(this.props)
        // console.log(`the init survey is ${this.state.initSurvey}`)
        const { initSurvey, redcapIds } = this.state
        const initSurveyData = `${initSurvey}Data`
        const dataArr = this.state.hasOwnProperty(initSurveyData) ?  this.state[initSurveyData] : []
        // console.log(dataArr)
        return (<React.Fragment>

        <NavBar {...this.props} isLoggedIn={this.props.loggedIn} withLogout={this.props.loggedIn} />
        
        <section>
            <div className="container py-3">
            <div className='row text-center d-flex justify-content-center'>
                <h3 className='white-font text-center py-3'>Administrator Board</h3>
            </div>
            <div className='row text-center d-flex justify-content-center'>
                <p className='white-font text-center py-3'>Select below to view the questionnaire entries</p>
                <div className='col-sm-12 mb-5'>
                    <button onClick={() => this.props.loadAdminBoardPage('prescreening')} className={this.props.adminboardPage === 'prescreening' ? 'lmh-primary-btn lmh-admin-btn selected': 'lmh-admin-btn unselected'}>Prescreening</button>
                    <button onClick={() => this.props.loadAdminBoardPage('journal')} className={this.props.adminboardPage === 'journal' ? 'lmh-primary-btn lmh-admin-btn ml-2 selected':'lmh-admin-btn ml-2 unselected'}>Journal Entry</button>
                    <button onClick={() => this.props.loadAdminBoardPage('userstats')} className={this.props.adminboardPage === 'userstats' ? 'lmh-primary-btn lmh-admin-btn ml-2 selected':'lmh-admin-btn ml-2 unselected'}>User Stats</button>
                    {/*<button onClick={() => this.props.loadAdminBoardPage('sendemail')} className={this.props.adminboardPage === 'sendemail' ? 'lmh-primary-btn lmh-admin-btn ml-2 selected':'lmh-admin-btn ml-2 unselected'}>Send Email</button>*/}
                </div>
            </div>
            {this.props.adminboardPage === 'prescreening' ? <DisplayData data={dataArr} switchSurvey={this.switchSurvey} targetSurvey={initSurvey} />:null}
            {this.props.adminboardPage === 'journal' ? <JournalEntryData redcapIds={redcapIds} lang={this.props.lang} />:null}
            {this.props.adminboardPage === 'userstats' ? <UserStats {...this.props} />:null}
            {/*this.props.adminboardPage === 'sendemail' ? <SendEmail {...this.props} />:null*/}
            {/*<ReactTabulator columns={columns} data={data} options={} />*/}
            </div>
        </section>
        <Footer />
        </React.Fragment>)
    }
}

export default AdminBoard