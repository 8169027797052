import React, { Component } from 'react';
import { Footer } from '../assets/footer'
import NavBar from '../containers/NavBar'
import AH from '../classes/v2/AmplifyHelper'

// import { connect } from 'react-redux';
// import { mapStateToProps, mapDispatchToProps} from './lang/langRedux';

class Resources extends Component {
    constructor(props)
    {
        super(props);
        // console.log(this.props.dict);
        this.state = {
            loggedIn: false
        }
    }
    async componentDidMount() {
        this.props.changeLang(this.props.match.params.lang);
        var self = this;
        await this.props.changeLang(this.props.match.params.lang)
        
        // check if user is logged in
        let loggedIn = await AH.getSession().then(r => r).catch(e => false)
        this.setState({
            loggedIn: loggedIn
        })
    }

    render(){

        let onlineResourcesList = [], hotlineResourcesList = [];
        this.props.dict.resources.online.forEach(resource => {
            onlineResourcesList.push(
                <li className='mh-paragraph-dark'><a href={resource.url}>{`${resource.title}`}</a>: {`${resource.value}`}</li>
            )
        });
        this.props.dict.resources.hotline.forEach(resource => {
            hotlineResourcesList.push(
                <li className='mh-paragraph-dark'>{`${resource.title}: ${resource.value}`}</li>
            )
        });
        return(
            <div className='container-fluid'>
            <NavBar {...this.props} isLoggedIn={this.state.loggedIn} withLogout={this.state.loggedIn} />
                <div className='container alert alert-light'>
                    <div className='row py-5'>
                        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center'><h3 className='mh-title-dark'>{this.props.dict.resources.buildingAndBenefiting}</h3></div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 text-center mx-auto'><p className='mh-paragraph-dark'>{this.props.dict.resources.indGriefResources}</p></div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto'>
                            <h4 className='mh-subtitle-dark'>{this.props.dict.resources.onlineGriefResources}</h4>
                            <ul>
                                {onlineResourcesList}
                            </ul>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 mx-auto'>
                            <h4 className='mh-subtitle-dark'>{this.props.dict.resources.hotlines}</h4>
                            <ul>
                                {hotlineResourcesList}
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Resources
// export default connect(mapStateToProps, mapDispatchToProps)(Resources);