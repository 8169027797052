import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Survey from '../ui/Survey'
import { changeLang, enableResetConfig } from '../../actions/config'
import { upsertConfig } from '../../actions/index'
import { getCurrentDayLogs } from '../../actions/days'
import { defaultLang } from '../lang/init'

const mapStateToProps = (state) =>
    ({
        auth: state.userReducer.auth,
        dict: state.configReducer.lang.dict,
        lang: state.configReducer.lang.lang,
        accountInfo: state.userReducer.accountInfo,
        resetConfig: state.configReducer.resetConfig,
        currentDay: state.daysReducer.currentDay
    })

const mapDispatchToProps = (dispatch) => 
    ({
        changeLang: (lang = defaultLang) => dispatch(changeLang(lang)),
        upsertConfig: (userId, param) => dispatch(upsertConfig(userId, param)),
        enableResetConfig: reset => dispatch(enableResetConfig(reset)),
        getCurrentDayLogs: userId => dispatch(getCurrentDayLogs(userId))
    })


const Container = connect(mapStateToProps, mapDispatchToProps)(Survey)
export default withRouter(Container)
