import React, { useState } from 'react'
import NavBar from '../containers/NavBar'
import { Footer } from '../assets/footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { houses, views } from '../init/initApp'
import SystemMessage from '../assets/v2/SystemMessage'
import ConfirmBox from '../assets/v2/ConfirmBox'
import GetImg from '../assets/GetImg'
import config from '../init/config'

import AH from '../classes/v2/AmplifyHelper'

import '../../scss/house.scss'
import '../../scss/house-query.scss'

class SelectConfig extends React.Component {
    
    constructor(props)
    {
        super(props)
        this.state = {
            confirmMessage: '',
            messageKeys: '',
            currentApp: 'Homebase',
            slideData: houses,
            loggedIn: false
        }
        this.initSystemMsg = this.initSystemMsg.bind(this)
        this.confirmSelect = this.confirmSelect.bind(this)
        this.confirm = this.confirm.bind(this)
        this.navigateUser = this.navigateUser.bind(this)
    }

    navigateUser()
    {
        const self = this
        console.log(this.props.accountInfo)
        // if user has not setup the house, prompt user to setup the house
        if(this.props.accountInfo.house === null)
        {
            console.log("NO HOUSE")
            this.setState({
                slideData: houses
            })
            // console.log(`reset config is ${this.props.resetConfig}`)
            if(this.props.resetConfig)
            {
                this.initSystemMsg([{
                    message: 'reset-house',
                    callback: e => {
                        // self.props.enableResetConfig(false)
                        self.setState({
                            messageKeys: []
                        })
                    }
                }])
                return
            }
            this.initSystemMsg(['welcome-msg', 'mh-intro-one', 'mh-intro-two', 'select-homebase', {
                message: 'select-homebase-tutorial',
                callback: e => {
                    self.setState({
                        messageKeys: []
                    })
                }
            }])
            return
        }
        // if user has not setup the view, prompt user to setup the view
        if(this.props.accountInfo.view === null)
        {
            console.log("NO VIEW")
            this.setState({
                slideData: views
            })
            if(this.props.resetConfig)
            {
                this.initSystemMsg([{
                    message: 'reset-view',
                    callback: e => {
                        // self.props.enableResetConfig(false)
                        self.setState({
                            messageKeys: []
                        })
                    }
                }])
                return
            }
            this.initSystemMsg(['homebase-selected', {
                message: 'select-view',
                callback: e => {
                    self.setState({
                        messageKeys: []
                    })
                }
            }])
            return
        }
        console.log("YOU GO")
        // if user has setup the house and view, redirect user
        return this.props.history.push(`/${this.props.match.params.lang}${config.paths.dashboard}`)
    }

    async componentWillMount()
    {
        await this.props.changeLang(this.props.match.params.lang)
        // console.log("config select page here")
        // const r = await this.props.upsertConfig(this.props.auth.id, { house: null, view: null })
        // get the session, if there is no session, return to the homepage
        try
        {
            const session = await AH.getSession().then(s => s).catch(e => {throw e})
            if(session === 'No current user')
            {
                // use is not logged in, prompt user to go back to login page
                this.props.history.push(config.paths.auth)
                return
            }
            this.setState({
                loggedIn: true
            })
            this.navigateUser()
            // console.log(session)
        }
        catch(e)
        {
            
        }
    }

    // initiate system message, take an array input for multiple messages
    initSystemMsg(arr = [])
    {
        if(arr.length < 1)
        {
            return this.setState({
                messageKeys: []
            });
        }
        return this.setState({
            messageKeys: arr
        })
    }
    
    confirmSelect(data)
    {
        if(data.key.includes('house'))
        {
            let confirmMsg = this.props.dict.house.confirmSelect.replace('[select]', data.title)
            this.setState({
                // confirmTargetApp: targetApp,
                confirmData: data,
                confirmMessage: confirmMsg.replace('[select]', data.title),
            })
        }
        if(data.key.includes('view'))
        {
            let confirmMsg = this.props.dict.house.confirmSelectView.replace('[select]', data.title)
            this.setState({
                // confirmTargetApp: targetApp,
                confirmData: data,
                confirmMessage: confirmMsg.replace('[select]', data.title),
            })
        }
    }
    async confirm(c = true)
    {
        let self = this

        if(c)
        {
            // this.switchApp(this.state.confirmTargetApp, this.state.confirmData);
            if(this.state.confirmData.key.includes('house'))
            {
                const r = await this.props.upsertConfig(this.props.auth.id, { house: this.state.confirmData.key })

                if(this.props.resetConfig && this.props.accountInfo.view !== null)
                {
                    this.setState({
                        confirmData: null,
                        confirmMessage: '',
                        // slideData: views
                    })
                    this.initSystemMsg([{
                        message: 'reset-house-complete',
                        callback: e => {
                            self.props.enableResetConfig(false)
                            self.setState({
                                messageKeys: []
                            })
                            if(self.props.accountInfo.view !== null)
                            {
                                self.props.history.push(config.paths.dashboard)
                            }
                        }
                    }])
                    return
                }
                this.setState({
                    confirmData: null,
                    confirmMessage: '',
                    slideData: views
                })
                this.initSystemMsg(['homebase-selected', {
                    message: 'select-view',
                    callback: e => { 
                        self.setState({
                            messageKeys: []
                        })

                        if(self.props.accountInfo.view !== null)
                        {
                            self.props.history.push(config.paths.dashboard)
                        }

                    }
                }])
                return
            }
            if(this.state.confirmData.key.includes('view'))
            {

                const r = await this.props.upsertConfig(this.props.auth.id, { view: this.state.confirmData.key })
                this.setState({
                    confirmData: null,
                    confirmMessage: '',
                    // slideData: views
                })
                if(this.props.resetConfig)
                {
                    this.initSystemMsg([{
                        message: 'reset-view-complete',
                        callback: e => {
                            self.props.enableResetConfig(false)
                            self.setState({
                                messageKeys: []
                            })
                            if(self.props.accountInfo.view !== null)
                            {
                                self.props.history.push(config.paths.dashboard)
                            }
                        }
                    }])
                    return
                }
                // this.initSystemMsg(['homebase-selected', 'select-view'])
                this.initSystemMsg([{
                    message: 'view-selected',
                    callback: e => {
                        self.props.history.push(config.paths.dashboard)
                    }
                }])
                return
            }
        }
        return this.setState({
            confirmMessage: ''
        });
    }

    render()
    {
        
        let confirmMsg = <ConfirmBox
            confirmMessage={this.state.confirmMessage}
            reject={() => this.confirm(false)}
            confirm={() => this.confirm(true)}
        />
        return (<div>
            <NavBar {...this.props} isLoggedIn={this.state.loggedIn} withLogout={this.state.loggedIn} hideItems />

            <SlideShow items={this.state.slideData || houses} dict={this.props.dict.init.initApp} onSelect={this.confirmSelect} />
            {/*<div className="login-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-12" id="login-wrapper" style={loginWrapperStyle}>
                <div className='row'>
                    <TitleWrapper
                        title={this.state.title}
                        handlePress={this.handlePress}
                    />
                    <TogglePanel currentPanel={this.state.currentPanel} />
                </div>
            </div>*/}
            {this.state.confirmMessage ? this.state.confirmMessage !== '' ? confirmMsg : null : null}
            {this.state.messageKeys.length > 0 ?
                <SystemMessage
                    // messageGroupsKey={['general', 'homebase']}
                    messageKeys={this.state.messageKeys}
                    messageObj={this.props.dict['systemMessages']}
                    ctnStyle={{backgroundColor: 'transparent'}}
                /> : null}
            <Footer />
        </div>)
    }
}

/**
 * 
    <SlideShow 
        items={houses} 
        dict={this.props.dict.init.initApp}
        onSelect={}
    />
 */
const SlideShow = props => {
    const [active, updateActive] = useState(0)
    const [extended, updateExtended] = useState(true)
    const lang = props.dict

    let items = [], navs = []
    props.items.forEach((item, idx) => {
        let newItem = {...item}
        newItem.title = lang[item.key]['name']
        newItem.desc = lang[item.key]['disc']
        items.push(<div key={`carousel-img-${idx}`} className={active === idx ? "carousel-item active" : "carousel-item"}>
            <GetImg
                local // when the image is locally hosted
                className={["d-block h-100 m-auto carousel-img"]}
                onClick={() => props.onSelect(newItem)}
                imageUrl={newItem.src} // "name-of-the-img.jpg"
                srcset={newItem.srcset}
                alt={newItem.title}
            />
            <div className={extended ? 'p-4 config-descrition-box' : 'p-4 config-descrition-box closed'} onClick={e => updateExtended(!extended)} >
                <h3>{`${newItem.title}`}<FontAwesomeIcon icon={extended ? faSortUp : faCaretDown} className='float-right cursor-pointer config-toggle-icon' style={{ width: '30px', height: '30px' }} /></h3>
                <p>{newItem.desc}</p>
            </div>
            {/*<GetImg className="d-block w-100" src={require('../../img/house1-md.jpg')} alt={''} />*/}
            <div className="carousel-caption d-md-block">
                <button className='btn btn-lg btn-primary lmh-dark-btn' onClick={() => props.onSelect(newItem)}>Select</button>
            </div>
        </div>)
        navs.push(<li key={`carousel-nav-${idx}`} className={active === idx ? "active" : null} data-target="#carouselSlideShow" data-slide-to={idx}></li>)
    })

    return (<div id="carouselSlideShow" className="carousel lmh-carousel mx-auto slide" data-interval="false">
    <ol className="carousel-indicators">
        {navs}
        {/*<li data-target="#carouselSlideShow" data-slide-to="0" className="active"></li>
        <li data-target="#carouselSlideShow" data-slide-to="1"></li>
        <li data-target="#carouselSlideShow" data-slide-to="2"></li>*/}
    </ol>
    <div className="carousel-inner">
        {items}
        {/*<div className="carousel-item active">
            <img className="d-block w-100" src={require('../../img/house1-md.jpg')} alt="First slide" />
        </div>
        <div className="carousel-item">
            <img className="d-block w-100" src={require('../../img/house1-md.jpg')} alt="Second slide" />
        </div>
        <div className="carousel-item">
            <img className="d-block w-100" src={require('../../img/house1-md.jpg')} alt="Third slide" />
            </div>*/}
    </div>
    <a className="carousel-control-prev" href="#carouselSlideShow" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carouselSlideShow" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
    </a>
    </div>)
}
    
export default SelectConfig