import en from './en.json'

const questionPrompts = (lang = 'en') => {
    switch(lang)
    {
        case 'en':
        default:
        return en
    }
}

export default questionPrompts