import methods from '../methods'
import { langPack, defaultLang } from '../components/lang/init'

const changeLang = (lang = defaultLang) => ({
    type: methods.LANG,
    payload: lang
})


const getPublicUrl = state => ({
    type: methods.PUBLIC_URL,
    payload: state
})

const addSystemFeedback = feedback => dispatch => {
    if(feedback === '')
    {
        return dispatch({
            type: methods.ADD_SYSTEM_FEEDBACK,
            payload: feedback
        })
    }
    dispatch({
        type: methods.ADD_SYSTEM_FEEDBACK,
        payload: feedback
    })

    return setTimeout(() => {

        dispatch({
            type: methods.ADD_SYSTEM_FEEDBACK,
            payload: ''
        })
    }, 4000)
}

const initConfirmMessage = msgObj => ({
    type: methods.INIT_CONFIRM,
    payload: msgObj
})

const storePayloadBeforeConfirm = payload => ({
    type: methods.STORE_PAYLOAD_BEFORE_CONFIRM,
    payload: payload
})


/**
 * To add/delete/load the instructions to user
 * @param {str} type 
 * @param {obj} obj 
    let instructionArr = []
    const instructionObj = {
        key: 'first',
        content: 'Please test the instruction screen',
        imgSrc: `backpack-icon-green-lg.png`
    }
    instructionArr.push(instructionObj)
    this.props.initInstructions('load', instructionArr)
    this.props.initInstructions('add', instructionObj)
    this.props.initInstructions('delete', instructionObj)
 */
const initInstructions = (type = 'load', obj) => dispatch => {
    switch(type)
    {
        case 'add':
            return dispatch({
                type: methods.ADD_INSTRUCTION,
                payload: obj
            })
        case 'delete':
            console.log(obj)
            return dispatch({
                type: methods.REMOVE_INSTRUCTION,
                payload: obj
            })
        case 'load':
        default:
            return dispatch({
                type: methods.LOAD_INSTRUCTION,
                payload: obj
            })
    }
}

const enableResetConfig = reset => ({
    type: methods.ENABLE_RESET_CONFIG,
    payload: reset
})

const loadAdminBoardPage = page => ({
    type: methods.LOAD_ADMINBOARD_PAGE,
    payload: page
})

const updateDataDisplayPreference = (simpleDisplay = true) => ({
    type: methods.UPDATE_DISPLAY_PREFERENCE,
    payload: simpleDisplay
})

export { 
    changeLang, 
    getPublicUrl, 
    addSystemFeedback, 
    initConfirmMessage, 
    storePayloadBeforeConfirm, 
    initInstructions, 
    enableResetConfig, 
    loadAdminBoardPage,
    updateDataDisplayPreference
}