import React from 'react'
import BeatLoader from "react-spinners/BeatLoader"
import { colors } from '../../init/colors'

const LoadingBlock = props => {
    if(props.loading)
    {
        // console.log('display')
        return (
        <div style={{ width: '100vw', height: '100vh', opacity: '0.6', position: 'absolute', zIndex: 999, backgroundColor: colors.darkGreen }} className='d-flex justify-content-center align-items-center flex-column'>
        <h2 className='white-title' style={{ color: colors.white }}>{props.msg}</h2>
            <BeatLoader
                // css={override}
                css={`size: 15;`}
                size={15}
                color={colors.lightYellow}
                loading={props.loading}
            />
        </div>)
    }
    return null
}

export default LoadingBlock