import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import store from './store';
import {Provider} from 'react-redux';
import configureStore from './configStore'
import config from './components/init/config'

const { store } = configureStore()
// remove all the console logs
if(config.currentEnv === 'prod')
{
    console.log = () => {}

}

store.subscribe(() => {
    const state = JSON.stringify(store.getState())
    localStorage.setItem('redux-store', state)
  })

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider> , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
