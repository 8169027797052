import React from 'react'
import GetImg from '../GetImg'
import { colors } from '../../init/colors'

/**
 * create a Dropdown selection
 * @param {*} props 
    usage:
    const optionsArr = [
        {
            href:"#",
            title:"Option 1",
            handleOptionClick: () => console.log('option 1 clicked')
        },
        {

        }
    ]
    <DropDown
        id='your-drop' // *
        title='select a value' // *
        options={optionsArr}  // *
    />
 */
const DropDown = props => {
    let options = []
    props.options.forEach((option, idx) => {
        options.push(<div key={`drop-down-item-container-${props.id}-${idx}`} className='dropdown-item py-3 text-break' style={{ whiteSpace: 'normal', wordWrap: 'break-word', cursor: 'pointer' }} onClick={option.handleOptionClick}>
            {option.img !== null && option.img !== undefined ?
                <GetImg
                    local // when the image is locally hosted
                    // onClick={null}
                    className={['mr-2']}
                    imageUrl={option.img.src} // "name-of-the-img.jpg"
                    srcset={{
                        sm: option.img.src,
                        md: option.img.src,
                        lg: option.img.src,
                        xl: option.img.src
                    }}
                    alt={option.img.alt}
                    style={{
                        width: '30px',
                        height: '30px',
                        backgroundColor: colors.darkGreen,
                        borderRadius: '5px'
                    }}
                />
                :null
            }

        {`${option.title}`}
        </div>)
    })
    return (<div className={`dropdown ${props.className ? props.className : ''}`} style={props.style || {}}>
    <button className="btn btn-secondary dropdown-toggle w-100" type="button" id={props.id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {props.title}
    </button>
    <div className="dropdown-menu w-100" aria-labelledby={props.id}>
        {options}
    {/*
        <a className="dropdown-item" href="#">Action</a>
        <a className="dropdown-item" href="#">Another action</a>
        <a className="dropdown-item" href="#">Something else here</a>
    */}
    </div>
  </div>)
}

export default DropDown