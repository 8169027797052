import React, { useState, useEffect, useCallback } from 'react'
import momentTimezone from 'moment-timezone'

import SH from '../classes/v2/SurveyHelper'
import EH from '../classes/v2/EmailHelper'
import GH from '../classes/v2/GeneralHelper'
// import DH from '../classes/v2/DatabaseHelper'
// import LH from '../classes/v2/LocalStorage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons'
import GetImg from '../assets/GetImg'
import '../../scss/survey.scss'
import config from '../init/config'
import { colors } from '../init/colors'
import BeatLoader from "react-spinners/BeatLoader"
import EmailHelper from '../classes/v2/EmailHelper'
import EligibleAnswers from './Survey/EligibleAnswers'
import LowRiskAnswers from './Survey/LowRiskAnswers'
import IneligibleAnswers from './Survey/IneligibleAnswers'
const publicIp = require('public-ip')

// let q = SH.getQuestionnaire('PG13')
// console.log(q)
const env = config.currentEnv
// const emailTeam = ['wjs2004@med.cornell.edu', 'mar2851@med.cornell.edu', 'paul.maciejewski@gmail.com', 'hgp2001@med.cornell.edu', 'hmd2002@med.cornell.edu']
// const emailAdmin = ['wjs2004@med.cornell.edu', 'lavendershe2016@gmail.com']
const surveysArr = ['DEMO', 'CDD', 'PG13', 'CSSRS']
const endOfSurveyKey = 'CSSRS-ineligible'

/**
 * RULES for suicidal screening
If someone says ‘yes’ to the question ‘Have you ever wished 
you were dead or wished you could go to sleep and not wake 
up?’ (this could be over a year ago, within the past 12 
months, and/or within the past 30 days) AND they say 
‘no’ to the question ‘Are you currently under the care 
of a mental health professional? (e.g. counselor, 
psychologist, psychiatrist, social worker)?’ 
then they can be deemed potentially eligible for the study 
and I will screen them further.

This will not apply to any other level of risk that we ask 
about in the pre-screening survey. For example, if someone 
says ‘yes’ to the question ‘Have you ever actually had any 
thoughts of killing yourself?’ AND they say ‘no’ to the 
question ‘Are you currently under the care of a mental health 
professional? (e.g. counselor, psychologist, psychiatrist, 
social worker)?’ then they are NOT eligible.
 */


const Survey = (props) => {
    // console.log("TEST SURVEY LOADING")
    // console.log(props.inComplete)
    let renderArr = []
    const [loading, setLoading] = useState(false)
    const [feedback, updateFeedback] = useState('')
    const [firstName, updateFirstName] = useState('')
    const [email, updateEmail] = useState('')
    const [phone, updatePhone] = useState('')
    const [submit, updateSubmit] = useState(false)
    const [currentSurvey, updateCurrentSurvey] = useState(0)
    const [answers, updateStoredAnswers] = useState({})
    const [breadcrumb, updateBreadcrumb] = useState([])
    const [submitHash, updateSubmitHash] = useState(GH.hash(12))
    const [submitFeedback, updateSubmitFeedback] = useState('')
    const [failedToSendEmail, updateFailedToSendEmail] = useState(false)
    const [ip, updateIp] = useState('')
    const [showEmergencyInfo, updateEmergencyInfo] = useState(false)
    const [incompleteArr, updateIncompleteArr] = useState([])
    const [consented, updateConsented] = useState(false)
    const [riskLevel, updateRiskLevel] = useState('[No contact data]')
    const [suicidal, updateSuicidal] = useState(false)
    const [potentialSuicidal, updatePotentialSuicidal] = useState(false)

    const targetSurvey =  surveysArr[currentSurvey]

    const submitForm = e => {
        e.preventDefault()
        // console.log('submit form called')
    }

    const consent = () => {
        if(env === 'dev') // can load the test dataset to save time
        {
            // console.log("It's the dev environment")
            updateStoredAnswers(LowRiskAnswers)
            // updateStoredAnswers(EligibleAnswers)
            // updateStoredAnswers(IneligibleAnswers)
        }
        updateConsented(true)
    }
    
    const resetTheForm = (noConfirm = false) => {
        if(noConfirm)
        {
            // return
            updateStoredAnswers({})
            localStorage.removeItem(config.storageKeys.survey)
            updateCurrentSurvey(0)
            return
        }
        // console.log(`survey is called`)
        const r = window.confirm('Are you sure you want to reset the questionnaire? Your data will not be saved.')
        if (r == true) {
            updateStoredAnswers({})
            localStorage.removeItem(config.storageKeys.survey)
            updateCurrentSurvey(0)
            return
        } else {
            return null
        }
    }

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const handleInput = async (e) => {
        console.log("input changed")
        // console.log(`target survey is ${targetSurvey}`)
        // console.log('store the answer')
        // console.log(e.target)
        // const aid = e.target.id
        const qid = e.target.name
        const v = e.target.value
        
        let targetObj = await answers.hasOwnProperty(targetSurvey) && Object.keys(answers[targetSurvey]).length > 0 ? answers[targetSurvey] : {}
        const optionObj = await SH.getOptionObjGivenValue(targetSurvey, qid, v)
        // console.log(optionObj)
        const answerStr = optionObj.display
        // const qObj = await SH.getQuestionGivenQid(targetSurvey, qid)
        // console.log(qObj)
        targetObj[qid] = {
            answer: answerStr || v,
            value: v
        }
        if(optionObj.hasOwnProperty('next'))
        {
            if(typeof optionObj['next'] === 'string') // it's indicating a single next item
            {
                const nextId = optionObj['next']
                targetObj[nextId] = {}
            }
            else
            {
                // the type of object is an array
                for(const i of optionObj['next'])
                {
                    const nextId = i
                    targetObj[nextId] = {}
                }
            }
        }
        let newAnswers = {...answers}
        newAnswers[targetSurvey] = targetObj
        // console.log(newAnswers)
        updateStoredAnswers(newAnswers)

        localStorage.setItem(config.storageKeys.survey, JSON.stringify(answers))
        handleSubmit(false, false)
        // console.log(answers)
        // const answerStr = SH.getAnswerGivenValue('PG13', qid, v)
        // answers[qid] = {
        //     answer: v
        // }
        // console.log(answers)
        // props.answers[item.id]['answer']
    }

    const handleClear = async (qid) => {
        // console.log('handle clear called ', qid)
        let targetObj = await answers.hasOwnProperty(targetSurvey) && Object.keys(answers[targetSurvey]).length > 0 ? answers[targetSurvey] : {}
        // console.log(targetObj)
        if(targetObj.hasOwnProperty(qid))
        {
            targetObj[qid] = ''
            let newAnswers = {...answers}
            newAnswers[targetSurvey] = targetObj

            updateStoredAnswers(newAnswers)

            localStorage.setItem(config.storageKeys.survey, JSON.stringify(answers))
            return
        }
    }

    const handleSubmit = async (complete = false, store = false) => {
        console.log("handle submit called")
        let ineligible = false, meetPG13 = false, higherRisk = false
        let PG13feedback = ``
        console.log(`email is ${email}`)
        console.log(`ip: ${ip}, hash: ${submitHash}`)
        // check eligibility based on Demo data
        if(answers.hasOwnProperty(surveysArr[0]))
        {
            let storeData = {}
            let DemoData = answers[surveysArr[0]]
            Object.keys(DemoData).map((k, idx) => {

                const d = DemoData[k]

                if(k === 'DEMO-02' && parseInt(d['value'],10) !== 3 && parseInt(d['value'],10) !== 7 && parseInt(d['value'],10) !== 8 && parseInt(d['value'],10) !== 9) higherRisk = true
                console.log(`higher risk true due to DEMO ${higherRisk}`)
                if(k === 'DEMO-06' && parseInt(d['value'],10) !== 1) ineligible = true
                if(k === 'DEMO-07' && parseInt(d['value'],10) !== 1) ineligible = true
                if(k === 'DEMO-08' && parseInt(d['value'],10) !== 1) ineligible = true
                if(k === 'DEMO-09' && parseInt(d['value'],10) !== 1) ineligible = true
                // if(k === 'DEMO-10' && parseInt(d['value'],10) !== 1) ineligible = true
                if(k === 'DEMO-12' && parseInt(d['value'],10) !== 1) ineligible = true
                if(k === 'DEMO-14' && parseInt(d['value'],10) !== 1) ineligible = true
                if(k === 'DEMO-18' && parseInt(d['value'],10) !== 2) ineligible = true
                if(complete)
                {
                    storeData[k] = JSON.stringify(d)
                }
            })
            if(complete && store)
            {
                console.log("store demo")
                storeData['ip_address'] = ip
                storeData['submission_hash'] = submitHash
                storeData['email'] = email
                storeData['phone'] = phone
                storeData['first_name'] = firstName
                storeData['eligibility'] = riskLevel+` tz: ${momentTimezone.tz.guess(true)}`
                // console.log(storeData)

                try
                {

                    const saveDemo = await SH.upsertSurvey(surveysArr[0], storeData, { submission_hash: submitHash })
                    console.log(saveDemo)
                }
                catch(e)
                {
                    const reportErr = EmailHelper.sendEmail('wjs2004@med.cornell.edu', `Failed to store ${surveysArr[0]} for ${email}`, e)
                    console.log(e)
                }
            }
        }

        // check eligibility based on CDD data and save data
        if(answers.hasOwnProperty(surveysArr[1]))
        {
            let storeData = {}
            const CDDData = answers[surveysArr[1]]
            Object.keys(CDDData).map((k, idx) => {
                const d = CDDData[k]
                if(complete)
                {
                    storeData[k] = JSON.stringify(d)
                }
            })
            if(complete && store)
            {
                console.log("store pg13")
                storeData['ip_address'] = ip
                storeData['submission_hash'] = submitHash
                storeData['email'] = email
                storeData['phone'] = phone
                storeData['first_name'] = firstName
                // console.log(storeData)

                try
                {
                    const saveDemo = await SH.upsertSurvey(surveysArr[1], storeData, { submission_hash: submitHash })
                    console.log(saveDemo)
                }
                catch(e)
                {
                    const reportErr = EmailHelper.sendEmail('wjs2004@med.cornell.edu', `Failed to store ${surveysArr[1]} for ${email}`, e)
                    console.log(e)
                }
            }
        }
        
        // check PG-13 score
        if(answers.hasOwnProperty(surveysArr[2]))
        {
            let storeData = {}
            const PG13 = answers[surveysArr[2]]
            Object.keys(PG13).map((k, idx) => {
                const d = PG13[k]
                if(complete)
                {
                    storeData[k] = JSON.stringify(d)
                }
            })
            const getPG13Result = await SH.PG13Analysis(PG13, false)
            PG13feedback = getPG13Result.feedback
            // console.log(getPG13Result)
            higherRisk = getPG13Result.total >= 30 ? true:higherRisk
            // console.log(`high risk ${higherRisk} after PG13`)
            // console.log(getPG13Result)
            if(complete && store)
            {
                storeData['ip_address'] = ip
                storeData['submission_hash'] = submitHash
                storeData['score'] = getPG13Result.total
                storeData['meet'] = getPG13Result.meet
                storeData['email'] = email
                storeData['phone'] = phone
                storeData['first_name'] = firstName
                // console.log(storeData)

                try
                {
                    const saveDemo = await SH.upsertSurvey(surveysArr[2], storeData, { submission_hash: submitHash })
                    console.log(saveDemo)
                }
                catch(e)
                {
                    const reportErr = EmailHelper.sendEmail('wjs2004@med.cornell.edu', `Failed to store ${surveysArr[2]} for ${email}`, e)
                    console.log(e)
                }
            }
        }
        
        // Check CSSRS result
        if(answers.hasOwnProperty(surveysArr[3]))
        {
            // console.log("check CSSRS")
            let storeData = {}
            const CSSRS = answers[surveysArr[3]]
            const CSSRSEligibility = await SH.CSSRSAnalyze(CSSRS)
            console.log(CSSRSEligibility)
            if(CSSRSEligibility.ineligible) { 
                ineligible = true
                updateSuicidal(true)
            }
            if(CSSRSEligibility.higherRisk) {
                higherRisk = true
                // updateSuicidal(true)
            }
            if(CSSRSEligibility?.potentialSuicidal) {
                higherRisk = true
                updatePotentialSuicidal(true)
            }
            if(CSSRSEligibility?.suicidal) {
                higherRisk = true
                updateSuicidal(true)
            }
            Object.keys(CSSRS).map((k, idx) => {
                const d = CSSRS[k]
                if(k === 'CSSRS-01b' && parseInt(d['value'], 10) === 1)
                {
                    ineligible = true
                    updateSuicidal(true)
                }
                if(k === 'CSSRS-01c' && parseInt(d['value'], 10) === 1)
                {
                    ineligible = true
                    updateSuicidal(true)
                }
                if(k === 'CSSRS-02b' && parseInt(d['value'], 10) === 1)
                {
                    ineligible = true
                    updateSuicidal(true)
                }
                if(k === 'CSSRS-02c' && parseInt(d['value'], 10) === 1)
                {
                    ineligible = true
                    updateSuicidal(true)
                }
                if(k === 'CSSRS-03a' && parseInt(d['value'], 10) === 1)
                {
                    ineligible = true
                    updateSuicidal(true)
                }
                if(k === 'CSSRS-01b' && parseInt(d['value'], 10) === 1)
                {
                    ineligible = true
                    updateSuicidal(true)
                }
                if(suicidal && k === 'CSSRS-04' && parseInt(d['value'], 10) === 2)
                {
                    ineligible = true
                }
                if(suicidal && k === 'CSSRS-05' && parseInt(d['value'], 10) === 2)
                {
                    ineligible = true
                }
                if(suicidal && k === 'CSSRS-06' && parseInt(d['value'], 10) === 2)
                {
                    ineligible = true
                }
                console.log(`the suicidal is updated as ${suicidal} higher risk ${higherRisk}`)
                if(complete && k !== endOfSurveyKey)
                {
                    storeData[k] = JSON.stringify(d)
                }
            })

            if(complete && store)
            {
                storeData['ip_address'] = ip
                storeData['submission_hash'] = submitHash
                storeData['email'] = email
                storeData['phone'] = phone
                storeData['first_name'] = firstName
                // console.log(storeData)
                try
                {
                    const saveDemo = await SH.upsertSurvey(surveysArr[3], storeData, { submission_hash: submitHash })
                    console.log(saveDemo)
                }
                catch(e)
                {
                    const reportErr = EmailHelper.sendEmail('wjs2004@med.cornell.edu', `Failed to store ${surveysArr[3]} for ${email}`, e)
                    console.log(e)
                }
            }
        }
        console.log("move to submitted page")
        console.log(`the high risk is ${higherRisk} and ineligible is ${ineligible}`)
        // set the page to submitted
        // console.log(`ineligible ${ineligible} higher risk ${higherRisk} meet PG-13 ${meetPG13}`)
        if(complete)
        {
            // console.log(answers)
            if(ineligible)
            {
                const ineligibleFeedback = `
                Thank you for completing the survey. <br/>
                Below are results from your responses to the Grief Intensity Scale:
                ${PG13feedback}<br/>
                Unfortunately, you are not eligible for this study. <br/>
                `
                updateEmergencyInfo(true)
                updateFeedback(ineligibleFeedback)

                // prepare for the email
                let feedbackHtml = `
                <div>
                    <p>Feedback for the answer submitted by ${email || 'ineligible user'}</p>
                    ${feedback}
                    <p>Below is the survey answer submitted by ${email || 'ineligible user'}</p>
                </div>
                `
                Object.keys(answers).map(k => {
                    const targetSurvey = answers[k]
                    const surveyTitle = SH.getQuestionnaire(k)['title']
                    feedbackHtml += `
                        <div>
                            <h3>${surveyTitle}</h3>
                        </div>
                    `
                    Object.keys(targetSurvey).map(sk => {
                        const ans = targetSurvey[sk]
                        const question = SH.getQuestionGivenQid(k, sk)['title']
                        // console.log(question)
                        const answer = typeof ans === 'string' ? JSON.parse(ans)['answer']:ans['answer']
                        feedbackHtml += `<div>
                        <p>${question}: </p>
                        <p>${answer}</p>
                        </div>`
                    })
                })
                try
                {
                    // const r = await EH.sendEmail(config.emailList[env], `Living Memory Home prescreening result from anonymous ineligible user`, feedbackHtml)
                }
                catch(e)
                {
                    // console.log(e)
                }
                // console.log('should reset the form')
                resetTheForm(true)
                return
            }
            // eligible but check higher risk
            if(higherRisk)
            {
                updateRiskLevel('[Minimum Risk]')
                
                if(suicidal || potentialSuicidal)
                {
                    updateRiskLevel('[At Risk]')
                    const higherRiskFeedback = `
                    Thank you for completing the survey. <br/>
                    Below are results from your responses to the Grief Intensity Scale:
                    ${PG13feedback}<br/>
                    Based on your answers, we will need to review your answers to determine your eligibility. Please provide your contact information below. We will do our best to ​be in touch with you ​within 2 ​business days.<br/>
                    `
                    updateFeedback(higherRiskFeedback)
                    return
                }
                updateRiskLevel('[Minimum Risk]')
                const higherRiskFeedback = `
                Thank you for completing the survey. <br/>
                Below are results from your responses to the Grief Intensity Scale:
                ${PG13feedback}<br/>
                Based on your answers, you are potentially eligible for our study. Please provide your contact information below. We will do our best to ​be in touch with you ​within 2 ​business days.<br/>
                `
                updateFeedback(higherRiskFeedback)
                return
            }
            updateRiskLevel('[Minimum Risk]')
            const eligibleFeedback = `
            Thank you for completing the survey. <br/>
            Below are results from your responses to the Grief Intensity Scale:
            ${PG13feedback}<br/>
            Based on your answers, you are potentially eligible for our study. Please provide your contact information below. We will do our best to ​be in touch with you ​within 2 ​business days.<br/>
            `
            updateFeedback(eligibleFeedback)
            return
        }

    }

    const checkCurrentSurveyComplete = () => {
        const surveyId = surveysArr[currentSurvey]
        const survey = SH.getQuestionnaire(surveyId)['questions']
        if(answers.hasOwnProperty(surveyId))
        {
            const complete = SH.checkComplete(survey, answers[surveyId])
            // console.log(complete)
            updateIncompleteArr(complete)
            return complete
        }
        const complete = SH.checkComplete(survey, null)
        updateIncompleteArr(complete)
        // console.log(complete)
        return complete
    }

    // console.log(`current survey is ${currentSurvey} and target survey is ${targetSurvey}`)

    const q = SH.getQuestionnaire(targetSurvey)
    // console.log('received survey')
    // console.log(q)
    // console.log(`target survey is ${targetSurvey}`)
    q.questions.forEach((item, idx) => {
        // console.log(item.id)
        // console.log(props.inComplete.includes(item.id))
        // console.log(`the item id is ${item.id} and target survey is ${targetSurvey}`)
        // console.log(answers.hasOwnProperty(targetSurvey))
        let hideItem = item.hide
        if(hideItem && answers.hasOwnProperty(targetSurvey) && answers[targetSurvey].hasOwnProperty(item.id))
        {
            hideItem = false
        }
        // console.log(`should hide item ${hideItem}`)
        switch(item.type)
        {
            case 'input':
                return renderArr.push(<div key={`${item.id}-${idx}`} className={incompleteArr.includes(item.id) ? "form-group incomplete-warning":"form-group"} id={`${item.id}-wrapper`} style={{ display: hideItem ? 'none': 'block' }}>
                    <label className="col-md-12 control-label" htmlFor={item.id}>{item.required ? `* ${item.title}`:item.title}</label>
                    <div className="col-md-12">
                        <InputItem survey={targetSurvey} qid={item.id} item={item} onChange={handleInput} answers={answers} required={item.hasOwnProperty('required') ? item.required : false} />
                    </div>
                </div>)
            case 'inputWRef':
                return renderArr.push(<div key={`${item.id}-${idx}`} className={incompleteArr.includes(item.id) ? "form-group incomplete-warning":"form-group"} id={`${item.id}-wrapper`} style={{ display: hideItem ? 'none': 'block' }}>
                    <label className="col-md-12 control-label" htmlFor={item.id}>{item.required ? `* ${item.title}`:item.title}</label>
                    <div className="col-md-12">
                        <InputWOption survey={targetSurvey} qid={item.id} items={item.options} qObj={item} onChange={handleInput} onClear={handleClear} answers={answers} required={item.hasOwnProperty('required') ? item.required : false} />
                        {/*<InputItem survey={targetSurvey} qid={item.id} item={item} onChange={handleInput} answers={answers} />
                        <RadioGroup survey={targetSurvey} qid={item.id} items={item.options} onChange={handleInput} answers={answers} />*/}
                    </div>
                </div>)
            case 'title':
                return renderArr.push(<div key={`${item.id}-${idx}`} className="form-group" id={`${item.id}-wrapper`} style={{ display: hideItem ? 'none': 'block' }}>
                    <p>{item.title}</p>
                    {/*<label className="col-md-12 control-label" htmlFor="radios">{item.title}</label>
                    <div className="col-md-12">
                        <RadioInlineGroup qid={item.id} items={item.options} onChange={handleInput} />
                    </div>*/}
                </div>)
            case 'radio-inline':
                return renderArr.push(<div key={`${item.id}-${idx}`} className={incompleteArr.includes(item.id) ? "form-group incomplete-warning":"form-group"} id={`${item.id}-wrapper`} style={{ display: hideItem ? 'none': 'block' }}>
                    <label className="col-md-12 control-label" htmlFor={item.id}>{item.required ? `* ${item.title}`:item.title}</label>
                    <div className="col-md-12">
                        <RadioInlineGroup survey={targetSurvey} qid={item.id} items={item.options} onChange={handleInput} answers={answers} required={item.hasOwnProperty('required') ? item.required : false} />
                    </div>
                </div>)
            case 'radio':
                return renderArr.push(<div key={`${item.id}-${idx}`} className={incompleteArr.includes(item.id) ? "form-group incomplete-warning":"form-group"} id={`${item.id}-wrapper`} style={{ display: hideItem ? 'none': 'block' }}>
                    <label className="col-md-12 control-label" htmlFor={item.id}>{item.required ? `* ${item.title}`:item.title}</label>
                    <div className="col-md-12">
                        <RadioGroup survey={targetSurvey} qid={item.id} items={item.options} onChange={handleInput} answers={answers} required={item.hasOwnProperty('required') ? item.required : false} suicidal={suicidal} potentialSuicidal={potentialSuicidal} />
                    </div>
                </div>)
        }
    })

    const getIP = async() => {
        const userIp = await publicIp.v4()
        updateIp(userIp)
    }

    const retrieveStoredAnswer = () => {
        // console.log('retrieve called')
        // const removeStoredAnswers = localStorage.removeItem(config.storageKeys.survey)
        const storedAnswers = JSON.parse(localStorage.getItem(config.storageKeys.survey))
        // console.log(storedAnswers)
        if(storedAnswers === null || !storedAnswers)
        {
            const initStorage = localStorage.setItem(config.storageKeys.survey, JSON.stringify(answers))
            return
        }
        let newAnswers = storedAnswers
        updateStoredAnswers(newAnswers)
        return
    }

    const getBreadcrumb = () => {
        let arr = []
        surveysArr.forEach((id, idx) => {
            const s = SH.getQuestionnaire(id)
            arr.push({
                id: id,
                title: s.title,
                handleClick: () => updateCurrentSurvey(idx)
            })
        })
        updateBreadcrumb(arr)
    }

    /*
    const testSendEmail = async () => {
        console.log("test send email called")
        const test = await EH.sendEmail(config.emailList[env], `Living Memory Home email function test`, `<h1>It works!</h1>`)
    }
    */

    useEffect(() => {
        retrieveStoredAnswer()
        // getBreadcrumb()
        getIP()
        // testSendEmail()
    }, [])
    // console.log(answers)
    // console.log('checkpoint 1')

    // users agree to participate in the survey
    if(!consented)
    {
        return (    <div className="w-100 survey-body">
        <div className='d-flex justify-content-center pt-5'>
            <GetImg         
                local // when the image is locally hosted
                // className={}
                // onClick={null}
                imageUrl={require('../../img/MH_logo_watercolor_bg-sm.png')} // "name-of-the-img.jpg"
                srcset={{
                    sm: require('../../img/MH_logo_watercolor_bg-sm.png'),
                    md: require('../../img/MH_logo_watercolor_bg-md.png'),
                    lg: require('../../img/MH_logo_watercolor_bg-lg.png'),
                    xl: require('../../img/MH_logo_watercolor_bg-xl.png')
                }}
                alt={'LMH logo'}
            />
        </div>

        <div className='text-center my-5 survey-title'>
            <h1>Living Memory Home study prescreening survey</h1>
            <p>This survey will help the research team better assess your eligibility.</p>
        </div>

        <div className='container survey-container mb-5 py-5' style={{ position: 'relative' }}>
            <Consent consent={consent} />
        </div>

        </div>)
    }
    // users are eligible amd t he email has been delivered
    if(submit && !failedToSendEmail)
    {
        // console.log('form submitted')
        return (<div className="w-100">

        <div className='text-center my-5 survey-title'>
            <h1>Living Memory Home prescreening survey</h1>
        </div>

        <div className='container survey-container p-5 m-auto'>
        <div className='col-xs-12 col-sm-8 ml-0'>
        <p>Thank you for submitting your prescreening result. The research team will reach out to you in 2 days. If you did not receive a further notification, please do not hesitation to contact us. wjs2004@med.cornell.edu</p>
        
        </div>
        <EmergencyInfo />
        </div>
        </div>)
    }
    // users are eligible but the system can't deliver email
    if(submit && failedToSendEmail)
    {
        return (<div className="w-100">

        <div className='text-center my-5 survey-title'>
            <h1>Living Memory Home prescreening survey</h1>
        </div>

        <div className='container survey-container p-5 m-auto'>
        <div className='col-xs-12 col-sm-8 ml-0'>
        <p>We are deeply sorry. Your survey result was successfully submitted, but the system seemed to encounter some problems in notifying the research team. If you are eligible to participate, please reach out to: wjs2004@med.cornell.edu</p>
        
        </div>
        <EmergencyInfo />
        </div>
        </div>)
    }
    // users are eligible with a minium risk
    if(feedback !== '' && !showEmergencyInfo)
    {
        // console.log('display feedback and parse feedbackhtml')
        const parsedFeedback = feedback.split('<br/>')
        let fbarr = []
        parsedFeedback.forEach((f, idx) => {
            fbarr.push(<p key={`feedback-unit-${idx}`}>{f}</p>)
        })

        let feedbackHtml = `
        <div>
            <p>Feedback for the answer submitted by ${firstName || 'no first name'} (${email || 'no email'} / ${phone || 'no phone'})</p>
            ${feedback}
            <p>Below is the survey answer submitted by ${firstName || 'no first name'} (${email || 'no email'} / ${phone || 'no phone'})</p>
        </div>
        `
        Object.keys(answers).map(k => {
            const targetSurvey = answers[k]
            const surveyTitle = SH.getQuestionnaire(k)['title']
            feedbackHtml += `
                <div>
                    <h3>${surveyTitle}</h3>
                </div>
            `
            Object.keys(targetSurvey).map(sk => {
                const ans = targetSurvey[sk]
                const question = SH.getQuestionGivenQid(k, sk)['title']
                // console.log(question)
                const answer = typeof ans === 'string' ? JSON.parse(ans)['answer']:ans['answer']
                feedbackHtml += `<div>
                <p>${question}: </p>
                <p>${answer}</p>
                </div>`
            })
        })

        // console.log(feedbackHtml)

        return(
        <div className="w-100">

        <div className='text-center my-5 survey-title'>
            <h1>Living Memory Home prescreening survey</h1>
        </div>

        <div className='container survey-container p-5'>
            <h3>Result:</h3>
            {fbarr}
            <div>
            <p>Please enter your email and phone number and click the button below to submit the result to the research team. Thank you for your interest in the Living Memory Home study.</p>
            </div>
            <div className='m-4'>
                <label htmlFor='name' >Your first name</label>
                <input className='ml-2' id='name' type="text" value={firstName} placeholder='e.g., Mary' onChange={e => updateFirstName(e.target.value)} />
            </div>
            <div className='m-4'>
                <label htmlFor='email' >Your contact email</label>
                <input className='ml-2' id='email' type="text" value={email} placeholder='email@example.com' onChange={e => updateEmail(e.target.value)} />
            </div>
            <div className='m-4'>
                <label htmlFor='phone' >Your contact phone number</label>
                <input className='ml-2' id='phone' type="tel" placeholder="(111)-111-1111" value={phone} onKeyUp={e => {
                    let num = e.target.value.replace(/\D/g,'')
                    updatePhone('(' + num.substring(0,3) + ')' + num.substring(3,6) + '-' + num.substring(6,10))
                    // var num = $(this).val().replace(/\D/g,''); 
                    // $(this).val(num.substring(0,1) + '(' + num.substring(1,4) + ')' + num.substring(4,7) + '-' + num.substring(7,11)); 
                }} 
                onChange={e => updatePhone(e.target.value)} 
                
                />
            </div>
            <div><p>{submitFeedback}</p></div>
            {loading ? 
                <>
                Sending the notification to the research team.
                <BeatLoader
                    // css={override}
                    css={`size: 15;`}
                    size={15}
                    color={colors.lightYellow}
                    loading={loading}
                />
                </>
                :
                <button className="btn btn-lg prescreening-btn" type="submit" onClick={async (e) => {
                    // console.log(`click sumit result`)
                    // props.handleStore(e, props.questionnaire)
                    const phoneReg = new RegExp(/\([0-9]{3}\)[0-9]{3}-[0-9]{4}/gm)
                    const emailvalidate = GH.validateEmail(email)
                    // console.log(`phone test result ${!phoneReg.test(phone)}`)
                    if(!phoneReg.test(phone))
                    {
                        // console.log("the phone test failed")
                        updateSubmitFeedback('Please double check your phone number format.')
                        return
                    }
                    if(!emailvalidate)
                    {
                        updateSubmitFeedback('Please double check your email format.')
                        return 
                    }
                    if(email === '' || phone === '')
                    {
                        updateSubmitFeedback('Please double check if you have entered all of the necessary information.')
                        return
                    }
                    updateSubmitFeedback('')
                    setLoading(true)
                    await handleSubmit(true, true)
                    try
                    {
                        const r = await EH.sendEmail(
                            config.emailList[env], 
                            `${riskLevel} tz: ${momentTimezone.tz.guess(true)} Living Memory Home prescreening result from: ${firstName || 'no name'} (${email || 'no email'} / ${phone || 'no phone'})`, 
                            feedbackHtml)
                        // console.log(r)
                        // console.log(typeof r)
                        if(typeof r === 'object' && !r.hasOwnProperty('success'))
                        {
                            // console.log('failed to send email')
                            updateFailedToSendEmail(true)
                        }
                    }
                    catch(e)
                    {
                        updateFailedToSendEmail(true)
                        console.log(e)
                    }
                    // console.log(r)
                    // const r = await EH.sendEmail(['wjs2004@med.cornell.edu'], `Living Memory Home prescreening result from: ${email}`, feedbackHtml)
                    await setLoading(false)
                    await resetTheForm(true)
                    await updateSubmit(true)
                    // console.log(r)
                    
                }}>Send the prescreening result to the research team</button>
            }

        </div>
        </div>)
    }
    if(feedback !== '' && showEmergencyInfo)
    {
        const parsedFeedback = feedback.split('<br/>')
        let fbarr = []
        parsedFeedback.forEach((f, idx) => {
            fbarr.push(<p key={`feedback-unit-${idx}`}>{f}</p>)
        })

        return(
            <div className="w-100">
    
            <div className='text-center my-5 survey-title'>
                <h1>Living Memory Home prescreening survey</h1>
            </div>
    
            <div className='container survey-container p-5'>
                <h3>Result:</h3>
                {fbarr}
                <EmergencyInfo />
            </div>
        </div>)
    }
    return(
    <div className="w-100 survey-body">
        <div className='d-flex justify-content-center pt-5'>
            <GetImg         
                local // when the image is locally hosted
                // className={}
                // onClick={null}
                imageUrl={require('../../img/MH_logo_watercolor_bg-sm.png')} // "name-of-the-img.jpg"
                srcset={{
                    sm: require('../../img/MH_logo_watercolor_bg-sm.png'),
                    md: require('../../img/MH_logo_watercolor_bg-md.png'),
                    lg: require('../../img/MH_logo_watercolor_bg-lg.png'),
                    xl: require('../../img/MH_logo_watercolor_bg-xl.png')
                }}
                alt={'LMH logo'}
            />
        </div>

        <div className='text-center my-5 survey-title'>
            <h1>Living Memory Home study prescreening survey</h1>
            <p>This survey will help the research team better assess your eligibility.</p>
        </div>

        <div className='container survey-container mb-5 py-5' style={{ position: 'relative' }}>
        <div className='d-flex justify-content-center align-items-center cursor-pointer restart-btn p-3' onClick={() => resetTheForm()} style={{ right: 10, top: 10, position: 'absolute' }}><p className='d-flex justify-content-center align-items-center'>Reset Survey<FontAwesomeIcon  icon={faRedoAlt} style={{ width: '30px', height: '30px', color: colors.darkGreen, cursor: 'pointer', marginLeft: '10px' }} /></p></div>
            <form onSubmit={submitForm}>
            <div className='col-sm-12 col-md-8 mx-auto'>
            <div className='row w-100 text-center d-flex justify-content-center'>
                <h4>{q.title}</h4>
            </div>
            
            <div className='row w-100 text-center d-flex justify-content-center'>
                <h4>{`Questionnaire: ${q.description}`}</h4>
                
            </div>
            <div className='row'>
                <p>* indicates a required question</p>
                <p>You may choose to refuse to answer the question by selecting ‘Refuse to answer,’ however, it could render you ineligible for this study.</p>
            </div>
            {/*<Breadcrumb items={breadcrumb} />*/}

            <div className='mt-5'>
                {renderArr}
            </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 secondary-btn-container mx-auto d-flex justify-content-center align-items-center'>
                {currentSurvey === 0 ? 
                    <button className='btn btn-lg btn-block prescreening-btn' onClick={async (e) => {
                        const incomplete = checkCurrentSurveyComplete()
                        if(incomplete.length > 0)
                        {
                            return alert(`Please complete all the required questions before proceeding.`)
                        }
                        updateCurrentSurvey(currentSurvey + 1)
                        scrollUp()
                        handleSubmit(false, false)
                        // console.log(result)
                    }}>Next</button>
                :null}
                {currentSurvey > 0 && currentSurvey < surveysArr.length - 1 ? 
                    <React.Fragment>
                        <button className='btn btn-lg prescreening-btn' onClick={async (e) => {

                            updateCurrentSurvey(currentSurvey -1)
                            scrollUp()
                            handleSubmit(false, false)
                            // console.log(result)
                        }}>Previous</button>
                        <button className='btn btn-lg prescreening-btn ml-4' onClick={async (e) => {
                            const incomplete = checkCurrentSurveyComplete()
                            if(incomplete.length > 0)
                            {
                                return alert(`Please complete all the required questions before proceeding.`)
                            }
                            updateCurrentSurvey(currentSurvey + 1)
                            scrollUp()
                            handleSubmit(false, false)
                            // console.log(result)
                        }}>Next</button>
                    </React.Fragment>
                :null}
                {currentSurvey === surveysArr.length - 1 ?
                    <React.Fragment>
                    <button className='btn btn-lg prescreening-btn' onClick={async (e) => {
                        updateCurrentSurvey(currentSurvey -1)
                        scrollUp()
                        handleSubmit(false, false)
                        // console.log(result)
                    }}>Previous</button>
                    <button className="btn btn-lg btn-block prescreening-btn  ml-4" type="submit" onClick={async (e) => {
                        const incomplete = checkCurrentSurveyComplete()
                        if(incomplete.length > 0)
                        {
                            return alert(`Please complete all the required questions before proceeding.`)
                        }
                        // props.handleStore(e, props.questionnaire)
                        await setLoading(true)
                        await handleSubmit(true, true)
                        await setLoading(false)
                        // const result = await SH.PG13Analysis(answers, true)
                        // updateFeedback(result)
                        // console.log(result)
                    }}>Submit</button>
                    </React.Fragment>
                :null}

                {loading && !props.feedback ? <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div> : null}
            </div>
        </form>
        </div>

    </div>)
}

const Breadcrumb = props => {
    let items = []
    const inputItems = props.items
    inputItems.forEach((item, idx) => {
        items.push(<span key={`breadcrumb-${item.id}-${idx}`} className='cursor-pointer breadcrumb-item' onClick={item.handleClick}>{item.title}</span>)
        if(idx < inputItems.length - 1)
        {
            items.push(<span key={`breadcrumb-arrow-${item.id}-${idx}`}>{` > `}</span>)
        }
    })

    return <div className="col-xs-12 m-auto">
        <p>{items}</p>
    </div>
}

const EmergencyInfo = props => {
    return (<div className='col-xs-12 col-sm-8'>
    <h4>General Resources for Respondents</h4>
    <p>The following resources are provided to all of our respondents following the completion of prescreening survey.</p>
    <p>We care about your well-being. If you are thinking about harming yourself or attempting suicide, please let
    someone know immediately by doing one or more of the following:</p>
    <ul>
        <li>Call 911</li>
        <li>Go to the nearest emergency room</li>
    </ul>

    <p>The following resources will connect you to a trained counselor at a suicide crisis center nearest you 24 hours
    per day, 7 days per week:</p>
    <ul>
        <li>Call the toll-free National Suicide Prevention Lifeline at 1-800-273-TALK (1-800-273- 8255).</li>
        <li>Text TALK to 741741.</li>
        <li>Visit <a href="https://suicidepreventionlifeline.org" target="_blank">suicidepreventionlifeline.org</a> and/or <a  target="_blank" href="https://afsp.org">afsp.org</a></li>
        <li>If you are a Veteran or Active Service Member you can call 1-800-273- 8255 and press 1 and/or text
        838255.</li>
        <li>If you are a Veteran or Active Service Member you can visit <a href="https://veteranscrisisline.net" target="_blank">veteranscrisisline.net</a>.
        You can ask a family member or friend to help you reach out or take you to the hospital.</li>
    </ul>
    </div>)
}

const RadioGroup = props => {
    // console.log(`suicidal is ${props.suicidal}`)
    if(props.items.length <= 0) return null
    // console.log(props.answers)
    let radios = [], survey = props.survey, answers = props.answers !== null && props.answers !== undefined ? props.answers : {}

    props.items.forEach((item, idx) => {
        if(answers.hasOwnProperty(survey) && answers[survey].hasOwnProperty(props.qid))
        {
            const itemChecked = parseInt(item.value, 10) === parseInt(answers[survey][props.qid]['value'], 10)
            // if(itemChecked)
            // {
            //     return radios.push(<div key={`${props.qid}-${item.id}`} className="radio">
            //     <label htmlFor={item.id} className='lmh-survey-label checked'>
            //       <input type="radio" name={props.qid} id={item.id} value={item.value} onChange={e => {
            //           console.log("checked change")
            //             props.onChange(e)
            //       }} checked />
            //       {item.display}
            //     </label>
            //     {props.suicidal && item.warning ? 
            //         <div className='alert alert-warning'><p>{item.warning}</p></div>
            //     :null
            //     }
            //     </div>)
            // }
            return radios.push(<div key={`${props.qid}-${item.id}`} className="radio">
            <label htmlFor={item.id} className={`lmh-survey-label ${itemChecked ? 'checked': ''}`} >
              <input type="radio" name={props.qid} id={item.id} value={item.value} 
              checked={itemChecked}
              onChange={e => {
                props.onChange(e)
                console.log("not changed check")
              }} />
              {item.display}
            </label>
            {(props.suicidal || props.potentialSuicidal) && item.warning && itemChecked ? 
                <div className='alert alert-warning'><p>{item.warning}</p></div>
            :null
            }
            </div>)
        }
        return radios.push(<div key={`${props.qid}-${item.id}`} className="radio">
        <label htmlFor={item.id} className='lmh-survey-label'>
          <input type="radio" name={props.qid} id={item.id} value={item.value} onChange={e => props.onChange(e)} />
          {item.display}
        </label>
        </div>)
    })
    return radios
}

const RadioInlineGroup = props => {
    if(props.items.length <= 0) return null
    let radios = [], survey = props.survey, answers = props.answers !== null && props.answers !== undefined ? props.answers : {}

    props.items.forEach((item, idx) => {
        if(answers.hasOwnProperty(survey) && answers[survey].hasOwnProperty(props.qid))
        {
            const itemChecked = parseInt(item.value, 10) === parseInt(answers[survey][props.qid]['value'], 10)
            if(itemChecked)
            {
                return radios.push(<label htmlFor={item.id}>
                    <input type="radio" name={props.qid} id={item.id} value={item.value} onChange={e => props.onChange(e)} checked />
                    {item.display}
                  </label>)
            }
            return radios.push(<label htmlFor={item.id}>
                <input type="radio" name={props.qid} id={item.id} value={item.value} onChange={e => props.onChange(e)} />
                {item.display}
              </label>)
        }
        return radios.push(<label htmlFor={item.id}>
          <input type="radio" name={props.qid} id={item.id} value={item.value} onChange={e => props.onChange(e)} />
          {item.display}
        </label>)
    })
    return   <div className="row"> {radios}</div>
}

const InputItem = props => {
    // console.log(props)
    const item = props.item, survey = props.survey, answers = props.answers !== null && props.answers !== undefined ? props.answers : {}
    if(answers.hasOwnProperty(survey) && answers[survey].hasOwnProperty(props.qid))
    {
        return <div className='row pl-3'><input className='w-100' type="text" name={props.qid} id={item.id} value={answers[survey][props.qid]['value']} onChange={e => props.onChange(e)} /></div>
    }
    return <div className='row pl-3'><input className='w-100' type="text" name={props.qid} id={item.id} value={item.value} onChange={e => props.onChange(e)} /></div>
}

const InputWOption = props => {
    const survey = props.survey, answers = props.answers !== null && props.answers !== undefined ? props.answers : {}
    // console.log(props.items)
    const item = props.qObj
    let hideInput = false

    if(answers.hasOwnProperty(survey) && answers[survey].hasOwnProperty(props.qid))
    {
        props.items.forEach((o, idx) => {
            // console.log(`the value is ${parseInt(answers[survey][props.qid]['value'], 10)} and the object value is ${parseInt(o.value, 10)}`)

            if(parseInt(o.value, 10) === parseInt(answers[survey][props.qid]['value'], 10))
            {
                hideInput = true
            }
        })
        // console.log(`should display input ${displayInput}`)
        // console.log(answers[survey][props.qid])
        return <React.Fragment>
            {!hideInput ? <div className='row pl-3'><input className='w-100' type="text" name={props.qid} id={item.id} value={answers[survey][props.qid]['value']} onChange={e => props.onChange(e)} /></div> :<div onClick={(e) => props.onClear(props.qid)} className='row pl-3'><input className='w-100' type="text" name={props.qid} id={item.id} value={answers[survey][props.qid]['answer']} onChange={e => props.onChange(e)} disabled /></div>}
            <RadioGroup {...props} />
        </React.Fragment>
    }
    return <React.Fragment>
        <div className='row pl-3'>
        <input className='w-100' type="text" name={props.qid} id={item.id} value={item.value} onChange={e => props.onChange(e)} /></div>
        <RadioGroup {...props} />
    </React.Fragment>

}

const Consent = props => {
    return (<div className='col-xs-10 col-sm-8 mx-auto'>
        <p>
            {`
            Thank you for your interest in our Living Memory Home (LMH) online bereavement resource. The LMH is a place where you can memorialize and reflect upon your loss of someone close to you.
            `}
        </p>
        <p>
            {`
            The survey that follows will ask you a series of questions that will help us determine if you are eligible to participate in a research study of the LMH. The purpose of this research is to evaluate features to help us to improve the LMH. If you choose to proceed, you will be asked to provide some information about yourself, the deceased, and your thoughts and feelings about, and reactions to, the death. At the end of the survey, you will receive information about whether or not you are eligible to participate in the study, and, if eligible, about next steps in the LMH research study participation process. The survey will take approximately 5 minutes to complete.
            `}
        </p>
        <p>
            {`
            The LMH research study eligibility survey uses Hypertext Transfer Protocol Secure (HTTPS) internet communication protocol to ensure security and privacy of information in user interactions with the site. Internet addresses (IPs) are routinely recorded but are stored and handled confidentially. Anonymous data will be used for research purposes.
            `}
        </p>
        <p>
            {`
            The LMH is a self-help resource and not a substitute for professional help. The LMH research study eligibility survey may provide some information about accessing professional mental health services, but receipt of this information should not be construed as a professional referral based on clinical judgment.
            `}
        </p>
        <p>
            {`
            This research is being conducted at the Cornell Center for Research on End-of-Life Care at Weill Cornell Medicine (WCM) and sponsored by the National Institute of Mental Health (NIMH). You can contact our research team (EOLresearch@med.cornell.edu) or WCM's Institutional Review Board (irb@med.cornell.edu) for answers to any questions that you may have about this research. 
            `}
        </p>
        <div className="alert alert-info" role="alert">
        {`
        I am aware that information that I provide in the LMH research study eligibility survey will be used for research purposes. Knowing this, I wish to proceed. 
        `}
        </div>
        <div className='w-100 d-flex justify-content-end'>
            <button className='lmh-primary-btn btn btn-lg mx-auto' onClick={() => props.consent()}>Click to proceed</button>
        </div>
    </div>)
}

export default Survey
export { RadioGroup, RadioInlineGroup, InputItem }