import { combineReducers } from 'redux'
import userReducer from './user'
import configReducer from './config'
import daysReducer from './days'
import surveyReducer from './survey'
// import surveyReducer from './questionnaires'
// import travelkitReducer from './travelkit'
// import modulesReducer from './modules'
import methods from '../methods'

const appReducer = combineReducers({
    userReducer, configReducer, daysReducer
})

const rootReducer = (state, action) => {
    switch(action.type)
    {
        case methods.CLEAR_STORE:
            localStorage.removeItem('redux-store')
            state = undefined
            return appReducer(state, action)
        default:
            return appReducer(state, action)
    }
}

export default rootReducer