// import DH from './DatabaseHelper'
import EmailHelper from './EmailHelper'
// import STM from './SystemTimeManage'
// import { tbs } from '../../init/initApp'
import FetchWrapper from './FetchWrapper'

const Fetch = new FetchWrapper()

const autoSaveInterval = 30 * 1000

class ActivityLogHelper {
    constructor()
    {
        // console.log('activity log helper initiated')
        // console.log(this._saveInterval)
        window.onbeforeunload = e => this.saveActivity(e)
        // window.onbeforeunload = null
    }

    initActivityAutoSave(cb)
    {
        // console.log('init activity')
        // this._autoSave = cb
        // this._autoSave()
        // this._saveInterval = setInterval(this._autoSave, autoSaveInterval)
        return 
        // console.log(this._autoSave)
    }

    saveActivity(e)
    {
        // e.preventDefault()
        // e.returnValue = '';
        clearInterval(this._saveInterval)
        // window.onbeforeunload = null
        // console.log('remove the message')
        if(this._autoSave !== undefined)
        {
            this._autoSave()
        }
        return undefined
    }

    async updateLogoutTime(dayLogsId)
    {
        try
        {
            if(!dayLogsId || dayLogsId === undefined || dayLogsId === null) return false
            // const res = await Fetch.post('/activity/v2/recordLogoutTime', {
            //     dayLogsId: dayLogsId
            // })
            // .then(r => r.json())
            // .then(r => {
            //     if(!r.success) throw r
            //     return r.results
            // })
            // .catch(e => {throw e})
            // return res
            return true
        }
        catch(e)
        {
            return false
            // console.log(e)
        }
    }

    async getAllActivitiesInDay(usersId, dayLogsId)
    {
        try
        {
            const logs = await Fetch.get(`/activity/v2/getAllActivitiesInDay/${usersId}/${dayLogsId}`)
            .then(r => r.json())
            .then(r => r)
            .catch(e => {throw e})
            // const logs = await DH.upsert(tbs.activityLogs, {}, [{users_id: usersId}, {day_logs_id: dayLogsId}])
            // .then(r => r).catch(e => { throw e })
            // console.log(logs)
            return logs
        }
       catch(e)
       {
            await EmailHelper.sendEmail('', 'FAILED_TO_GET_ALL_ACTIVITIES_PER_DAY', `The system failed to get all activities for user id ${usersId} in day id ${dayLogsId}`)
            return e
       }
    }

    async upsertActivityLog(usersId, dayLogsId, day, type, activityKey = '', fieldData = {})
    {
        try
        {
            let newFieldData = {...fieldData}
            newFieldData['activity_key'] = activityKey
            // const r = await fetch('/activity/v2/upsertActivityLog', {
            //     method: 'POST',
            //     mode: 'cors',
            //     headers: new Headers({
            //     'Access-Control-Allow-Origin': '*',
            //     'Content-Type': 'application/json',
            //     'Accept': 'application/json'
            //     }),
            //     body: JSON.stringify({
            //         fieldData: {
            //             usersId: usersId,
            //             day: day,
            //             dayLogsId: dayLogsId,
            //             type: type,
            //             fieldData: newFieldData
            //         }
            //     })
            // })
            const r = await Fetch.post('/activity/v2/upsertActivityLog', {
                fieldData: {
                    usersId: usersId,
                    day: day,
                    dayLogsId: dayLogsId,
                    type: type,
                    fieldData: newFieldData
                }
            })
            .then(r => r.json())
            .then(response => {
                if(!response.success) throw response
                return response.results
            })
            return r
        }
        catch(e)
        {
            await EmailHelper.sendEmail('', 'FAILED_TO_UPSERT_ACTIVITY', `The system failed to upsert activity ${type} for user id ${usersId} in day id ${dayLogsId}`)
            return e
        }
    }

    async upsertMemorialLogs(username = '', dayLogsId, activityLogs)
    {
        if(!username) return false
        // console.log(`day logs id ${dayLogsId}`)
        // console.log(activityLogs)
        try{
            const toUpsertObj = {
                id: dayLogsId,
                activity_logs: JSON.stringify(activityLogs)
            }
            const r = await Fetch.post('/activity/v2/upsertMemorialLogs', {
                username: username,
                fieldData: toUpsertObj
            })
            .then(r => r.json())
            .then(r => {
                // console.log(r)
                if(r.results.length > 0) return r.results[0]
                throw {
                    code: 'FAILED_TO_UPSERT_MEMORIAL_LOG',
                    message: `The activity log failed to be stored for day ${dayLogsId}, with field data ${JSON.stringify(toUpsertObj)}`
                }
            })
            .catch(e => {throw e})
            return r
            // const r = await DH.upsert(tbs.dayLogs, toUpsertObj, {}).then(r => r).catch(e => { throw e })
            // console.log(r)

        }
        catch(e)
        {
            // console.log("error happened for upserting memorial log", e)
            await EmailHelper.sendEmail('', e.code, e.message)
            return e            
        }
    }

    async getLastMemorialLog(usersId)
    {
        const url = `/activity/v2/getLatestDayLogWithOfferingData`
        return Fetch.get(url, [usersId])
    }

}

export default new ActivityLogHelper()