import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withAdminControl from './withAdminControl'
import AdminBoard from '../ui/AdminBoard'
import { changeLang } from '../../actions/config'
import { loadAdminBoardPage, updateDataDisplayPreference } from '../../actions/config'
import { defaultLang } from '../lang/init'


const mapStateToProps = (state) =>
    ({
        // auth: state.userReducer.auth,
        dict: state.configReducer.lang.dict,
        lang: state.configReducer.lang.lang,
        adminboardPage: state.configReducer.adminboardPage,
        simpleDisplay: state.configReducer.simpleDisplay
        // accountInfo: state.userReducer.accountInfo,
        // resetConfig: state.configReducer.resetConfig,
        // currentDay: state.daysReducer.currentDay
    })

const mapDispatchToProps = (dispatch) => 
    ({
        // loadAdminBoardPage: (page) => dispatch(loadAdminBoardPage(page))
        changeLang: (lang = defaultLang) => dispatch(changeLang(lang)),
        loadAdminBoardPage: (page = 'prescreening') => dispatch(loadAdminBoardPage(page)),
        updateDataDisplayPreference: (simpleDisplay = true) => dispatch(updateDataDisplayPreference(simpleDisplay))
        // upsertConfig: (userId, param) => dispatch(upsertConfig(userId, param)),
        // enableResetConfig: reset => dispatch(enableResetConfig(reset)),
        // manageAccountInfo: (userId, param = {}) => dispatch(manageAccountInfo(userId, param))
        // getCurrentDayLogs: userId => dispatch(getCurrentDayLogs(userId))
    })


const Container = connect(mapStateToProps, mapDispatchToProps)(AdminBoard)
export default withRouter(withAdminControl(Container))
