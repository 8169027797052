import React, { Component } from 'react'
import config from '../init/config'
import { NavItems } from '../init/initApp'
import { RectBtnPlain } from '../assets/v2/Buttons'
import STM from '../classes/v2/SystemTimeManage'
import SLG from '../classes/v2/SystemLogs'
import LS from '../classes/v2/LocalStorage'
import ALH from '../classes/v2/ActivityLogHelper'
import AmplifyHelper from '../classes/v2/AmplifyHelper'
import EH from '../classes/v2/EmailHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { colors } from '../init/colors'
import GetImg from '../assets/GetImg'

const env = config.currentEnv

class NavBar extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            getHelp: false
        }
        this.logout = this.logout.bind(this);
        this.callingHelp = this.callingHelp.bind(this);
    }
    async logout() {
        // remove the configs stored in the localstorage
        await LS.removeAll()
        await this.props.logout()
        try
        {
            const logoutAmplify = await AmplifyHelper.signOut(true).then(r => r).catch(e => { throw e })
            if(logoutAmplify === undefined)
            {
                // successfully logged out
            }
            // console.log(logoutAmplify)
            const lastDay = await STM.getLatestDayLog(this.props.auth.id, (e, d) => {
                if(e) throw e
                return d
            })

            // console.log(lastDay)

            const logout_time = STM.getCurrentUTCTimestamp();
            const fieldData = {
                logout_time: logout_time
            }

            const updateLogout = await STM.updateDayLogs(lastDay.day_hash, fieldData, (err, data) => {
                return data
            })
            
            // console.log(updateLogout)
            const logoutTitle = this.props.dict.errorMsg.logoutTitle.toUpperCase()
            const logoutMsg = this.props.dict.errorMsg.logoutSucceed
            this.props.history.push(`/${this.props.lang}/error?message=${logoutMsg}&title=${logoutTitle}`)
            // this.props.history.push(config.paths.auth)
        }
        catch(e)
        {
            const code = 'FAILED_TO_LOGOUT'
            const message = `The system failed to logout user ${this.props.auth.username}`
            const send = await EH.sendEmail([], code, message)
            // redirect to error page
            const errTitle = this.props.dict.errorMsg.logoutErrTitle.toUpperCase()
            const errMsg = this.props.dict.errorMsg.logoutFailed
            this.props.history.push(`/${this.props.lang}/error?message=${errMsg}&title=${errTitle}`)
        }
/*
        return AWSCognito.getSession((err, accessToken) => {
            if(err)
            {
                const errMsg = 'The system failed to retrieve your current session, hence you might not be able to log out immediately. Please bear with us, and we will fix it as soon as possible.';
                return SLG.recordErr(accessToken, 'FAILED_TO_LOGOUT', errMsg, (err, results) => {
                    return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                });
            }
            // parse the access token and proceed to check if user has logged in
            return AWSCognito.getUsernameFromAccessToken(accessToken, username => {
                if(!username) // the accessToken is not validated, log user out directly
                {
                    return fetch('/session/v1/closeSession')
                        .then(r => r.json())
                        .then(result => {
                            if(!result.success)
                            {
                                const errMsg = `No valid sessions were found. Please make sure you have logged in before.`;
                                // cannot store the logout request in MySQL database, disallow users to log out
                                return SLG.recordErr(accessToken, 'NO_VALID_SESSION_ON_LOGOUT', errMsg, (err, results) => {
                                    return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                });
                            }
                            return window.location.assign(`/${this.props.lang}`);
                        });
                }
                return User.returnUserId(username, userId => {
                    // first retrieve the daylog and check the login out time
                    return STM.getLatestDayLog(1, (err, data) => {
                        if(err) {
                            // cannot store the logout request in MySQL database, disallow users to log out
                            return SLG.recordErr(accessToken, err.errCode, err.message, (err, results) => {
                                return window.location.assign(`/${this.props.lang}/error?message=${err.message}`);
                            });
                        }
                        const logout_time = STM.getCurrentUTCTimestamp();
                        const fieldData = {
                            logout_time: logout_time
                        }
                        return STM.updateDayLogs(data.day_hash, fieldData, (err, data) => {
                            if(err)
                            {
                                const errMsg = `The system has encountered some problem when processing your signout request, please try again later.`;
                                // cannot store the logout request in MySQL database, disallow users to log out
                                return SLG.recordErr(accessToken, 'FAILED_TO_STORE_LOGOUT', errMsg, (err, results) => {
                                    return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                });
                            }
                            // now you can finally log the logout in the logout table
                            return SLG.recordLog(accessToken, false, (err, results) => {
                                if(err)
                                {
                                    const errMsg = `The system has encountered some problem when processing your signout request, please try again later.`;
                                    // cannot store the logout request in MySQL database, disallow users to log out
                                    return SLG.recordErr(accessToken, 'FAILED_TO_STORE_LOGOUT', errMsg, (err, results) => {
                                        return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                    });
                                }
                                // do something with the results message
                                return AWSCognito.signOut((err, singoutResult) => {
                                    if(err)
                                    {
                                        const errMsg = `The system has encountered some problem when processing your signout request, please try again later.`;
                                        // cannot store the logout request in MySQL database, disallow users to log out
                                        return SLG.recordErr(accessToken, 'FAILED_TO_STORE_LOGOUT', errMsg, (err, results) => {
                                            return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                        });
                                    }
                                    return window.location.assign(`/${this.props.lang}`);
                                });
                            });
                        });
                    });

                });
            });
        });
*/
    }

    async callingHelp()
    {
        this.setState({
            getHelp: true
        })
        // window.open('/get-help')
        // 'hgp2001@med.cornell.edu', 'mar2851@med.cornell.edu', 'hmd2002@med.cornell.edu', 'wjs2004@med.cornell.edu'
        // store the action in the activity log
        const help = await ALH.upsertActivityLog(this.props.auth.id, this.props.currentDay.id, this.props.currentDay.day, 'Help button clicked', 'emergencyHelp', {})
        // console.log(help)
        // EH.sendEmail(config.emailList[env], '[Living Memory Home] Warning! The get help button is clicked.', `Please be alerted to the request for help from a user of the Living Memory Home. User email is ${this.props.auth.email}.`)
    }

    render()
    {
        // console.log(this.props)
        let items = [], helpMeBtn = []
        NavItems().forEach(item => {
            items.push(
                <li key={item.key} className="nav-item">
                    <a className={this.props.changingMH ? "nav-link disabled" : "nav-link" } href={this.props.changingMH ? null : ("/" + this.props.lang + item.url)} target={item.hasOwnProperty("target") ? item.target : ''} title="Please make your selection before visiting a different page">{item.title}<span className="sr-only"></span></a>
                </li>
            )
        })
        helpMeBtn.push(<React.Fragment key={`helpme-btn`}>
            <button key={`help-me-btn`} className='lmh-primary-btn' style={{ borderRadius: '5px' }} onClick={this.callingHelp}>
                {this.props.dict['nav']['help']} <FontAwesomeIcon icon={faBell} className='mr-2' style={{ width: '15px', height: '15px', color: colors.darkGreen }} />
            </button>
            {this.state.getHelp ? 
                <div style={{ width: '100vw', height: '100vh', position: 'absolute', zIndex: 998, top: 0, left: 0, margin: 0 }} className='d-flex justify-content-center align-items-center flex-column'  onClick={() => {
                    this.setState({
                        getHelp: false
                    })
                }}>
                    <div style={{ width: '100%', height: '100%', backgroundColor: colors.darkGreen, opacity: 0.6 }}></div>
                    <div style={{ width: '350px', height: '600px', backgroundColor: colors.white, zIndex: 999, position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, margin: 'auto', padding: '20px', overflow: 'auto' }}>
                        <GetImg
                            local // when the image is locally hosted
                            className={['warning-btn-img', 'mx-auto', 'w-100']}
                            onClick={e => null}
                            imageUrl={require('../../img/emergency.png')} // "name-of-the-img.jpg"
                            srcset={{
                                sm: require('../../img/emergency.png'),
                                md: require('../../img/emergency.png'),
                                lg: require('../../img/emergency.png'),
                                xl: require('../../img/emergency.png')
                            }}
                            alt={'get help'}
                        />
                        <div className='mt-5 w-100'>
                            <p className='mh-title-dark mx-auto'>{this.props.dict['nav']['title']}</p>
                            <p className='mh-subtitle-dark mx-auto'>{this.props.dict['nav']['harming']}</p>
                            <p className='mh-subtitle-dark mx-auto'>{this.props.dict['nav']['suicideLink'].split('[link]')[0]}<a href="https://suicidepreventionlifeline.org/talk-to-someone-now/" target='_blank'>{this.props.dict['nav']['suicideLink'].split('[link]')[1]}</a>{this.props.dict['nav']['suicideLink'].split('[link]')[2]}</p>
                            <p className='mh-subtitle-dark mx-auto'>{this.props.dict['nav']['link'].split('[link]')[0]}<a href="https://afsp.org/im-having-thoughts-of-suicide" target='_blank'>{this.props.dict['nav']['link'].split('[link]')[1]}</a>{this.props.dict['nav']['link'].split('[link]')[2]}</p>
                            <button className='lmh-primary-btn btn-lg mx-auto' onClick={() => {
                                this.setState({
                                    getHelp: false
                                })
                            }}>{this.props.dict['nav']['close']}</button>
                        </div>
                    </div>
                </div>
            :null}


        </React.Fragment>)
        const spanStyle = {
            flexWrap: 'wrap',
            width: '50%',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }
        if(this.props.hasOwnProperty('isLoggedIn') && !this.props.isLoggedIn)
        {
            return(
                <nav className="navbar navbar-expand-lg lmh-navbar navbar-dark">
                    <a className="navbar-brand navbar-logo-cont" href={"/" + this.props.lang + "/house"}><img className="banner-logo" src={require('../../img/logo.svg')} />
                        <span className='ml-3 brand-name' style={spanStyle}>{this.props.dict.banner.lmh}</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/*helpMeBtn*/}

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href={"/" + this.props.lang + '/resources'} target='_blank' >Grief Resources<span className="sr-only"></span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            )

        }
        if(this.props.hasOwnProperty('isLoggedIn') && this.props.isLoggedIn && this.props.hasOwnProperty('hideItems'))
        {
            // console.log(`is logged in but need to hide items`)
            return(
                <nav className="navbar navbar-expand-lg lmh-navbar navbar-dark">
                    <a className="navbar-brand navbar-logo-cont" href={"/" + this.props.lang}><img className="banner-logo" src={require('../../img/logo.svg')} />
                        <span className='ml-3 brand-name' style={spanStyle}>{this.props.dict.banner.lmh}</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {helpMeBtn}

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {/*items*/}
                            <li className="nav-item">
                                <a className="nav-link" href={"/" + this.props.lang + '/resources'} target='_blank' >Grief Resources<span className="sr-only"></span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={this.logout} href="#">{this.props.dict.banner.logout}<span className="sr-only"></span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            )
        }
        if(this.props.hasOwnProperty('isLoggedIn') && this.props.isLoggedIn)
        {
            return(
                <nav className="navbar navbar-expand-lg lmh-navbar navbar-dark">
                    <a className="navbar-brand navbar-logo-cont" href={"/" + this.props.lang}><img className="banner-logo" src={require('../../img/logo.svg')} />
                        <span className='ml-3 brand-name' style={spanStyle}>{this.props.dict.banner.lmh}</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {helpMeBtn}

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {items}
                            <li className="nav-item">
                                <a className="nav-link" onClick={this.logout} href="#">{this.props.dict.banner.logout}<span className="sr-only"></span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            )
        }
        return(
            <nav className="navbar navbar-expand-lg lmh-navbar navbar-dark">
                <a className="navbar-brand navbar-logo-cont" href={"/" + this.props.lang}><img className="banner-logo" src={require('../../img/logo.svg')} />
                    <span className='ml-3 brand-name' style={spanStyle}>{this.props.dict.banner.lmh}</span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        {items}
                    </ul>
                </div>
            </nav>
        )
    }
}

export default NavBar