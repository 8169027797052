import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Index from '../ui/Index'
import { changeLang } from '../../actions/config'
import { defaultLang } from '../lang/init'

const mapStateToProps = (state) =>
    ({
        dict: state.configReducer.lang.dict,
        lang: state.configReducer.lang.lang,
        auth: state.userReducer.auth
    })

const mapDispatchToProps = (dispatch) => 
    ({
        changeLang: (lang = defaultLang) => dispatch(changeLang(lang))
    })


const Container = connect(mapStateToProps, mapDispatchToProps)(Index)
export default withRouter(Container)
