// import DB from './DatabaseHelper'
// import { tbs } from '../../init/initApp'
import STM from './SystemTimeManage'
import FetchWrapper from './FetchWrapper'
const Fetch = new FetchWrapper()

/**
 * interact with the offering API
 * usage:
    import { Offering } from 'components/classes/offering';
    const OF = new Offering();
 */
export class Offering {
    constructor() {

    }
    /**
     * store the canvasURL in the database
     * usage:
        OF.storeCanvasURL(1, 1, 'canvasURL', (err, stored) => {
            if(err) return false;
            // do something
        });
     */
    storeCanvasURL(usersId, daysId, canvasURL, onResults) {
        // first get the day hash and usersId
        if(!usersId || !daysId) return onResults('Please enter the users id or day hash', null);
        const fieldData = {
            users_id: usersId,
            offering_img: canvasURL
        }
        const where = {
            id: daysId
        }
        return Fetch.post('/activity/v2/storeCanvasURL', {
            usersId: usersId,
            dayId: daysId,
            imgUrl: canvasURL
        })
        .then(r => r.json())
        .then(r => {
            if(!r.success)
            {
                return onResults(r, null)
            }
            return onResults(null, r.results)
        })
        .catch(e => onResults(e.message, null))
    }
    /**
     * get the canvasURL to display in the diary given users id and day_logs id
     * @param {int} usersId 
     * @param {int} daysId 
     * @param {func} onResults 
     * usage:
        OF.retreiveCanvasURL(1, 1, (err, canvasURL) => {
            if(err) return false;
            // do something
        });
     */
    retrieveCanvasURL(usersId, daysId, onResults) {
        return Fetch.get(`/activity/v2/retrieveCanvasURL/${usersId}/${daysId}`)
        .then(r => r.json())
        .then(r => {
            if(!r.success) return onResults(r, null)
            return onResults(null, r?.results[0]['offering_img'])
        })
        .catch(e => onResults({message: 'The system failed to get the canvas snapshot.'}, null))
        // return DB.getByMultiCondition(tbs.dayLogs, whereArr, (err, data) => {
        //     if(err) return onResults({message: 'The system failed to get the canvas snapshot.'}, null);
        //     return onResults(null, data[0]['offering_img']);
        // });
    }
    /**
     * get request to retrieve offering images given a range of days
     * @param {int} usersId 
     * @param {func} onResults 
     * usage: (to get the data from day 1 to day 10)
        OF.getOfferingImgGivenRange(1, 1, 10, (err, data) => {
            if(err) return err.message;
            data.forEach(dayObj => {
                const day = dayObj.day;
                const imgUrl = dayObj.offering_img;
                // use S3B to retrieve image
            })
        });
     */
    getOfferingImgGivenRange(usersId, beginDay, endDay, onResults) {

        const url = `/activity/v1/getOfferingImg/${usersId}/${beginDay}/${endDay}`
        
        return Fetch.get(url)
        .then(r => r.json())
        .then(response => {
            if(!response.success) 
            {
                return onResults({
                    message: response.message
                }, null);
            }
            return onResults(null, response.data);
        })

        /*
        return fetch(`/activity/v1/getOfferingImg/${usersId}/${beginDay}/${endDay}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) 
            {
                return onResults({
                    message: response.message
                }, null);
            }
            return onResults(null, response.data);
        });
        */
    }
    /**
     * get request to get the last offering image from a user
     * @param {int} usersId 
     * @param {func} onResults 
     * usage:
        OF.getLastOfferingImg(1, (err, data) => {
            if(err) return err.message;
            return OF.retreiveCanvasURL(1, data.id, (err, canvasURL) => {
                if(err) return false;
                // do something
            });
        });
     */
    getLastOfferingImg(usersId, onResults) {
        const url = `/activity/v1/getLastOfferingImg/${usersId}`

        return Fetch.get(url)
        .then(r => r.json())
        .then(response => {
            if(!response.success) 
            {
                return onResults({
                    message: response.message
                }, null);
            }
            return onResults(null, response.data[0]);
        })
        /* unauthorized fetches
        return fetch(`/activity/v1/getLastOfferingImg/${usersId}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) 
            {
                return onResults({
                    message: response.message
                }, null);
            }
            return onResults(null, response.data[0]);
        })
        */
    }
    /**
     * retrieve the offering data from the MySQL database
     * @param {int} usersId 
     * @param {int} day  // given -1 for the latest offering data
     * @param {func} onResults 
     * usage:
        OF.getOfferingData(1, 1, (err, data) => {
            if(err) return err.message;
            return data;
        });
     */
    getOfferingData(usersId, day = -1, onResults) {
        if(day < 0)
        {
            // get the latest offering data
            return Fetch.get(`/activity/v1/getOfferingData/${usersId}/${day}`)
            .then(r => r.json())
            .then(response => {
                if(!response.success)
                {
                    return onResults({
                        message: response.message
                    }, null);
                }
                if(response.data.length > 0)
                {
                    return onResults(null, response.data[0]['offering_data']);
                }
                return onResults(null, []);
            })
            /* unauthorized fetches
            return fetch(`/activity/v1/getOfferingData/${usersId}/${day}`)
            .then(r => r.json())
            .then(response => {
                if(!response.success)
                {
                    return onResults({
                        message: response.message
                    }, null);
                }
                if(response.data.length > 0)
                {
                    return onResults(null, response.data[0]['offering_data']);
                }
                return onResults(null, []);
            });
            */
        }
        const url = `/activity/v1/getOfferingData/${usersId}/${day}`
        return Fetch.get(url)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            if(response.data.length > 0)
            {
                return onResults(null, response.data[response.data.length-1]['offering_data']);
            }
            return onResults(null, []);
        })
        /* unauthorized fetches
        return fetch(`/activity/v1/getOfferingData/${usersId}/${day}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            if(response.data.length > 0)
            {
                return onResults(null, response.data[response.data.length-1]['offering_data']);
            }
            return onResults(null, []);
        })
        */
    }
    /**
     * get the complete day log entry given the id of the day log
     * @param {int} usersId
     * @param {int} dayLogsId
     * @param {func} onResults
     * usage:
        OF.getOfferingDataByDayLogsId(22, 126, (err, data) => {
            if(err) return;
            return data;
        });
     */
    getOfferingDataByDayLogsId = (usersId, dayLogsId, onResults) => {

        const url = `/activity/v1/getOfferingDataByDayLogsId/${usersId}/${dayLogsId}`
        return Fetch.get(url)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            return onResults(null, response.data)
        })
        /*
        return fetch(`/activity/v1/getOfferingDataByDayLogsId/${usersId}/${dayLogsId}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            return onResults(null, response.data)
        })
        */
    }

    /**
     * store the activity log in the database, activity_logs table
     * @param {int} usersId 
     * @param {int} dayLogsId 
     * @param {obj} activityObj 
     * @param {func} onResults 
     * usage:
        const activityObj = {
            users_id: 1,
            day_logs_id: 60, // the day logs id for the activity
            day: 21,
            activity_hash: 'your-activity-hash', // a unique hash to differentiate each activity
            activity_key: 'your-activity-key', // refer to the activity feedback key
            start_time: '2019-03-11 10:00:00',
            end_time: '2019-03-11 10:00:01',
            duration: 0,
            type: 'remove'
        }
        OF.storeActivityLog()
     */
    storeActivityLog(activityObj, onResults) {
        const url = '/activity/v1/storeActivityLog'
        const body = {
            activityObj: activityObj
        }
        return Fetch.post(url, body)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            return onResults(null, response.data);
        })

        /* unauthorized fetches
        return fetch('/activity/v1/storeActivityLog', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }),
            body: JSON.stringify({
                activityObj: activityObj
            })
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            return onResults(null, response.data);
        });
        */
    }
    /**
     * store the last_edited time in the activity obj
     * @param {*} activityObj 
     * @param {*} onResults 
     * 
     */
    upsertActivityLog(activityObj, whereObj, onResults) {
        const dateStr = STM.getCurrentLocalTimestamp(false)
        activityObj['end_time'] = dateStr
        const url = '/activity/v1/upsertActivityLog'
        const body = {
            fieldData: activityObj,
            whereObj: whereObj
        }

        return Fetch.post(url, body)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            return onResults(null, response.data);
        })

        /*
        return fetch('/activity/v1/upsertActivityLog', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }),
            body: JSON.stringify({
                fieldData: activityObj,
                whereObj: whereObj
            })
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null)
            }
            return onResults(null, response.data);
        })
        */
    }
    /**
     * store the offering data in the database
     * @param {int} usersId 
     * @param {int} day 
     * @param {func} onResults
     * usage:
        const offeringData = {
            something
        }
        OF.storeOfferingData(1, 1, offeringData, (err, data) => {

        });
     */
    storeOfferingData(usersId, dayLogsId, offeringData, onResults) {

        const url = `/activity/v1/storeOfferingData`
        const body = {
            usersId: usersId,
            dayLogsId: dayLogsId,
            offeringData: offeringData
        }

        return Fetch.post(url, body)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null);
            }
            return onResults(null, response.data);
        })

        /* unauthorized fetches
        return fetch(`/activity/v1/storeOfferingData`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }),
            body: JSON.stringify({
                usersId: usersId,
                dayLogsId: dayLogsId,
                offeringData: offeringData
            })
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults({
                    message: response.message
                }, null);
            }
            return onResults(null, response.data);
        });
        */
    }

    /**
     * store the activity log object in the database, day_logs table
     * @param {int} usersId 
     * @param {str} dayHash 
     * @param {arr} activityLogsArr 
     * @param {func} onResults 
     * usage:
        OF.storeActivityLogObj(1, 'hash', activityLogsArr, (err, data) => {
            if(err) return false;
            return data;
        });
     */
    storeActivityLogObj(usersId, dayHash, activityLogsArr, onResults) {

        const url = '/activity/v1/storeActivityLogsObj'
        const body = {
            usersId: usersId,
            dayHash: dayHash,
            activityLogsObj: activityLogsArr
        }

        return Fetch.post(url, body)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                throw response.message;
            }
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults(err.message || 'Failed to store the activity log', null)
        })


        /*
        return fetch('/activity/v1/storeActivityLogsObj', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }),
            body: JSON.stringify({
                usersId: usersId,
                dayHash: dayHash,
                activityLogsObj: activityLogsArr
            })
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                throw response.message;
            }
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults(err.message || 'Failed to store the activity log', null)
        });
        */
    }
    /**
     * clear the offering_data column without clearing the activity_logs in the day_logs table
     * @param {int} usersId 
     * @param {str} dayHash 
     * @param {func} onResults 
     */
    resetOffering(dayLogsId, onResults) {

        const url = '/activity/v1/resetOffering'
        const body = {
            id: dayLogsId
        }

        return Fetch.post(url, body)
        .then(r => r.json())
        .then(response => {
            if(!response.success) throw response.message;
            return onResults(null, response.data);
        })
        .catch(err => {
            // do something
            return onResults(err.message, null);
        })

        /* unauthorized fetches
        return fetch('/activity/v1/resetOffering', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                id: dayLogsId
            })
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success) throw response.message;
            return onResults(null, response.data);
        })
        .catch(err => {
            // do something
            return onResults(err.message, null);
        });
        */
    }

    returnOfferingDefaultImg(view) {
        switch(view){
            case 'view2': return require('../../../img/view2_offering_img.jpeg');
            case 'view3': return require('../../../img/view3_offering_img.jpeg');
            case 'view4': return require('../../../img/view4_offering_img.jpeg');
            case 'view5': return require('../../../img/view5_offering_img.jpeg');
            case 'view6': return require('../../../img/view6_offering_img.jpeg');
            case 'view1': 
            default: return require('../../../img/view1_offering_img.jpeg');
        }
    }

}

export default new Offering()