import { hash, Base64ToImage } from '../init/general';
import FetchWrapper from './v2/FetchWrapper';
var b64toBlob = require('b64-to-blob');

const Fetch = new FetchWrapper()

/**
 * Middleware to send requests to putObject and deleteObject in AWS S3 bucket
 * usage:
    import { S3Wrapper } from 'components/classes/s3bucket';
    const S3B = new S3Wrapper();
 */

export class S3Wrapper {
    constructor()
    {

    }
    /**
     * upload images to S3 bucket
     * @param {formData} formData 
     * @param {str} folder 
     * @param {func} onResults 
     * usage:
        const S3B = new S3Wrapper;
        S3B.putImage(formData, 'folder', (err, feedback) => {
            if(err) return console.log(err);
            return feedback;
        });
     */
    putImage(formData, folder = '', onResults)
    {
        // return fetch('/s3/v1/uploadPhoto', {
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //     }),
        //     method: 'POST',
        //     body: formData
        // })
        return Fetch.postForm('/s3/v1/uploadPhoto', formData)
        .then(r => r.json())
        .then(response => {
            console.log(response)
            if(!response.success)
            {
                // Error handling: if the file upload failed
                return onResults(`Error: ${response.errMsg}`, null);
            }
            // console.log(response);
            // send the feedback to the window to trigger the following events
            let uploadedFeedback = {
                uploaded: true,
                filePath: response.data.key,
                fileName: response.data.key,
                originalName: response.data.etag,
                newFileName: response.data.key,
                data: response.data
            }
            return onResults(null, uploadedFeedback);
        })
        .catch(err => {
            return onResults('Oh, no. The file cannot be uploaded currently. We are sorry for the inconvenience.', null);
        });
    }

    /**
     * upload the file to the folder
     * @param {blog} blob 
     * @param {str} contentType 
     * @param {str} folder 
     * @param {func} onResults 
     * usage:
        S3B.uploadBlob(blob, 'image/jpeg', 'ritual_imgs', (err, results) => {
            if(err)
            {
                // TODO: error control
            }
            // store the new filename in the database for retrieve
            const canvasKey = results.data.key;
        });
     */
    uploadBlob(blob, contentType, folder = '', onResults)
    {
        let formData = new FormData;
        formData.append('file', blob);
        formData.append('folder', folder);
        formData.append('contentType', contentType);
        // return fetch('/s3/v1/uploadBlob', {
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //     }),
        //     method: 'POST',
        //     body: formData
        // })
        return Fetch.postForm('/s3/v1/uploadBlob', formData)
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                // Error handling: if the file upload failed
                return onResults(`Error: ${response.errMsg}`, null);
            }
            // {
            //     "originalname": "blob",
            //     "key": "afc41930b940d7b1a7e818cb49a566aa",
            //     "contentType": "image/jpeg",
            //     "metadata": {
            //       "mimetype": "image/jpeg",
            //       "fieldname": "file",
            //       "originalname": "blob"
            //     },
            //   }
            return onResults(null, response);
        })
        .catch(err => {
            return onResults('Oh, no. The file cannot be uploaded currently. We are sorry for the inconvenience.', null);
        });
    }

    /**
     * query the image file from S3 bucket
     * @param {str} filekey 
     * @param {str} folder 
     * @param {func} onResults 
     * usage:
        S3B = new S3Wrapper();
        S3B.getImage('your-file-key', 'folder', (err, data) => {

        });
     */
    getImage(filekey = '', folder = '', onResults)
    {
        // return fetch('/s3/v1/getImagesFromFolder', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
        //         filekey: filekey,
        //         folder: folder
        //     })
        // })
        return Fetch.post('/s3/v1/getImagesFromFolder', {
            filekey: filekey,
            folder: folder
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success) throw response
            return onResults(null, response.data);
        })
        .catch(err => {
            // console.log(err)
            return onResults('The system failed to retrieve your window sill images.', null);
        })
    }
    /**
     * TODO: create a function to post request to the S3 bucket
     */
    removeObject(filekey = '', folder = '', onResults)
    {
        
        // return fetch(`/s3/v1/deleteS3File/${filekey}/${folder}`, {
        //     method: 'DELETE',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     })
        // })
        return Fetch.delete(`/s3/v1/deleteS3File/${filekey}/${folder}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults(response.message, null);
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults('The system failed to delete your images.', null);
        });
    }
}