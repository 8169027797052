import methods from '../methods'
import { combineReducers } from 'redux'
// import FetchWrapper from '../utilities/FetchWrapper'
// const Fetch = new FetchWrapper()

const authState = {
    // username: '',
    accessToken: '',
    isLoggedIn: false
}

const session = (state = {}, action) => {
  switch (action.type) {
    case methods.LOAD_SESSION:
      return action.payload
    case methods.CLEAR_SESSION:
      return {}
    default: return state
  }
}

const auth = (state = authState, action) => {
  switch (action.type) {
    case methods.LOGIN:
      // call the API to login and check username
      return action.payload
    case methods.LOGOUT:
        return authState
    default:
      return state
  }
}

const loginErr = (state = {}, action) => {
  // console.log(action.payload)
  switch(action.type) {
    case methods.LOGIN_ERR:
      return action.payload
    case methods.CLEAR_LOGIN_ERR:
        return {}
    default:
      return state
  }
}

const registerFeedback = (state = '', action) => {
  switch(action.type) {
    case methods.REGISTER_MSG:
      return action.payload
    case methods.CLEAR_REGISTER_ERR:
      return ''
    default:
      return state
  }
}

let defaultAccountInfo = {
  nickname: 'visitor',
  deceased: 'the deceased',
  house: null,
  view: null
}
const accountInfo = (state = defaultAccountInfo, action) => {
  // console.log('account info updated ', action.payload)
  let newState = {...state}
  switch(action.type) {
    case methods.LOAD_ACCOUNT_INFO:
      const newProfile = action.payload
      newState = Object.assign(newState, newProfile)
      // console.log(newProfile)
      // Object.keys(newProfile).map(k => {
      //   newState[k] = newProfile[k]
      // }) 
      // console.log(newState)

      return newState || defaultAccountInfo
    case methods.UPDATE_ACCOUNT_INFO:
      Object.keys(action.payload).map((k, i) => {
        newState[k] = action.payload[k]
      })
      return newState
    default: return state
  }
}

export default combineReducers({ session, auth, loginErr, registerFeedback, accountInfo })
  