import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from "react-tooltip"
import NavBar from '../containers/NavBar'
import SystemMessage from '../assets/v2/SystemMessage'
import ConfirmBox from '../assets/v2/ConfirmBox'
import { S3Wrapper } from '../classes/s3bucket'
import GetImg from '../assets/GetImg'
import PH from '../classes/v2/ProfileHelper'
import PasswordFeedback from '../assets/v2/PasswordFeedback'
import BeatLoader from "react-spinners/BeatLoader"
import { colors } from '../init/colors'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import config from '../init/config'
// import { RectBtnPlain } from '../assets/v2/Buttons'
import { faSortUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Footer } from '../assets/footer'
import { activitiesFeedback } from '../init/initApp'
import JournalPreview from '../assets/pagination'
import { houses, views, defaultHomeBaseObj, defaultViewsObj, defaultVal } from '../init/initApp'
import AH from '../classes/v2/AmplifyHelper'
import EH from '../classes/v2/ErrorHelper'

// classes
import STM from '../classes/v2/SystemTimeManage'
import OF from '../classes/v2/OfferingHelper'

import './Dashboard.scss'

const homebaseObj = defaultHomeBaseObj()
const viewsObj = defaultViewsObj()
const S3B = new S3Wrapper()

class Dashboard extends React.Component {

    constructor(props)
    {
        super(props)
        this.state = {
            since: null,
            // offeringImg: OF.returnOfferingDefaultImg(this.props.config.view),
            attemptToRetrieve: 5,
            imgRetrieved: false,
            entries: [],
            messageKeys: [],
            loggedIn: false,
            displayAccount: false
        }
        this.enterMH = this.enterMH.bind(this);
        this.changeView = this.changeView.bind(this)
        this.confirmReset = this.confirmReset.bind(this)
        // this.checkCurrentVisitDay = this.checkCurrentVisitDay.bind(this)
        // this.getLatestOfferingImg = this.getLatestOfferingImg.bind(this)
        this.toggleAccount = this.toggleAccount.bind(this)
        this.updateAccount = this.updateAccount.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
    }

    async componentDidMount()
    {
        // console.log(this.props)
        // get the last offering image, if no, then display the default view image
        // const offeringImg = await this.getLatestOfferingImg()

        this.setState({
            // loggedIn,
            // offeringImg: offeringImg,
            since: this.props.accountInfo.since
        })
    }

    async updateAccount(json)
    {
        try
        {
            const inputData = {
                user_first_name: json.firstName || defaultVal.homeOwner,
                deceased_first_name: json.deceasedName || defaultVal.deceasedName,
                redcap_id: json?.redcapId || ''
            }
            // console.log(inputData)
            const updateResult = await this.props.manageAccountInfo(this.props.auth.id, inputData)
            // console.log(updateResult)
            return updateResult
        }
        catch(e)
        {
            return false
        }

    }

    async updatePassword(newPass = '')
    {
        // console.log(newPass)
    }

    async confirmReset(type = 'house', confirm = true)
    {
        if(!confirm)
        {
            return this.setState({
                confirmMessage: '',
                toConfirmAction: null
            })
        }
        // console.log('users confirm to reset')
        // const self = this
        try
        {
            switch(type)
            {
                case 'view':
                    const viewReset = await this.props.upsertConfig(this.props.auth.id, { view: null })
                break
                case 'house':
                default:
                    const houseReset = await this.props.upsertConfig(this.props.auth.id, { house: null })
            }
            // console.log('reset complete')
            this.props.enableResetConfig(true)
            this.props.history.push(config.paths.config)
        }
        catch(e)
        {
            // console.log(e)
        }
    }
    // async getLatestOfferingImg()
    // {
    //     const defaultImgUrl = await OF.returnOfferingDefaultImg(this.props.accountInfo.view)

    //     try
    //     {
    //         const offeringImg = await OF.getLastOfferingImg(this.props.auth.id, (e, d) => {
    //             if(e) return false
    //             return d.offering_img
    //         })
    //         // console.log(offeringImg)
            
    //         if(offeringImg === undefined || offeringImg === null || !offeringImg)
    //         {
    //             return defaultImgUrl
    //         }
    //         // console.log(`get offering img key: ${offeringImg}`)
    //         return S3B.getImage(offeringImg, 'ritual_imgs', (err, data) => {
    //             // console.log(err)
    //             if(err)
    //             {
    //                 return defaultImgUrl
    //             }
    //             // console.log(data)
    //             return data
    //         })
    //     }
    //     catch(e)
    //     {
    //         // console.log(e)
    //         return defaultImgUrl
    //     }
    // }

    enterMH()
    {
        // get the selected view from config and redirect users to the next page with the view
        return this.props.history.push(`/${this.props.lang}${config.paths.memorial}?sh=${this.props.accountInfo.house}&sv=${this.props.accountInfo.view}`)
        // return window.location.href = `/${this.props.lang}/mainapp?sh=${this.props.config.house}&sv=${this.props.config.view}`;
    }

    changeView(target = 'house')
    {
        let msg = this.props.dict.house.resetConfirm
        let replaceStr = target === 'house' ? this.props.dict.house.LMH : this.props.dict.house.view
        this.setState({
            confirmMessage: msg.replace('[house]', replaceStr),
            toConfirmAction: () => this.confirmReset(target, true),
            toRejectAction: () => this.confirmReset(target, false)
        })
    }

    toggleAccount()
    {
        this.setState({
            displayAccount: !this.state.displayAccount
        })
    }


    render()
    {
        if(this.props.accountInfo.house === null || this.props.accountInfo.view === null)
        {
            // console.log('dashboard: the house and view might be empty')
            return (<React.Fragment>
            {this.state.messageKeys.length > 0 ?
                <SystemMessage
                    messageKeys={this.state.messageKeys || []}
                    messageObj={this.props.dict['systemMessages']}
                    ctnStyle={{backgroundColor: 'transparent'}}
                /> : null}
            </React.Fragment>)
        }
        // console.log(this.props.accountInfo)
        // console.log(this.props.dict)
        const initApp = this.props.dict.init.initApp
        let activityLogsCont = []
        if(this.props.currentDay && this.props.currentDay.offering_data)
        {
            const offeringData = JSON.parse(this.props.currentDay.offering_data) || {};
            Object.keys(offeringData).forEach((key, idx) => {
                const targetKey = offeringData[key].originKey;
                if(activitiesFeedback()[targetKey])
                {
                    activityLogsCont.push(<li className='house-activity-log' key={idx}>{activitiesFeedback()[targetKey]}</li>);
                }
            });
        }
        else
        {
            activityLogsCont.push(<li className='house-activity-log' key={1}>{this.props.dict.house.clickToDecorate}</li>)
        }
        return (<React.Fragment>
            <NavBar {...this.props} isLoggedIn={this.props.loggedIn} withLogout={this.props.loggedIn} />
                {this.state.confirmMessage && this.state.confirmMessage !== ''
                    ?
                    <ConfirmBox
                        confirmMessage={this.state.confirmMessage}
                        reject={() => this.state.toRejectAction()}
                        confirm={() => this.state.toConfirmAction()}
                    />
                :null}
                <div className='dashboard-home-wrapper'>
                    <div className={"carousel-item dashboard-home-img active"}>
                        <GetImg
                            local // when the image is locally hosted
                            className={["d-block w-100"]}
                            onClick={() => null}
                            imageUrl={homebaseObj.imgSrcs[this.props.accountInfo.house]['url_md']} // "name-of-the-img.jpg"
                            srcset={{
                                sm: homebaseObj.imgSrcs[this.props.accountInfo.house]['url'],
                                md: homebaseObj.imgSrcs[this.props.accountInfo.house]['url_md'],
                                lg: homebaseObj.imgSrcs[this.props.accountInfo.house]['url_lg'],
                                xl: homebaseObj.imgSrcs[this.props.accountInfo.house]['url_lg']
                            }}
                            alt={initApp[this.props.accountInfo.house]['name']}
                        />
                        {/*<GetImg className="d-block w-100" src={require('../../img/house1-md.jpg')} alt={''} />*/}
                    </div>

                    <div className='d-flex house-caption-container responsive-options-container'>
                        <AccordionBox 
                            title={this.props.dict.house['welcome']}
                            desc={`${`${this.props.dict.house.welcomeHome.replace('[the deceased]', this.props.accountInfo.deceased).replace('[user]', this.props.accountInfo.nickname)}`}`}
                        >
                        <div className='w-100' style={{ position:'relative', height: '25px' }}>
                        <FontAwesomeIcon className='mh-paragraph-dark' data-tip={this.props.dict.house.clickToReset.replace('[house]', this.props.dict.house.myLMH)} onClick={() => this.changeView('house')} icon={faEdit} style={{ width: '25px', height: '25px', cursor: 'pointer', position: 'absolute', right: 0 }} />
                        </div>
                        <div className='w-100 d-none d-lg-block mx-auto py-1'>
                        <p className='mh-paragraph-dark text-left'>{initApp[this.props.accountInfo.house]['disc']}</p>
                        </div>
                        <div className='w-100 d-none d-lg-block mx-auto py-1 pt-3'><p className='mh-paragraph-dark p-0 m-0'>{this.props.dict.house.myMemoryHomeSince} {STM.formatLocalTime(this.props.accountInfo.since) || this.props.dict.house.noDataYet}</p></div>
                        <div className='w-100 d-none d-lg-block mx-auto py-1'><p className='mh-paragraph-dark p-0 m-0'>Days since the first visit of {initApp[this.props.accountInfo.house]['name'] || this.props.dict.house.myMH}: {' '}
                            {`${STM.calcDaysOfVisit(this.props.accountInfo.since)} ${this.props.dict.house.days}` ||  this.props.dict.house.noDataYet}</p>
                        </div>
                            <div className='row d-flex justify-content-between px-3 pt-lg-2'>
                                <div className='col-xs-4 text-center d-flex flex-column align-items-center py-2 house-icon-container' 
                                    data-tip={this.props.dict.house['clickToMemorialSpace']}
                                    onClick={() => this.props.history.push(config.paths.memorial)}>
                                    <GetImg
                                        local // when the image is locally hosted
                                        className={["d-block dashboard-house-icon"]}
                                        onClick={() => null}
                                        imageUrl={require('../../img/school_icon.svg')} // "name-of-the-img.jpg"
                                        srcset={{
                                            sm: require('../../img/school_icon.svg'),
                                            md: require('../../img/school_icon.svg'),
                                            lg: require('../../img/school_icon.svg'),
                                            xl: require('../../img/school_icon.svg')
                                        }}
                                        alt={this.props.dict.house['myMemorialSpace']}
                                    />
                                    <p className='dashboard-option-text'>{this.props.dict.house['myMemorialSpace'].toUpperCase()}</p>
                                </div>
                                <div className='col-xs-4 text-center d-flex flex-column align-items-center py-2 house-icon-container' 
                                    data-tip={this.props.dict.house['clickToDiary']}
                                    onClick={() => this.props.history.push(config.paths.diary)}>
                                    <GetImg
                                        local // when the image is locally hosted
                                        className={["d-block dashboard-house-icon"]}
                                        onClick={() => null}
                                        imageUrl={require('../../img/book.svg')} // "name-of-the-img.jpg"
                                        srcset={{
                                            sm: require('../../img/book.svg'),
                                            md: require('../../img/book.svg'),
                                            lg: require('../../img/book.svg'),
                                            xl: require('../../img/book.svg')
                                        }}
                                        alt={this.props.dict.house['myWritingDesk']}
                                    />
                                    <p className='dashboard-option-text'>{this.props.dict.house['myWritingDesk'].toUpperCase()}</p>
                                </div>
                                <div className='col-xs-4 text-center d-flex flex-column align-items-center py-2 house-icon-container' 
                                    data-tip={this.props.dict.house['clickToEditAccount']}
                                    onClick={() => this.toggleAccount()}>
                                    <GetImg
                                        local // when the image is locally hosted
                                        className={["d-block dashboard-house-icon"]}
                                        imageUrl={require('../../img/account_icon.svg')} // "name-of-the-img.jpg"
                                        srcset={{
                                            sm: require('../../img/account_icon.svg'),
                                            md: require('../../img/account_icon.svg'),
                                            lg: require('../../img/account_icon.svg'),
                                            xl: require('../../img/account_icon.svg')
                                        }}
                                        alt={this.props.dict.house['account']}
                                    />
                                    <p className='dashboard-option-text'>{this.props.dict.house['account'].toUpperCase()}</p>
                                </div>
                            </div>
                        </AccordionBox>

                    </div>
                    {this.state.displayAccount ? <AccordionBox 
                        title={this.props.dict.house['account']}
                        desc={this.props.dict.house['editAccount']}
                        extraClass='account-info-box'
                    >
                        <AccountInfo 
                            isAdmin={this.props?.auth?.role === 0}
                            firstNameLabel={this.props.dict.house['firstName']}
                            deceasedNameLabel={this.props.dict.house['deceasedName']}
                            submitBtnLabel={this.props.dict.house['submit']}
                            changePassword={this.props.dict.house['changePassword']}
                            passwordLabel={this.props.dict.house['password']}
                            oldPasswordLabel={this.props.dict.house['oldPass']}
                            confirmPasswordLabel={this.props.dict.house['password']}
                            passwordValidMsg=''
                            passwordCriteriaMsg={this.props.dict.house['redefinePass']}
                            oldPassWrongMsg={this.props.dict.house['oldPassWrong']}
                            failedUpdatePassMsg={this.props.dict.house['failedUpdate']}
                            successUpdateMsg={this.props.dict.house['successfullyResetPass']}
                            updatedMsg={this.props.dict.house['update']}
                            failedUpdateMsg={this.props.dict.house['updateFailed']}
                            onSubmit={this.updateAccount}
                            onUpdatePassword={this.updatePassword}
                            profile={this.props.accountInfo}
                        />
                    </AccordionBox>:null}
                </div>

            {/*<div className="login-wrapper col-xs-12 col-sm-12 col-md-12 col-lg-12" id="login-wrapper" style={loginWrapperStyle}>
                <div className='row'>
                    <TitleWrapper
                        title={this.state.title}
                        handlePress={this.handlePress}
                    />
                    <TogglePanel currentPanel={this.state.currentPanel} />
                </div>
            </div>*/}

            {this.state.entries.size > 0 ?
                <JournalPreview
                    entries={this.state.entries}
                    userData={this.props.userData}
                    dayLogs={this.props.dayLogs}
                    view={this.props.config.view}
                >
                    <JournalPreview.Section>
                        <JournalPreview.Activity></JournalPreview.Activity>
                    </JournalPreview.Section>
                    <JournalPreview.Section>
                        <JournalPreview.Article />
                    </JournalPreview.Section>
                </JournalPreview>
                :
                null}
                <ReactTooltip />
            <Footer />
            {this.state.messageKeys.length > 0 ?
                <SystemMessage
                    // messageGroupsKey={['general', 'homebase']}
                    messageKeys={this.props.messageKeys || []}
                    messageObj={this.props.dict['systemMessages']}
                    ctnStyle={{backgroundColor: 'transparent'}}
                /> : null}
        </React.Fragment>)
    }
}

/**
 * @param {*} props 
 * usage:
    <AccordionBox 
        title=''
        desc=''
    >
        {nestedComponents}
    </AccordionBox>
 */
const AccordionBox = props => {
    const [extended, updateExtended] = useState(true)
    const { extraClass } = props
    return(<div className={extended ? `p-4 config-descrition-box house-option-box ml-2 ${extraClass}` : `p-4 config-descrition-box closed ml-2 ${extraClass}`} style={{ position: 'relative' }}>
        <h3 className='dashboard-welcome-message' onClick={e => updateExtended(!extended)}  >{props.title}<FontAwesomeIcon icon={extended ? faSortUp : faCaretDown} className='d-none d-lg-block float-right cursor-pointer config-toggle-icon' style={{ width: '30px', height: '30px' }} /></h3>
        <p className='d-none d-lg-block'>{props.desc}</p>
        {props.children}
    </div>)
}
/**
 * usage:
    <AccountInfo 
        firstNameLabel=''
        deceasedNameLabel=''
        submitBtnLabel=''
        changePassword=''
        passwordLabel=''
        oldPasswordLabel=''
        confirmPasswordLabel=''
        passwordValidMsg=''
        updatedMsg=''
        onSubmit={function}
        onUpdatePassword={function}
    />
 */
const AccountInfo = props => {
	const [password, updatePassword] = useState('')
	const [oldPassword, updateOldPassword] = useState('')
	const [firstName, updateFirstName] = useState(props.profile.nickname)
	const [deceasedName, updateDeceasedName] = useState(props.profile.deceased)
    const [redcapId, updateRedcapId] = useState(props.profile?.redcap_id || '')
    const [loading, updateLoading] = useState(false)
    const [errMsg, updateErrMsg] = useState('')
    const [activePanel, updateActivePanel] = useState('profile')

    const [validateResult, updateValidateResult] = useState({})
    console.log(props)
    const switchPanel = (activePanel = 'profile') => {
        switch(activePanel){
            case 'profile':
                return <form onSubmit={e => e.preventDefault()} className='col-sm-12 mt-4'>
    
                    <div className="form-group">
                        <label htmlFor="firstName">{props.firstNameLabel}</label>
                        <input type="text" value={firstName} onChange={e => updateFirstName(e.target.value)} className="form-control" id="firstName" placeholder={props.firstNameLabel} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="deceasedName">{props.deceasedNameLabel}</label>
                        <input type="text" value={deceasedName} onChange={e => updateDeceasedName(e.target.value)} className="form-control" id="deceasedName" placeholder={props.deceasedNameLabel} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="redcapId">{"REDCap ID"}</label>
                        <input type="text" value={redcapId} onChange={e => updateRedcapId(e.target.value)} className="form-control" id="redcapId" placeholder={"REDCap ID"} />
                    </div>
    
                    <div className='w-100 d-flex justify-content-between mx-auto'>
        
                        {loading ? 
                            <>
                            <BeatLoader
                                css={`size: 15;`}
                                size={15}
                                color={colors.lightYellow}
                                loading={loading}
                            />
                            </>
                            :
                            <button type="submit" className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
                                e.preventDefault()
                                updateLoading(true)
        
                                // update user profile
                                const updateResult = await props.onSubmit({
                                    firstName: firstName,
                                    deceasedName: deceasedName,
                                    redcapId: redcapId || ''
                                })
        
                                // console.log(updateResult)
                                updateLoading(false)
        
                                if(updateResult)
                                {
                                    updateErrMsg(props.updatedMsg)
                                    return
                                }
                                updateErrMsg(props.failedUpdateMsg)
                                return
                                // allow to register
                                // const r = await props.signup(username, password, deceasedName, firstName)
        
                                // if(!r)
                                // {
                                //     return updateLoading(false)
                                // }
                                // updateLoading(false)
                                // props.switchPage('confirmRegister')
                                // console.log(r)
        
                            }}>{props.submitBtnLabel}</button>}
                    </div>
                    {errMsg ? <div className='w-100 mt-4 alert alert-success'><p>{errMsg}</p></div> : null}
        
                </form>
            case 'password':
                return <form onSubmit={e => e.preventDefault()} className='col-sm-12 mt-4'>
                <div className="form-group" >
                    <label htmlFor="inputOldPassword">{props.oldPasswordLabel}</label>
                    <input value={oldPassword} onChange={e => {
                        // let v = PH.validatePassword(e.target.value)
                        // updatePasswordChanged(true)
                        // updateValidateResult(v)
                        updateOldPassword(e.target.value)
                    }} type="password" className="form-control" id="inputOldPassword" placeholder={props.oldPasswordLabel} />
                </div>
                <div className="form-group" >
                    <label htmlFor="inputPassword">{props.passwordLabel}</label>
                    <input value={password} onChange={e => {
                        let v = PH.validatePassword(e.target.value)
                        // console.log(v)
                        // updatePasswordChanged(true)
                        updateValidateResult(v)
                        updatePassword(e.target.value)
                    }} type="password" className="form-control" id="inputPassword" placeholder={props.passwordLabel} />
                    <PasswordFeedback {...validateResult} />
                </div>
                {/*<div className="form-group">
                    <label htmlFor="confirmPassword">{`${lang.LoginRegisterPanel.confirmPassword}*`}</label>
                    <input type="password" value={confirmPassword} onChange={e => updateConfirmPassword(e.target.value)} className="form-control" id="confirmPassword" placeholder={lang.LoginRegisterPanel.confirmPasswordPlaceholder} />
                </div>*/}
                <div className='w-100 d-flex justify-content-between'>
    
                    {loading ? 
                        <>
                        <BeatLoader
                            css={`size: 15;`}
                            size={15}
                            color={colors.lightYellow}
                            loading={loading}
                        />
                        </>
                        :
                        <button type="submit" className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
                            e.preventDefault()
                            updateLoading(true)
    
                            
                            if(!validateResult.lowercase || !validateResult.uppercase || !validateResult.number || !validateResult.length || !validateResult.special)
                            {
                                // props.addLoginErr({
                                //     code: 'PasswordNotValid',
                                //     message: 'Please redefine your password to meet the criteria.'
                                // })
                                updateErrMsg(props.passwordCriteriaMsg || 'Please redefine your password to meet the criteria.')
                                return updateLoading(false)
                            }
    
                            // update user password
                            const updatePass = await AH.changePassword(oldPassword, password)
                            // console.log(updatePass)
                            // const updateResult = await props.onUpdatePassword(password)
                            const currentUser = await AH.getUser()
                            // console.log(currentUser.username)
                            // console.log(updateResult)
                            if(updatePass === 'SUCCESS')
                            {
                                updateErrMsg(props.successUpdateMsg || 'You have successfully updated your password')
                                updateLoading(false)
                                return
                            }
                            if(updatePass.hasOwnProperty('code'))
                            {
                                switch(updatePass.code)
                                {
                                    case 'NotAuthorizedException':
                                        updateErrMsg(props.oldPassWrongMsg || 'Please double check your old password.')
                                    break
                                    default:
                                        updateErrMsg(props.failedUpdatePassMsg || 'The system has failed to update your password. Please try again later.')
                                        EH.sendErrorEmail(currentUser.username, updatePass.code, updatePass.message)
                                }
                            }
                            updateLoading(false)
                            return
                        }}>{props.changePassword}</button>}
    
                </div>
                {errMsg ? <div className='w-100 mt-4 alert alert-success'><p>{errMsg}</p></div> : null}
            </form>

            case 'admin':
                return <div className='row justify-content-center my-5'>
                <button className="btn btn-lg btn-primary lmh-login-btn-primary" onClick={async e => {
                    e.preventDefault()
                    window.location.replace('/adminboard')

                }}>Admin</button>
                </div>
        }
    }

    const { isAdmin } = props

    return (<React.Fragment>

        <ul className="nav nav-tabs">
        <li className="nav-item" onClick={e => updateActivePanel('profile')}>
            <a className={activePanel === "profile" ? "nav-link active account-manage-nav":"nav-link account-manage-nav"} aria-current="page" href="#">Profile</a>
        </li>
        <li className="nav-item" onClick={e => updateActivePanel('password')}>
            <a className={activePanel === "password" ? "nav-link active account-manage-nav":"nav-link account-manage-nav"} href="#">Password</a>
        </li>
        {isAdmin ? <li className="nav-item" onClick={e => updateActivePanel('admin')}>
            <a className={activePanel === "admin" ? "nav-link active account-manage-nav":"nav-link account-manage-nav"} href="#">Admin</a>
        </li>
        :null}
        </ul>
        {switchPanel(activePanel)}
        </React.Fragment>)
}



export default Dashboard