import STM from '../components/classes/v2/SystemTimeManage'
import methods from '../methods'
import ErrorHelper from '../components/classes/v2/ErrorHelper'
import AH from '../components/classes/v2/ActivityLogHelper'
import JH from '../components/classes/v2/JournalHelper'
import AmplifyHelper from '../components/classes/v2/AmplifyHelper'

// import { StorageGateway } from 'aws-sdk'

// save the data every 30 seconds
/**
 * Determine if at least 12 hours have passed since the last login time and user can skip to the next day session
 * @param {int} userId 
 */
const getCurrentDayLogs = userId => async dispatch => {
    // console.log('clear day')
    // dispatch({
    //     type: methods.LOAD_DAY_LOG,
    //     payload: {}
    // })
    console.log('reducer get current day')
    try {
        console.log('reducer getcurrentdaylogs called')
        const currentDayLogs = await STM.getLatestDayLog(userId, (e, d) => {
            if (e) return false
            return d
        })
        console.log(currentDayLogs)
        if (!currentDayLogs || Object.keys(currentDayLogs).length <= 0 || STM.shouldSetNewDay(currentDayLogs.created_at)) // user just initiated the first day
        {
            console.log('set a new day')
            const newDay = await STM.setNewDay(userId, r => r)
            console.log(`is new day ${newDay}`)
            console.log(newDay)
            if(!newDay.hasOwnProperty('code'))
            {
                dispatch({
                    type: methods.LOAD_DAY_LOG,
                    payload: newDay
                })
                return true
            }
            throw newDay
        }

        console.log('no need to set a new day. use the same day')

        dispatch({
            type: methods.LOAD_DAY_LOG,
            payload: currentDayLogs
        })
        return false
    }
    catch (e) {
        console.log(e)
        ErrorHelper.sendErrorEmail(userId, e.code, e.message)
        return false
    }
}

const updateMemorialStepLog = (dayLogId, activityLog) => async dispatch => {
    // console.log('pass activity log to the action creator')
    // console.log(activityLog)
    const username = await AmplifyHelper.getUsername()
    // console.log("user is ", username)
    // store the activity log in the database
    try
    {
        const r = await AH.upsertMemorialLogs(username, dayLogId, activityLog)
        // console.log(`activity log inserted `, r)
        return dispatch({
            type: methods.UPDATE_MEMORIAL_STEP_LOG,
            payload: activityLog
        })
    }
    catch(e)
    {
        // console.log(e)
        return e
    }
}

const addActivityLog = (userId, dayLogId, day, type, activityKey, fieldData) => async dispatch => {
    try {
        // update the dayLogs
        const save = await AH.upsertActivityLog(userId, dayLogId, day, type, activityKey, fieldData)
        // console.log(save)
        if(Array.isArray(save) && save.length > 0)
        {
            dispatch({
                type: methods.ADD_ACTIVITY_LOG,
                payload: save[0]
            })
        }
        // console.log(save)
        // dispatch({
        //     type: methods.ADD_ACTIVITY_LOG,
        //     payload: {
        //         userId,
        //         dayLogId,
        //         activityObj
        //     }
        // })
        return true
    }
    catch (e) {
        ErrorHelper.sendErrorEmail(userId, e.code, e.message)
        return false
    }
}

const loadActivityLog = (userId, dayLogId) => async dispatch => {
    // console.log('load activities in a day called')
    try
    {
        const logs = await AH.getAllActivitiesInDay(userId, dayLogId)
        // console.log(logs)
        return dispatch({
            type: methods.LOAD_ACTIVITY_LOG,
            payload: logs || []
        })
    }
    catch(e)
    {
        return false
    }
}

const getCurrentJournalDay = (usersId, dayLogsId, day) => async dispatch => {
    if(!usersId || !dayLogsId || !day) return false
    try
    {
        // console.log(`action: get the journal day of ${usersId} with day logs id ${dayLogsId} on ${day} day`)
        const r = await JH.getCurrentJournalDay(usersId, dayLogsId, day, (e, day) => {
            // console.log(e)
            // console.log(day)
            if(e) throw e
            return day
        })

        console.log(`the day from reducer is ${r}`)
        const entries = await JH.loadDayJournalEntries(usersId, dayLogsId, r)
        console.log(entries)
        // console.log(r)
        dispatch({
            type: methods.LOAD_CURRENT_JOURNAL_DAY,
            payload: parseInt(r, 10)
        })
        dispatch({
            type: methods.LOAD_DAY_JOURNALS,
            payload: entries
        })
        return r
    }
    catch(e)
    {
        console.log(`error happened while trying to get the journal day`)
        console.log(e)
        return false
    }
}

const addDayJournalEntry = (id, usersId, dayLogId, journalDay, typeKey, qKey, journalEntry = '', juornalEntryHash = '') => async dispatch => {
    console.log(`the received id is ${id} and the type ${typeKey} with question ${qKey}`)
    if(!id || !usersId || !dayLogId || !journalDay || !typeKey || !qKey) return false
    console.log(`received id is ${id}`)
    try
    {
        const newEntryObj = {
            id: id,
            users_id: usersId,
            day_logs_id: dayLogId,
            question_day_key: typeKey,
            journal_day: journalDay,
            question_key: qKey,
            journal_entry: journalEntry,
            journal_entry_hash: juornalEntryHash
        }
        console.log("the new entry obj ",newEntryObj) 
        dispatch({
            type: methods.ADD_DAY_JOURNALS,
            payload: newEntryObj
        })
        return newEntryObj
    }
    catch(e)
    {
        return false
    }
}

const removeJournalEntry = (id, idx) => async dispatch => {
    // console.log('remove journal entry called')
    // const deleteResult = await JH.deleteJournalEntry(id)
    // console.log(deleteResult)
    // console.log(deleteResult)
    // console.log(`the id is ${id} and idx is ${idx}`)
    return dispatch({
        type: methods.REMOVE_DAY_JOURNALS,
        payload: id
    })
    // return true
}

const updateJournalEntries = (entries = []) => ({
    type: methods.UPDATE_DAY_JOURNAL,
    payload: entries
})

export {
    getCurrentDayLogs,
    addActivityLog,
    loadActivityLog,
    updateMemorialStepLog,
    addDayJournalEntry,
    getCurrentJournalDay,
    removeJournalEntry,
    updateJournalEntries
}