import React, { Component } from 'react'
import './assets.scss'
/**
 * display a small square pop up from bottom or top to show the short feedback message when system has completed a command and need to inform user
 * usage:
    import Toast from './assets/Toast';
    <Toast
        message='entry saved'
        duration={3000}
    />
 */

export class Toast extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            active: true
        }
        this.close = this.close.bind(this);
    }
    componentDidMount()
    {
        var self = this
        let duration = 3000
        if(this.props.duration)
        {
            duration = this.props.duration
        }
        setTimeout(function() {
            self.setState({
                active: false
            })
        }, duration)
    }
    close() {
        this.setState({
            active: false
        });
    }
    render()
    {
        if(this.state.active)
        {
            return (
                <div role="alert" aria-live="assertive" aria-atomic="true" className="toast show" data-autohide="false">
                    <div className="toast-header" style={{
                        // backgroundColor: '#B6BA9F',
                        // color: '#262D20'
                    }}>
                        <strong className="mr-auto">{this.props.message}</strong>
                        <button className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" onClick={this.close}>
                         <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <div role="alert" aria-live="assertive" aria-atomic="true" className="toast fade" data-autohide="false">
                <div className="toast-header" style={{
                    // backgroundColor: '#B6BA9F',
                    // color: '#262D20'
                }}>
                    <strong className="mr-auto">{this.props.message}</strong>
                    <button className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" onClick={this.close}>
                        <span style={{ color: '#fff' }} aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default Toast