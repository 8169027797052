exports.config = {
    aws: {
        Auth: {
            // identityPoolId: 'us-east-1:9710b08e-646a-46e2-bba8-b14becc9bf60',
            // REQUIRED - Amazon Cognito Region
            region: 'us-east-1',
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: 'us-east-1_uC0tiolvy',
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: '6amk16klhev9rrobr129v1oe93',
        }
    }
}