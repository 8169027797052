import React, { Component } from 'react';
// import { CognitoWrapper } from '../classes/cognito';
import AWSCognito from '../classes/v2/AmplifyHelper'
import config from '../init/config';
import { NavItems } from '../init/initApp';
import { RectBtnPlain } from './buttons';
import { Localstorage } from '../classes/localstorage';
import { SystemTimeManagement, SystemLogs } from '../classes/systemConfig';
// import { Userdata } from '../classes/user';
import User from '../classes/v2/UserHelper'
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps} from '../lang/langRedux';

// var AWSCognito = new CognitoWrapper(config);
const LS = new Localstorage();
const STM = new SystemTimeManagement();
const SLG = new SystemLogs();
// const User = new Userdata();


/**
 * Define default values for homebase
 */

export default function Banner() {
    const bannerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '50%',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
    const spanStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '50%',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
    return(
        <div className="col-xs-12 banner justify-content-around mr-3">
            <a href={"/" + this.props.lang} style={bannerStyle}>
                <img className="banner-logo" src={require('../../img/logo.svg')} />
                <span style={spanStyle}>LIVING MEMORY HOME</span>
            </a>
            <div className='banner-logout-cont'>
                <RectBtnPlain
                    key='homebase'
                    btnValue='homebase'
                    btnTitle='Homebase'
                    handleClick={() => { window.location.replace('/house') }}
                />
                <RectBtnPlain
                    key='space'
                    btnValue='Space'
                    handleClick={() => { window.location.replace('/mainapp') }}
                    btnTitle='Memorial Space'
                />
                <RectBtnPlain
                    key='desk'
                    btnValue='Desk'
                    handleClick={() => { window.location.replace('/diary') }}
                    btnTitle='Writing Desk'
                />
                <RectBtnPlain
                    key='resources'
                    btnValue='Grief Resources'
                    handleClick={() => { window.open('/resources', '_blank') }}
                    btnTitle='Grief Resources'
                />
            </div>
        </div>

    );
}



class UnconnectedNavBar extends Component {
    
    constructor(props){
        super(props);
        this.logout = this.logout.bind(this);
    }
    logout() {
        // remove the configs stored in the localstorage
        LS.removeAll(true);
        return AWSCognito.getSessionAccessToken((err, accessToken) => {
            if(err)
            {
                const errMsg = 'The system failed to retrieve your current session, hence you might not be able to log out immediately. Please bear with us, and we will fix it as soon as possible.';
                return SLG.recordErr(accessToken, 'FAILED_TO_LOGOUT', errMsg, (err, results) => {
                    return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                });
            }
            // parse the access token and proceed to check if user has logged in
            return AWSCognito.getUsernameFromAccessToken(accessToken, username => {
                if(!username) // the accessToken is not validated, log user out directly
                {
                    return fetch('/session/v1/closeSession')
                        .then(r => r.json())
                        .then(result => {
                            if(!result.success)
                            {
                                const errMsg = `No valid sessions were found. Please make sure you have logged in before.`;
                                // cannot store the logout request in MySQL database, disallow users to log out
                                return SLG.recordErr(accessToken, 'NO_VALID_SESSION_ON_LOGOUT', errMsg, (err, results) => {
                                    return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                });
                            }
                            return window.location.assign(`/${this.props.lang}`);
                        });
                }
                return User.returnUserId(userId => {
                    // first retrieve the daylog and check the login out time
                    return STM.getLatestDayLog(1, (err, data) => {
                        if(err) {
                            // cannot store the logout request in MySQL database, disallow users to log out
                            return SLG.recordErr(accessToken, err.errCode, err.message, (err, results) => {
                                return window.location.assign(`/${this.props.lang}/error?message=${err.message}`);
                            });
                        }
                        const logout_time = STM.getCurrentUTCTimestamp();
                        const fieldData = {
                            logout_time: logout_time
                        }
                        return STM.updateDayLogs(data.day_hash, fieldData, (err, data) => {
                            if(err)
                            {
                                const errMsg = `The system has encountered some problem when processing your signout request, please try again later.`;
                                // cannot store the logout request in MySQL database, disallow users to log out
                                return SLG.recordErr(accessToken, 'FAILED_TO_STORE_LOGOUT', errMsg, (err, results) => {
                                    return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                });
                            }
                            // now you can finally log the logout in the logout table
                            return SLG.recordLog(accessToken, false, (err, results) => {
                                if(err)
                                {
                                    const errMsg = `The system has encountered some problem when processing your signout request, please try again later.`;
                                    // cannot store the logout request in MySQL database, disallow users to log out
                                    return SLG.recordErr(accessToken, 'FAILED_TO_STORE_LOGOUT', errMsg, (err, results) => {
                                        return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                    });
                                }
                                // do something with the results message
                                return AWSCognito.legacySignOut((err, singoutResult) => {
                                    if(err)
                                    {
                                        const errMsg = `The system has encountered some problem when processing your signout request, please try again later.`;
                                        // cannot store the logout request in MySQL database, disallow users to log out
                                        return SLG.recordErr(accessToken, 'FAILED_TO_STORE_LOGOUT', errMsg, (err, results) => {
                                            return window.location.assign(`/${this.props.lang}/error?message=${errMsg}`);
                                        });
                                    }
                                    return window.location.assign(`/${this.props.lang}`);
                                });
                            });
                        });
                    });

                });
            });
        });
    }

    render()
    {
        // console.log(this.props)
        let items = []
        NavItems().forEach(item => {
            items.push(
                <li key={item.key} className="nav-item">
                    <a className={this.props.changingMH ? "nav-link disabled" : "nav-link" } href={this.props.changingMH ? null : ("/" + this.props.lang + item.url)} target={item.hasOwnProperty("target") ? item.target : ''} title="Please make your selection before visiting a different page">{item.title}<span className="sr-only"></span></a>
                </li>
            )
        })
        const spanStyle = {
            flexWrap: 'wrap',
            width: '50%',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }
        if(this.props.hasOwnProperty('isLoggedIn') && !this.props.isLoggedIn)
        {
            return(
                <nav className="navbar navbar-expand-lg lmh-navbar navbar-dark">
                    <a className="navbar-brand navbar-logo-cont" href={"/" + this.props.lang + "/house"}><img className="banner-logo" src={require('../../img/logo.svg')} />
                        <span className='ml-3 brand-name' style={spanStyle}>{this.props.dict.banner.lmh}</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href={"/" + this.props.lang + '/resources'} target='_blank' >Grief Resources<span className="sr-only"></span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            )

        }
        if(this.props.hasOwnProperty('isLoggedIn') && this.props.isLoggedIn)
        {
            return(
                <nav className="navbar navbar-expand-lg lmh-navbar navbar-dark">
                    <a className="navbar-brand navbar-logo-cont" href={"/" + this.props.lang}><img className="banner-logo" src={require('../../img/logo.svg')} />
                        <span className='ml-3 brand-name' style={spanStyle}>{this.props.dict.banner.lmh}</span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            {items}
                            <li className="nav-item">
                                <a className="nav-link" onClick={this.logout} href="#">{this.props.dict.banner.logout}<span className="sr-only"></span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            )
        }
        return(
            <nav className="navbar navbar-expand-lg lmh-navbar navbar-dark">
                <a className="navbar-brand navbar-logo-cont" href={"/" + this.props.lang}><img className="banner-logo" src={require('../../img/logo.svg')} />
                    <span className='ml-3 brand-name' style={spanStyle}>{this.props.dict.banner.lmh}</span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        {items}
                    </ul>
                </div>
            </nav>
        )
    }
}
// export const NavBar = connect(mapStateToProps, mapDispatchToProps)(UnconnectedNavBar);
export { UnconnectedNavBar }

// <IconRectBtnPlain
// key={'homebase'}
// btnValue={'homebase'}
// alt={'Return to homebase'}
// btnTitle={'Homebase'}
// handleClick={() => { window.location.replace('/house') }}
// />
// <IconRectBtnPlain
// key={'space'}
// btnValue={'space'}
// alt={'Memorial Space'}
// btnTitle={'Memorial Space'}
// handleClick={() => { window.location.replace('/mainapp') }}
// />
// <IconRectBtnPlain
// key={'desk'}
// btnValue={'desk'}
// alt={'Writing Desk'}
// btnTitle={'Writing Desk'}
// handleClick={() => { window.location.replace('/diary') }}
// />
// <IconRectBtnPlain
// key={'resources'}
// btnValue={'resources'}
// alt={'Grief Resources'}
// handleClick={() => { window.open('/resources', '_blank') }}
// btnTitle='Grief Resources'
// />
// <IconRectBtnPlain
// key={'logout'}
// btnValue={'logout'}
// alt={'Logout'}
// handleClick={this.logout}
// btnTitle='Logout'
// />


// ==========================================
//                 DEPRECATED
// ==========================================
