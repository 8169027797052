import p5 from 'p5';
import React from 'react';
import { makeid, postMessage, hash } from '../../init/general'
import { canvasDimensions, sessionKeys, s3folders } from '../../init/initApp'
import { Localstorage } from '../../classes/localstorage'
import { S3Wrapper } from '../../classes/s3bucket'
import defaultOffering from './defaultOffering'
import PreloadedDefaultOffering from './preloadedDefaultOffering'
import config from '../../init/config'
// import decoratePresets from './decoratePresets'

const LS = new Localstorage()
const S3B = new S3Wrapper()
// const STM = new SystemTimeManagement();
// const OF = new Offering()
// console.log(defaultOffering)
/**
 * Get the items from localStorage if the offering items are set before
 */
// let getImgUrl = '/api/v1/getS3Images';
/**
 * Define basic dimensions
 */
export const W_H_RATIO = canvasDimensions.W_H_RATIO, WINDOW_PADDING = canvasDimensions.WINDOW_PADDING, HEIGHT_RATIO = canvasDimensions.HEIGHT_RATIO;
let HEIGHT = window.innerHeight * HEIGHT_RATIO - WINDOW_PADDING || 798,
WIDTH = HEIGHT * W_H_RATIO,
PADDING = WIDTH / 120,
WIDTH_UNIT = WIDTH / 4 - PADDING * 2;
let BOTTOM_RIGHT_START_X_2 = WIDTH - WIDTH_UNIT,
    BOTTOM_RIGHT_START_X_1 = BOTTOM_RIGHT_START_X_2 - WIDTH_UNIT - PADDING,
    BOTTOM_LEFT_START_X_1 = 0,
    BOTTOM_LEFT_START_X_2 = BOTTOM_LEFT_START_X_1 + WIDTH_UNIT + PADDING;

export default function Sketch (p) {
    /**
     * 1. Init variables for height, width
     * 2. WIDTH_UNIT separates the canvas into 4 chunks to place the object,
     * the default width of each object should be relative to WIDTH_UNIT to ensure 
     * it is resized properly to be placed in the canvas
     * 3. define 4 start x starting from bottom right to bottom left 
     */
    let positioningOffering = false, retrievingOffering = false;
    let collisionDetectArr = {}, collidedAction = {}, collidedActionLeftKey = {}, collided = false, collidedItemKey='', collidedOtherOffering = false;
    let displayMouseHint = false, warningEnabled = false, warningMsgOpacity = 0, msgBoxBgColor = 'rgba(38, 45, 32, .8)', disableCanvasInteraction = false;
    // let instructionStep = 0;
    // const operationPanel = document.getElementById('operation-panel')
    // const systemMsgPanel = document.getElementById('system-msg-cont'); const systemMsgText = document.getElementById('system-msg');
    // This JSON stores all the assets that can be consumed in the canvas, they do not have to be displayed at the beginning
    // Callback defines the function when user's mouse hover over the asset

    let defaultAssets = defaultOffering, defaultKeys = Object.keys(defaultOffering)
    

    
    // used to create uploaded photos
    const createAsset = (originKey, url, widthUnitRatio, heightFilling, padding, callback) => {
        // let cb = null
        // if(defaultAssets[originKey].hasOwnProperty('callback') && typeof defaultAssets[originKey]['callback'] === 'string')
        // {
        //     cb = pairCallback(defaultAssets[originKey]['callback'], originKey)
        // }
        let obj = {
            originKey: originKey,
            url: originKey,
            // get startX() { return BOTTOM_RIGHT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / widthUnitRatio; },
            get heightFilling() { return PADDING * heightFilling; },
            get padding() { return PADDING * padding; },
            img: p.loadImage(url),
            callback: callback
        }
        return obj;
    }

    const createAsset2 = (originKey, url, widthUnitRatio, heightFilling, padding, startX, newKey) => {
        let cb = null
        // console.log(defaultAssets)
        if(defaultAssets[originKey].hasOwnProperty('callback') && typeof defaultAssets[originKey]['callback'] === 'string')
        {
            cb = pairCallback(defaultAssets[originKey]['callback'], newKey)
        }
        // console.log(`callback of ${originKey} is ${cb}`)
        let obj = {
            originKey: defaultAssets[originKey].originKey,
            url: url,
            get startX() { return BOTTOM_LEFT_START_X_2 * widthUnitRatio * startX; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / widthUnitRatio; },
            get heightFilling() { return PADDING * heightFilling; },
            get padding() { return PADDING * padding; },
            img: p.loadImage(url),
            callback: cb === null ? () => null : cb
        }
        return obj;
    }

    const createAsset3 = (originKey, widthUnitRatio, heightFilling, padding, startX, newKey) => {
        if(defaultAssets.hasOwnProperty(originKey))
        {
            const url = defaultAssets[originKey]['url']
            let cb = null
            if(defaultAssets[originKey].hasOwnProperty('callback') && typeof defaultAssets[originKey]['callback'] === 'string')
            {
                // console.log('the callback is '+defaultAssets[originKey].callback)
                cb = pairCallback(defaultAssets[originKey]['callback'], newKey)
            }
            let obj = {
                originKey: originKey,
                url: url,
                get startX() { return BOTTOM_LEFT_START_X_2 * widthUnitRatio * startX; },
                get width() { return WIDTH; },
                get height() { return HEIGHT; },
                get widthUnit() { return WIDTH_UNIT / widthUnitRatio; },
                get heightFilling() { return PADDING * heightFilling; },
                get padding() { return PADDING * padding; },
                img: p.loadImage(url),
                callback: cb === null ? () => null : cb
            }
            // console.log(`create asset 3 creating the asset ${originKey} with width ${WIDTH} and height ${HEIGHT} starting from bottom left ${BOTTOM_LEFT_START_X_2} becomes ${BOTTOM_LEFT_START_X_2 * widthUnitRatio * startX}`)

            // console.log(obj)
            return obj;
        }

    }

    const pairCallback = (cb = '', assetKey) =>
    {
        // console.log(`to paired callback for asset key ${assetKey} ${cb}`)
        if(cb === '' || assetKey === '') return null
        switch(cb)
        {
            case 'uploadPhoto':
                return () => {
                    removeOffering(assetKey)
                    uploadPhoto(assetKey)
                }
            case 'removeOffering':
                return () => {
                    removeOffering(assetKey)
                }
            case 'openDiary':
                return () => {
                    openDiaryLink(assetKey)
                }
        }
    }

    const offsetStartX = (input, widthUnitRatio) => {
        // console.log(`input is ${input} and the width ratio is ${widthUnitRatio}`)
        let output = Math.round(input / (widthUnitRatio * BOTTOM_LEFT_START_X_2) * 100) / 100
        return output
    }

    const prepToSaveObj = (originKey, posX, offeringKey) => {
        // console.log(`the origin key is ${originKey}`)
        // console.log(`the offering key is ${offeringKey}`)
        // console.log(assetObj)
        if(assetObj.hasOwnProperty(offeringKey))
        {
            let obj = assetObj[offeringKey]
            // console.log(obj)
            // obj['startX'] = offsetStartX(posX, obj.widthUnitRatio)
            let toSave = {}, assetInfo = {}
            if(defaultAssets.hasOwnProperty(originKey))
            {
                assetInfo = defaultAssets[originKey]
            }
            else
            {
                assetInfo = defaultAssets[offeringKey]
            }
            const startX = offsetStartX(obj.startX, assetInfo.widthUnitRatio)
            // console.log(`startX ${startX}`)
            toSave[offeringKey] = {
                heightFilling: assetInfo['heightFilling'],
                originKey: assetInfo['originKey'],
                padding: assetInfo['padding'],
                startX: startX,
                widthUnitRatio: assetInfo['widthUnitRatio']
            }
            // console.log(obj)
            return toSave
        }
        if(defaultAssets.hasOwnProperty(originKey))
        {
            // console.log(assetObj)
            let obj = defaultAssets[originKey]
            // obj['startX'] = offsetStartX(posX, obj.widthUnitRatio)
            let toSave = {}
            const startX = offsetStartX(posX, obj.widthUnitRatio)
            // console.log(`startX ${startX}`)
            toSave[offeringKey] = {
                heightFilling: obj['heightFilling'],
                originKey: obj['originKey'],
                padding: obj['padding'],
                startX: startX,
                widthUnitRatio: obj['widthUnitRatio']
            }
            // console.log(obj)
            return toSave
        }

        /*
        if(assetObj.hasOwnProperty(offeringKey))
        {
            let obj = assetObj[offeringKey]
            // obj['startX'] = offsetStartX(posX, obj.widthUnitRatio)
            let toSave = {}
            toSave[offeringKey] = {
                heightFilling: obj['heightFilling'],
                originKey: obj['originKey'],
                padding: obj['padding'],
                startX: offsetStartX(posX, obj.widthUnitRatio),
                widthUnitRatio: obj['widthUnitRatio']
            }
            // console.log(obj)
            return toSave
        }
        */
    }

    /** 
     * ============================= Canvas functions ============================= 
    */
    // Calculate the relative dimensions
    const updateDimensionsGivenHeight = (height) => {
        WIDTH = height * W_H_RATIO - WINDOW_PADDING;
        PADDING = WIDTH / 120;
        WIDTH_UNIT = WIDTH / 4 - PADDING * 2;
        BOTTOM_RIGHT_START_X_2 = WIDTH - WIDTH_UNIT;
        BOTTOM_RIGHT_START_X_1 = BOTTOM_RIGHT_START_X_2 - WIDTH_UNIT - PADDING;
        BOTTOM_LEFT_START_X_1 = 0;
        BOTTOM_LEFT_START_X_2 = BOTTOM_LEFT_START_X_1 + WIDTH_UNIT + PADDING;
        p.resizeCanvas( height * W_H_RATIO - PADDING , height - PADDING  );
    }
    // Function to calculate the desired object height with the ratio between desired object width and real object width
    // Filling is when the object should look taller or shorter(minus filling), and is usually not necessary
    const returnRelativeHeightGivenWidth = (targetObj, givenWidth, filling = 0) => {
        const height = givenWidth / targetObj.width * targetObj.height + filling;
        return height;
    }

    // Function to calculate start y with canvas height, object width (to calculate relative height) and desired padding
    // Padding is for further adjusting starting height, and is usually not necessary
    const returnStartYGivenWidth = (targetObj, height, givenWidth, padding = 0) => {
        const startY = height - givenWidth / targetObj.width * targetObj.height - padding;
        return startY;
    }

    // Display a hint message with mouse to instruct users how to interact with the app
    // Usage: update the mouseHintMsg or enter a string mouseHint('This is a hint');
    let mouseHintMsg = 'no hint'
    const mouseHint = (mouseHintMsg = 'no hint') => {
        if(displayMouseHint) {
            // make a rectangle with black see through background and 1px white border
            const width = WIDTH_UNIT, height = PADDING * 6;
            const startX = p.mouseX - width/2, startY = p.mouseY - height;
            const textPadding = PADDING;
            p.stroke(255, 255, 255);
            p.fill(msgBoxBgColor);
            p.rect(startX, startY, width, height);
            p.noStroke();
            p.fill('rgba(255, 255, 255, 1)');
            p.textAlign(p.CENTER, p.CENTER);
            p.textSize(14);
            p.text(mouseHintMsg, startX + textPadding, startY + textPadding, width - textPadding*2, height - textPadding*2);
        }
    }

    /** 
     * ============================= Offering control functions ============================= 
     * genOffering: generate the offering object that allows the user to position and place on the canvas
     * positionOffering: move the offering around the canvas offering area to place it (snap to the grid)
     * placeOffering: put the offering on the canvas, push the offering object into the assetObj to be rendered
     * cancelPlaceOffering: if user triggered the positionOffering but doesn't want to place it, click right mouse to cancel
     * removeOffering: click on a offering to remove it
    */
    // A function to generate object and place it along the defined start y horizon
    let offeringArr = [];
    const genOffering = (offeringObj) => 
    {
        // console.log(offeringObj)
        const img = p.loadImage(offeringObj.url)
        // console.log(offeringObj.url)
        const height = returnRelativeHeightGivenWidth(offeringObj, offeringObj.widthUnit, offeringObj.heightFilling)
        let cb = null
        if(defaultAssets[offeringObj.originKey].hasOwnProperty('callback') && typeof defaultAssets[offeringObj.originKey]['callback'] === 'string')
        {
            cb = pairCallback(defaultAssets[offeringObj.originKey]['callback'], offeringObj.originKey)
        }
        let offeringItem = {
            originKey: offeringObj.originKey,
            url: offeringObj.url,
            img: img,
            get width() { return WIDTH },
            get height() { return HEIGHT },
            get widthUnit() { return WIDTH_UNIT / offeringObj.widthUnitRatio; },
            get padding() { return PADDING * offeringObj.padding; },
            get heightFilling() { return PADDING * offeringObj.heightFilling; },
            callback: cb === null ? () => null : cb
        }
        // console.log(offeringItem)
        return offeringItem;
        
       /*
       let obj = {
            originKey: offeringObj.originKey,
            url: offeringObj.url,
            // get startX() { return BOTTOM_LEFT_START_X_2 * widthUnitRatio * startX; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { 
                if(offeringObj.hasOwnProperty('widthUnitRatio'))
                {
                    return WIDTH_UNIT / offeringObj.widthUnitRatio;
                }
                return offeringObj.widthUnit
            },
            get heightFilling() { return PADDING * offeringObj.heightFilling; },
            get padding() { return PADDING * offeringObj.padding; },
            img: p.loadImage(offeringObj.url),
            callback: offeringObj.callback
        }
        console.log(obj)
        return obj
        */
    };
    const positionOffering = (offeringArr) => {
        if( !retrievingOffering && offeringArr.length > 0 ) // TODO: There should be a limit of offering items?
        {
            // operationPanel.style.display = 'none';
            positioningOffering = true;
            displayMouseHint = true;
            if( !collidedOtherOffering ) { mouseHintMsg = 'click to place, right click to cancel'; }
            else { mouseHintMsg = 'This item cannot overlap with another item'; }
            try
            {
                offeringArr.forEach( offering => {
                    // console.log(offering.img)
                    // maxX = defaultAssets.diaryClose.startX - offering.widthUnit;
                    maxX = BOTTOM_LEFT_START_X_2 * defaultAssets.diaryClose.widthUnitRatio * defaultAssets.diaryClose.startX; // use diary as a standard
                    // console.log(`the max x is ${maxX}`)
                    let offeringX, halfWidth = offering.widthUnit / 2;
                    // console.log(`the half width is ${halfWidth} and the min range is ${minX} and the max range is ${maxX}`)
                    offeringX = Math.round( (p.mouseX - halfWidth) * 100 ) / 100
                    // console.log(`offeringX is ${offeringX}`)

                    // console.log(Math.round( (p.mouseX - halfWidth) * 100 ) / 100)
                    if( p.mouseX >= minX +halfWidth && p.mouseX <= maxX - halfWidth )
                    {
                        
                        offeringX = Math.round( (p.mouseX - halfWidth) * 100 ) / 100;
                        
                        p.image(offering.img, offeringX, 
                            returnStartYGivenWidth(offering.img, offering.height, offering.widthUnit, offering.padding ),
                            offering.widthUnit, 
                            returnRelativeHeightGivenWidth(offering.img, offering.widthUnit, offering.heightFilling) );
                    }
                    else if( p.mouseX < minX + halfWidth ) // It shouldn't go over the canvas left border
                    {
                        p.image(offering.img, minX, 
                            returnStartYGivenWidth(offering.img, offering.height, offering.widthUnit, offering.padding ),
                            offering.widthUnit, 
                            returnRelativeHeightGivenWidth(offering.img, offering.widthUnit, offering.heightFilling) );
                    }
                    else if( p.mouseX > maxX - halfWidth ) // It shouldn't overlap with the diary
                    {
                        p.image(offering.img, maxX - offering.widthUnit, 
                            returnStartYGivenWidth(offering.img, offering.height, offering.widthUnit, offering.padding ),
                            offering.widthUnit, 
                            returnRelativeHeightGivenWidth(offering.img, offering.widthUnit, offering.heightFilling) );
                    }
                });
            }
            catch(e)
            {
                // console.log(e)
            }

        }
        else {
            cancelPlaceOffering(offeringArr);
        }
    }
    // A function to place offering item in the array for rendering using the native offering object
    const placeOffering = (offeringArr, posX = null, assetKey = '') => {
        // if user has indicated a location to place the offering
        if(posX !== null && !collidedOtherOffering)
        {
            // operationPanel.style.display = 'block';
            positioningOffering = false;
            offeringArr.forEach(offering => {
                let offeringX = Math.round( posX * 100 ) / 100;
                const offeringY = Math.round( returnStartYGivenWidth(offering.img, offering.height, offering.widthUnit, offering.padding ) * 100 ) / 100;
                // get relative ratio to the canvas x and y
                let canvasToObjRatioX = Math.round( offeringX / offering.width * 100 ) / 100 ;
                let canvasToObjRatioY = Math.round( offeringY / offering.height * 100 ) / 100 ;
                let cb = null
                const offeringKey = assetKey ? assetKey : makeid(8)
                if(defaultAssets[offering.originKey].hasOwnProperty('callback') && typeof defaultAssets[offering.originKey]['callback'] === 'string')
                {
                    cb = pairCallback(defaultAssets[offering.originKey]['callback'], offeringKey)
                }
                let newOfferingObj = {
                    originKey: offering.originKey,
                    url: offering.url,
                    get startX() { return WIDTH * canvasToObjRatioX; },
                    get width() { return WIDTH; },
                    get height() { return HEIGHT; },
                    get widthUnit() { return offering.widthUnit; },
                    get heightFilling() { return offering.heightFilling; } ,
                    get padding() { return offering.padding; },
                    img: offering.img,
                    callback: cb
                }
                
                // const toSaveObj = prepToSaveObj(offering.originKey, WIDTH * canvasToObjRatioX, offeringKey)
                // postMessage("addAsset", toSaveObj)
                // console.log(toSaveObj)
                // push new offering to the assetObj
                if(assetKey)
                {
                    assetObj[assetKey] = newOfferingObj;
                    assetObj[assetKey]['hash'] = hash();
                    // bookkeepingassets();
                }
                else
                {
                    assetObj[offeringKey] = newOfferingObj;
                    assetObj[assetKey][offeringKey] = hash();
                    // bookkeepingassets();
                }
            });
            return;
        }
        // if user has not indicated the start x to place the offering or is redirected by positioning offering
        if( posX === null && !collidedOtherOffering && positioningOffering )
        {
            // console.log('user has not indicated the startX, generate one based on the p.mouse position')
            // operationPanel.style.display = 'block';
            positioningOffering = false;
            offeringArr.forEach( offering => {
                displayMouseHint = false;
                mouseHintMsg = '';
                // remember the location of the mouseX and mouseY and push this location to the assetObjs
                // the location should be relative to the canvas width
                // first define the location with mouse x and the y horizon (don't use mouse y), if mouseX is < 0 or > windowWidth, then modify it
                let offeringX, halfWidth = offering.widthUnit / 2;
                // maxX = defaultAssets.diaryClose.startX;
                maxX = BOTTOM_LEFT_START_X_2 * defaultAssets.diaryClose.widthUnitRatio * defaultAssets.diaryClose.startX;
                if( p.mouseX >= minX +halfWidth && p.mouseX <= maxX - halfWidth ) // allow users to place offerings on a fixed range
                {
                    // console.log('mouse is within the acceptable range')
                    offeringX = Math.round( (p.mouseX - halfWidth) * 100 ) / 100;
                    const offeringY = Math.round( returnStartYGivenWidth(offering.img, offering.height, offering.widthUnit, offering.padding ) * 100 ) / 100;
                    // get relative ratio to the canvas x and y
                    let canvasToObjRatioX = Math.round( offeringX / offering.width * 100 ) / 100 ;
                    let canvasToObjRatioY = Math.round( offeringY / offering.height * 100 ) / 100 ;
                    let cb = null
                    const offeringKey = makeid(8)
                    if(defaultAssets.hasOwnProperty(offering.originKey) && defaultAssets[offering.originKey].hasOwnProperty('callback') && typeof defaultAssets[offering.originKey]['callback'] === 'string')
                    {
                        cb = pairCallback(defaultAssets[offering.originKey]['callback'], offeringKey)
                    }
                    const newOfferingObj = {
                        originKey: offering.originKey,
                        url: offering.url,
                        get startX() { return WIDTH * canvasToObjRatioX; },
                        get width() { return WIDTH; },
                        get height() { return HEIGHT; },
                        get widthUnit() { return offering.widthUnit; },
                        get heightFilling() { return offering.heightFilling; } ,
                        get padding() { return offering.padding; },
                        img: offering.img,
                        callback: cb
                    }
                    // const toSaveObj = prepToSaveObj(offering.originKey, WIDTH * canvasToObjRatioX)
                    // console.log(toSaveObj)

                    // push new offering to the assetObj
                    // const offeringKey = makeid(8);
                    assetObj[offeringKey] = newOfferingObj;
                    const toSaveObj = prepToSaveObj(offering.originKey, WIDTH * canvasToObjRatioX, offeringKey)
                    // console.log(toSaveObj)
                    postMessage("addAsset", toSaveObj)
                }
                else if( p.mouseX < minX + halfWidth ) // can't go over the minimum x
                {
                    // console.log('mouse is ouside the min X')
                    const offeringX = minX;
                    const offeringY = Math.round( returnStartYGivenWidth(offering.img, offering.height, offering.widthUnit, offering.padding ) * 100 ) / 100;
                    // get relative ratio to the canvas x and y
                    const canvasToObjRatioX = Math.round( offeringX / offering.width * 100 ) / 100 ;
                    const canvasToObjRatioY = Math.round( offeringY / offering.height * 100 ) / 100 ;
                    let cb = null
                    const offeringKey = makeid(8)
                    if(defaultAssets.hasOwnProperty(offering.originKey) && defaultAssets[offering.originKey].hasOwnProperty('callback') && typeof defaultAssets[offering.originKey]['callback'] === 'string')
                    {
                        cb = pairCallback(defaultAssets[offering.originKey]['callback'], offeringKey)
                    }
                    const newOfferingObj = {
                        originKey: offering.originKey,
                        url: offering.url,
                        get startX() { return WIDTH * canvasToObjRatioX; },
                        get width() { return WIDTH; },
                        get height() { return HEIGHT; },
                        get widthUnit() { return offering.widthUnit; },
                        get heightFilling() { return offering.heightFilling; } ,
                        get padding() { return offering.padding; },
                        img: offering.img,
                        callback: cb
                    };

                    // push new offering to the assetObj
                    // const offeringKey = makeid(8);
                    assetObj[offeringKey] = newOfferingObj;
                    // console.log(toSaveObj)
                    const toSaveObj = prepToSaveObj(offering.originKey, WIDTH * canvasToObjRatioX, offeringKey)
                    postMessage("addAsset", toSaveObj)
                    // postMessage("addAsset", toSaveObj)
                }
                else if( p.mouseX > maxX - halfWidth ) // can't overlap with the diary area
                {
                    // console.log('mouse is over the max X')
                    offeringX = maxX - offering.widthUnit;
                    const offeringY = Math.round( returnStartYGivenWidth(offering.img, offering.height, offering.widthUnit, offering.padding ) * 100 ) / 100;
                    // get relative ratio to the canvas x and y
                    const canvasToObjRatioX = Math.round( offeringX / offering.width * 100 ) / 100 ;
                    const canvasToObjRatioY = Math.round( offeringY / offering.height * 100 ) / 100 ;
                    let cb = null
                    const offeringKey = makeid(8)
                    if(defaultAssets.hasOwnProperty(offering.originKey) && defaultAssets[offering.originKey].hasOwnProperty('callback') && typeof defaultAssets[offering.originKey]['callback'] === 'string')
                    {
                        cb = pairCallback(defaultAssets[offering.originKey]['callback'], offeringKey)
                    }
                    const newOfferingObj = {
                        originKey: offering.originKey,
                        url: offering.url,
                        get startX() { return WIDTH * canvasToObjRatioX; },
                        get width() { return WIDTH; },
                        get height() { return HEIGHT; },
                        get widthUnit() { return offering.widthUnit; },
                        get heightFilling() { return offering.heightFilling; } ,
                        get padding() { return offering.padding; },
                        img: offering.img,
                        callback: cb
                    };
                    // const toSaveObj = prepToSaveObj(offering.originKey, WIDTH * canvasToObjRatioX)
                    // console.log(toSaveObj)
                    // push new offering to the assetObj
                    // const offeringKey = makeid(8);
                    assetObj[offeringKey] = newOfferingObj;
                    const toSaveObj = prepToSaveObj(offering.originKey, WIDTH * canvasToObjRatioX, offeringKey)
                    postMessage("addAsset", toSaveObj)
                    // postMessage("addAsset", {toSaveObj})
                }
            } );
            // clear the offering array
            offeringArr.splice(0, offeringArr.length);
            retrievingOffering = false;
            // save the action in the localStorage
            return // bookkeepingassets();
            
        }
        else
        {
            // console.log('no offering go away')
            offeringArr.splice(0, offeringArr.length);
            retrievingOffering = false;
            // display message to convince user choose another location
            displayMouseHint = true;
            return;
        }
        return;
   }

    // A function to place offering item in the array for rendering
    const modifyExistingOffering = (assetKey, optionKey) => {
        let pos = collisionDetectArr[assetKey].pos; // get the position of the origin item
        // let optionAsset = JSON.parse(JSON.stringify(defaultAssets[optionKey]));
        let optionAsset = defaultAssets[optionKey];
        optionAsset.img = p.loadImage(optionAsset.url);
        positioningOffering = true;
        placeOffering([optionAsset], pos[0], assetKey);
        // post a message to the parent node to create an activity log
        var completeTimestamp = new Date();
        postMessage("activityComplete", {
            timeInit: completeTimestamp.toUTCString(),
            activityKey: optionKey,
            type: "modify",
            timeComplete: completeTimestamp.toUTCString(),
        });
   }

    const cancelPlaceOffering = (offeringArr) => {
        // let originObj = JSON.stringify(localStorage.getItem(sessionKeys.offeringObjs));
        positioningOffering = false;
        offeringArr.splice(0, offeringArr.length);
    }


    // Create a collided function attached to the offering to remove it when user clicks on it
    const removeOffering = (assetKey) => {
        // console.log(`to remove assetKey ${assetKey}`)
        // console.log(assetObj)
        displayMouseHint = true;
        mouseHintMsg = 'right click to remove the item';
        collided = true;
        collidedActionLeftKey[assetKey] = async function() {
            const originKey = assetObj[assetKey].originKey
            delete assetObj[assetKey];
            delete collisionDetectArr[assetKey];
            // console.log(assetObj)
            // console.log(collisionDetectArr)
            try
            {
                // remove the offering from reducer
                postMessage('removeAsset', assetKey)
                // console.log(`remove asset key ${originKey}`)
                if(!defaultKeys.hasOwnProperty(originKey)) // the object is imported from S3 bucket
                {
                    S3B.removeObject(originKey, s3folders.userPhotos, (e, r) => {
                        // console.log(e)
                        // console.log(r)
                    })
                }
                /*
                // save the assetObj in the localStorage, so we don't lose track of what users offer
                LS.set(sessionKeys.offeringObjs, assetObj)
                .then(() => {
                    var completeTimestamp = new Date();
                    postMessage("activityComplete", {
                        timeInit: completeTimestamp.toUTCString(),
                        activityKey: originKey,
                        type: "remove",
                        timeComplete: completeTimestamp.toUTCString(),
                    });
                });
                */
            }
            catch(err)
            {
                // TODO: if the storage failed, do some error control
                // console.log(err)
            }
            return true;
        }
    }
    // Store the assetObj in the localstorage, it should simply keep the keys
    /*
    const bookKeepingAssets = () => {
        try
        {
            // save the assetObj in the localStorage, so we don't lose track of what users offer
            // LS.set(sessionKeys.offeringObjs, assetObj);
        }
        catch(err)
        {
            // TODO: if the storage failed, do some error control
        }
    }
    */
    const loadPrevImg = (modifiedAsset) => {
        const offeringItem = genOffering(modifiedAsset);
        offeringArr.push(offeringItem);
        collidedOtherOffering = false;
        retrievingOffering = true;
        placeOffering(offeringArr, modifiedAsset.startX, modifiedAsset.originKey);
    }
    // retrieve the previous offering assets from the MySQL database
    const retrievePrevAssets = () => {
        // console.log('retrieve called')
        // console.log(assetObj)
        const o = defaultAssets['diaryClose']
        const startX = o.startX
        // console.log(startX)
        const diaryDefault = createAsset2(o.originKey, o.url, o.widthUnitRatio, o.heightFilling, o.padding, startX, o.originKey)
        assetObj['diaryClose'] = diaryDefault

        if(Object.keys(assetObj).length <= 0)
        {
            assetObj = { diaryClose: diaryDefault }
        }
        assetObj['diaryClose'] = diaryDefault
        assetKeys = Object.keys(assetObj)
        // console.log(assetObj)
        return assetKeys.forEach(key => {
            const loadImgPromise = new Promise(res => {
                let asset = assetObj[key]
                // get the image from S3 bucket, if cannot find, then switch to the default serving
                // fetchS3Img(getImgUrl, asset.originKey)
                // .then(imgUrl => {
                //     if(imgUrl.success)
                //     {
                //         asset.img = p.loadImage(imgUrl.data);
                //         res(asset)
                //     }
                //     else
                //     {
                //         asset.img = p.loadImage(imgUrl.data);
                //         res(asset)
                //     }
                // })
                // .catch(err => {
                //     asset.img = p.loadImage(asset.url)
                //     res(asset)
                // })
                asset.img = p.loadImage(asset.url)
                res(asset)
            })
            loadImgPromise.then(modifiedAsset => {
                assetObj[key] = modifiedAsset
            })
        })
        // return AH.getUser()
        // .then(session => {
        //     console.log('get user')
        //     if(!session.hasOwnProperty('username')) throw { code: 'NO_SESSION', message: 'The system cannot retrieve session' }
        //     // console.log(session)
        //     // const getUser = User.getUserData(session.username).then(r => r)
        //     // console.log(getUser)
        //     return User.getUserData(session.username)
        //     .then(getUser => {
        //         // console.log(getUser)
        //         if(getUser.length <= 0 || !getUser) throw false
        //         // console.log('user is here')
        //         let userData = getUser[0]
        //         console.log('call the user to get current day')
        //         return STM.getCurrentDayData(userData.id, (err, dayLogs) => {
        //             // console.log(err)
        //         })
        //     })
        // })
        // .catch(e)
        // {

        // }


                    // console.log(dayLogs)
                    // if(err) throw false 
                    // get the latest day data containing offering obj and check if it's the same day
                    /*
                    return OF.getOfferingData(userData.id, dayLogs.day, (err, data) => {
                        // console.log(err)
                        // console.log(data)
                        if(err) throw false
                        if(data !== undefined && data !== null && Object.keys(data).length > 0)
                        {
                            assetObj = JSON.parse(data) || { diaryClose: defaultAssets.diaryClose };
                            assetKeys = Object.keys(assetObj);
                            // console.log(assetObj)
                            return assetKeys.forEach(key => {
                                const loadImgPromise = new Promise(res => {
                                    let asset = assetObj[key];
                                    // get the image from S3 bucket, if cannot find, then switch to the default serving
                                    fetchS3Img(getImgUrl, asset.originKey)
                                    .then(imgUrl => {
                                        if(imgUrl.success)
                                        {
                                            asset.img = p.loadImage(imgUrl.data);
                                            res(asset);
                                        }
                                        else
                                        {
                                            asset.img = p.loadImage(imgUrl.data);
                                            res(asset);
                                        }
                                    })
                                    .catch(err => {
                                        asset.img = p.loadImage(asset.url);
                                        res(asset);
                                    });
                                    asset.img = p.loadImage(asset.url);
                                    res(asset);
                                });
                                loadImgPromise.then(modifiedAsset => {
                                    assetObj[key] = modifiedAsset;
                                });
                            })
                        }
                        throw false
                    })
                    */
        // .catch(e => e) // error happened in retrieving or user doesn't have previous images
        // {
            // console.log(session)

        /*
        console.log(userData)
        return await User.getUserDataFromSession((err, userData) => {
            if(err) return;
            // check the current visit day to determine which day the activity log belongs to
            return STM.getCurrentDayData(userData.id, (err, dayLogs) => {
                if(err) return;
                // get the latest day data containing offering obj and check if it's the same day
                return OF.getOfferingData(userData.id, dayLogs.day, (err, data) => {
                    if(err) return;
                    if(data !== undefined && data !== null && Object.keys(data).length > 0)
                    {

                        assetObj = JSON.parse(data) || { diaryClose: defaultAssets.diaryClose };
                        assetKeys = Object.keys(assetObj);
                        return assetKeys.forEach(key => {
                            const loadImgPromise = new Promise(res => {
                                let asset = assetObj[key];
                                // get the image from S3 bucket, if cannot find, then switch to the default serving
                                fetchS3Img(getImgUrl, asset.originKey)
                                .then(imgUrl => {
                                    if(imgUrl.success)
                                    {
                                        asset.img = p.loadImage(imgUrl.data);
                                        res(asset);
                                    }
                                    else
                                    {
                                        asset.img = p.loadImage(imgUrl.data);
                                        res(asset);
                                    }
                                })
                                .catch(err => {
                                    asset.img = p.loadImage(asset.url);
                                    res(asset);
                                });
                                asset.img = p.loadImage(asset.url);
                                res(asset);
                            });
                            loadImgPromise.then(modifiedAsset => {
                                assetObj[key] = modifiedAsset;
                            });
                        });
                    }
                    assetObj = { diaryClose: defaultAssets.diaryClose };
                    assetKeys = Object.keys(assetObj);
                    return assetKeys.forEach(key => {
                        const loadImgPromise = new Promise(res => {
                            let asset = assetObj[key];
                            // get the image from S3 bucket, if cannot find, then switch to the default serving
                            fetchS3Img(getImgUrl, asset.originKey)
                            .then(imgUrl => {
                                if(imgUrl.success)
                                {
                                    asset.img = p.loadImage(imgUrl.data);
                                    res(asset);
                                }
                                else
                                {
                                    asset.img = p.loadImage(imgUrl.data);
                                    res(asset);
                                }
                            })
                            .catch(err => {
                                asset.img = p.loadImage(asset.url);
                                res(asset);
                            });
                            asset.img = p.loadImage(asset.url);
                            res(asset);
                        });
                        loadImgPromise.then(modifiedAsset => {
                            assetObj[key] = modifiedAsset;
                        });
                    });
                });
            });
        });

        */
        // }
    }

    // asset callback (when user hovers over the asset, before user clicks on it)
    /**
     * ======================= Assets interactive callbacks =======================
     * trigger the asset's interactive callback function on mouseover or mouseclicked
     * Assets to interact: candle, flower vase, album
     * lightCandle: allow user to light the candle when clicking on it
     * openDiaryLink: open a link to start the Memory Home journal activity and change the journal image to an opened journal
     * closeDiary: change the image into close journal
     * postMessage: deliver message to the parent panel to trigger event listener
    */
    const lightCandle = (assetKey) => {
        let pos = collisionDetectArr[assetKey].pos; // get the position of the origin item
        let candleLightAsset = JSON.parse(JSON.stringify(defaultAssets.candleLight));
        candleLightAsset.startX = pos[0];
        candleLightAsset.img = p.loadImage(candleLightAsset.url);
        mouseHintMsg = 'click to light the candle';
        displayMouseHint = true;
        collided = true;
        collidedAction[assetKey] = function() {
            modifyExistingOffering(assetKey, 'candleLight');
        }
    }

    let hideDiaryMsg = false;
    // Open diary link
    const openDiaryLink = (assetKey) => {
        // let pos = collisionDetectArr[assetKey].pos; // get the position of the origin item
        if(!hideDiaryMsg) 
        {
            displayMouseHint = true;
            mouseHintMsg = 'click to redirect to the Writing Desk';
        }
        collided = true;
        collidedAction[assetKey] = function() {
            window.location.replace(config.paths.diary)
        }
    }

    const closeDiary = (assetKey) => {
        let pos = collisionDetectArr[assetKey].pos; // get the position of the origin item
        let diaryCloseObj = defaultAssets.diaryClose;
        diaryCloseObj.img = p.loadImage(diaryCloseObj.url);
        mouseHintMsg = 'click to close your journal';
        displayMouseHint = true;
        collided = true;
        collidedAction[assetKey] = function() {
            assetObj[assetKey] = diaryCloseObj;
        }
    }

    const uploadPhoto = (assetKey) => {
        displayMouseHint = true;
        mouseHintMsg = 'Click to Upload Photo';
        // console.log(`upload photo function called ${assetKey}`)
        // console.log(collisionDetectArr)
        // console.log(collisionDetectArr[assetKey])
        try
        {
            // let pos = collisionDetectArr[assetKey].pos
            collided = true
            collidedAction[assetKey] = function() {
                // console.log(`post upload photo to the canvas`)
                // post message to the parent panel including the assetKey to retrieve the asset
                postMessage('uploadPhoto', { assetKey: assetKey });
            }
        }
        catch(e)
        {

        }
        /*
        let pos = collisionDetectArr[assetKey].pos; // get the position of the origin item
        collided = true;
        collidedAction[assetKey] = function() {
            console.log(`post upload photo to the canvas`)
            // post message to the parent panel including the assetKey to retrieve the asset
            postMessage('uploadPhoto', { assetKey: assetKey });
        }
        */
    }

    /** 
     * ============================= Native p5 functions ================================
     */
    var bgImg = p.loadImage(require('../../../img/window_frame_wide-lg.png'));
    var viewImg = p.loadImage(require('../../../img/scene1-md.jpg')), pCanvas, defaultAssetInit = { diaryClose: defaultAssets.diaryClose }, assetObj = {};
    // The asset keys will be used to loop assetObj. Define the range where users can place offerings
    var assetKeys = Object.keys(assetObj), minX = 0.1, maxX;
    p.setup = function () {
        retrievePrevAssets()
        retrievingOffering = false
        // console.log(assetObj)
        p.createCanvas( WIDTH - PADDING , HEIGHT - PADDING )
        p.colorMode(p.RGB, 255, 255, 255, 1.0)
        p.pixelDensity(1)
        p.frameRate(12)
        pCanvas = p.canvas
        pCanvas.setAttribute('id', 'viewCanvas')
    }

    // viewStartX
    const viewStartX = WIDTH * 0.1;
    p.draw = function () {
        p.clear();
        // Create background and views
        p.image(viewImg, viewStartX, 0, WIDTH * 0.8, returnRelativeHeightGivenWidth(viewImg, WIDTH * 0.8, 0));
        p.image(bgImg, 0, 0, WIDTH, HEIGHT);
        assetKeys = Object.keys(assetObj);
        // Loop assetObj and place the coordinates in the collisionDetectArr
        assetKeys.forEach( key => {
            const asset = assetObj[key];
            try{
                let startX = asset.startX,
                objKey = asset.originKey,
                startY = returnStartYGivenWidth(asset.img, asset.height, asset.widthUnit, asset.padding),
                objW = asset.widthUnit, objH = returnRelativeHeightGivenWidth(asset.img, asset.widthUnit, asset.heightFilling);
                p.image(asset.img, startX, startY, objW, objH);
                collisionDetectArr[key]= {
                    originKey: objKey,
                    pos: [startX, startY, objW, objH],
                    callback: asset.callback || null
                };
            }
            catch(err) {
                // if the asset can't be rendered, then remove it temporarily
                delete assetObj[key];
                delete collisionDetectArr[key];
            }
        } );
        // This is the object that user will place on the canvas, 
        // when user places the object, it will be added to the assetObj
        positionOffering(offeringArr);
        mouseHint(mouseHintMsg);
    };
    // ======================== Collision detection ========================
    // check the mouseX and mouseY to detect the collision with the object
    const exceptionKey = 'diaryClose'
    p.mouseMoved = function() {
        // if(!disableCanvasInteraction)
        // {
            const collisionKeys = Object.keys(collisionDetectArr);
            collided = false;
            collidedItemKey = '';
            displayMouseHint = false;
            collidedOtherOffering = false;
            // create a new condition when the offering object is collided with other assets
            // Get the items that are currently on the canvas and the dimensions to detect collistion
            collisionKeys.forEach(key => {
                if(disableCanvasInteraction && key === exceptionKey)
                {
                    const targetBlock = collisionDetectArr[key];
                    let x = targetBlock.pos[0], y = targetBlock.pos[1], w = targetBlock.pos[2], h = targetBlock.pos[3];
                    const hw =  w/2; // half width: p.mouseX will be larger because it doesn't start from the beginning
                    // user is not placing an offering, trigger mouse hover action
                    if( offeringArr.length <= 0 && !positioningOffering ) 
                    {
                        //check upperleft
                        if( p.mouseX > x && p.mouseX < x + w &&
                            p.mouseY > y && p.mouseY < y + h)
                        {
                            collided = true;
                            collidedItemKey = key; // if collided, store this object key in the collidedItemKey for collidedAction
                            if(assetObj.hasOwnProperty(key) && assetObj[key].callback !== null)
                            {
                                assetObj[key].callback(targetBlock.originKey)
                            }
                            // if( defaultAssets.hasOwnProperty(targetBlock.originKey) && defaultAssets[targetBlock.originKey].callback !== null ) {
                            //     // defaultAssets[targetBlock.originKey].callback(key);
                            // }
                            else if ( targetBlock.callback !== null )
                            {
                                targetBlock.callback(key);
                            }
                            else
                            {
                                // execute the default removeOffering function
                                targetBlock.callback = function() {
                                    removeOffering(key);
                                }
                            }
                        }
                    }
                    else  // The user is placing offering, check if the offering overlaps with the existing offering
                    {
                        // get the half width of the offering item to help offset the mouse x
                        const ohw = offeringArr[0].widthUnit / 2;
                        const buffer = PADDING * 4;
                        if( p.mouseX - ohw > x + buffer && p.mouseX - ohw < x + w - buffer )
                        {
                            collided = true;
                            collidedOtherOffering = true;
                            collidedItemKey = key;
                            mouseHintMsg = `Object cannot overlap with another item`;
                        }
                        else if( p.mouseX + ohw > x + buffer && p.mouseX + ohw < x + w - buffer )
                        {
                            collided = true;
                            collidedOtherOffering = true;
                            displayMouseHint = true;
                            collidedItemKey = key;
                            mouseHintMsg = `Object cannot overlap with another item`;
                        }
                    }
                }
                else if(!disableCanvasInteraction)
                {
                    const targetBlock = collisionDetectArr[key];
                    let x = targetBlock.pos[0], y = targetBlock.pos[1], w = targetBlock.pos[2], h = targetBlock.pos[3];
                    const hw =  w/2; // half width: p.mouseX will be larger because it doesn't start from the beginning
                    // user is not placing an offering, trigger mouse hover action
                    if( offeringArr.length <= 0 && !positioningOffering ) 
                    {
                        //check upperleft
                        if( p.mouseX > x && p.mouseX < x + w &&
                            p.mouseY > y && p.mouseY < y + h)
                        {
                            collided = true;
                            collidedItemKey = key; // if collided, store this object key in the collidedItemKey for collidedAction
                            if(assetObj.hasOwnProperty(key) && assetObj[key].callback !== null)
                            {
                                assetObj[key].callback(targetBlock.originKey)
                            }
                            // if( defaultAssets.hasOwnProperty(targetBlock.originKey) && defaultAssets[targetBlock.originKey].callback !== null ) {
                            //     // defaultAssets[targetBlock.originKey].callback(key);
                            // }
                            else if ( targetBlock.callback !== null )
                            {
                                targetBlock.callback(key);
                            }
                            else
                            {
                                // execute the default removeOffering function
                                targetBlock.callback = function() {
                                    removeOffering(key);
                                }
                            }
                        }
                    }
                    else  // The user is placing offering, check if the offering overlaps with the existing offering
                    {
                        // get the half width of the offering item to help offset the mouse x
                        const ohw = offeringArr[0].widthUnit / 2;
                        const buffer = PADDING * 4;
                        if( p.mouseX - ohw > x + buffer && p.mouseX - ohw < x + w - buffer )
                        {
                            collided = true;
                            collidedOtherOffering = true;
                            collidedItemKey = key;
                            mouseHintMsg = `Object cannot overlap with another item`;
                        }
                        else if( p.mouseX + ohw > x + buffer && p.mouseX + ohw < x + w - buffer )
                        {
                            collided = true;
                            collidedOtherOffering = true;
                            displayMouseHint = true;
                            collidedItemKey = key;
                            mouseHintMsg = `Object cannot overlap with another item`;
                        }
                    }
                }
                else
                {
                    return
                }
    
            } );
        // }

    };
    p.mousePressed = function(e) {
        // console.log(`disable canvas interaction: ${disableCanvasInteraction}`)
        if(!disableCanvasInteraction || (disableCanvasInteraction && collidedItemKey === exceptionKey))
        {
            // console.log(collisionDetectArr);
            // console.log(offeringArr[0]);
            // console.log(assetObj)
            // console.log(collidedActionLeftKey);
            const mouseKey = e.which;
            switch(mouseKey){
                case 1:
                    if(offeringArr.length > 0) {
                        // console.log('place offering')
                        // console.log(offeringArr[0])
                        
                        placeOffering(offeringArr);
                        var completeTimestamp = new Date();
                        postMessage("activityComplete", {
                            timeComplete: completeTimestamp.toUTCString(),
                        });
                    }
                    else // there are no offering in the array, display the panel
                    {
                        // operationPanel.style.display = 'block';
                    }
                    if( collided && collidedItemKey !== '' && collidedAction.hasOwnProperty(collidedItemKey) ) {
                        collidedAction[collidedItemKey]();
                        delete collidedAction[collidedItemKey];
                    }
                break;
                case 3:
                    if(offeringArr.length > 0) {
                        cancelPlaceOffering(offeringArr);
                        var completeTimestamp = new Date();
                        postMessage("activityCancel", {
                            type: "cancel",
                            timeComplete: completeTimestamp.toUTCString(),
                        });
                    }
                    if( collided && collidedItemKey !== '' && collidedActionLeftKey.hasOwnProperty(collidedItemKey) )
                    {
                        collidedActionLeftKey[collidedItemKey]();
                        delete collidedActionLeftKey[collidedItemKey];
                    }
                break;
                default: // treat it as left key
                    if(offeringArr.length > 0) {
                        placeOffering(offeringArr);
                    }
            }
        }
    }
    p.windowResized = function () {
        HEIGHT = window.innerHeight * HEIGHT_RATIO - WINDOW_PADDING;
        WIDTH = HEIGHT * W_H_RATIO - WINDOW_PADDING;
        PADDING = WIDTH / 120;
        WIDTH_UNIT = WIDTH / 4 - PADDING * 2;
        BOTTOM_RIGHT_START_X_2 = WIDTH - WIDTH_UNIT;
        BOTTOM_RIGHT_START_X_1 = BOTTOM_RIGHT_START_X_2 - WIDTH_UNIT - PADDING;
        BOTTOM_LEFT_START_X_1 = 0;
        BOTTOM_LEFT_START_X_2 = BOTTOM_LEFT_START_X_1 + WIDTH_UNIT + PADDING;
        p.resizeCanvas( WIDTH - PADDING , HEIGHT - PADDING );
    };

    // ======================= extra P5 wrapper function =======================
    /**
     * JSON format
     * {
     *  origin: 'window',
     *  for: 'the command you want to execute',
     *  details: { the json data you want to pass }
     * }
     * access origin: message.detail.origin
     * access for: message.detail.for
     * access details: message.detail.details
     */
    p.respondToMessage = function(message) {
        if(message.detail.origin !== 'window') return;
        const data = message.detail.details;
        switch(message.detail.for)
        {
            case "displayPhoto":
                // create a native object
                let padding, isLandscape = false;
                // console.log('display photo request received')
                // console.log(data)
                const originKey = data.fileName;
                // console.log(`the uploaded key is ${originKey}`)
                // The original asset key of the photo frame should be marked
                const assetKey = data.assetKey;
                if(collisionDetectArr[assetKey] === undefined ) return; // file is not uploaded successfully
                try{
                    if(collisionDetectArr[assetKey]['originKey'].includes('photoFrameH'))
                    {
                        isLandscape = true;
                    }
                }
                catch(err) // if for some reason the trick doesn't work, the default value is false
                {
                    isLandscape = false;
                }
                // console.log(`the photo key ${assetKey}`)
                // console.log('display photo')
                return S3B.getImage(originKey, s3folders.userPhotos, (err, data) => {
                    // console.log(err)
                    // console.log(data)
                    if(err) return;
                    // console.log('checkpoint 1')
                    const url = data;
                    // get the relative ratio of width unit to define the photo width
                    const widthUnitRatio = isLandscape ? WIDTH_UNIT / collisionDetectArr[assetKey]['pos'][2]*1.3 : WIDTH_UNIT / collisionDetectArr[assetKey]['pos'][2]*1.5
                    const heightFilling = 0;
                    isLandscape ? padding = 17.5 : padding = 14;
                    
                    const newId = makeid(8)
                    defaultAssets[originKey] = {
                        widthUnitRatio,
                        heightFilling,
                        originKey,
                        padding,
                        url: url,
                        callback: 'removeOffering',
                    }
                    const photoFrameKey = assetObj[assetKey]['originKey']
                    // console.log(`default assets key ${assetKey}`)
                    // console.log(defaultAssets[photoFrameKey])
                    // const startX = offsetStartX(assetObj[assetKey]['startX'], defaultAssets[photoFrameKey]['widthUnitRatio']) - 0.24
                    // create the photo asset and add it to the list of offeringArr to be rendered
                    
                    // let photoAsset = createAsset2(newId, url, widthUnitRatio, heightFilling, padding, startX, newId)
                    let photoAsset = createAsset(originKey, url, widthUnitRatio, heightFilling, padding, 'removeOffering')
                    // assetObj[newId] = photoAsset
                    // console.log(photoAsset)
                    offeringArr.push(photoAsset);
                    // user gets to position the photo in the album
                    positionOffering(offeringArr);
                })
            break;
        }
    }
    // When the component receives new props and need to react respectively
    p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
        viewImg = p.loadImage(props.viewObj.imgSrcs[props.viewBase].url_md);
        HEIGHT = window.innerHeight * HEIGHT_RATIO;
        updateDimensionsGivenHeight(HEIGHT);
        // user has selected an offering base: candle, vase or album
        if ( props.hasOwnProperty('currentOffering') && props.currentOffering !== null )
        {
            console.log('current offering')
            console.log(props.currentOffering)
            try
            {
                offeringArr.push(genOffering(defaultAssets[props.currentOffering], p))
                postMessage('offeringRetrieved')
                return
                // defaultAssets[props.currentOffering].callback();
            }
            catch(err) {
                // console.log(err)
                //TODO: design a proper error control
                // console.log(`failed to load the offerincurrentOfferingg object ${props.currentOffering}`);
                postMessage('offeringRetrieved')
                return
            }
        }
        if( props.hasOwnProperty('retrievedOffering') && props.retrievedOffering !== null &&  props.retrievedOffering !== undefined)
        {
            // console.log('retrieved offering is passed')
            // console.log(props.retrievedOffering)
            const obj = props.retrievedOffering
            if(Object.keys(obj).length <= 0)
            {
                assetObj = {}
                const k = 'diaryClose'
                const o = defaultAssets[k]
                const offering = createAsset2(o.originKey, o.url, o.widthUnitRatio, o.heightFilling, o.padding, o.startX, k)
                assetObj[k] = offering
                const toSaveObj = prepToSaveObj(o.originKey, o.startX, o.originKey)
                postMessage('addAsset', toSaveObj)
                postMessage('offeringRetrieved')
                return
            }
            // console.log('create asset called')
            // console.log(obj)
            Object.keys(obj).map(k => {
                const o = obj[k]
                if(defaultAssets.hasOwnProperty(o.originKey))
                {
                    const offering = createAsset3(o.originKey, o.widthUnitRatio, o.heightFilling, o.padding, o.startX, k)
                    assetObj[k] = offering
                    return postMessage('offeringRetrieved')
                }
                // console.log(`not found key ${k}`)
                // console.log(obj[k])
                // const o = obj[k]
                return S3B.getImage(o.originKey, s3folders.userPhotos, (err, data) => {
                    // console.log(err)
                    // console.log(data)
                    if(err) return;
                    // console.log('checkpoint 1')
                    const url = data;
                    // get the relative ratio of width unit to define the photo width
                    // const widthUnitRatio = isLandscape ? WIDTH_UNIT / collisionDetectArr[assetKey]['pos'][2]*1.3 : WIDTH_UNIT / collisionDetectArr[assetKey]['pos'][2]*1.5
                    // const heightFilling = 0;
                    // isLandscape ? padding = 17.5 : padding = 14;
                    // console.log(`photo key ${k}`)
                    defaultAssets[k] = {
                        widthUnitRatio: o.widthUnitRatio,
                        heightFilling: o.heightFilling,
                        originKey: o.originKey,
                        padding: o.padding,
                        startX: o.startX,
                        url: data,
                        callback: 'removeOffering',
                    }
                    // create the photo asset and add it to the list of offeringArr to be rendered
                    // const photoAsset = createAsset(originKey, url, widthUnitRatio, heightFilling, padding, function() {
                    //     removeOffering(k)
                    // });
                    const photoAsset = createAsset2(k, data, o.widthUnitRatio, o.heightFilling, o.padding, o.startX, k)
                    assetObj[k] = photoAsset
                })
            })
            postMessage('offeringRetrieved')
        }
        if( props.hasOwnProperty('predefinedOffering') && props.predefinedOffering !== null  && props.predefinedOffering !== undefined)
        {
            // console.log('predefined offering provided')
            // console.log(props.predefinedOffering)
            // if(props.predefinedOffering === 'init')
            // {
            //     assetObj = { diaryClose: defaultAssets.diaryClose }
            // }
            const obj = props.predefinedOffering
            let allAssets = {}

            if(Object.keys(obj).length <= 0)
            {
                // console.log('should provide default assets')
                assetObj = {}
                const k = 'diaryClose'
                const o = defaultAssets[k]
                const offering = createAsset2(o.originKey, o.url, o.widthUnitRatio, o.heightFilling, o.padding, o.startX, k)
                assetObj[k] = offering
                const toSaveObj = prepToSaveObj(o.originKey, o.startX, k)
                // allAssets.push(toSaveObj)
                postMessage('addAsset', toSaveObj)
                postMessage('offeringRetrieved')
                // assetObj = { diaryClose: defaultAssets.diaryClose }
                return
            }
            // console.log('create asset called')
            // console.log(obj)
            Object.keys(obj).map(k => {
                const o = obj[k]
                const offering = createAsset2(o.originKey, o.url, o.widthUnitRatio, o.heightFilling, o.padding, o.startX, k)
                assetObj[k] = offering
                const toSaveObj = prepToSaveObj(o.originKey, o.startX, k)
                allAssets = {...allAssets, ...toSaveObj}
                // allAssets.push(toSaveObj)
                // postMessage('addAsset', toSaveObj)
            })
            postMessage('addAsset', allAssets)
            postMessage('offeringRetrieved')
            // const offering = createAsset2(obj.originKey, obj.url, obj.widthUnitRatio, obj.heightFilling, obj.padding, obj.startX, obj.callback)
            // offeringArr.push(offering)
        }
        if( props.hasOwnProperty('disableCanvasInteraction')  && props.disableCanvasInteraction !== null )
        {
            disableCanvasInteraction = props.disableCanvasInteraction
        }

        // ========================== DEPRECATED ==============================
        // user has added an extra item to the offering base: add the flower to the vase or add photo to the album
        if( props.hasOwnProperty('modifyOffering') && props.modifyOffering !== null )
        {
            // Modify the object in the assetObj array
            const optionKey = props.modifyOffering.optionKey;
            const targetAssetKey = props.modifyOffering.targetAssetKey;
            try {
                modifyExistingOffering(targetAssetKey, optionKey);
                postMessage('offeringRetrieved')
                return
            }
            catch(err) {
                //TODO: design a proper error control
                // console.log(err);
                postMessage('offeringRetrieved')
                return
            }
        }

    };

    // =============================== DEPRECATED ================================
    /*
    const offerFlower = (assetKey) => {
        let pos = collisionDetectArr[assetKey].pos; // get the position of the origin item
        mouseHintMsg = 'Click the vase to select the flower you wish to offer';
        displayMouseHint = true;
        collided = true;
        collidedAction[assetKey] = function() {
            // create and dispatch the event
            let selectFlowerEvent = new CustomEvent('displayOptions', {
                detail: {
                    key: assetKey,
                    pos: pos,
                    optionsArr: [
                        {
                            btnValue: 'flowerOrchid',
                            imgSrc: require('../../../img/orchid_icon-sm.png'),
                            alt: 'offer an orchid',
                            iconTitle: 'orchid'
                        },
                        {
                            btnValue: 'flowerRose',
                            imgSrc: require('../../../img/rose_icon-sm.png'),
                            alt: 'offer a rose',
                            iconTitle: 'rose'
                        },
                        {
                            btnValue: 'flowerLily',
                            imgSrc: require('../../../img/lily_icon-sm.png'),
                            alt: 'offer a lily',
                            iconTitle: 'lily'
                        },
                        {
                            btnValue: 'flowerHydrangea',
                            imgSrc: require('../../../img/hydran_icon-sm.png'),
                            alt: 'offer a hydrangea',
                            iconTitle: 'hydrangea'
                        },
                        {
                            btnValue: 'flowerChrysan',
                            imgSrc: require('../../../img/chrys_icon-sm.png'),
                            alt: 'offer a chrysanthemums',
                            iconTitle: 'chrysanthemums'
                        },
                    ],
                }
            });
            window.dispatchEvent(selectFlowerEvent);
        }
    }
    */
    /*
    let defaultAssets = {
        diaryClose: {
            originKey: 'diaryClose',
            url: require('../../../img/diary4-md.png'),
            widthUnitRatio: 0.8, 
            heightFilling: 3, 
            padding: 2,
            startX: 3.4,
            // get startX() { return BOTTOM_RIGHT_START_X_2 - PADDING * 6; },
            // get width() { return WIDTH; } ,
            // get height() { return HEIGHT; },
            // get widthUnit() { return WIDTH_UNIT * 1.2; },
            // get heightFilling() { return 0 * PADDING } ,
            // get padding() { return 0 * PADDING },
            // img: null,
            callback: function(objPointer) {
                openDiaryLink(objPointer);
            },
        },
        // diaryClose: {
        //     originKey: 'diaryClose',
        //     url: require('../../../img/diary4-md.png'),
        //     get startX() { return BOTTOM_RIGHT_START_X_2 - PADDING * 6; },
        //     get width() { return WIDTH; } ,
        //     get height() { return HEIGHT; },
        //     get widthUnit() { return WIDTH_UNIT * 1.2; },
        //     get heightFilling() { return 0 * PADDING } ,
        //     get padding() { return 0 * PADDING },
        //     img: null,
        //     callback: function(objPointer) {
        //         openDiaryLink(objPointer);
        //     },
        // },
        diaryOpen: {
            originKey: 'diaryOpen',
            url: require('../../../img/diary4-md.png'),
            get startX() { return BOTTOM_RIGHT_START_X_1 - PADDING * 6; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT * 2; },
            get heightFilling() { return PADDING * 0; },
            get padding() { return PADDING * 2; },
            img: null,
            callback: function(objPointer) {
                closeDiary(objPointer);
            },
        },
        candleLight: {
            originKey: 'candleLight',
            url: require('../../../img/candle_light_watercolor-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 2.5; },
            get heightFilling() { return PADDING * -3; },
            get padding() { return PADDING * 0 },
            img: null,
            callback: function(objPointer) {
                removeOffering(objPointer);
            },
        },
        candleDim: {
            originKey: 'candleDim',
            url: require('../../../img/candle_dim_watercolor-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 2.5; },
            get heightFilling() { return PADDING * -3; },
            get padding() { return PADDING * 0 },
            img: null,
            callback: function(objPointer) {
                // find the candle object in the asset object and replace it with candleLight
                lightCandle(objPointer);
            },
        },
        flowerVase: {
            originKey: 'flowerVase',
            url: require('../../../img/vase-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.4; },
            get heightFilling() { return PADDING * -3; } ,
            get padding() { return PADDING * 0; },
            img: null,
            callback: function(objPointer) {
                // allow users to select types of flowers to offer
                offerFlower(objPointer);
            },
        },
        flowerOrchid: {
            originKey: 'flowerOrchid',
            url: require('../../../img/orchid-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.4; },
            get heightFilling() { return PADDING * -3; } ,
            get padding() { return PADDING * 0; },
            img: null,
            callback: function(objPointer) {
                removeOffering(objPointer);
            },
        },
        flowerRose: {
            originKey: 'flowerRose',
            url: require('../../../img/rose-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.4; },
            get heightFilling() { return PADDING * -3; } ,
            get padding() { return PADDING * 0; },
            img: null,
            callback: function(objPointer) {
                removeOffering(objPointer);
            },
        },
        flowerLily: {
            originKey: 'flowerLily',
            url: require('../../../img/lily-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.4; },
            get heightFilling() { return PADDING * -3; } ,
            get padding() { return PADDING * 0; },
            img: null,
            callback: function(objPointer) {
                removeOffering(objPointer);
            },
        },
        flowerHydrangea: {
            originKey: 'flowerHydrangea',
            url: require('../../../img/hydrangea-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.4; },
            get heightFilling() { return PADDING * -3; } ,
            get padding() { return PADDING * 0; },
            img: null,
            callback: function(objPointer) {
                removeOffering(objPointer);
            },
        },
        flowerChrysan: {
            originKey: 'flowerChrysan',
            url: require('../../../img/chrysanthemums-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.4; },
            get heightFilling() { return PADDING * -3; } ,
            get padding() { return PADDING * 0; },
            img: null,
            callback: function(objPointer) {
                removeOffering(objPointer);
            },
        },
        photoFrameH: {
            originKey: 'photoFrameH',
            url: require('../../../img/photo_frame_h-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.4; },
            get heightFilling() { return PADDING * 0; } ,
            get padding() { return PADDING * 6; },
            img: null,
            callback: function(objPointer) {
                // trigger the dragdrop menu
                removeOffering(objPointer);
                uploadPhoto(objPointer);
            },
        },
        photoFrameV: {
            originKey: 'photoFrameV',
            url: require('../../../img/photo_frame_v-md.png'),
            get startX() { return BOTTOM_LEFT_START_X_2; },
            get width() { return WIDTH; },
            get height() { return HEIGHT; },
            get widthUnit() { return WIDTH_UNIT / 1.8; },
            get heightFilling() { return PADDING * 0; } ,
            get padding() { return PADDING * 6; },
            img: null,
            callback: function(objPointer) {
                removeOffering(objPointer);
                uploadPhoto(objPointer);
            },
        }
    }
    */
};
// Add the buttons here to trigger the actions
export class P5Wrapper extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            operationPanelDisplay: true,
            preloaded: false
        }
        this.preventLeftClick = this.preventLeftClick.bind(this)
        this.respondToMessage = this.respondToMessage.bind(this)
        this.completePreload = this.completePreload.bind(this)
    }

    completePreload()
    {
        this.setState({
            preloaded: true
        })
    }
    
    preventLeftClick(e)
    {
        // enable to prevent left click
        e.preventDefault()
    }

    respondToMessage(e)
    {
        this.canvas.respondToMessage(e)
    }

    componentDidMount() {
        
        // console.log(this.props.sketch)
        // console.log(this.wrapper)
        const self = this

        let timeOut = 0, limit = 10
        this.waitToLoadInterval = setInterval(() => {
            if(this.wrapper !== null && this.wrapper !== undefined)
            {
                // console.log('ada! wrapper is here')
                // console.log(this.wrapper)
                self.canvas = new p5(self.props.sketch, self.wrapper)
                if (self.canvas.myCustomRedrawAccordingToNewPropsHandler) {
                    self.canvas.myCustomRedrawAccordingToNewPropsHandler(self.props)
                }
                if(self.canvas.respondToMessage) {
                    window.addEventListener('msgToCanvas', self.respondToMessage);
                }
                clearInterval(self.waitToLoadInterval)
                return
            }
            if(timeOut >= 10)
            {
                clearInterval(self.waitToLoadInterval)
                return
            }
            timeOut += 1
        }, 300)


        
        /*
        if(this.canvas.respondToMessage) {
            window.addEventListener('msgToCanvas', (e) => {
                this.canvas.respondToMessage(e)
            });
        }
        */
        window.addEventListener('contextmenu', this.preventLeftClick)
    }
    componentWillReceiveProps(newprops) {
        if (this.props.sketch !== newprops.sketch) {
            this.canvas.remove()
            this.canvas = new p5(newprops.sketch, this.wrapper)
        }
        if (this.canvas && this.canvas.hasOwnProperty('myCustomRedrawAccordingToNewPropsHandler')) {
            this.canvas.myCustomRedrawAccordingToNewPropsHandler(newprops)
        }
    }
    componentWillUnmount() {
        clearInterval(this.waitToLoadInterval)
        window.removeEventListener('contextmenu', this.preventLeftClick)
        window.removeEventListener('msgToCanvas', this.respondToMessage)
        this.canvas.remove()
    }
    render() {
        // const hideStyle = { visibility: 'hidden', opacity: 0 };
        // const opStyle = {
        //     height: this.props.windowHeight - WINDOW_PADDING,
        //     width: this.props.windowHeight * W_H_RATIO - WINDOW_PADDING,
        //     transitionDelay: '0s',
        //     visibility: 'visible',
        //     opacity: 1,
        // }

        if(!this.state.preloaded)
        {
            return <PreloadedDefaultOffering loadComplete={this.completePreload} />
        }

        return (
            <div className='app-wrapper text-center' ref={wrapper => this.wrapper = wrapper}>
            {/*this.props.children*/}
            </div>
        );

    }
}

