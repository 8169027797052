/**
 * Check if localstorage is supported, and polyfill if it's not supported.
 * Interact with localstorage
 */
import { localStoragePolyfill } from '../init/general';
const SecureStorage = require('secure-web-storage');
const CryptoJS = require("crypto-js");

export class Localstorage {
    constructor(SECRET_KEY = '')
    {
        // check if localstorage is supported
        if( typeof(localStorage) === 'undefined' )
        {
            // get the polyfill for localstorage
            localStoragePolyfill();
        }
        this.secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
            
                return key.toString();
            },
            encrypt: function encrypt(data) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
            
                data = data.toString();
            
                return data;
            },
            decrypt: function decrypt(data) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            
                data = data.toString(CryptoJS.enc.Utf8);
            
                return data;
            }
        });
    }
    /**
     * return the item in parsed JSON format
     * @param {string} key 
     * usage:
        LS.get('canvasUrl')
        .then(results => {
            if(!results) return;
            return results;
        });
     */
    get(key, encrypt = true)
    {
        // const value = localStorage.getItem(key);
        if(!encrypt)
        {
            return new Promise((res, rej) => {
                const val = localStorage.getItem(key);
                return res(val);
            });
        }
        return new Promise((res, rej) => {
            const val = this.secureStorage.getItem(key);
            return res(val);
        });
    }
    secureGet(key)
    {
        return this.secureStorage.getItem(key);
    }

    /**
     * return the item in parsed JSON format
     * @param {string} key 
     * @param {string} value 
     */

    set(key, value, encrypt = true)
    {
        if(!encrypt)
        {
            return new Promise(res => {
                if(typeof value !== 'string') {
                    localStorage.setItem(key, JSON.stringify(value));
                    return res();
                }
                else {
                    localStorage.setItem(key, value)
                    return res();
                }
            });
        }
        return new Promise(res => {
            this.secureStorage.setItem(key, value)
            return res();
        });
    }

    secureSet(key, value)
    {
        return this.secureStorage.setItem(key, value);
    }

    remove(key, encrypt = true)
    {
        if(!encrypt)
        {
            return new Promise(res => {
                localStorage.removeItem(key)
                return res();
            });
        }
    
        return new Promise(res => {
            this.secureStorage.removeItem(key)
            return res();
        });
    }

    removeAll(encrypt = true)
    {
        if(!encrypt)
        {
            return new Promise(res => {
                localStorage.clear()
                return res();
            });
        }
        return new Promise(res => {
            this.secureStorage.clear()
            return res();
        });
    }

}