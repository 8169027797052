const methods = {
    LANG: 'LANG',
    FETCH_DATA: "FETCH_DATA",
    CANCEL_FETCHING: "CANCEL_FETCHING",
    ADD_ERROR: "ADD_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    ADD_SYSTEM_FEEDBACK: "ADD_SYSTEM_FEEDBACK",
    LOGIN: "LOGIN",
    LOAD_SESSION: "LOAD_SESSION",
    LOGIN_ERR: "LOGIN_ERR",
    CLEAR_LOGIN_ERR: "CLEAR_LOGIN_ERR",
    LOGOUT: "LOGOUT",
    REGISTER_USER: "REGISTER_USER",
    REGISTER_MSG: "REGISTER_MSG",
    CLEAR_REGISTER_ERR: "CLEAR_REGISTER_ERR",
    PUBLIC_URL: "PUBLIC_URL",
    LOAD_USER_PROFILE: "LOAD_USER_PROFILE",
    ADD_PG13_ANSWER: "ADD_PG13_ANSWER",
    CLEAR_PG13_ANSWER: "CLEAR_PG13_ANSWER",
    ADD_DEMO_ANSWER: "ADD_DEMO_ANSWER",
    CLEAR_DEMO_ANSWER: "CLEAR_DEMO_ANSWER",
    ADD_BCI_ANSWER: "ADD_BCI_ANSWER",
    CLEAR_BCI_ANSWER: "CLEAR_BCI_ANSWER",
    CLEAR_SESSION: "CLEAR_SESSION",
    CLEAR_STORE: "CLEAR_STORE",
    ADD_EMERGENCY_CONTACT: "ADD_EMERGENCY_CONTACT",
    EDIT_EMERGENCY_CONTACT: "EDIT_EMERGENCY_CONTACT",
    REMOVE_EMERGENCY_CONTACT: "REMOVE_EMERGENCY_CONTACT",
    LOAD_EMERGENCY_CONTACT_LIST: "LOAD_EMERGENCY_CONTACT_LIST",
    INIT_CONFIRM: 'INIT_CONFIRM',
    STORE_PAYLOAD_BEFORE_CONFIRM: 'STORE_PAYLOAD_BEFORE_CONFIRM',
    CLEAR_PAYLOAD_AFTER_CONFIRM: 'CLEAR_PAYLOAD_AFTER_CONFIRM',
    LOAD_MUSICS_LIST: 'LOAD_MUSICS_LIST',
    LOAD_IMAGES_LIST: 'LOAD_IMAGES_LIST',
    EDIT_IMAGES_LIST: 'EDIT_IMAGES_LIST',
    EDIT_GRIEF_MAP_STATUS: 'EDIT_GRIEF_MAP_STATUS',
    LOAD_ACCOUNT_INFO: 'LOAD_ACCOUNT_INFO',
    UPDATE_ACCOUNT_INFO: 'UPDATE_ACCOUNT_INFO',
    CLEAR_DIALOGUES: 'CLEAR_DIALOGUES',
    ADD_DIALOGUE: 'ADD_DIALOGUE',
    EDIT_DIALOGUE: 'EDIT_DIALOGUE',
    REMOVE_DIALOGUE: 'REMOVE_DIALOGUE',
    LOAD_SELF_CARE_DIALOGS: 'LOAD_SELF_CARE_DIALOGS',
    UPDATE_MODULE_PROGRESS: 'UPDATE_MODULE_PROGRESS',
    UPDATE_SYSTEM_MSG: 'UPDATE_SYSTEM_MSG',
    ENABLE_RESET_CONFIG: 'ENABLE_RESET_CONFIG',
    LOAD_DAY_LOG: 'LOAD_DAY_LOG',
    ADD_ACTIVITY_LOG: 'ADD_ACTIVITY_LOG',
    LOAD_ACTIVITY_LOG: 'LOAD_ACTIVITY_LOG',
    UPDATE_MEMORIAL_STEP_LOG: 'UPDATE_MEMORIAL_STEP_LOG',
    LOAD_DAY_JOURNALS: 'LOAD_DAY_JOURNALS',
    ADD_DAY_JOURNALS: 'ADD_DAY_JOURNALS',
    UPDATE_DAY_JOURNAL: 'UPDATE_DAY_JOURNAL',
    REMOVE_DAY_JOURNALS: 'REMOVE_DAY_JOURNALS',
    LOAD_CURRENT_JOURNAL_DAY: 'LOAD_CURRENT_JOURNAL_DAY',

    ADD_CURSOR: 'ADD_CURSOR',
    LOAD_DEMO_ENTRIES: 'LOAD_DEMO_ENTRIES',
    CONCAT_DEMO_ENTRIES: 'CONCAT_DEMO_ENTRIES',
    LOAD_PG13_ENTRIES: 'LOAD_PG13_ENTRIES',
    CONCAT_PG13_ENTRIES: 'CONCAT_PG13_ENTRIES',
    LOAD_CSSRS_ENTRIES: 'LOAD_CSSRS_ENTRIES',
    CONCAT_CSSRS_ENTRIES: 'CONCAT_CSSRS_ENTRIES',
    LOAD_CDD_ENTRIES: 'LOAD_CDD_ENTRIES',
    CONCAT_CDD_ENTRIES: 'CONCAT_CDD_ENTRIES',

    LOAD_ADMINBOARD_PAGE: 'LOAD_ADMINBOARD_PAGE',
    UPDATE_DISPLAY_PREFERENCE: 'UPDATE_DISPLAY_PREFERENCE'
}

export default methods