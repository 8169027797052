import methods from '../methods'
// import FetchWrapper from '../utilities/FetchWrapper'
import AH from '../components/classes/v2/AmplifyHelper'
// import AH from '../helpers/AmplifyHelper'
import GH from '../components/classes/v2/GeneralHelper'
import PH from '../components/classes/v2/ProfileHelper'
import UH from '../components/classes/v2/UserHelper'
import STM from '../components/classes/v2/SystemTimeManage'
import ErrorHelper from '../components/classes/v2/ErrorHelper'
// import initProps from '../config/props'

// const Fetch = new FetchWrapper()
// const tb = initProps.db
// const AH = new AmplifyHelper()
const defaultUserFirstName = 'homeowner'
const defaultDeceasedName='the deceased'
export const signup = (email, password, deceasedName = defaultDeceasedName, firstName = defaultUserFirstName, fieldData = {}) => async dispatch => {

  if(!email || !password || email === '' || password === '' || email === null || password === null)
  {
    throw {
      code: 'MISSING_REQUIRED_INFORMATION',
      message: 'Please fill in all the necessary information to register.'
    }
    /*
    dispatch({
      type: methods.LOGIN_ERR,
      payload: 
    })
    return false
    */
  }

  try
  {
    const signUpResult = await AH.signUp({ email, password }).then(r => r).catch(e => e)
    console.log(signUpResult)
    if(signUpResult.hasOwnProperty('code'))
    {
      switch(signUpResult)
      {
        case "UsernameExistsException":
          throw {
            code: signUpResult.code,
            message: signUpResult.message
          }
        break
        default: throw signUpResult
      }
    }
  
    // save the data in the database
    const username = signUpResult.userSub
  
    const insertUser = await UH.insertUser({
      username: username,
      email: email,
      user_roles_id: 1
    })
    // console.log(insertUser)
    if(insertUser.hasOwnProperty('code'))
    {
      // failed to insert the user in the database
      throw {
        code: insertUser.code,
        message: insertUser.message || 'Error: an unknown error happened while initiating your user data. The system manager will receive an email to look into your problem. Please allow us to get back to you ASAP.'
      }

    }
    
    let usersId = 0
    if(Array.isArray(insertUser) && insertUser.length > 0)
    {
      usersId = insertUser[0].id
    }
    else
    {
      usersId = insertUser.id
    }

    // save other data to the profile database
    let fields = {}
    Object.keys(fieldData).map((k, idx) => {
      fields[k] = fieldData[k]
    })
    fields['deceased_first_name'] = deceasedName
    fields['user_first_name'] = firstName
    const insertProfile = await PH.registerProfile(usersId, fields)
    console.log(insertProfile)
    if(!insertProfile?.success)
    {
      throw {
        code: "FAILED_TO_INSERT_PROFILE",
        message: insertProfile.data || 'Error: an unknown error happened while registering a user profile. You might not see your customized section.'
      }
      /*
      dispatch({
        type: methods.LOGIN_ERR,
        payoload: {
          code: insertProfile.code,
          message: insertProfile.message || 'Error: an unknown error happened while storing your user profile. You might not see your customized section.'
        }
      })
      ErrorHelper.sendErrorEmail(usersId, 'FAILED_TO_STORE_USER_PROFILE', `To the system manager: the system failed to store the user's profile for user ${email}. Please double check with the user.`)
      return false
      
      */
    }
  
    // successfully initiated the profile, store the userdata in the reducer for confirmation code
    dispatch({
      type: methods.LOGIN,
      payload: {
        username: username,
        email: email,
        id: usersId
      }
    })
    return true
  }
  catch(e)
  {
    dispatch({
      type: methods.LOGIN_ERR,
      payload: {
        code: e.code,
        message: e.message
      }
    })
    ErrorHelper.sendErrorEmail(email, e.code, e.message)
    return false
  }

}

export const confirmSignUp = (username, code) => async dispatch => {
  // console.log(`to confirm signup of user ${username} with code ${code}`)
  if(!username || !code)
  {
    dispatch({
      type: methods.LOGIN_ERR,
      payoload: {
        code: 'MISSING_NECESSARY_DATA',
        message: 'Error: Please double check your confirmation code.'
      }
    })
    return false
  }
  try
  {
    const r = await AH.confirmSignUp(username, code)
    // console.log(r)
    if(!r.hasOwnProperty('code')) return r
    throw r
  }
  catch(e)
  {
    // console.log(e)
    dispatch({
      type: methods.LOGIN_ERR,
      payload: {
        code: e.code || 'UNKNOWN_ERROR',
        message: e.message || 'Error: an unknown error happened while confirming your registration email. Please double check your confirmation code.'
      }
    })
    return e
  }
}

export const addReigsterMsg = msg => ({
  type: methods.REGISTER_MSG,
  payload: msg
})

export const authUser = inputAuth => async dispatch => {
  const auth = GH.trimText(inputAuth)
  // console.log(auth)
  try
  {
    // console.log('test signing in')
    const r = await AH.signIn(auth)
    // console.log("sign in result",r)
  
    if(r.hasOwnProperty('username') && r.username !== '' && r.username !== null) // auth successful
    {
      // console.log('sign in successful')
      let newObj = {
        email: r.attributes.email,
        username: r.username,
      }
      // console.log("store signed in user info", newObj)
      // get user id
      let userData = await UH.getUserData(r.username).then(id => id).catch(e => false)
      let userId = 0
      // console.log("get user data from DB", userData)

      if(userData.length <= 0 || !userData)
      {
        userId = false
      }
      else
      {
        userId = userData[0]['id']
        newObj['role'] = userData[0]['user_roles_id']
      }
      
      // console.log(userData)
      // console.log(`user id is ${userId}`)
      // let userId = await UH.getUserIdGivenUsername(r.username).then(id => id).catch(e => false)
      // let usersId = 0

      if(!userId) // Although user has registered from Cognito, he/she hasn't been inserted into MySQL database
      {
        // console.log("user has not been stored in the DB, insert user")
        // console.log('cannot get user id, the user does not exist in the database')
        const insertUser = await UH.insertUser({
          username: r.username,
          email: r.attributes.email,
          user_roles_id: 1
        })
        // console.log("creating user result ",insertUser)
        if(Array.isArray(insertUser) && insertUser.length > 0)
        {
          userId = insertUser[0].id
        }
        else
        {
          userId = insertUser.id
        }
      }
      // console.log(`got user id ${userId}`)
      // get user profile
      const profile = await PH.upsertProfile(userId, {}).then(p => p).catch(e => false)
      // console.log(profile)
      if(!profile)
      {
        // console.log('user does not have profile yet, create one with '+userId)

        // save other data to the profile database
        profile = await PH.upsertProfile(userId, { deceased_first_name: defaultDeceasedName, user_first_name: defaultUserFirstName }).then(r => r).catch(e => false)
        // console.log(profile)
        if(profile?.code)
        {
          throw {
            code: profile.code,
            message: profile.message || 'Error: an unknown error happened while storing your user profile. You might not see your customized section.'
          }
          /*
          dispatch({
            type: methods.LOGIN_ERR,
            payoload: {
              code: insertProfile.code,
              message: insertProfile.message || 'Error: an unknown error happened while storing your user profile. You might not see your customized section.'
            }
          })
          ErrorHelper.sendErrorEmail(usersId, 'FAILED_TO_STORE_USER_PROFILE', `To the system manager: the system failed to store the user's profile for user ${email}. Please double check with the user.`)
          return false
          
          */
        }
      }

      // get LMH config
      const config = await PH.upsertConfig(userId, {}).then(c => c).catch(e => false)
      // console.log(config)
      // console.log(config['created_at'])
      
      newObj['id'] = userId
      let accountInfo = {
        nickname: profile.user_first_name || defaultUserFirstName,
        deceased: profile.deceased_first_name || defaultDeceasedName,
        house: config.house || null,
        view: config.view || null,
        since: config.created_at || STM.formatLocalTime(),
        redcap_id: profile?.redcap_id
      }
      // console.log(accountInfo)

      // const sessionObj = r.signInUserSession
      console.log(newObj)
      const newAuth = Object.assign({}, newObj)
      dispatch({
        type: methods.CLEAR_LOGIN_ERR,
        payload: {}
      })
      /*
      dispatch({
        type: methods.LOAD_SESSION,
        payload: sessionObj
      })
      */
      dispatch({
          type: methods.LOGIN,
          payload: newAuth
      })

      dispatch({
        type: methods.LOAD_ACCOUNT_INFO,
        payload: accountInfo
      })

      return r.username
    }

    throw r
  }
  catch(e)
  {
    console.log(e)
    const partAuth = {
      username: inputAuth.username
    }
    dispatch({
      type: methods.LOGIN,
      payload: partAuth
    })
    let msg = 'The system failed to log you in currently. Please try again later.'
    switch(e.code)
    {
      case "UserNotConfirmedException":
        dispatch({
          type: methods.LOGIN_ERR,
          payload: {
            code: e.code,
            message: e.message
          }
        })
        break
      case "UserNotFoundException":
        msg = "The system fail to identify the credential you provided. Maybe you want to register as a member first."
        dispatch({
          type: methods.LOGIN_ERR,
          payload: {
            code: e.code,
            message: msg
          }
        })
        break
      case "USER_NOT_FOUND":
        const msg = "The system fail to identify the credential you provided. Maybe you want to register as a member first."
        dispatch({
          type: methods.LOGIN_ERR,
          payload: {
            code: e.code,
            message: msg
          }
        })
        break
      case "NotAuthorizedException":
        msg = "Please double check your username or password"
        dispatch({
          type: methods.LOGIN_ERR,
          payload: {
            code: e.code,
            message: msg
          }
        })
        break
      default:
        dispatch({
          type: methods.LOGIN_ERR,
          payload: {
            code: e.code,
            message: e.message || 'The system failed to log you in currently. Please try again later.'
          }
        })
    }
    return e
  }
}

export const sendForgotPassCode = email => ({
  type: methods.LOGIN,
  payload: {
    email: email
  }
})

export const loadSession = session => dispatch => {
  /*
  return AH.getSession()
  .then(session => {
    if(typeof session !== 'string')
    {
      const newObj = {
        username: session.accessToken.payload.email || '',
        accessToken: session.accessToken.jwtToken,
        isLoggedIn: true
      }
      dispatch({
        type: methods.LOGIN,
        payload: newObj
      })
      return dispatch({
        type: methods.LOAD_SESSION,
        payload: session
      })
    }
  })
  .catch(err => {

  })
  */
}

export const logout = () => ({
  type: methods.CLEAR_STORE
})

export const clearAuth = auth => dispatch => {

  /*
  return AH.signOut(false)
  .then(async () => {
    // get the login recorded
      return Fetch.get(`/users/v1/recordAuthActivity/${auth.id}/0`, [], (err, result) => {
        if(err)
        {
          dispatch({
            type: methods.LOGIN_ERR,
            payload: {
              code: "LogoutFailed",
              message: 'The system failed to log you out currently. Please try again later.'
            }
          })
          return false
        }
        dispatch({
          type: methods.CLEAR_STORE,
          payload: undefined
        })
        dispatch({
          type: methods.LOGOUT,
          auth
        })
        return true
      })
  })
  .catch(err => {
    dispatch({
      type: methods.LOGIN_ERR,
      payload: {
        code: "LogoutFailed",
        message: 'The system failed to log you out currently. Please try again later.'
      }
    })
    return false
  })
*/
}

export const addLoginErr = err => ({
  type: methods.LOGIN_ERR,
  payload: err
})

export const clearLoginErr = err => ({
  type: methods.CLEAR_LOGIN_ERR,
  payload: {}
})

export const addRegisterFeedback = feedback => ({
  type: methods.REGISTER_MSG,
  payload: feedback
})

export const clearRegisterFeedback = feedback => ({
  type: methods.CLEAR_REGISTER_ERR,
  payload: ''
})


export const upsertConfig = (userId, param) => async dispatch => {
  const r = await PH.upsertConfig(userId, param)
  
  return dispatch({
      type: methods.UPDATE_ACCOUNT_INFO,
      payload: param
  })
  
}

// load the account information including nickname, deceased's name and progress
export const manageAccountInfo = (userId, param = {}) => async dispatch => {
  if(!userId) return null
  try
  {

    const profile = await PH.upsertProfile(userId, param)
    .then(r => r).catch(e => false)
    
    // return true
    if(profile)
    {
      let accountInfo = {
        nickname: profile.user_first_name || defaultUserFirstName,
        deceased: profile.deceased_first_name || defaultDeceasedName,
        redcap_id: profile.redcap_id
      }
      dispatch({
        type: methods.LOAD_ACCOUNT_INFO,
        payload: accountInfo
      })
      return true
    }

    throw profile
  }
  catch(e)
  {
    // console.log(e)
    dispatch({
      type: methods.LOAD_ACCOUNT_INFO,
      payload: null
    })
    return false
  }
  /*
  // console.log(userId)
  if(!userId) return null
  try
  {
    // console.log(param)
    const profile = await PH.upsertProfile(userId, param)
    .then(r => r).catch(e => false)
    // console.log(profile)

    if(profile)
    {
      return dispatch({
        type: methods.LOAD_ACCOUNT_INFO,
        payload: profile
      })
    }

    throw profile
  }
  catch(e)
  {
    // console.log(e)
    return dispatch({
      type: methods.LOAD_ACCOUNT_INFO,
      payload: null
    })
  }
  */
}

// export const fetchUserProfile = accessToken => dispatch => {
//   return Fetch.post('/users/v1/getUserProfile', {
//     accessToken: accessToken
//   }, (err, result) => {
//     if(err || !result.success)
//     {
//       // add error
//       return
//     }
//     return dispatch({
//       type: methods.LOAD_USER_PROFILE,
//       payload: result.data
//     })
//   })
// }