import jstz from 'jstz';
import { Localstorage } from './localstorage';
import { sessionKeys, tbs } from '../init/initApp';
import { arrToObjWithColumnKey, hash } from '../init/general';
import AWSCognito from '../classes/v2/AmplifyHelper'
// import { Userdata } from './user';
import User from './v2/UserHelper'
// import { Database } from './db';
import FetchWrapper from './v2/FetchWrapper';

const moment = require('moment-timezone');

// const User = new Userdata();
// const DB = new Database();
const Fetch = new FetchWrapper()
/**
 * Manage the timestamp and day of the system
 * usage:
    import { SystemTimeManagement } from 'components/classes/systemConfig';
    const STM = new SystemTimeManagement();
 */
export class SystemTimeManagement extends Localstorage {
    constructor(SECRET_KEY = '') {
        super(SECRET_KEY = '');
        this.timezone = jstz.determine();
    }

    getCurrentLocalTimestamp(promise = false) {
        if(!promise)
        {
            return this.formatLocalTime('', false);
        }
        return this.formatLocalTime('', true);
    }
    getCurrentUTCTimestamp(promise = false) {
        if(!promise)
        {
            return this.formatUTCTime('', false);
        }
        return this.formatUTCTime('', true);
    }

    getTimezoneName(promise = true) {
        if(promise)
        {
            return new Promise(res => {
                return res(this.timezone.name());
            });
        }
        return this.timezone.name();
    }

    // get the local time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatLocalTime(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = new Date(timestamp);
            var y = now.getFullYear();
            var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
            var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
            var h = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
            var mm = now.getMinutes() < 10 ? "0" + now.getUTCMinutes() : now.getMinutes();
            var s = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
            const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = new Date();
        var y = now.getFullYear();
        var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        var h = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
        var mm = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
        var s = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
        const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }

    // get the local time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatLocalDate(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = new Date(timestamp);
            var y = now.getFullYear();
            var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
            var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
            const completeStr = `${y}-${m}-${d}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = new Date();
        var y = now.getFullYear();
        var m = now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
        var d = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
        const completeStr = `${y}-${m}-${d}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }


    // get the current time and prepare it into 'YYYY-MM-DD HH:MM:SS' format for MySQL DATETIME field
    formatUTCTime(timestamp = '', promise = false) {
        if(timestamp !== '')
        {
            var now = new Date(timestamp);
            var y = now.getUTCFullYear();
            var m = now.getUTCMonth() < 9 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1;
            var d = now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate();
            var h = now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours();
            var mm = now.getUTCMinutes() < 10 ? "0" + now.getUTCMinutes() : now.getUTCMinutes();
            var s = now.getUTCSeconds() < 10 ? "0" + now.getUTCSeconds() : now.getUTCSeconds();
            const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
            if(promise)
            {
                return new Promise(res => {
                    return res(completeStr);
                });
            }
            return completeStr;
        }
        var now = new Date();
        var y = now.getUTCFullYear();
        var m = now.getUTCMonth() < 9 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1;
        var d = now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate();
        var h = now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours();
        var mm = now.getUTCMinutes() < 10 ? "0" + now.getUTCMinutes() : now.getUTCMinutes();
        var s = now.getUTCSeconds() < 10 ? "0" + now.getUTCSeconds() : now.getUTCSeconds();
        const completeStr = `${y}-${m}-${d} ${h}:${mm}:${s}`;
        if(promise)
        {
            return new Promise(res => {
                return res(completeStr);
            });
        }
        return completeStr;
    }

    /**
     * convert the local timestring to UTC timestring
     * @param {timestring} LocalTime 
     * usage:
        import { SystemTimeManagement } from './assets/systemConfig';
        var STM = SystemTimeManagement;
        STM.convertLocaltoUTC("2019-02-05T14:48:37-05:00")
        .then(UTCTime => {
            // do something
            // if you want UTC time string
            STM.formatUTCTime(UTCTime);
        });
     */
    convertLocaltoUTC(LocalTime, promise = false) {
        const timezone = jstz.determine();
        const tzName = timezone.name(); 
        var convert = moment(LocalTime).tz(tzName).utc().format();
        if(promise)
        {
            return new Promise(res => {
                return res(convert);
            });
        }
        return convert;
    }

    /**
     * convert the UTC timestring to local timestring
     * @param {timestring} UTC 
     * usage:
        import { SystemTimeManagement } from './assets/systemConfig';
        var STM = SystemTimeManagement;
        STM.convertUTCtoLocal("2019-02-05T14:48:37-05:00")
        .then(LocalTime => {
            // do something
            // if you want UTC time string
            STM.formatLocalTime(LocalTime);
        });
     */
    convertUTCtoLocal(UTC, promise = false) {
        const timezone = jstz.determine();
        const tzName = timezone.name(); 
        var convert = moment(UTC).tz(tzName).format();
        if(promise)
        {
            return new Promise(res => {
                return res(convert);
            });
        }
        return convert;
    }
    /**
     * It's always a good practice to convert the time to unix timestring when storing in the MySQL database
     * @param {str} timestamp
     * @param {func} onResults
     * usage:
        var timestamp = '2019-02-26 03:12:06';

        // optional: combine with getting the timezone of localtime
        const timezone = STM.getTimezoneName(false); // do not return a promise
        timestamp += ` ${timezone}`;

        STM.convertToUnixTime(timestamp, unixTime => {
            console.log(unixTime); // 1551150726
        });

     */
    convertToUnixTime(timestamp = '', onResults) {
        if(!timestamp) return onResults(false);
        let day = new Date(timestamp);
        day = Math.floor(day.getTime() / 1000);
        return onResults(day);
    }
    /**
     * convert the unix timestring to timestamp for MySQL database
     * @param {int} unixTime 
     * @param {str} type ('Local' or 'UTC')
     * @param {func} onResults 
     * usage:
        STM.convertUnixTimeToTimestamp(unixTime, 'UTC', timestamp => {
            console.log(timestamp); // 2019-02-25 22:12:06
        });
     */
    convertUnixTimeToTimestamp (unixTime = null, type = 'UTC',onResults) {
        if(!unixTime) return onResults(false);
        let day = new Date(unixTime * 1000);
        switch(type)
        {
            case 'Local':
                day = this.formatLocalTime(day);
                return onResults(day);
            case 'UTC':
            default:
                day = this.formatUTCTime(day);
                return onResults(day);
        }
    }

    /**
     * return true if day 2 is {howmany} hours behind day 1
     * @param {timestamp} d1 
     * @param {timestamp} d2 
     * @param {int} hrs 
     * usage:
        let d1 = '2018-09-20 16:08:06';
        let d2 = '2018-09-20 20:55:16';
        let result = STM.compareDate(d1, d2, 3);
        console.log(result) // true
    */
    compareDate(d1, d2, hrs = 12) {
        let date1 = new Date(d1).getTime();
        let date2 = new Date(d2).getTime();
        var diff = (date2 - date1) / 1000 / 60 / 60; // convert the time to hours
        if(diff < hrs) // day 2 time is before day 1
        {
            return false;
        }
        return true;
    }
    /** Calculate the duration of between two time stamps */
    calcDuration(t1, t2) {
        let h,m,s;
        // parse the time and get the timestring in milliseconds .getTime()
        const t1Str = new Date(t1);
        const t2Str = new Date(t2);
        // minuse the time
        let result = t2Str - t1Str;
        // get the time in format (h:m:s) - 1 second is 1000 milliseconds
        //Step1: check if it's over 1 hour - very rare, but if it's over 1 hour, then divide it by 3600000
        //Step2: check if it's over 1 minute - rare, divide it by 60000
        //Step3: if it's over 1 second - divide it by 1000
        if(result >= 3600000)
        {
            h = Math.floor(result / 3600000)
            var hrmd = result%3600000
            m = Math.floor(hrmd/60000)
            var mrmd = hrmd%60000
            s = Math.floor(mrmd/1000)
            return `${h}:${m}:${s}`
        }
        if(result >= 60000 & result < 3600000)
        {
            h = 0
            m = Math.floor(result/60000)
            var mrmd = result%60000
            s = Math.floor(mrmd/1000)
            return `${h}:${m}:${s}`
        }
        if(result >= 1000 & result < 60000)
        {
            h = 0; m = 0
            s = Math.floor(result/1000)
            return `${h}:${m}:${s}`
        }
        h = 0; m = 0
        s = Math.floor(result/1000)
        return `${h}:${m}:${s}`
    }
    calcDaysOfVisit(since) {
        const from = moment(since);
        const now = moment(new Date());
        return now.diff(from, 'days');
    }
    createNewDayEntry(usersId, timestamp, onResults)
    {
        // return fetch('/activity/v1/addDay', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //     'Access-Control-Allow-Origin': '*',
        //     'Content-Type': 'application/json',
        //     'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
        //         usersId: usersId,
        //         loginTime: timestamp
        //     })
        // })
        return Fetch.post('/activity/v1/addDay', {
            usersId: usersId,
            loginTime: timestamp
        })
        .then(r => r.json())
        .then(results => {
            //do something
            if(!results.success)
            {
                throw results.err.message;
            }
            // do something
            return onResults(true);
        })
        .catch(err => {
            // error handling
            return onResults(false);
        });
    }
    /**
     * determine if the system should set a new day for the visitor or extend the expiration of previous visit
     * @param {timestamp} timestamp // has to be UTC timestamp
     * @param {string} username
     * @param {integer} usersId
     * @param {function} onResults 
     */
    setNewDay(usersId, onResults)
    {
        const currentUTCTimestamp = this.getCurrentUTCTimestamp();
        this.getCurrentDayData(usersId, (err, data) => {
            if(err)
            {
                // go ahead and set a new day
                return this.createNewDayEntry(usersId, currentUTCTimestamp, results => {
                    if(!results) return onResults(false); // failed to create a new day
                    return onResults(true); // succeeded in creating a new day
                });
            }
            // got the old time, use the format time function to compare the two times
            const oldUTCTimestamp = this.formatLocalTime(data.created_at);
            const isNewDay = this.compareDate(oldUTCTimestamp, currentUTCTimestamp, 12);
            if(isNewDay)
            {
                return this.createNewDayEntry(usersId, currentUTCTimestamp, results => {
                    if(!results) return onResults(false); // failed to create a new day
                    return onResults(true); // succeeded in creating a new day
                });
            }
            return this.setSameDay(usersId, '', results => {
                if(!results) return onResults(false); // cannot set the same day
                return onResults(true); // successfully set the same day
            });
        });
    }
    /**
     * create a new entry for the same day
     * @param {int} usersId 
     * @param {func} onResults 
     * usage:
        STM.setSameDay(1, result => {
            if(!result) return;

        });
     */
    setSameDay(usersId, oldDayHash, onResults)
    {
        if(!usersId) return onResults(false); // failed to enter the user's id, should not set any same day
        if(!oldDayHash) // user has not entered the old day data, try to get it from the usersId first
        {
            return this.getCurrentDayData(usersId, (err, data) => {
                if(err) return onResults(false);
                // got the previous day data, use it to set the same day
                // return fetch('/activity/v1/addCurrentDay', {
                //     method: 'POST',
                //     mode: 'cors',
                //     headers: new Headers({
                //     'Access-Control-Allow-Origin': '*',
                //     'Content-Type': 'application/json',
                //     'Accept': 'application/json'
                //     }),
                //     body: JSON.stringify({
                        // usersId: usersId,
                        // dayHash: data.day_hash,
                        // loginTime: this.getCurrentUTCTimestamp()
                //     })
                // })
                return Fetch.post('/activity/v1/addCurrentDay', {
                    usersId: usersId,
                    dayHash: data.day_hash,
                    loginTime: this.getCurrentUTCTimestamp()
                })
                .then(r => r.json())
                .then(result => {
                    if(!result.success) return onResults(false);
                    return onResults(true);
                });
            });
        }
        // return fetch('/activity/v1/addCurrentDay', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //     'Access-Control-Allow-Origin': '*',
        //     'Content-Type': 'application/json',
        //     'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
                // usersId: usersId,
                // dayHash: oldDayHash,
                // loginTime: this.getCurrentUTCTimestamp()
        //     })
        // })
        return Fetch.post('/activity/v1/addCurrentDay', {
            usersId: usersId,
            dayHash: oldDayHash,
            loginTime: this.getCurrentUTCTimestamp()
        })
        .then(r => r.json())
        .then(result => {
            if(!result.success) return onResults(false);
            return onResults(true);
        });
    }
    /**
     * check if 12 hours has passed since last visit
     * @param {string} timestamp (in UTC format!)
     * @param {function} cb
     */
    checkIsNewDay(timestamp = '', usersId, cb) {
        const hourThreashold = 12;
        // get the local timestamp, store the UTC timestamp
        const UTC = this.getCurrentUTCTimestamp();
        if(timestamp) // user has specified the time
        {
            if(this.compareDate(timestamp, UTC, hourThreashold)) // it's a new day
            {
                // new day is true
                return cb(true);
            }
            return cb(false);
        }
        this.getCurrentDayData(usersId, (err, data) => {
            if(err) // user might not have record in the database
            {
                return cb(true);
            }
            return cb(false); // new day is false
        });
    }
    /**
     * Get the latest log in day of a user
     * @param {str} username 
     * @param {func} onResults 
     * usage:
        getCurrentDayData('username-hash', (err, data) => {
            if(err) return false;
            console.log(data);
        });
     */
    getCurrentDayData(usersId = null, onResults) {
        //fetch the latest day
        
        if(!usersId) return onResults({
            message: `User's id is not entered`
        }, null);
        try
        {
            return Fetch.get(`/activity/v2/getCurrentDayData/${usersId}`)
            .then(r => r.json())
            .then(data => {
                return onResults(null, data[0]);
            })
            .catch(err => {
                return this.setNewDay(usersId, (r) => {
                    if(r)
                    {
                        return Fetch.get(`/activity/v2/getCurrentDayData/${usersId}`)
                        .then(r => r.json())
                        .then(r => onResults(r, null))
                        .catch(e => onResults(null, e))
                    }
                    // if(r) return DB.getLastRows(tbs.dayLogs, { users_id: usersId }, 1, (err, data) => {
                    //     return data
                    // })
                    return onResults(r, null)
                })
                // go ahead and set a new day
                return onResults(err, null);
            })
        }
        catch(e)
        {
            return onResults({
                errCode: 'FAILED_TO_QUERY_MH_DAY_LOGS',
                message: 'The system failed to query your Memory Home visit history.'
            }, null);
        }


        // return DB.getLastRows(tbs.dayLogs, { users_id: usersId }, 1, (err, data) => {
        //     if(err)
        //     {
        //         return this.setNewDay(usersId, (r) => {
        //             if(r) return DB.getLastRows(tbs.dayLogs, { users_id: usersId }, 1, (err, data) => {
        //                 return data
        //             })
        //             return onResults(r, null)
        //         })
        //         // go ahead and set a new day
        //         return onResults(err, null);
        //     }
        //     else if(data.length > 0)
        //     {
        //         return onResults(null, data[0]);
        //     }
        //     return onResults({
        //         errCode: 'FAILED_TO_QUERY_MH_DAY_LOGS',
        //         message: 'The system failed to query your Memory Home visit history.'
        //     }, null);
        // });
    }
    /**
     * update the day log when user adds a new offering or log out
     * @param {int} usersId 
     * @param {obj} fieldData 
     * @param {func} onResults 
     * usage:
        const fieldData = {
            logout_time: '2019-02-26 06:33:32',
            offering_data: JSON.stringify({
                offerings: ['candle-light', 'candle-unlight']
            })
        }
        STM.updateDayLogs('your-day-hash', fieldData, (err, data) => {
            if(err) return console.log(err.errCode);
            // do something
        });
     */
    updateDayLogs(dayHash, fieldData, onResults) {
        // return fetch('/activity/v1/updateDay', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
                // dayHash: dayHash,
                // data: fieldData
        //     })
        // })
        return Fetch.post('/activity/v1/updateDay', {
            dayHash: dayHash,
            data: fieldData
        })
        .then(r => r.json())
        .then(result => {
            if(!result.success) return onResults({ errCode: 'FAILED_TO_UPDATE_DAY', message: 'The system failed to update the day record' }, null);
            return onResults(null, result.data);
        });
    }
    /**
     * retrieve the latest entry of the day logs of a certain user
     * @param {int} usersId 
     * @param {func} onResults 
     * usage:
        STM.getLatestDayLog(1, (err, data) => {
            if(err) return err.errCode;
        });
     */
    getLatestDayLog(usersId, onResults) {
        // return fetch(`/activity/v1/getLatestDay/${usersId}`)
        return Fetch.get(`/activity/v1/getLatestDay/${usersId}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults({
                errCode: response.errCode,
                message: response.message
            }, null);
            if(response.data.length > 1)
            {
                return onResults(null, response.data[response.data.length - 1])
            }
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults({
                errCode: 'FAILED_TO_QUERY_LATEST_DAY_LOG',
                message: 'The system failed to query your latest day log.'
            }, null);
        })
    }
    /**
     * retrieve the latest entry of the day logs with offering data
     * @param {int} usersId 
     * @param {func} onResults 
     * usage:
        STM.getLatestDayLogWithOfferingData(1, (err, data) => {
            if(err) return err.errCode;
            return data;
        });
     */
    getLatestDayLogWithOfferingData(usersId, onResults) {
        // return fetch(`/activity/v1/getLatestDayLogWithOfferingData/${usersId}`)
        return Fetch.get(`/activity/v1/getLatestDayLogWithOfferingData/${usersId}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults({
                errCode: response.errCode,
                message: response.message
            }, null);
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults({
                errCode: 'FAILED_TO_QUERY_LATEST_DAY_LOG',
                message: 'The system failed to query your latest day log with offering data.'
            });
        });
    }
}
/**
 * The class to interact with system messages component, deals with retrieving messages from localstorage and MySQL database
 */
export class SystemMessageManagement extends Localstorage {
    constructor(SECRET_KEY = '')
    {
        super(SECRET_KEY = '');
    }

    /**
     * get the messages stored in the localstorage, it should always be performed before fetching the messages from MySQL database
     * @param {str} querySessionKey 
     * @param {func} onResults 
     * usage:
        SMM.getLocalMessages(querySessionKey, results => {
            if(!results) return;
            // do something
        });
     */
    getLocalMessages(querySessionKey, onResults)
    {
        this.get(querySessionKey, true)
        .then(results => {
            if(results) // got the localstorage records, store them in the states
            {
                return onResults(results);
            }
            return onResults(false);
        })
        .catch(err => {
            return onResults(false);
        });
    }
    /**
     * retrieve messages from the MySQL database
     * @param {arr} groupsKeyArr 
     * @param {str} querySessionKey 
     * @param {func} onResults 
     * usage:
        SMM.fetchMessages(this.props.messageGroupsKey, querySessionKey, data => {
            if(!data) return;
            // do something
        });
    */
    fetchMessages(groupsKeyArr = [], querySessionKey, onResults)
    {
        var resultsArr = [];
        var loopPromiseArr = [];
        // prepare an array of group keys to query the relevant system messages
        //fetch the messages by group
        groupsKeyArr.forEach(key => {
            const fetchPromise = new Promise((res, rej) => {
                // get the system messages that are relevant to this page
                // return fetch(`/activity/v1/getSystemMessagesByGroups/${key}`)
                return Fetch.get(`/activity/v1/getSystemMessagesByGroups/${key}`)
                .then(r => r.json())
                .then(data => {
                    if(!data.success)
                    {
                        return rej();
                    }
                    else
                    {
                        resultsArr = resultsArr.concat(data.results);
                        return res(resultsArr);
                    }
                })
                .catch(err => {
                    // failed to find the key
                    return rej();
                });
            });
            loopPromiseArr.push(fetchPromise);
        });
        return Promise.all(loopPromiseArr)
        .then(() => {
            return arrToObjWithColumnKey(resultsArr, 'message_key')
            .then(obj => {
                this.set(querySessionKey, obj, true);
                // use the returned JSON to set the state
                return onResults(obj)
            });
        })
        .catch(err => {
            onResults(false);
        });
    }
}

/**
 * get the Memory Home config from the database and store them in the localstorage
 */
export class SystemConfig extends Localstorage {
    constructor(SECRET_KEY = '')
    {
        super(SECRET_KEY = '');
    }
    /**
     * return a callback of MH config
     * @param {str} username 
     * @param {func} onResults
     * usage:
        SC.getMHConfig()
     */
    getMHConfig(username = '', onResults)
    {
        // get the configuration from localstorage first.
        return this.get(sessionKeys.MHConfig)
        .then(results => {
            if(results)
            {
                return onResults(results);
            }
            else
            {
                return this.fetchMHConfig(username)
                .then(results => {
                    return onResults(results);
                })
                .catch(err => {
                    return onResults(false);
                });
            }
        });
    }
    /**
     * return a promise of MH config
     * @param {str|int} username 
     */
    fetchMHConfig(username = '', onResults = null)
    {
        if(username === '' || !username) return new Promise((res, rej) => {
            rej('username is not entered');
        });
        if(typeof username === 'number')
        {
            console.log('entered user name number')
            // return fetch(`/activity/v1/getMHConfigById/${username}`)
            return Fetch.get(`/activity/v1/getMHConfigById/${username}`)
            .then(r => r.json())
            .then(config => {
                if(!config.success) return onResults(config.message, null)
                this.set(sessionKeys.MHConfig, config.results[0], true);
                return onResults(null, config.results[0]);
            });
        }
        // return fetch(`/activity/v1/getMHConfig/${username}`)
        return Fetch.get(`/activity/v1/getMHConfig/${username}`)
        .then(response => response.json())
        .then(config => {
            return new Promise((res, rej) => {
                if(!config.success)
                {
                    return rej('failed to retrieve the config from database');
                }
                this.set(sessionKeys.MHConfig, config.results[0], true);
                return res(config.results[0]);
            });
        })
        .catch(err => {
            return new Promise((res, rej) => {
                rej(err.message);
            });
        });
    }
    updateMHConfig(usersId = null, fieldData = {}, onResults)
    {
        // return fetch('/activity/v1/updateMHConfig', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Access-Control-Allow-Origin': '*'
        //     }),
        //     body: JSON.stringify({
        //         userId: usersId,
        //         fieldData: fieldData
        //     })
        // })
        return Fetch.post('/activity/v1/updateMHConfig', {
            userId: usersId,
            fieldData: fieldData
        })
        .then(r => r.json())
        .then(response => {
            if(!response.success)
            {
                return onResults(response.message, null);
            }
            return onResults(null, response.data);
        })
        .catch(err => {
            return onResults('ERR_FAILED_TO_UPDATE_MH_CONFIG', null);
        });
    }
    getPrevLogInOut(usersId, onResults) {
        if(!usersId) return onResults({message: 'The user id is not set'}, null);
        // return fetch(`/activity/v1/checkLogInOut/${usersId}`)
        return Fetch.get(`/activity/v1/checkLogInOut/${usersId}`)
        .then(r => r.json())
        .then(results => {

        });
    }
    /**
     * reset the Memory Home config to null in the database
     * @param {int} usersId 
     * @param {str} valueKey 'view' or 'house' 
     * @param {func} onResults 
     * usage:
        SC.resetConfig(1, 'view', (err, results) => {
            if(err)
            {
                SLG.recordLog('access-token', err.errCode, err.message, () => {
                    // do something
                });
            }
            return true;
        });
     */
    resetConfig(usersId, valueKey = 'view', onResults)
    {
        if(!usersId) return onResults({
            message: 'No user id is provided'
        }, null);
        // return fetch(`/mhconfig/v2/resetMHConfig/${valueKey}`)
        return Fetch.get(`/mhconfig/v2/resetMHConfig/${valueKey}`)
        .then(r => r.json())
        .then(response => {
            if(!response.success) return onResults(response, null);
            return onResults(null, response.data);
        })
        .catch(err => {
            // do something
            return onResults({
                errCode: 'FAILED_TO_RESET_MHCONFIG',
                message: `The system has failed to reset the ${valueKey} configuration of your Living Memory Home`
            }, null);
        });
    }
}


/**
 * usage:
    import { SystemLogs } from './assets/systemConfig';
    const SLG = new SystemLogs();
 */
export class SystemLogs extends Localstorage {
    constructor(SECRET_KEY = '')
    {
        super(SECRET_KEY = '');
    }
    /**
     * store the login/out time into the database
     * @param {string} accessToken 
     * @param {boolean} login 
     * @param {function} onResults 
     * usage:
        const SLG = new SystemLogs();
        SLG.recordLog('access-token', true, (err, results) => {
            if(err) return;
            // do something with the results message
        });
     */
    recordLog(accessToken, login = true, onResults) {
        return AWSCognito.getUsernameFromAccessToken(accessToken, (username) => {
            // const bodyData = JSON.stringify({
                // username: username,
                // login: login
            // });
            const bodyData = {
                username: username,
                login: login
            }
            // return fetch('/activity/v1/recordLog', {
            //     method: 'POST',
            //     mode: 'cors',
            //     headers: new Headers({
            //         'Access-Control-Allow-Origin': '*',
            //         'Content-Type': 'application/json',
            //         'Accept': 'application/json'
            //     }),
            //     body: bodyData
            // })
            return Fetch.post('/activity/v1/recordLog', bodyData)
            .then(r => r.json())
            .then(results => {
                if(!results.success)
                {
                    return onResults(results.message, null);
                }
                return onResults(null, results.message)
            })
            .catch(err => {
                return onResults('The system failed to store the log', null);
            });
        });
    }

    async recordLogGivenUsername(username = '', login = true) {
        if(username === '' || username === undefined) return false
        // const bodyData = JSON.stringify({
        //     username: username,
        //     login: login
        // });
        const bodyData = {
            username: username,
            login: login
        }

        // const logResult = await fetch('/activity/v1/recordLog', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: bodyData
        // })
        const logResult = await Fetch.post('/activity/v1/recordLog', bodyData)
        .then(r => r.json())
        .then(results => {
            if(!results.success)
            {
                throw results
                // return onResults(results.message, null);
            }
            return results.message
            // return onResults(null, results.message)
        })
        .catch(err => {
            return err
            // return onResults('The system failed to store the log', null);
        })

        return logResult

        /*
        return fetch('/activity/v1/recordLog', {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }),
            body: bodyData
        })
        .then(r => r.json())
        .then(results => {
            if(!results.success)
            {
                return onResults(results.message, null);
            }
            return onResults(null, results.message)
        })
        .catch(err => {
            return onResults('The system failed to store the log', null);
        });
        */
    }

    /**
     * remember the error system encountered
     * @param {string} accessToken 
     * @param {string} errCode 
     * @param {function} onResults 
     * usage:
        SLG.recordErr('accessToken', 'FAILED_TO_LOGIN', 'The system failed to login', (err, stored) => {
            return window.location.assign(`/error?message=${errMsg}`);
        });
     */
    recordErr(accessToken, errCode, errMsg, onResults) {
        return AWSCognito.getUsernameFromAccessToken(accessToken, username => {
            if(!username) return onResults('The system could not store the error record', null);
            return User.returnUserId(usersId => {
                if(!usersId) return onResults('The system could not store the error record', null);
                // return fetch('/activity/v1/error', {
                //     method: 'POST',
                //     mode: 'cors',
                //     headers: new Headers({
                //         'Access-Control-Allow-Origin': '*',
                //         'Content-Type': 'application/json',
                //         'Accept': 'application/json'
                //     }),
                //     body: JSON.stringify({
                        // usersId: usersId,
                        // errCode: errCode
                //     })
                // })
                return Fetch.post('/activity/v1/error', {
                    usersId: usersId,
                    errCode: errCode
                })
                .then(r => r.json())
                .then(response => {
                    if(!response.success) return onResults('The system could not store the error record', null);
                    // do something response.data
                    return onResults(null, errMsg);
                })
                .catch(err => {
                    // do something
                    return onResults('The system could not store the error record', null);
                });
            });
        });
    }
    /**
     * add error and send message to notify the developer
     * @param {*} username 
     * @param {*} errCode 
     * usage:
        const addErr = await SLG.recordErrGivenUsername(null, 'TEST_ERROR')
    */

    async recordErrGivenUsername(username, errCode)
    {
        let usersId = await User.returnUserId(u => u).catch(e => false)
        if(!usersId) usersId = null

        // const r = await fetch('/activity/v1/error', {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }),
        //     body: JSON.stringify({
        //         usersId: usersId,
        //         errCode: errCode
        //     })
        // })
        const r = await Fetch.post('/activity/v1/error', {
            usersId: usersId,
            errCode: errCode
        })
        .then(r => r.json()).then(response => response)
        .catch(async e => {
            // notify the developer that store error failed
            const emailDev = await fetch('/api/v2/sendEmail', {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }),
                body: JSON.stringify({
                    email: 'wjs2004@med.cornell.edu',
                    subject: 'System failed to store error',
                    html: `<h1>${errCode}</h1>
                    <p>The system has failed to store this error</p>
                    <p>The user id is: ${usersId}</p>
                    `
                })
            })
        })

        return r
    }
}