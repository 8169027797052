import React, { useEffect, useState, createRef } from 'react'
import UH from '../../classes/v2/UserHelper'
import GH from '../../classes/v2/GeneralHelper'
import Tabulator from 'tabulator-tables'
// import 'react-tabulator/lib/styles.css'; // required styles
import 'tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css'; // theme
import './AdminBoard.scss'


let columns = [//define the table columns
    {title: "REDCap ID", field: "redcap_id", headerFilter:"input"},
    {title: "Total Journal Day", field: "totalJournalDays", headerFilter:"input"},
    {title: "Total Entry Length", field: "totalEntryLength", headerFilter:"input"},
    {title:"Total Time Spent (seconds)", field:"totalTimeSpent", headerFilter:"input"},
    {title:"More Details", field:"redcap_id", formatter:(cell, formatterParams, onRendered) => {
        //cell - the cell component
        //formatterParams - parameters set for the column
        //onRendered - function to call when the formatter has been rendered
    
        // return "Mr" + cell.getValue(); //return the contents of the cell;
        // console.log("REDCAP ID",cell.getValue())
        // return `<button onClick=>show daily breaks</button>`
        const btn = document.createElement("button")
        // console.log(btn.className)
        btn.className += 'btn btn-secondary'
        btn.innerText = "Toggle Details"
        btn.onclick = () => {
            const targetRow = document.getElementById(`subtable-redcap-id-${cell.getValue()}`)
            // console.log(targetRow.style.display)
            if(targetRow.style.display !== 'none') return targetRow.style.display = 'none'
            return targetRow.style.display = 'block'
        }
        return btn
    }}
]
let nestedColumns = [
    {title: "Journal Day/day", field: "journal_day"},
    {title: "Entry Length/day", field: "eachDayTextLength"},
    {title: "Time Spent (seconds)/day", field: "timeSpentPerDay"},
]
const options = {
    tooltipGenerationMode:"hover", //regenerate tooltip as users mouse enters the cell;
    tooltipsHeader:true, //enable header tooltips
    // responsiveLayout: true,  //hide columns that dont fit on the table
    resizableColumns: true,
    tooltips:true,            //show tool tips on cells
    pagination:"local",       //paginate the data
    paginationSize:10,         //allow 7 rows per page of data
    headerFilter:"input",
    layout:"fitColumns",

    // autoColumns:true,
    // layout:"fitColumns",      //fit columns to width of table
    movableColumns:true,      //allow column order to be changed
    // resizableRows:true,       //allow row order to be changed
    // initialSort:[             //set the initial sort order of the data
    //     {column:"id", dir:"asc"},
    // ],
}


const UserStats = (props) => {
    const [error, updateError] = useState('')
    const [data, updateData] = useState([])
    const [dailyData, updateDailyData] = useState([])

    const _table = createRef()
    let tableRef = null

    useEffect(() => {
        getDataAndRender()
        getFullDataAndRender()
    }, [])

    const getDataAndRender = async () => {
        const r = await UH.getAllUserStats()
        if(r.length <= 0)
        {
            updateError("no data to display")
        }
        // console.log(r)
        tableRef = new Tabulator("#lmh-table", {
            data:r,
            columns:columns,
            ...options,
            rowFormatter:function(row){
                //create and style holder elements
               var holderEl = document.createElement("div");
               var tableEl = document.createElement("div");
        
               holderEl.style.boxSizing = "border-box";
               holderEl.style.padding = "10px 30px 10px 10px";
               holderEl.style.borderTop = "1px solid #333";
               holderEl.style.borderBotom = "1px solid #333";
               holderEl.style.background = "#ddd";

        
               tableEl.style.border = "1px solid #333";
        
               holderEl.appendChild(tableEl);

               row.getElement().appendChild(holderEl);
        
            //    var subTable = new Tabulator(tableEl, {
            //        layout:"fitColumns",
            //        data:row.getData().serviceHistory,
            //        columns:[
            //        {title:"Date", field:"date", sorter:"date"},
            //        {title:"Engineer", field:"engineer"},
            //        {title:"Action", field:"actions"},
            //        ]
            //    })
            },
        })
    }

    const getFullDataAndRender = async () => {

        const group = await UH.getAllUserStats()
        const daily = await UH.getDailyUseStatsGivenREDCapId('all')

        let combinedDataset = [...group]
        updateData(combinedDataset)
        updateDailyData(daily)
        const loop = await GH.asyncForEach(group, async (u, idx) => {
            const userDaily = await daily.filter(d => d?.redcap_id === u?.redcap_id)
            // const daily = await UH.getDailyUseStatsGivenREDCapId(u?.redcap_id)
            combinedDataset[idx]['daily'] = userDaily
        })
        updateData(combinedDataset)

        // console.log(r)
        tableRef = new Tabulator("#lmh-table", {
            data:combinedDataset,
            columns:columns,
            ...options,
            rowFormatter:function(row){
                //create and style holder elements
            // var holderEl = document.createElement("div");
            var tableEl = document.createElement("div");
            tableEl.id = `subtable-redcap-id-${row.getData().redcap_id}`

            // holderEl.style.boxSizing = "border-box";
            // holderEl.style.padding = "10px 30px 10px 10px";
            // holderEl.style.borderTop = "1px solid #333";
            // holderEl.style.borderBotom = "1px solid #333";
            // holderEl.style.background = "#ddd";

            // tableEl.style.border = "1px solid #333";

            // holderEl.appendChild(tableEl);
            // holderEl.style.display = 'none'

            row.getElement().appendChild(tableEl);
                // console.log(tableEl)
            var subTable = new Tabulator(tableEl, {
                layout:"fitColumns",
                data:row.getData().daily,
                columns:nestedColumns,
                ...options
            })

            },
        })

    }

    return <div>
    <div>{error}</div>
    <UserStatsTable data={data} dailyData={dailyData} />
    </div>

}

const UserStatsTable = props => {

    const _table = createRef(), _hiddenTable = createRef()
    let tableRef = null, hiddenTableRef = null
    const data = props.data
    const title = 'LMH User Usage Data'
    const SheetTitle = 'User Usage Data'
    const dailyTitle = "LMH User Daily Breakdown Data"
    const dailySheetTitle = "Daily Breakdown Data"

    tableRef = new Tabulator("#lmh-table", {
        data:data,
        columns:columns,
        ...options,
        rowFormatter:function(row){
            //create and style holder elements
        var holderEl = document.createElement("div");
        holderEl.id = `subtable-redcap-id-${row.getData().redcap_id}`
        var tableEl = document.createElement("div");

        holderEl.style.boxSizing = "border-box";
        holderEl.style.padding = "10px 30px 10px 10px";
        holderEl.style.borderTop = "1px solid #333";
        holderEl.style.borderBotom = "1px solid #333";
        holderEl.style.background = "#ddd";

        tableEl.style.border = "1px solid #333";

        holderEl.appendChild(tableEl);
        holderEl.style.display = 'none'

        row.getElement().appendChild(holderEl);
        if(row.getData().daily)
        {
           var subTable = new Tabulator(tableEl, {
               layout:"fitColumns",
               data:row.getData().daily,
               columns:nestedColumns,
               ...options
           })
        }

        },
    })

    hiddenTableRef = new Tabulator("#hidden-table", {
        layout:"fitColumns",
        data:props.dailyData,
        columns:[                    
            {title: "REDCap ID", field: "redcap_id", headerFilter:"input"},
            ...nestedColumns],
        ...options
    })

    return (<React.Fragment>
        {data.length > 0 ?<div className='my-2'>
        <div className='col-xs-12'>
        <button id='download-csv' className='lmh-primary-btn' onClick={() => {
            // console.log(`to download data`)
            try
            {
                // console.log("table ref", tableRef)
    
                if(tableRef)
                {
                    // console.log("got table ref")
                    tableRef.download("csv", `${title}.csv`)
                    hiddenTableRef.download("csv", `${dailyTitle}.csv`)
                }
                // console.log("done")
            }
            catch(e)
            {
                // console.log("failed to download data", e)
            }
    
        }}>Download both tables in CSV</button>
        <button id='download-xlsx' className='lmh-primary-btn ml-2' onClick={() => {
            if(tableRef)
            {
                tableRef.download("xlsx", `${title}.xlsx`, {sheetName:SheetTitle})
                hiddenTableRef.download("xlsx", `${dailyTitle}.xlsx`, {sheetName:dailySheetTitle})
            }
        }}>Download both tables in XLSX</button>
        </div>
        </div>
        :null}        
        <div ref={_table} className='' id='lmh-table'>
        </div>
        <div ref={_hiddenTable} id='hidden-table' style={{
            display: 'none'
        }}></div>
    </React.Fragment>)
}

export default UserStats