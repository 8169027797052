import React, { Component }  from 'react';
// import { Link } from 'react-router-dom';
import '../../scss/index.scss';
import '../../scss/index-query.scss';
import AH from '../classes/v2/AmplifyHelper'
import config from '../init/config'
import ReactTooltip from 'react-tooltip'
import { colors } from '../init/colors'
import PreloadImages from './WelcomeAnimation/preloadWelcomeImgs'
// import connect from "react-redux/es/connect/connect";
// import {mapDispatchToProps, mapStateToProps} from "./lang/langRedux";
// import P5Wrapper from 'react-p5-wrapper'
// import sketch from './WelcomeAnimation/sketch'
const WIDTH_HEIGHT_RATIO = 1.81
let HEIGHT = window.innerHeight
let WIDTH = window.innerHeight * WIDTH_HEIGHT_RATIO
let BEGIN_X = WIDTH/2.3, 
BEGIN_Y = HEIGHT - HEIGHT / 1.95, 
DOOR_WIDTH = WIDTH / 7.7, 
DOOR_HEIGHT = HEIGHT / 1.95

// const nextPage = '/login';
/*
const logoSM = require('../img/MH_logo_watercolor_bg-sm.png'),
    logoMD = require('../img/MH_logo_watercolor_bg-md.png'),
    logoLG = require('../img/MH_logo_watercolor_bg-lg.png'),
    logoXL = require('../img/MH_logo_watercolor_bg-xl.png');
*/
const updateDimensionsGivenHeight = () => {
    HEIGHT = window.innerHeight
    WIDTH = window.innerHeight * WIDTH_HEIGHT_RATIO
    // p.resizeCanvas(WIDTH, HEIGHT);
    BEGIN_X = WIDTH/2.3
    BEGIN_Y = HEIGHT - HEIGHT / 1.95
    DOOR_WIDTH = WIDTH / 7.7
    DOOR_HEIGHT = HEIGHT / 1.95
}

class Index extends Component {

    _isMounted = false

    constructor(props)
    {
        super(props)
        // this.state = {
        //     loggedIn: false
        // }
    }

    async componentWillMount() {
        
        // load the login information
        const checkLogin = await AH.getSession().then(r => r).catch(e => e)
        if(checkLogin !== 'No current user') return this.props.history.push(`/${this.props.match.params.lang}${config.paths.dashboard}`)
        // console.log(checkLogin)
        /*
        console.log(this.props)
        */
    }
    componentDidMount() {
        this._isMounted = true
        this.props.changeLang(this.props.match.params.lang);
        var self = this;
        const loginLink = document.getElementById('login-options-link');
        const doorLogin = document.getElementById('door-group')
        doorLogin.addEventListener('click', e => {
            if(!this.props.loggedIn) return this.props.history.push(config.paths.auth)
            return this.props.history.push(config.paths.dashboard)
            // window.location.replace('/login')
        })

        ReactTooltip.show(this._door)
        // const enterNotice = document.getElementById('enter-notice');
        // loginLink.addEventListener('mouseover', () => {
        //     enterNotice.classList.add('show');
        //     self._bgWrapper.classList.add('scaleUp');
        // });
        // enterNotice.addEventListener('mouseout', () => {
        //     enterNotice.classList.remove('show');
        //     self._bgWrapper.classList.remove('scaleUp');
        // });

        // const canvas = document.getElementById('lmh-welcome-animation')
        // var ctx = canvas.getContext('2d')
        // var bg = new Image();
        // let door = new Image()
        // bg.onload = function() {
        //     ctx.drawImage(bg, 0, 0, WIDTH, HEIGHT)
        // };
        // door.onload = () => {
        //     ctx.drawImage(door, BEGIN_X, BEGIN_Y, DOOR_WIDTH, DOOR_HEIGHT)
        // }
        // bg.src = require('../../img/LMH_anime_wall.png')
        // door.src = require('../../img/LMH_anime_door2.png')

        // window.addEventListener('resize', updateDimensionsGivenHeight)
    }

    componentWillUnmount()
    {
        this._isMounted = false
        window.removeEventListener('resize', updateDimensionsGivenHeight)
    }


    render() {
        return (<React.Fragment>
            <PreloadImages isMounted={this._isMounted} />
            <div id='home-image'></div>

            <div className="lmh-welcome-image" style={{ overflow:"hidden", position: 'relative' }}>
            {/*<canvas id={'lmh-welcome-animation'} width={WIDTH} height={HEIGHT} />*/}
            <ReactTooltip 
                textColor={colors.darkGreen}
                backgroundColor={colors.white}
                borderColor={colors.white}
                arrowColor={colors.white}
            />
            {/*<svg className="lmh-title" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285 221.1">
                <defs/>
                <image id="door-frame" href={require("../img/title-bg-lg.png")} width="120%" height="80%" x={-30} y={-72} />
                <text fontFamily="Montserrat-Bold" fontSize="12" transform="translate(70 10)">
                    LIVING MEMORY HOME
                </text>
                <text fontFamily="Montserrat-Regular" fontSize="10.5" transform="translate(70 24)">
                    WEILL CORNELL MEDICINE
                </text>
                <path fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth=".5" d="M70.6 12.1h146"/>
            </svg>*/}

            {/*<svg xmlns="http://www.w3.org/2000/svg" className="lmh-welcome-image" viewBox="0 0 100 100">
                <g id="door-group" width="40%" height="60%" x={28} y={40}>
                    <image id="door-frame" href={require("../img/door_frame-sm.png")} width="40%" height="60%" x={28} y={40} />
                    <image id="door-panel" href={require("../img/door-sm.png")} width="40%" height="60%" x={28} y={40} />
                </g>

            </svg>*/}
            
            {/*<div  className='col-xs-12 col-sm-12 col-md-12 col-xl-12 text-center justify-content-center position-absolute'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-xl-6 m-auto'>
                    <h1 className='memory-home-title'>{this.props.dict.index.livingMemoryHome}</h1>
                    <h4 className='memory-home-subtitle'>{this.props.dict.index.centerTitle}</h4>
                </div>
            </div>*/}

            <div ref={r => this._door = r} data-tip={this.props.dict.index['greet']} onMouseEnter={() => {
                    const home = document.getElementById('home-image')
                    // home.style.transform = "scale(1.2)"
                    home.style.backgroundSize = `auto 120%`;
                }} 
                onMouseLeave={() => {
                    const home = document.getElementById('home-image')
                    
                    home.style.backgroundSize = `auto 100%`;
                }}
                id="door-group">
                <img id="door-frame" src={require("../../img/door_frame-sm.png")} />
                <img id="door-panel" src={require("../../img/door-sm.png")} />
            </div>
            {/*<div className='bottom-wrapper'>
                <div id='login-options-wrapper'></div>
                <div id='enter-wrapper'>
                    <Link to={`/${this.props.lang}${nextPage}`} className='walk-in' id='login-options-link' ref={loginLink => this._loginLink = loginLink} >
                        <span id="enter-notice" ref={enterNotice => this._enterNotice = enterNotice}>{this.props.dict.index.clickToEnterLMH}</span>
                        <img className='enter' src={require('../img/MH_logo_watercolor_bg-sm.png')} alt='enter memory home' />
                    </Link>
                </div>
            </div>*/}
            </div>

            </React.Fragment>);
    }
}

{/*<div className='wrapper row' id='bg-wrapper' ref={bgWrapper => this._bgWrapper = bgWrapper}>

    <div className='col-xs-12 col-sm-12 col-md-12 col-xl-12 text-center justify-content-center position-absolute'>
        <div className='col-xs-12 col-sm-12 col-md-6 col-xl-6 m-auto'>
            <h1 className='memory-home-title'>{this.props.dict.index.livingMemoryHome}</h1>
            <h4 className='memory-home-subtitle'>{this.props.dict.index.centerTitle}</h4>

        </div>
    </div>
    <div className='bottom-wrapper'>
        <div id='login-options-wrapper'></div>
        <div id='enter-wrapper'>
            <Link to={`/${this.props.lang}${nextPage}`} className='walk-in' id='login-options-link' ref={loginLink => this._loginLink = loginLink} >
                <span id="enter-notice" ref={enterNotice => this._enterNotice = enterNotice}>{this.props.dict.index.clickToEnterLMH}</span>
                <img className='enter' src={require('../img/MH_logo_watercolor_bg-sm.png')} alt='enter memory home' />
            </Link>
        </div>
    </div>
</div>*/}

export default Index
// <img src={logoMD} alt='Living Memory Home'/>