import React, { useState } from 'react'

const Tip = ({ dict, defaultDisplay }) => {

    const [display, updateDisplay] = useState(defaultDisplay)

    // console.log(`default display: ${defaultDisplay}`)

    const tips = dict['tips']
    let tipsList = []
    tips.map((t, i) => {
        tipsList.push(<li className='' key={`tip-${i}`}>{t}</li>)
    })


    return (
        <div className="accordion accordion-font" id="tipsAccordion">
        <div className="accordion-item">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tip" aria-expanded="true" aria-controls="tip"  onClick={e => {
                updateDisplay(!display)
            }}>
                {dict['toggleTitle']}
            </button>
            <div id="tip" className={display ? `accordion-collapse show`: `accordion-collapse`} aria-labelledby="headingOne" data-bs-parent="#tipsAccordion">
            <div className='accordion-body lmh-accord-body'>
            <p className=''>{dict['intro']}</p>
            <ul className=''>
            {tipsList}
            </ul>
            </div>
            </div>
        </div>
        </div>
)
}
export default Tip