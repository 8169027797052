const fs = require('fs');

export function makeid(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
}

/** Enter the timestamp to compare dates, if d2 is newer, return true, or false */
export function compareDate(d1, d2, hrs) {
  let date1 = new Date(parseInt(d1, 10));
  let date2 = new Date(parseInt(d2, 10));
  // compare year
  if( date1.getFullYear() > date2.getFullYear() )
  {
    return false; // date1 year is larger than date2
  }
  // if date1 year is smaller or the same than date2, check the month
  // compare month
  else if( date1.getMonth() > date2.getMonth() ) 
  {
    return false;
  }
  // compare date
  else if( date1.getDate() > date2.getDate() )
  {
    return false;
  }
  // compare hour
  else if( date1.getHours() > date2.getHours() )
  {
    return false;
  }
  else if( date2.getHours() - date1.getHours() < hrs  )
  {
    return false;
  }
  return true;
}

/** Calculate the duration of between two time stamps */
export function calcDuration(t1, t2) {
  let h,m,s;
  // parse the time and get the timestring in milliseconds .getTime()
  const t1Str = new Date(t1);
  const t2Str = new Date(t2);
  // minuse the time
  let result = t2Str.getTime() - t1Str.getTime();
  // get the time in format (h:m:s) - 1 second is 1000 milliseconds
  //Step1: check if it's over 1 hour - very rare, but if it's over 1 hour, then divide it by 3600000
  //Step2: check if it's over 1 minute - rare, divide it by 60000
  //Step3: if it's over 1 second - divide it by 1000
  switch(result)
  {
    case result >= 3600000:
      h = result / 3600000;
      var hrmd = result%3600000;
      m = hrmd/60000;
      var mrmd = hrmd%60000;
      s = mrmd/1000;
      return `${h}:${m}:${s}`;
    case result >= 60000 & result < 3600000:
      h = 0;
      m = result/60000;
      var mrmd = result%60000;
      s = mrmd/1000;
      return `${h}:${m}:${s}`;
    case result >= 1000 & result < 60000:
      h = 0; m = 0;
      s = result/1000;
      return `${h}:${m}:${s}`;
    default:
      h = 0; m = 0;
      s = result/1000;
      return `${h}:${m}:${s}`;
  }
  
}

/**
 *  This function is used to create base64 strings from the image file arraybuffer
 */
export function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach((b) => binary += String.fromCharCode(b));

  return window.btoa(binary);
};

// check if drag and drop is supported in the browser
export function validateDragNDropCompatibility() {
  var div = document.createElement('div');
  return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
}

export function localStoragePolyfill() {
  window.localStorage = {
    getItem: function (sKey) {
      if (!sKey || !this.hasOwnProperty(sKey)) { return null; }
      return unescape(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
    },
    key: function (nKeyId) {
      return unescape(document.cookie.replace(/\s*\=(?:.(?!;))*$/, "").split(/\s*\=(?:[^;](?!;))*[^;]?;\s*/)[nKeyId]);
    },
    setItem: function (sKey, sValue) {
      if(!sKey) { return; }
      document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
      this.length = document.cookie.match(/\=/g).length;
    },
    length: 0,
    removeItem: function (sKey) {
      if (!sKey || !this.hasOwnProperty(sKey)) { return; }
      document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      this.length--;
    },
    hasOwnProperty: function (sKey) {
      return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }
  };
  window.localStorage.length = (document.cookie.match(/\=/g) || window.localStorage).length;
  return;
}

export function postMessage(command, details = {}) {
  window.postMessage({
      origin: 'canvas',
      for: command,
      details: details
  }, '*');
}

export function hash(limit = 6) {
  const date = new Date();
  const year = String(date.getFullYear());
  const month = String(date.getMonth());
  const day = String(date.getDay());
  const sec = String(date.getMilliseconds());
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  for (let i = 0; i < limit; i++){
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  const str = year + month + day + sec + "-" + text;
  return str;
}

export function fetchS3Img(getImgUrl, key) {
  // console.log('fetch s3 image called')
  const param = '/' + key;
  return fetch(getImgUrl + param, {
      mode: 'cors',
      headers: new Headers({
          'Access-Control-Allow-Origin': '*'
      }),
      method: 'GET',
  })
  .then(response => response.json())
  .then(responseJson => {
    return responseJson;
  })
  .catch(err => {
    return false;
  });
}

/**
 * retrieve static images from s3 bucket, return the base64 encoded data
 * @param {string} filename 
 * usage:
    fetchStaticImg('your_filename')
    .then(file => {
      if(!file)
      {
        // do something
      }
      // do something
    });
 */

export function fetchStaticImg(filename) {
  return fetch(`/v1/getS3StaticImages/${filename}`)
  .then(response => response.json())
  .then(base64data => {
    if(!base64data.success)
    {
      return false;
    }
    return base64data.data;
  });
}

/**
 * capitalize the first letter of a string
 * param {string} string
 * usage:
  var modifiedStr = capitalizeFirstLetter('example');
*/
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * convert the results (array) retrieved from MySQL database into an object with the certain column as index key
 * return a Promise
 * @param {string} nameOfTheKey 
 * usage:
  // each item in the array needs to contain the needle, such as "journal" as a needle:
  // ex: [
  //   {
  //     message_group: 'journal',
  //     message: 'hello, welcome to your journal'
  //   },
  //   {
  //     message_group: 'journal',
  //     message: 'Thank you for filling in the journal.'
  //   }
  // ]

  var message_key = 'journal';
  arrToObjWithColumnKey(message_key)
  .then(obj => {
    // do something
  });
 */
export function arrToObjWithColumnKey(arr, needle)
{
  var obj = {};
  var promiseArr = [];
  arr.forEach(item => {
    const loopPromise = new Promise((res) => {
      obj[item[needle]] = item;
      res();
    });
    promiseArr.push(loopPromise);
  });
  return Promise.all(promiseArr)
  .then(() => {
    return obj;
  });
}

export function parseUrlQuery(targetParam = '', promise = false) {
  const urlParams = new URLSearchParams(window.location.search);
  if(promise)
  {
    if(targetParam)
    {
      return urlParams.get(targetParam);
    }
    return new Promise(res => {
      return res(urlParams);
    });
  }
  if(targetParam)
  {
    return urlParams.get(targetParam);
  }
  return urlParams;
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const Base64ToImage = (img) => {
  // strip off the data: url prefix to get just the base64-encoded bytes
  const ext = img.includes('image/jpeg') ? '.jpeg' : '.png';
  var data = img.replace(/^data:image\/\w+;base64,/, "");
  var buf = new Buffer(data, 'base64');
  fs.writeFile(`image${ext}`, buf);
}

// export function String.format() {
//   var s = arguments[0];
//   for (var i = 0; i < arguments.length - 1; i++) {       
//     var reg = new RegExp("\\{" + i + "\\}", "gm");             
//     s = s.replace(reg, arguments[i + 1]);
//   }
//   return s;
// }