import React from 'react'
import GetImg from '../../assets/GetImg'
import tutorial from './tutorial'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
const About = props => {

    const dict = props.dict
    // console.log(props)
    const tutorialLang = dict['tutorial']

    let tutorialSections = [], tutorialObj = tutorial(tutorialLang)
    // console.log('test')
    Object.keys(tutorialObj).map((t, idx) => {
        // console.log(`map key ${t}`)
        tutorialSections.push(<div key={`tutorial-section-${idx}`} className='mt-2'>
            <SelectDecoration {...tutorialObj[t]} ditc={tutorialLang} id={t} />
        </div>)
    })

    return (<div className={props.display ? `about-panel top-right-about-panel writing-desk-about-panel col-sm-6 col-md-6 p-4` : `about-panel top-right-about-panel col-sm-6 col-md-6 hide p-4`}>
        <span style={{ position: "absolute", right: '1em', top: '1em', cursor: 'pointer' }} onClick={() => {
            props.triggerVideo()
            props.onClose()
        }}>
            <FontAwesomeIcon className='mh-paragraph-dark mr-2' icon={faVideo} />
            {dict['video']}
        </span>
        
        <h4 className=''>{dict['about']}</h4>
        <p className='m-0 p-0'>{dict['aboutIntroOne'].replace('[deceased]', props.deceased)}</p>
        {tutorialSections}
        <p></p>

        {/*<span className='about-panel-triangle'></span>*/}
    </div>)
}


const SelectDecoration = props => {
    // console.log(props)
    let paragraphs = [], figures = []
    if(props.hasOwnProperty('paragraph'))
    {
        props.paragraph.split('/n').map((p, idx) => {
            paragraphs.push(<p key={`about-${props.id}-description-${idx}`}>{p}</p>)
        })
    }

    if(props.hasOwnProperty('img'))
    {
        props.img.map((f, idx) => {
            figures.push(<GetImg
                key={`about-${props.id}-img-${idx}`}
                local // when the image is locally hosted
                className={['float-img']}
                onClick={() => null}
                imageUrl={f.url} // "name-of-the-img.jpg"
                srcset={f.srcset}
                alt={''}
            />)
        })
    }

    

    return <section className='about-section'>
        
        <div className='float-img-container'>
        {figures}
            {/*<GetImg
                local // when the image is locally hosted
                className={['float-img']}
                onClick={() => null}
                imageUrl={require('../../../../img/ms_intro_1_select-sm.png')} // "name-of-the-img.jpg"
                srcset={{
                    sm: require('../../../../img/ms_intro_1_select-sm.png'),
                    md: require('../../../../img/ms_intro_1_select-md.png'),
                    lg: require('../../../../img/ms_intro_1_select-lg.png'),
                    xl: require('../../../../img/ms_intro_1_select-xl.png')
                }}
                alt={''}
            />*/}
        </div>
        <div>
        <h6><strong>{props.title}</strong></h6>
        
        {paragraphs}
        </div>

    </section>
}

export default About