import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withPreLoader from './withPreLoader'
import Error from '../ui/Error'

// import { changeLang } from '../../actions/config'
// import { authUser, addLoginErr, addRegisterFeedback, signup, confirmSignUp, sendForgotPassCode } from '../../actions/index'
// import { defaultLang } from '../lang/init'

const mapStateToProps = (state) =>
    ({
        // dict: state.configReducer.lang.dict,
        // lang: state.configReducer.lang.lang,
        // auth: state.userReducer.auth,
        // accountInfo: state.userReducer.accountInfo
    })

const mapDispatchToProps = (dispatch) => 
    ({
        // changeLang: (lang = defaultLang) => dispatch(changeLang(lang)),
    })


const Container = connect(mapStateToProps, mapDispatchToProps)(Error)
export default withRouter(withPreLoader(Container))
