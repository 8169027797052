import React, { Component } from 'react'
import { RectBtnPlain } from './Buttons'
import { defaultVal } from '../../init/initApp'
/**
 * a floating panel to display system message when user triggers a certain activity
 * usage:
    import { SystemMessage } from './assets/systemMessage';
    <SystemMessage 
        messageGroupsKey=['to_search_message_groups_for_the_page']
        messageKeys=['your_message_key']
        messageObj = {
            key: 'message',
            key2: 'message2'
        }
    />
 */

class SystemMessage extends Component {
    // constructor(props)
    // {
    //     super(props);
    //     this.state = {
    //         active: false,
    //         msgObj: {},
    //         messageKeys: props.messageKeys
    //     }
    //     this.next = this.next.bind(this);
    //     this.displayMsg = this.displayMsg.bind(this);
    //     this.close = this.close.bind(this);
    // }
    constructor(props)
    {
        super(props);
        this.state = {
            active: false,
            msgObj: {},
            onClick: null,
            e: null
        }
        this.next = this.next.bind(this);
        this.displayMsg = this.displayMsg.bind(this);
        this.close = this.close.bind(this);
    }
    componentDidMount() {
        this.getAndRenderMessage(this.props.messageKeys);
    }

    getAndRenderMessage(keys){
        let msgObj = {}
        if(typeof keys[0] === 'string')
        {
            msgObj = this.props.messageObj.find((element) => {
                return element["message_key"] == keys[0];
            })
        }
        if(typeof keys[0] === 'object')
        {
            msgObj = this.props.messageObj.find((element) => {
                return element["message_key"] == keys[0]['message'];
            })
            // note the action
            this.setState({
                onClick: keys[0]['callback']
            })
        }
        if(msgObj){
            this.setState({
                msgObj: msgObj,
                active: true,
                messageKeys: keys,
            }, () => {
                this.displayMsg();
            });
        }
        else {
            return this.setState({
                active: false
            });
        }
    }

    next(e) {
        if(e.target.className.includes('system-message-text-container') || 
        e.target.className.includes('system-message-title') || e.target.className.includes('system-message-text')) return
        
        if(this.state.onClick !== null)
        {
            this.state.onClick(this.state.e)
            this.setState({
                onClick: null
            })
        }
        if(this.state.messageKeys && this.state.messageKeys.length > 0)
        {
            const newMessageKeys = this.state.messageKeys.splice(1, this.state.messageKeys.length - 1);
            // console.log("next");
            return this.setState({
                messageKeys: newMessageKeys,
            }, () => this.getAndRenderMessage(this.state.messageKeys));
        }
        return this.close()
    }
    displayMsg() {
        if(this.state.messageKeys.length > 0)
        {
            // const key = this.state.messageKeys[0];
            let msg =  this.state.msgObj.message.slice();
            let title =  this.state.msgObj.message_title.slice();
            return this.setState({
                active: true,
                currentMessage: msg || '',
                currentTitle: title || ''
            });
        }
        return this.close();
    }
    close() {
        return this.setState({
            messageKeys: [],
            active: false,
            currentMessage: '',
            currentTitle: ''
        });
    }
    render() {
        const systemMsgWrapperStyles = {
            position: 'absolute',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: `rgba(0,0,0,0.6)`,
            zIndex: 999,
        }
        const systemMsgImgContStyles = {
            // minWidth: '600px',
            // width: '50%',
        }
        if(!this.state.messageKeys || this.state.messageKeys.length <= 0) // the system message panel isn't active
        {
            return (
                <div></div>
            );
        }
        
        // should display messages to user
        return (
            <div style={systemMsgWrapperStyles} className={this.state.active ? 'system-message-wrapper active' : 'system-message-wrapper'} onClick={this.next}>
                <div style={systemMsgImgContStyles} className='system-message-img-container'>
                    <div className='system-message-text-container'>
                        <h3 className='system-message-title'>{this.state.currentTitle ? this.state.currentTitle : ''}</h3>
                        
                        {this.state.msgObj.hasOwnProperty('input') && this.state.msgObj.input ?
                            <textarea className='system-message-text system-message' type="text" rows="5" cols="50" onChange={e => {
                                    let newObj = {}
                                    newObj[this.state.msgObj.input] = e.target.value
                                    // this.props.onInput(newObj)
                                    this.setState({
                                        e: newObj
                                    })
                                // if(this.props.hasOwnProperty('onInput'))
                                // {
                                //     let newObj = {}
                                //     newObj[this.state.msgObj.input] = e.target.value
                                //     this.props.onInput(newObj)
                                // }
                                // this.setState(newObj)
                                // this.setState(e)
                            }}></textarea>    
                            :
                            <p className='system-message-text system-message'>{this.state.currentMessage ? this.state.currentMessage.replace('[deceased]', this.props.deceased ? this.props.deceased : defaultVal) : this.state.currentMessage}</p>}
                        <RectBtnPlain
                            btnValue='close'
                            btnTitle={this.state.msgObj.hasOwnProperty('confirm_button') ? this.state.msgObj.confirm_button :'OK'}
                            ctnStyle={{ backgroundColor: 'transparent' }}
                            btnStyle={{ marginTop: '20px', minWidth: '50%'}}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SystemMessage