import FetchWrapper from './FetchWrapper'
import GH from './GeneralHelper'
// import DB from './DatabaseHelper'
import config from '../../init/config'

const PG13Arr = require('../../../json/PG13.json')
const DemoArr = require('../../../json/Demo.json')
const CDDArr = require('../../../json/CDD.json')
const CSSRSArr = require('../../../json/CSSRS.json')
const Fetch = new FetchWrapper()

class SurveyHelper {
    constructor()
    {

    }

    async upsertSurvey(table, fieldData = {}, where) {
        // console.log("upsert survey called")
        const r = await Fetch.post('/survey/v2/upsertSurvey', {
            table,
            fieldData,
            where
        })
        .then(r => r.json()).then(response => {
            if(!response.success) throw response
            return response.results
        })
        .catch(e => e)
        
        return r
    }

    getQuestionnaire(surveyKey = config.surveys.PG13.surveyKey)
    {
        switch(surveyKey)
        {
            case config.surveys.CSSRS.surveyKey:
                // console.log('cssrs is called')
                return CSSRSArr
            case config.surveys.CDD.surveyKey:
                // console.log('cdd is called')
                return CDDArr
            case config.surveys.DEMO.surveyKey:
                // console.log('demo is called')
                return DemoArr
            case config.surveys.PG13.surveyKey:
            default:
                return PG13Arr
        }
    }

    getQuestionGivenQid(survey, qid = '')
    {
        // console.log('called')
        if(!survey && !qid) return false
        // console.log(`type of survey ${typeof survey}`)
        if(typeof survey === 'string')
        {
            let newSurvey = this.getQuestionnaire(survey)
            let qObj = {}
            const questions = newSurvey['questions']
            questions.forEach(question => {
                if(question.id === qid)
                {
                    qObj = question
                }
            })
            return qObj
        }
        // console.log('check point 2')
        let qObj = {}
        const questions = survey['questions']
        questions.forEach(question => {
            if(question.id === qid)
            {
                qObj = question
            }
        })
        return qObj
    }

    getAnswerGivenValue(survey, qid = '', value = 0)
    {
        // console.log('called 2')
        // console.log(survey)

        if(!survey && !qid && !value) return false
        const qObj = this.getQuestionGivenQid(survey, qid)
        // console.log(qObj)
        const options = qObj['options']
        let answer = ''
        options.forEach(option => {
            if(parseInt(option.value, 10) === parseInt(value, 10))
            {
                answer = option.display
            }
        })
        return answer
    }

    getOptionObjGivenValue(survey, qid = '', value = 0)
    {
        // console.log('called 2')
        // console.log(survey)

        if(!survey && !qid && !value) return false
        const qObj = this.getQuestionGivenQid(survey, qid)
        // console.log(qObj)
        const options = qObj['options']
        let answer = {}
        options.forEach(option => {
            if(parseInt(option.value, 10) === parseInt(value, 10))
            {
                answer = option
            }
        })
        return answer
    }

    async PG13Analysis(inputAnswers, stringify = false)
    {
        let answers = Object.assign({}, inputAnswers) // mutate the input answer
        try
        {
            let meetP13 = false, meetP1 = false, meetP2 = false
            let total = 0
            Object.keys(answers).forEach(key => {
                if(key === "PG-01" && parseInt(answers[key]["value"], 10) === 1)
                {
                    // console.log(answers[key])
                    // total += parseInt(answers[key]["answer"], 10)
                    meetP1 = true
                }
                if(key === 'PG-02' && parseInt(answers[key]['value'], 10) >= 12)
                {
                    meetP2 = true
                }
                if(key === "PG-13" && parseInt(answers[key]["value"], 10) === 1)
                {
                    // total += parseInt(answers[key]["answer"], 10)
                    meetP13 = true
                }
                if(key !== "PG-01" && key !== "PG-02" && key !== "PG-13")
                {
                    total += parseInt(answers[key]["value"], 10)
                }

            })

            if(meetP2 && meetP13 && total >= 30)
            {
                return {
                    total: total,
                    time: meetP2,
                    meet: true,
                    feedback: `
                    Your answers to the above questions indicate that you are reporting a clinically significant level of symptoms of Prolonged Grief Disorder. This tool is not intended to be a substitute for a clinical diagnosis. We encourage you to seek an evaluation and support from a mental health professional.
                    `
                }
            }
            if(!meetP2 && meetP13 && total >= 30)
            {
                return {
                    total: total,
                    time: meetP2,
                    meet: false,
                    feedback: `
                    Your answers to the above questions indicate that you have experienced a clinically significant threshold of grief, but that not enough time has elapsed and/or you are not impaired enough to meet the threshold for a Prolonged Grief Diagnosis. This tool is not intended to be a substitute for a clinical diagnosis, however, so we encourage you to seek evaluation and support from a mental health professional.
                    `
                }
            }
            return {
                total: total,
                time: meetP2,
                meet: false,
                feedback: `
                Your answers to the above questions indicate that you are not experiencing a clinically significant level of grief. This tool is not intended to be a substitute for a clinical diagnosis, however, so we encourage you to seek evaluation and support from a mental health professional.
                `
            }

        }
        catch(err)
        {
            return false
        }
    }

    async CSSRSAnalyze(inputAnswers)
    {
        // console.log("CSSRS analyze called")
        let ineligible = false, higherRisk = false, potentialSuicidal = false, suicidal = false
        let answers = Object.assign({}, inputAnswers) // mutate the input answer
        // console.log(answers)
        Object.keys(answers).map((k, idx) => {
            // console.log(k)
            if(answers.hasOwnProperty(k))
            {
                // console.log("analyze the answer")
                const targetA = answers[k]
                // console.log(answers[k])
                if(Object.keys(targetA).length > 0)
                {
                    const v = targetA['value'].toString()
                    if(k === 'CSSRS-01' && targetA['value'] === "1")
                    {
                        higherRisk = true
                        potentialSuicidal = true
                    }
                    if(k === 'CSSRS-01b' && targetA['value'] === "1")
                    {
                        ineligible = true
                        higherRisk = true
                        potentialSuicidal = true
                        suicidal = true
                    }
                    if(k === 'CSSRS-01c' && targetA['value'] === "1")
                    {
                        ineligible = true
                        higherRisk = true
                        potentialSuicidal = true
                        suicidal = true
                    }
    
                    if(k === 'CSSRS-02' && targetA['value'] === "1")
                    {
                        higherRisk = true
                        potentialSuicidal = true
                        suicidal = true
                    }
                    if(k === 'CSSRS-02b' && targetA['value'] === "1")
                    {
                        ineligible = true
                        higherRisk = true
                        potentialSuicidal = true
                        suicidal = true
                    }
                    if(k === 'CSSRS-02c' && targetA['value'] === "1")
                    {
                        ineligible = true
                        higherRisk = true
                        potentialSuicidal = true
                        suicidal = true
                    }
    
                    if(k === 'CSSRS-03' && targetA['value'] === "1")
                    {
                        higherRisk = true
                        potentialSuicidal = true
                        suicidal = true
                    }
                    if(k === 'CSSRS-03a' && targetA['value'] === "1")
                    {
                        ineligible = true
                        higherRisk = true
                        potentialSuicidal = true
                        suicidal = true
                    }
    
                    /* EMERGENCY CONTACT section is optional now
                    if(k === 'CSSRS-04' && v === "2")
                    {
                        // console.log("user is not under care")
                        ineligible = true
                    }
                    if(k === 'CSSRS-05' && v === "2")
                    {
                        // console.log("user is unwilling to provide emergency contact")

                        ineligible = true
                    }
                    if(k === 'CSSRS-06' && v === "2")
                    {
                        ineligible = true
                    }
                    */
                }
            }
        })

        return {
            ineligible,
            higherRisk,
            potentialSuicidal,
            suicidal
        }
    }

    checkComplete(surveyArr, inputAnswer = null)
    {
        
        let requiredAnswers = [], unansweredQuestions = []
        // console.log(surveyArr)
        // console.log(inputAnswer)
        surveyArr.forEach(survey => {
            if(survey.hasOwnProperty('required') && survey.required)
            {
                requiredAnswers.push(survey.id)
            }
        })
        // console.log(requiredAnswers)
        if(inputAnswer === null)
        {
            return requiredAnswers
        }
        requiredAnswers.forEach((a, idx) => {
            if(!inputAnswer.hasOwnProperty(a) || inputAnswer[a] === null || (typeof inputAnswer[a] === 'object' && Object.keys(inputAnswer[a]).length <= 0) || (typeof inputAnswer[a] === 'string' && inputAnswer[a] === '') )
            {
                unansweredQuestions.push(a)
            }
        })
        // console.log(unansweredQuestions)
        return unansweredQuestions
    }

    /**
     * fetch the survey entries from database
     * @param {int} cursor 
     */
    async fetchSurveyEntries(key = 'PG13', cursor = 0)
    {
        try
        {
            const fetchResult = await Fetch.get('/survey/v2/getSurveyEntriesGivenSurveyKey', [key, cursor])
            .then(r => r.json()).then(r => r).catch(e => e)
            if(!fetchResult?.success)
            {
                throw fetchResult?.error
            }
            return fetchResult?.data || []
        }
        catch(e)
        {
            return []
        }
        // console.log(fetchResult)
    }

    // ======================================
    //             DEPRECATED
    // ======================================
/*
    sortScore(inputObj, desc = true)
    {
        let sorted = []
        try
        {
            Object.keys(inputObj).map(key => {
                sorted.push([key, inputObj[key]])
            })
            if(desc)
            {
                return sorted.sort((a, b) => b[1]-a[1])
            }
            return sorted.sort((a, b) => a[1]-b[1])
        }
        catch(err)
        {
            return false
        }
    }
    // async updateSurveyAnswers(uid, qid, submissionHash, answers)
    // {
    //     // console.log(answers)
    //     const ip = await GH.getIp()
    //     const newAnswer = this.stringifySurveyObj(answers)
    //     let data = {
    //         ip_address: ip,
    //         submission_hash: submissionHash,
    //         ...newAnswer
    //     }

    //     try
    //     {
    //         // const update = await DB.upsert(qid, data, {
    //         //     user_id: uid,
    //         //     submission_hash: submissionHash
    //         // })
    //         const update = await Fetch.post('/survey/v1/upsertSurvey', {
    //                 table: qid,
    //                 uid: uid,
    //                 params: data
    //             }, (err, result) => {
    //                 if(err)
    //                 {
    //                     // console.log(err)
    //                     return err
    //                 }
    //                 return result
    //             })
    //             .then(r => r.json())
    //             .then(r => {
    //                 if(r.success)
    //                 {
    //                     return r
    //                 }
    //                 throw r
    //             })
    //             .catch(e => {
    //                 return e
    //             })
    //         // console.log(update)
    //         if(update.success && update.data.length > 0)
    //         {
    //             return update.data[0]
    //         }
    //         throw update
    //     }
    //     catch(e)
    //     {
    //         return e
    //     }

    //     // console.log(data)
    //     // return Fetch.post('/survey/v1/upsertSurvey', {
    //     //     table: qid,
    //     //     uid: uid,
    //     //     params: data
    //     // }, (err, result) => {
    //     //     if(err)
    //     //     {
    //     //         // console.log(err)
    //     //         return err
    //     //     }
    //     //     return result
    //     // })
    // }

    // removeNullValues(surveyObj) {
    //     let newObj = {}
    //     Object.keys(surveyObj).map(key => {
    //         if(key !== 'created_at' && key !== 'last_edited' && surveyObj[key] !== null)
    //         {
    //             newObj[key] = surveyObj[key]
    //         }
    //     })
    //     return newObj
    // }

    //return true if there are null values
    // checkNullColumns(surveyObj) {
    //     // console.log(`called`)

    //     if(Object.keys(surveyObj).length <= 0) return true
    //     let nullValues = 0
    //     Object.keys(surveyObj).map(k => {
    //         if(surveyObj[k] === null) nullValues += 1
    //     })
    //     const hasNullValues = nullValues > 0 ? true : false
    //     // console.log(`has null value ${hasNullValues}`)
    //     return hasNullValues
    // }

    // stringifySurveyObj(surveyObj) {
    //     let newObj = {}
    //     Object.keys(surveyObj).map(key => {
    //         if(surveyObj[key].hasOwnProperty("id"))
    //         {
    //             newObj[key] = JSON.stringify(surveyObj[key])
    //         }
    //         else
    //         {
    //             newObj[key] = surveyObj[key]
    //         }
    //     })
    //     return newObj
    // }

    // async storeSurveyAnswers(uid, qid, answers)
    // {
    //     const ip = await GH.getIp()
    //     const hash = await GH.hash(10)
    //     let data = {
    //         ip_address: ip,
    //         submission_hash: hash,
    //         ...answers
    //     }
    //     // console.log(data)
    //     return Fetch.post('/survey/v1/upsertSurvey', {
    //         table: qid,
    //         uid: uid,
    //         params: data
    //     }, (err, result) => {
    //         if(err)
    //         {
    //             // console.log(err)
    //             return err
    //         }
    //         return result
    //     })
    // }

    // async parseSurveyAnswers(retrievedSurveyAnswers, answerKey)
    // {
    //     // console.log(retrievedSurveyAnswers)
    //     let newAnswerObj = {}
    //     try
    //     {
    //         await Object.keys(retrievedSurveyAnswers).map(key => {
    //             if(key.includes(answerKey))
    //             {
    //                 const answerObj = JSON.parse(retrievedSurveyAnswers[key])
    //                 newAnswerObj[key] = answerObj
    //             }
    //         })

    //         return newAnswerObj
    //     }
    //     catch(err)
    //     {
    //         return false
    //     }
    // }

    // async getSurveyGivenContactKey(qid, uid, contactKey)
    // {
    //     try
    //     {
    //         const result = await DB.upsert(qid, {}, {
    //             user_id: uid,
    //             contact_person_key: contactKey
    //         })
    //         if(!result.success || !result ) throw result
    //         if(result.data.length <= 0) return []
    //         return result.data
    //     }
    //     catch(e)
    //     {
    //         return e
    //     }
    // }

    // getLastSurvey(qid, uid)
    // {
    //     return Fetch.post('/survey/v1/getLastSurveyEntry', {
    //         qid: qid,
    //         uid: uid
    //     }, (err, result) => {
    //         if(err)
    //         {
    //             return false
    //         }
    //         return result.data
    //     })
    // }
    async BCIAnalysis(inputAnswers, stringify = false)
    {
        let answers = Object.assign({}, inputAnswers) // mutate the input answer
        if(inputAnswers.hasOwnProperty('submission_hash') || inputAnswers.hasOwnProperty('ip_address') 
            || inputAnswers.hasOwnProperty('created_at') || inputAnswers.hasOwnProperty('id'))
        {
            answers = await this.parseSurveyAnswers(inputAnswers, 'BCI')
        }

        try
        {
            let connectingWithOthers = 0, change = 0, imaginingHopefulFuture = 0, acceptingTheLoss = 0, guilt = 0
            // let connectingItems = ["BCI-05", "BCI-10", "BCI-11", "BCI-12", "BCI-13", "BCI-16", "BCI-27", "BCI-29", "BCI-30", "BCI-32"], 
            // changeItems = ["BCI-03", "BCI-04", "BCI-08", "BCI-14", "BCI-15", "BCI-20", "BCI-21", "BCI-22"], 
            // imagineItems = ["BCI-01", "BCI-02", "BCI-06", "BCI-07", "BCI-09"], 
            // acceptingItems = ["BCI-17", "BCI-18", "BCI-19"], 
            // guiltItems = ["BCI-24", "BCI-25", "BCI-26", "BCI-28", "BCI-34"]
            Object.keys(answers).forEach(key => {
                const q = this.getQuestionGivenQid(BCIArr, key)
                const a = answers[key]
                const score = parseInt(a.answer, 10)
                switch(q.category)
                {
                    case "Challenges with Connecting with Others":
                        connectingWithOthers += score
                        // connectingItems.push(key)
                        break
                    case "Challenges with Change":
                        change += score
                        // changeItems.push(key)
                        break
                    case "Challenges Imagining a Hopeful Future":
                        imaginingHopefulFuture += score
                        // imagineItems.push(key)
                        break
                    case "Challenges with Accepting the Loss":
                        acceptingTheLoss += score
                        // acceptingItems.push(key)
                        break
                    case "Challenges with guilt":
                        guilt += score
                        // guiltItems.push(key)
                        break
                }
            })

            if(stringify)
            {
                return `The scores for Connecting with Others: ${connectingWithOthers}, </br> 
                The score for Change: ${change}, </br>, 
                The score for Imagining a Hopeful Future: ${imaginingHopefulFuture}, </br>
                The score for Accepting the Loss: ${acceptingTheLoss}, </br>
                The score for Guilt: ${guilt}`
            }
            return {
                connecting: connectingWithOthers,
                change: change,
                imagining: imaginingHopefulFuture,
                accepting: acceptingTheLoss,
                guilt: guilt
            }
        }
        catch(err)
        {
            return false
        }
    }
*/

}

export default new SurveyHelper()