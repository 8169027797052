import React,{useState,useEffect}  from 'react'
import welcomeImages from './welcomeImages'

const PreloadImages =props => {

    let loadedImage=0
    let totalImages=[]

    const [progressPercent, updateprogressPercent] = useState(0)
    
    //on mount
    useEffect(()=>{
        // console.log('preloader called')
        // Object.keys(defaultOffering).map((k, idx) => {
        //     totalImages.push(defaultOffering[k]['url'])
        // })
        if(props.isMounted)
        {
            welcomeImages.forEach((img) => {
                totalImages.push(img)
            })
            fetchImages(totalImages);
        }

    },[]); 


   const fetchImages=(imageArray)=>{
        imageArray.forEach(eachImage => {
            fetchImage(eachImage);
        });
    }


    const checkComplete= (percent)=>{
        // console.log(`loaded ${percent}`)
        if(percent>=100)
        {
            // console.log('completed')
            if(props.hasOwnProperty('loadComplete'))
            {
               props.loadComplete();
            }
            // else do nothing
        }
    }

    const fetchImage = (pathToResource) => {
        // console.log(pathToResource)
        fetch(pathToResource)
            .then(validateResponse)
            .then(readResponseAsBlob)
            .then(showImage)
    }

    const readResponseAsBlob= (response) => {
        // console.log(response)
        return response.blob();
    }
    
    const showImage = (responseAsBlob) =>{
        let imgUrl = URL.createObjectURL(responseAsBlob);
        let tempImg= new Image();
        tempImg.onload = (e)=>{
            updateProgress();
        }
        tempImg.onerror= (e)=>{
            //temp: update the stupid thing anyway, else we are stuck at the loading bar when a single image fails
            updateProgress();
        }
        tempImg.src = imgUrl;
    }
    
    const validateResponse =(response)=>{
        if (!response.ok) {
            //throw Error(response.statusText);
            console.error(response.statusText)
        }
        if (response.status !== 200) {
            //throw Error('File does not exist');
            console.error('File does not exist')
        }
        return response;
    }
    

    const updateProgress =()=>{
       loadedImage=loadedImage+1;
       updateprogressPercent(Math.ceil((loadedImage/totalImages.length)*100))
       checkComplete(Math.ceil((loadedImage/totalImages.length)*100))
    }

    //run check complete once
    if(progressPercent < 100)
    {
        const progressInstance = <div className="progress offering-progressbar">
            <div className="progress-bar progress-bar-animated bg-info" role="progressbar" style={{ width: `${progressPercent}%` }} aria-valuenow={progressPercent.toString()} aria-valuemin="0" aria-valuemax="100"></div>
        </div>;
        return(progressInstance)
    }
    return null
}

export default PreloadImages;