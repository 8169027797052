import React, { useState, useEffect, createRef } from 'react'
// import { ReactTabulator } from 'react-tabulator'
import Tabulator from 'tabulator-tables'
// import 'react-tabulator/lib/styles.css'; // required styles
import 'tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css'; // theme
// import 'tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css';
import JournalHelper from '../../classes/v2/JournalHelper'
import DropDown from '../../assets/v2/DropDown';
import './AdminBoard.scss'
import SystemTimeManage from '../../classes/v2/SystemTimeManage';
import GeneralHelper from '../../classes/v2/GeneralHelper';

const JournalEntryData = ({ redcapIds, lang }) => {
    const [REDCapId, updateREDCapId] = useState('')
    const [data, updateData] = useState([])
    const [feedback, updateFeedback] = useState("")
    // console.log(`feedback ${feedback}`)
    console.log("redcap Ids", redcapIds)
    
    const retrieveJournalEntry = async (input = null) =>
    {
        console.log(`input is ${input}`)
        try
        {
            if(input=== 'All')
            {
                return fetchAllJournalEntries()
            }
            const r = await JournalHelper.getJournalEntriesGivenREDCapId(input.trim())
            if(r.length > 0)
            {
                console.log(r)
                updateFeedback("")
                let newEntries = []
                const loop = await GeneralHelper.asyncForEach(r, async (entry, idx) => {
                    let newObj = {...entry}
                    console.log(`key is ${newObj['question_key']}`)
                    const question = JournalHelper.getNarrativeNoteQuestionsGivenCatNKey(newObj['question_day_key'],newObj['question_key'],lang)
                    const type = JournalHelper.determineTypeOfQuestion(newObj['question_day_key'],newObj['question_key'],lang)
                    newObj['type'] = type
                    newObj['question_content'] = question
                    newEntries.push(newObj)
                })

                return updateData(newEntries)
            }
            updateData([])
            return updateFeedback("Could not find the user data of REDCap ID "+REDCapId+", please double check your input")

        }
        catch(e)
        {
            return updateFeedback("Could not find the user data of REDCap ID "+REDCapId+", please double check your input")
            
        }

    }

    const fetchAllJournalEntries = async () => {
        // const targetQ = JournalHelper.getNarrativeNoteQuestionsGivenCatNKey('day1', 'q1', lang)
        // console.log(`target q ${targetQ}`)
        // console.log("test fetching")
        try
        {
            let allEntries = await JournalHelper.retrieveAllJournalEntries()
            console.log("result ", allEntries)
            let newEntries = []
            const loop = await GeneralHelper.asyncForEach(allEntries, async (entry, idx) => {
                let newObj = {...entry}
                const question = JournalHelper.getNarrativeNoteQuestionsGivenCatNKey(newObj['question_day_key'],newObj['question_key'],lang)
                const type = JournalHelper.determineTypeOfQuestion(newObj['question_day_key'],newObj['question_key'],lang)
                newObj['type'] = type
                newObj['question_content'] = question
                newEntries.push(newObj)
            })
            updateData(newEntries)
        }
        catch(e)
        {
            console.log("Error ", e)
        }

    }

    useEffect(() => {
        fetchAllJournalEntries()
    }, [])
    let newRedcapIds = []
    if(redcapIds && redcapIds.length > 0)
    {
        newRedcapIds = ['All', ...redcapIds]
    }
    return (<React.Fragment>
        {/*<SurveyQueryInput updateSurveyData={async (input) => {
            // query data using REDCap ID
            await updateREDCapId(input.trim())
            await retrieveJournalEntry(input.trim())
        }} />*/}
        <div className='col-sm-12'>
            <DropdownSelect inputArr={newRedcapIds} onSelect={retrieveJournalEntry} />
        </div>
        
        <div className='col-sm-12'>
            <p className="white-font">{feedback}</p>
        </div>
        {<SurveyDataTable lang={lang} data={data} REDCapId={REDCapId} />}

    </React.Fragment>)
}

const DropdownSelect = ({ onSelect, inputArr }) => {
    if(!inputArr) return null
    let optionsArr = []
    inputArr.forEach(id => {
        optionsArr.push({
            href:"#",
            title: id,
            handleOptionClick: () => onSelect(id)
        })
    })

    return <DropDown
        id='redcap-id' // *
        title='Choose the redcap ID to retrieve entries or all to retrieve all' // *
        options={optionsArr}  // *
    />
}

const SurveyQueryInput = ({ updateSurveyData }) => {
    const [REDCapId, updateREDCapId] = useState('')

    return (<div className='col-sm-12 d-flex justify-content-center'>
        <label htmlFor="redcap-id-input" className="white-font">Enter a REDCap ID to retrieve the data:
            <input className='ml-2 ' id="redcap-id-input" onChange={e => updateREDCapId(e.target.value)} />
        </label>
        <button className="lmh-primary-btn ml-2" onClick={() => updateSurveyData(REDCapId)}>Submit</button>
    </div>)
}

const SurveyDataTable = ({ data, REDCapId, lang }) => {
    // console.log(data)
    const _table = createRef()
    let tableRef = null
    const title = 'LMH_journal_entry'
    const SheetTitle = 'LML Entry Data '+REDCapId

    if(data.length > 0)
    {
        // console.log("should update data")
        let columns = [                 //define the table columns
            // {title:"ID", field:"id", headerFilter:"input"},
            {title: "REDCap ID", field: "redcap_id", headerFilter:"input"},
            {title: "Journal Day", field: "journal_day", headerFilter:"input"},
            // {title: "Question Type", field: "question_day_key", headerFilter: "input", formatter: (cell, formatterParams, onRendered) => {
            //     const typeOfCKey = cell.getValue()
            //     const typeOfQKey = cell.getRow().getData().question_key
            //     const q = JournalHelper.determineTypeOfQuestion(typeOfCKey, typeOfQKey, lang)
            //     return q
            // }},
            // {title: "Question", field: "question_day_key", headerFilter: "input", width:"32px", formatter: (cell, formatterParams, onRendered) => {
            //     const typeOfCKey = cell.getValue()
            //     const typeOfQKey = cell.getRow().getData().question_key
            //     const q = JournalHelper.getNarrativeNoteQuestionsGivenCatNKey(typeOfCKey, typeOfQKey, lang)
            //     return q
            // }},
            {title: "Question Type", field: "type", headerFilter: "input"},
            {title: "Question", field: "question_content", headerFilter: "input", width:"32px"},
            // {title: "Question Category", field: "question_day_key", headerFilter:"input"},
            // {title: "Question Key", field: "question_key", headerFilter:"input"},
            {title:"Time", field:"created_at", sorter:"date", hozAlign:"center", headerFilter:"input", formatter: (cell, formatterParams, onRendered) => {
                const t = cell.getValue()
                const format = SystemTimeManage.formatLocalTime(t)
                return format
            }},
            {title: "Entry", field: "journal_entry", headerFilter:"input", formatter:"textarea", width: 500}
        ]
        const options = {
            tooltipGenerationMode:"hover", //regenerate tooltip as users mouse enters the cell;
            tooltipsHeader:true, //enable header tooltips
            // responsiveLayout: true,  //hide columns that dont fit on the table
            resizableColumns: true,
            tooltips:true,            //show tool tips on cells
            pagination:"local",       //paginate the data
            paginationSize:10,         //allow 7 rows per page of data
            headerFilter:"input",
            layout:"fitData",
    
            // autoColumns:true,
            // layout:"fitColumns",      //fit columns to width of table
            // movableColumns:true,      //allow column order to be changed
            // resizableRows:true,       //allow row order to be changed
            // initialSort:[             //set the initial sort order of the data
            //     {column:"id", dir:"asc"},
            // ],
        }
    
        tableRef = new Tabulator("#lmh-table", {
            data:data,
            columns:columns,
            ...options
        })
    }
    else
    {
        let columns = [                 //define the table columns
            {title:"ID", field:"id", headerFilter:"input"},
            {title: "REDCap ID", field: "redcap_id", headerFilter:"input"},
            {title: "Journal Day", field: "journal_day", headerFilter:"input"},
            {title: "type", field: "question_day_key", headerFilter:"input"},
            {title:"Time", field:"created_at", sorter:"date", hozAlign:"center", headerFilter:"input"},
            {title: "Entry", field: "journal_entry", headerFilter:"input", formatter:"textarea", width: 500}
        ]
        const options = {
            tooltipGenerationMode:"hover", //regenerate tooltip as users mouse enters the cell;
            tooltipsHeader:true, //enable header tooltips
            // responsiveLayout: true,  //hide columns that dont fit on the table
            resizableColumns: true,
            tooltips:true,            //show tool tips on cells
            pagination:"local",       //paginate the data
            paginationSize:10,         //allow 7 rows per page of data
            headerFilter:"input",
            layout:"fitData",
    
            // autoColumns:true,
            // layout:"fitColumns",      //fit columns to width of table
            // movableColumns:true,      //allow column order to be changed
            // resizableRows:true,       //allow row order to be changed
            // initialSort:[             //set the initial sort order of the data
            //     {column:"id", dir:"asc"},
            // ],
        }
    
        tableRef = new Tabulator("#lmh-table", {
            data:data,
            columns:columns,
            ...options
        })
    }

    
    return <React.Fragment>
        
        {data.length > 0 ?<div className='my-2'>
        <div className='col-xs-12'>
        <button id='download-csv' className='lmh-primary-btn' onClick={() => {
            if(tableRef)
            {
                tableRef.download("csv", `${title}.csv`)
            }
        }}>Download CSV</button>
        <button id='download-xlsx' className='lmh-primary-btn ml-2' onClick={() => {
            if(tableRef)
            {
                tableRef.download("xlsx", `${title}.xlsx`, {sheetName:SheetTitle})
            }
        }}>Download XLSX</button>
        </div>
        </div>
        :null}

        <div ref={_table} className='' id='lmh-table'>
        {/*<ReactTabulator data={newDataArr} columns={columns} options={options} />*/}
        
        </div>
    </React.Fragment>
}

export default JournalEntryData