import { postMessage } from '../../init/general'

const assets = {
    diaryClose: {
        originKey: 'diaryClose',
        url: require('../../../img/diary4-md.png'),
        widthUnitRatio: 0.8, 
        heightFilling: 3, 
        padding: 2,
        startX: 3.7,
        // get startX() { return BOTTOM_RIGHT_START_X_2 - PADDING * 6; },
        // get width() { return WIDTH; } ,
        // get height() { return HEIGHT; },
        // get widthUnit() { return WIDTH_UNIT * 1.2; },
        // get heightFilling() { return 0 * PADDING } ,
        // get padding() { return 0 * PADDING },
        // img: null,
        callback: 'openDiary',
    },
    diaryOpen: {
        originKey: 'diaryOpen',
        url: require('../../../img/diary4-md.png'),
        widthUnitRatio: 0.8,
        heightFilling: 3, 
        padding: 2,
        startX: 3.4,
        // get startX() { return BOTTOM_RIGHT_START_X_1 - PADDING * 6; },
        // get width() { return WIDTH; },
        // get height() { return HEIGHT; },
        // get widthUnit() { return WIDTH_UNIT * 2; },
        // get heightFilling() { return PADDING * 0; },
        // get padding() { return PADDING * 2; },
        callback: e => null,
    },
    candleLight: {
        originKey: 'candleLight',
        url: require('../../../img/candle_light_watercolor-md.png'),
        widthUnitRatio: 2.5, 
        heightFilling: -3, 
        padding: 0,
        // get widthUnit() { return WIDTH_UNIT / 2.5; },
        // get heightFilling() { return PADDING * -3; },
        // get padding() { return PADDING * 0 },
        callback: 'removeOffering'
    },
    candleDim: {
        originKey: 'candleDim',
        url: require('../../../img/candle_dim_watercolor-md.png'),
        widthUnitRatio: 2.5, 
        heightFilling: -3, 
        padding: 0,
        callback: e => null,
    },
    photoFrameH: {
        originKey: 'photoFrameH',
        url: require('../../../img/photo_frame_h-md.png'),
        widthUnitRatio: 1.4, 
        heightFilling: 0, 
        padding: 6,
        callback: 'uploadPhoto'
    },
    photoFrameV: {
        originKey: 'photoFrameV',
        url: require('../../../img/photo_frame_v-md.png'),
        widthUnitRatio: 1.8, 
        heightFilling: 0, 
        padding: 6,
        callback: 'uploadPhoto'
    },
    flowerLily: {
        originKey: 'flowerLily',
        url: require('../../../img/lily-md.png'),
        widthUnitRatio: 1.4, 
        heightFilling: -3, 
        padding: 0,
        callback: 'removeOffering'
    },
    flowerHydrangea: {
        originKey: 'flowerHydrangea',
        url: require('../../../img/hydrangea-md.png'),
        widthUnitRatio: 1.4, 
        heightFilling: -3, 
        padding: 0,
        callback: 'removeOffering'
    },
    flowerRose: {
        originKey: 'flowerRose',
        url: require('../../../img/rose-md.png'),
        widthUnitRatio: 1.4, 
        heightFilling: -3, 
        padding: 0,
        callback: 'removeOffering'
    },
    flowerChrysan: {
        originKey: 'flowerChrysan',
        url: require('../../../img/chrysanthemums-md.png'),
        widthUnitRatio: 1.4, 
        heightFilling: -3, 
        padding: 0,
        callback: 'removeOffering'
    },
    flowerOrchid: {
        originKey: 'flowerOrchid',
        url: require('../../../img/orchid-md.png'),
        widthUnitRatio: 1.4, 
        heightFilling: -3, 
        padding: 0,
        callback: 'removeOffering'
    },
    flowerVase: {
        originKey: 'flowerVase',
        url: require('../../../img/vase-md.png'),
        widthUnitRatio: 1.4, 
        heightFilling: -3, 
        padding: 0,
        callback: 'removeOffering'
    }
}

export default assets